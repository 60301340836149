import { FC, useContext } from 'react';
import { ModalContext, useModalManageData } from 'ui/Modal';
import { rewardStatusMap, rewardTariffMap } from 'src/const';
import {
  BoldSumWithWarningIconRowByCompare,
  DataCard,
} from 'commonComponents/DataCard';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { getAffiliateInfo, getRewardStatusIcon } from '../../helpers';
import { useGetRewardItem } from './queries/generated/GetRewardItem';
import { getDataCardAffiliateTitles } from './helpers';
import { UpdateRewardButtons } from '../UpdateRewardButtons';
import { RewardModalSteps } from '../../const';
import { AcceptRewardForm } from '../AcceptRewardForm';
import { useChangeModalHeader } from './hooks';

interface RewardItemProps {
  rewardId: string;
}

export const RewardItem: FC<RewardItemProps> = ({ rewardId }) => {
  const { state } = useContext(ModalContext);

  const { data, loading, error } = useGetRewardItem({
    variables: {
      id: rewardId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-reward-error' },
    hasData: !!data,
  });

  useChangeModalHeader({ rewardId, affiliate: data?.reward.affiliate });

  if (!data) {
    return null;
  }

  const { createdAt, sum, paidSum, status, updatedAt, affiliate, tariffType } =
    data.reward;

  const { email, name } = getAffiliateInfo(affiliate);

  const { email: affiliateEmailTitle, name: affiliateNameTitle } =
    getDataCardAffiliateTitles(affiliate);

  if (state.step === RewardModalSteps.ConfirmationStep) {
    return (
      <AcceptRewardForm
        sum={sum}
        affiliateId={affiliate.id}
        affiliateName={affiliate.name}
        rewardId={rewardId}
      />
    );
  }

  return (
    <DataCard
      statusConfig={{
        status: rewardStatusMap[status],
        iconConfig: getRewardStatusIcon(status),
      }}
      rows={[
        {
          title: affiliateNameTitle,
          value: name,
        },
        {
          title: affiliateEmailTitle,
          value: email,
        },
        {
          title: 'Модель оплаты',
          value: rewardTariffMap[tariffType],
        },
        {
          title: 'Сумма',
          value: formatSumWithCurrency(sum.amount, sum.currency),
        },
        !!paidSum && {
          title: 'Фактическая сумма',
          value: (
            <BoldSumWithWarningIconRowByCompare
              comparedSum={sum}
              renderedSum={paidSum}
            />
          ),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
        updatedAt && {
          title: 'Обновлен',
          value: formatDate(updatedAt, DateFormat.FullDate),
        },
      ]}
      customFooter={
        <UpdateRewardButtons status={status} sum={sum} rewardId={rewardId} />
      }
    />
  );
};
