import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PartnerReferralLinkMediaItemDetailsData } from './PartnerReferralLinkMediaItemDetailsData';
import { AffiliateReferralLinkMediaItemRewardTariffData } from '../../../../../../../../queries/generated/AffiliateReferralLinkMediaItemRewardTariffData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnerReferralLinkMediaItemVariables = SchemaTypes.Exact<{
  partnerId: SchemaTypes.Scalars['ID']['input'];
  referralLinkMediaItemId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPartnerReferralLinkMediaItem = { __typename: 'Query', partner: { __typename: 'Partner', id: string, referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem', createdAt: string, id: string, name: string, referralLink: string, mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string }, mediaItemRewardTariff: { __typename: 'MediaItemCpaRewardTariff', fallbackRewardTariff: { __typename: 'RevShareRewardTariff', id: string, title: string }, rewardTariff: { __typename: 'CpaRewardTariff', countries: Array<any>, id: string, title: string, baselineSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, rewardSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } | { __typename: 'MediaItemRevShareRewardTariff', rewardTariff: { __typename: 'RevShareRewardTariff', id: string, title: string } } } } };


export const GetPartnerReferralLinkMediaItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPartnerReferralLinkMediaItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"partnerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"referralLinkMediaItemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"partnerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"referralLinkMediaItemId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PartnerReferralLinkMediaItemDetailsData"}}]}}]}}]}},...PartnerReferralLinkMediaItemDetailsData.definitions,...AffiliateReferralLinkMediaItemRewardTariffData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPartnerReferralLinkMediaItem__
 *
 * To run a query within a React component, call `useGetPartnerReferralLinkMediaItem` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerReferralLinkMediaItem` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerReferralLinkMediaItem({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      referralLinkMediaItemId: // value for 'referralLinkMediaItemId'
 *   },
 * });
 */
export function useGetPartnerReferralLinkMediaItem(baseOptions: ApolloReactHooks.QueryHookOptions<GetPartnerReferralLinkMediaItem, GetPartnerReferralLinkMediaItemVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPartnerReferralLinkMediaItem, GetPartnerReferralLinkMediaItemVariables>(GetPartnerReferralLinkMediaItemDocument, options);
      }
export function useGetPartnerReferralLinkMediaItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPartnerReferralLinkMediaItem, GetPartnerReferralLinkMediaItemVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPartnerReferralLinkMediaItem, GetPartnerReferralLinkMediaItemVariables>(GetPartnerReferralLinkMediaItemDocument, options);
        }
export type GetPartnerReferralLinkMediaItemHookResult = ReturnType<typeof useGetPartnerReferralLinkMediaItem>;
export type GetPartnerReferralLinkMediaItemLazyQueryHookResult = ReturnType<typeof useGetPartnerReferralLinkMediaItemLazyQuery>;
export type GetPartnerReferralLinkMediaItemQueryResult = Apollo.QueryResult<GetPartnerReferralLinkMediaItem, GetPartnerReferralLinkMediaItemVariables>;