import { FC, FocusEvent } from 'react';
import { InputSum } from '../InputSum/InputSum';
import { CommonProps, RangeZeroToFifteen } from '../types';

interface InputNumberProps extends CommonProps {
  placeholder?: string;
  invalidChars?: Array<string>;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  customMaxLength?: RangeZeroToFifteen;
  decimalScale?: number;
}

export const InputNumber: FC<InputNumberProps> = ({ ...rest }) => (
  <InputSum {...rest} />
);
