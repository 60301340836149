import { useCallback, useContext, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import Block from 'ui/Block';
import BlockTable from 'ui/Block/BlockTable';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import { BlockContext } from 'ui/Block/store';
import { nbsp } from 'src/const';
import { TableFilter } from 'ui/TableFilter';
import { ColumnDef } from '@tanstack/react-table';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetWithdrawalsByTableSize } from '../../hooks/useGetWithdrawalsByTableSize';
import { useWithdrawalOpenModal } from '../../hooks/useWithdrawalOpenModal';
import styles from './WithdrawalTable.module.scss';
import { useGetWithdrawalFilter } from './hooks/useGetWithdrawalFilter';
import { allWithdrawalFilters } from './const';

const countPerPage = 10;

interface WithdrawalsTableProps<T extends object> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

const WithdrawalsTable = <T extends { __typename: string }>({
  playerId,
  columns,
  shouldReturnToPrevPage,
  routeToCustomPage,
  isFullScreenTable,
}: WithdrawalsTableProps<T>) => {
  const { openWithdrawal } = useWithdrawalOpenModal<T>(playerId);
  const { state } = useContext(BlockContext);
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const { loadWithdrawals, data, loading, error, fetchMore, refetch } =
    useGetWithdrawalsByTableSize(playerId, isFullScreenTable);

  const withdrawals = data?.player.withdrawals;
  const withdrawalsList =
    withdrawals &&
    (withdrawals.edges.map(({ node }) => node) as unknown as Array<T>);
  const hasNextPage = data?.player.withdrawals.pageInfo.hasNextPage;

  useBlockComponentState({
    loadData: loadWithdrawals,
    loading,
    error,
  });

  const refetchWithdrawals = () => {
    refetch?.();
  };

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            playerId,
            first: countPerPage,
            after: withdrawals?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [playerId, withdrawals?.pageInfo.endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    refetch,
  });

  const {
    onChangeFilters,
    availableWithdrawalFilters,
    onSubmitFilter,
    isFiltered,
  } = useGetWithdrawalFilter({
    playerId,
    loadWithdrawals,
  });

  const isEmptyList = isListEmpty(withdrawalsList);

  const isFilterBtnDisabled = isEmptyList && !isFiltered;

  const emptyText =
    isEmptyList && isFiltered
      ? `Результаты${nbsp}по${nbsp}заданным фильтрам отсутствуют`
      : 'Игрок не совершал выводы';

  return (
    <Block
      title="Выводы"
      id="playerWithdrawals"
      headerContent={
        <div className={styles.actionsContainer}>
          {isFullScreenTable && (
            <ActionButton
              actionType={ButtonType.Filter}
              hidden={!state.isOpened}
              disabled={isFilterBtnDisabled}
              onClick={() => setIsOpenFilters(!isOpenFilters)}
            />
          )}
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={refetchWithdrawals}
          />
        </div>
      }
      isEmpty={isEmptyList}
      emptyText={emptyText}
      shrinkLoaderWrapper={false}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      <>
        {isOpenFilters && isFullScreenTable && (
          <div className={styles.filterContainer}>
            <TableFilter
              availableFilters={availableWithdrawalFilters}
              allFilters={allWithdrawalFilters}
              onChangeFilters={onChangeFilters}
              onSubmit={onSubmitFilter}
            />
          </div>
        )}
        {hasList(withdrawalsList) && (
          <BlockTable
            isFullScreenTable={isFullScreenTable}
            data={withdrawalsList}
            columns={columns}
            onClickOnRow={openWithdrawal}
            routeToCustomPage={routeToCustomPage}
          />
        )}
      </>
    </Block>
  );
};

export default WithdrawalsTable;
