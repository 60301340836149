import React, { FC } from 'react';
import ResetClientTwoFactor from 'commonComponents/ResetClientTwoFactor';
import { useResetPartner2FA } from './useResetPartner2FA';

interface ResetPartnerTwoFactorProps {
  partnerId: string;
  twoFactorEnabled?: boolean;
}

export const ResetPartnerTwoFactor: FC<ResetPartnerTwoFactorProps> = ({
  partnerId,
  twoFactorEnabled,
}) => {
  const { loading, resetPartner2FA, error } = useResetPartner2FA({
    partnerId,
  });

  return (
    <ResetClientTwoFactor
      onResetClientTwoFactor={resetPartner2FA}
      twoFactorEnabled={twoFactorEnabled}
      isLoading={loading}
      error={error}
    />
  );
};
