import { FC, FocusEvent, useId, useRef } from 'react';
import { Currency } from 'commonTypes';
import { NumericFormat } from 'react-number-format';
import classnames from 'classnames';
import { FormFieldWrapper } from 'ui/FormFieldWrapper';
import { setNumericFormatMaxLength } from 'ui/Input/utils/setNumericFormatMaxLength';
import styles from './InputSum.module.scss';
import { useInputManagement } from '../hooks/useInputManagement';
import { CommonProps, RangeZeroToFifteen } from '../types';
import { InputSize } from '../const';

interface InputSumProps extends CommonProps {
  currency?: Currency;
  placeholder?: string;
  invalidChars?: Array<string>;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  customMaxLength?: RangeZeroToFifteen;
  decimalScale?: number;
}

export const InputSum: FC<InputSumProps> = ({
  currency,
  name,
  label,
  wrapperClassName,
  inputClassName,
  autoFocus,
  disabled,
  inputSize = InputSize.Default,
  onChange,
  placeholder,
  invalidChars,
  onBlur,
  decimalScale,
  customMaxLength,
}) => {
  const id = useId();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { handleChange, field, touched, error, handleKeyDown, handleBlur } =
    useInputManagement({
      name,
      inputRef,
      autoFocus,
      onChange,
      invalidChars,
      isNumberType: true,
    });

  const customHandleBlur = (event: FocusEvent<HTMLInputElement>) => {
    handleBlur(event);
    onBlur?.(event);
  };

  return (
    <FormFieldWrapper
      name={name}
      idForLabel={id}
      label={label}
      wrapperClassName={wrapperClassName}
    >
      <div className={styles.inputContainer}>
        <NumericFormat
          placeholder={placeholder}
          className={classnames(
            inputClassName,
            styles.inputSum,
            error && touched && styles.error,
            styles[inputSize],
            currency && styles.withCurrency
          )}
          name={name}
          id={id}
          value={field.value}
          displayType="input"
          thousandSeparator=" "
          decimalSeparator=","
          onBlur={customHandleBlur}
          disabled={disabled}
          onValueChange={({ value }) => {
            handleChange(value);
          }}
          onKeyDown={handleKeyDown}
          decimalScale={decimalScale}
          isAllowed={(numericFormatValues) => {
            if (customMaxLength) {
              return setNumericFormatMaxLength(
                numericFormatValues,
                customMaxLength
              );
            }

            return true;
          }}
        />
        {currency && <div className={styles.currency}>{currency}</div>}
      </div>
    </FormFieldWrapper>
  );
};
