import { FC } from 'react';
import styles from './TextOverflowMiddle.module.scss';

interface TextOverflowMiddleProps {
  value: string;
}

export const TextOverflowMiddle: FC<TextOverflowMiddleProps> = ({ value }) => {
  const halfTextLength = Math.round(value.length / 2);
  const textStartPart = value.substring(0, halfTextLength);
  const textEndPart = value.substring(halfTextLength);

  return (
    <div className={styles.container}>
      <span className={styles.textStartPart}>{textStartPart}</span>
      <span className={styles.textEndPart}>{textEndPart}</span>
    </div>
  );
};
