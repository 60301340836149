import React, { FC } from 'react';
import { Dropdown } from 'ui/Dropdown';
import { useRevShareTariffDropdown } from './useRevShareTariffDropdown';

interface CpaTariffDropdownProps {
  name: string;
  label: string;
}

export const RevShareTariffDropdown: FC<CpaTariffDropdownProps> = ({
  label,
  name,
}) => {
  const {
    revShareTariffsOptions,
    loading,
    error,
    fetchMoreCallback,
    hasNextPage,
  } = useRevShareTariffDropdown();

  return (
    <Dropdown
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMoreCallback();
          }
        },
        hasNextPage,
      }}
      loading={loading}
      loadingError={error}
      name={name}
      label={label}
      options={revShareTariffsOptions}
    />
  );
};
