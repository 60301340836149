import { FC } from 'react';
import { SidebarLoader } from 'ui/SidebarLoader';
import Error from 'ui/Error';
import { ReferralLinkMediaItemDataCard } from '../ReferralLinkMediaItemDataCard';
import { useGetCompanyReferralLinkMediaItem } from '../CompanyReferralLinkMediaItem/queries/generated/GetCompanyReferralLinkMediaItem';

interface AboutReferralLinkMediaItemProps {
  companyId: string;
  referralLinkMediaItemId: string;
}

export const AboutReferralLinkMediaItem: FC<
  AboutReferralLinkMediaItemProps
> = ({ companyId, referralLinkMediaItemId }) => {
  const { data, loading, error } = useGetCompanyReferralLinkMediaItem({
    variables: {
      companyId,
      referralLinkMediaItemId,
    },
  });

  return (
    <SidebarLoader loading={loading}>
      {error && <Error error={error} />}
      <ReferralLinkMediaItemDataCard data={data} />
    </SidebarLoader>
  );
};
