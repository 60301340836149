import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PlayerExternalMoneyTransferData } from '../../../ExternalMoneyTransfersTable/queries/generated/PlayerExternalMoneyTransferData';
import { PlayerExternalMoneyTransferBasicData } from '../../../ExternalMoneyTransfersTable/queries/generated/PlayerExternalMoneyTransferBasicData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPlayerExternalMoneyTransferVariables = SchemaTypes.Exact<{
  externalMoneyTransferId: SchemaTypes.Scalars['ID']['input'];
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPlayerExternalMoneyTransfer = { __typename: 'Query', player: { __typename: 'Player', id: string, externalMoneyTransfer: { __typename: 'ExternalMoneyTransfer', createdAt: string, id: string, affiliate: { __typename: 'Company', id: string, name: string, companyEmail: string } | { __typename: 'Partner', id: string, name: string, partnerEmail?: string | null }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } };


export const GetPlayerExternalMoneyTransferDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPlayerExternalMoneyTransfer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"externalMoneyTransferId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"externalMoneyTransfer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"externalMoneyTransferId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlayerExternalMoneyTransferData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},...PlayerExternalMoneyTransferData.definitions,...PlayerExternalMoneyTransferBasicData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPlayerExternalMoneyTransfer__
 *
 * To run a query within a React component, call `useGetPlayerExternalMoneyTransfer` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerExternalMoneyTransfer` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerExternalMoneyTransfer({
 *   variables: {
 *      externalMoneyTransferId: // value for 'externalMoneyTransferId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerExternalMoneyTransfer(baseOptions: ApolloReactHooks.QueryHookOptions<GetPlayerExternalMoneyTransfer, GetPlayerExternalMoneyTransferVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPlayerExternalMoneyTransfer, GetPlayerExternalMoneyTransferVariables>(GetPlayerExternalMoneyTransferDocument, options);
      }
export function useGetPlayerExternalMoneyTransferLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayerExternalMoneyTransfer, GetPlayerExternalMoneyTransferVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPlayerExternalMoneyTransfer, GetPlayerExternalMoneyTransferVariables>(GetPlayerExternalMoneyTransferDocument, options);
        }
export type GetPlayerExternalMoneyTransferHookResult = ReturnType<typeof useGetPlayerExternalMoneyTransfer>;
export type GetPlayerExternalMoneyTransferLazyQueryHookResult = ReturnType<typeof useGetPlayerExternalMoneyTransferLazyQuery>;
export type GetPlayerExternalMoneyTransferQueryResult = Apollo.QueryResult<GetPlayerExternalMoneyTransfer, GetPlayerExternalMoneyTransferVariables>;