import { FC } from 'react';
import { SuspectState } from 'generatedGraphql';
import { SuspiciousCell } from 'ui/Table/components/SuspiciousCell';
import { nbsp } from 'src/const';
import { useSuspiciousRewardActions } from './hooks/useSuspiciousRewardActions';

interface SuspiciousCellWrapperProps {
  id: string;
  suspectState: SuspectState;
}

export const SuspiciousCellWrapper: FC<SuspiciousCellWrapperProps> = ({
  id,
  suspectState,
}) => {
  const {
    suspectRewardAction,
    unsuspectRewardAction,
    suspectLoading,
    unsuspectLoading,
  } = useSuspiciousRewardActions({ id });

  return (
    <SuspiciousCell
      tooltipSuspectMessage={`Заблокировать для${nbsp}массового подтверждения`}
      tooltipUnsuspectMessage={`Разблокировать для${nbsp}массового подтверждения`}
      suspectState={suspectState}
      suspectActionConfig={{
        action: suspectRewardAction,
        loading: suspectLoading,
      }}
      unsuspectActionConfig={{
        action: unsuspectRewardAction,
        loading: unsuspectLoading,
      }}
    />
  );
};
