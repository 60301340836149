import { useField } from 'formik';
import { RefObject, useEffect, KeyboardEvent, FocusEventHandler } from 'react';
import { blockInvalidChars } from 'src/utils/blockInvalidChars';

type HTMLInputTextareaElement = HTMLInputElement | HTMLTextAreaElement;

interface UseInputManagementProps {
  name: string;
  inputRef: RefObject<HTMLInputTextareaElement> | null;
  onChange?: (value: string) => void;
  invalidChars?: Array<string>;
  autoFocus?: boolean;
  isNumberType?: boolean;
}
export const useInputManagement = ({
  name,
  inputRef,
  onChange,
  invalidChars,
  autoFocus,
  isNumberType = false,
}: UseInputManagementProps) => {
  const [field, meta, setters] = useField(name);
  const { touched, error } = meta;

  const handleChange = (value: string) => {
    onChange?.(value);

    // This condition is necessary so that an empty string is returned in an empty Input.Sum or Input.Number
    if (value === '') {
      setters.setValue(value);

      return;
    }

    // Digit inputs format feature (uses for Input.Number and Input.Sum)
    const numberValue = Number(value);
    const formattedValue =
      isNumberType && !Number.isNaN(numberValue) ? numberValue : value;

    setters.setValue(formattedValue);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputTextareaElement>) => {
    if (!invalidChars?.length) {
      return;
    }

    blockInvalidChars(event, invalidChars);
  };

  const handleBlur: FocusEventHandler<HTMLInputTextareaElement> = (event) => {
    field.onBlur(event);
  };

  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
    }
  }, [autoFocus, inputRef]);

  return {
    handleChange,
    handleKeyDown,
    handleBlur,
    field,
    touched,
    error,
  };
};
