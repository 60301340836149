import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { FreespinData } from '../../../FreespinsTable/queries/generated/FreespinData';
import { FreespinBasicData } from '../../../FreespinsTable/queries/generated/FreespinBasicData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetFreespinVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetFreespin = { __typename: 'Query', freespin: { __typename: 'Freespin', id: string, updatedAt: string, bonusWager?: number | null, count: number, createdAt: string, createdAutomatically: boolean, currency: CommonType.Currency, status: SchemaTypes.FreespinStatus, game: { __typename: 'Game', id: string, name: string }, minAccountBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, promoCode?: { __typename: 'PromoCode', id: string, token: string } | null } };


export const GetFreespinDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFreespin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"freespin"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FreespinData"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},...FreespinData.definitions,...FreespinBasicData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetFreespin__
 *
 * To run a query within a React component, call `useGetFreespin` and pass it any options that fit your needs.
 * When your component renders, `useGetFreespin` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreespin({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFreespin(baseOptions: ApolloReactHooks.QueryHookOptions<GetFreespin, GetFreespinVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetFreespin, GetFreespinVariables>(GetFreespinDocument, options);
      }
export function useGetFreespinLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFreespin, GetFreespinVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetFreespin, GetFreespinVariables>(GetFreespinDocument, options);
        }
export type GetFreespinHookResult = ReturnType<typeof useGetFreespin>;
export type GetFreespinLazyQueryHookResult = ReturnType<typeof useGetFreespinLazyQuery>;
export type GetFreespinQueryResult = Apollo.QueryResult<GetFreespin, GetFreespinVariables>;