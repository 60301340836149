import { FC } from 'react';
import { faCancel, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { QualifiedReferralStatus } from 'generatedGraphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'ui/Tooltip';
import styles from './QualifiedReferralActionButtons.module.scss';
import { useReferralActions } from './hooks/useReferralActions';
import { QualifiedReferralData } from '../../../../queries/generated/QualifiedReferralData';

interface QualifiedReferralActionButtonsProps {
  qualifiedReferral: QualifiedReferralData;
}

export const QualifiedReferralActionButtons: FC<
  QualifiedReferralActionButtonsProps
> = ({ qualifiedReferral }) => {
  const { id, status } = qualifiedReferral;

  const { approveReferral, rejectReferral, approveLoading, rejectLoading } =
    useReferralActions({ id });

  const isDisabled = status !== QualifiedReferralStatus.Moderation;

  const isDisableOrApproveLoading = isDisabled || approveLoading;
  const isDisableOrRejectLoading = isDisabled || rejectLoading;

  return (
    <div className={styles.container}>
      <Tooltip
        tooltipContent={isDisableOrApproveLoading ? undefined : 'Подтвердить'}
        placement="left"
      >
        <button
          disabled={isDisableOrApproveLoading}
          className={styles.approveButton}
          onClick={approveReferral}
          data-testid="approve-referral"
          type="button"
        >
          <FontAwesomeIcon icon={faCheckCircle} size="lg" />
        </button>
      </Tooltip>
      <Tooltip
        tooltipContent={isDisableOrRejectLoading ? undefined : 'Отклонить'}
        placement="left"
      >
        <button
          disabled={isDisableOrRejectLoading}
          className={styles.rejectButton}
          onClick={rejectReferral}
          data-testid="reject-referral"
          type="button"
        >
          <FontAwesomeIcon icon={faCancel} size="lg" />
        </button>
      </Tooltip>
    </div>
  );
};
