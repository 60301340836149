import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { useGetCpaPartnerReportColumns } from './hooks/useGetCpaPartnerReportColumns';
import { CpaPartnerReportData } from '../../queries/generated/CpaPartnerReportData';

interface CpaPartnerReportTableProps {
  cpaPartnerReport?: Array<CpaPartnerReportData>;
  hasTotalRow: boolean;
}

export const CpaPartnerReportTable: FC<CpaPartnerReportTableProps> = ({
  cpaPartnerReport,
  hasTotalRow,
}) => {
  const { columns } = useGetCpaPartnerReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={cpaPartnerReport}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
