import { FC } from 'react';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import styles from './ViewNote.module.scss';

interface NotesProps {
  note?: string;
  onEditForm: () => void;
  isEditButtonVisible?: boolean;
}

const ViewNote: FC<NotesProps> = ({
  note,
  onEditForm,
  isEditButtonVisible,
}) => (
  <div className={styles.viewNoteWrapper}>
    <div className={styles.text}>
      {note || <span className={styles.emptyNote}>Заметки нет</span>}
    </div>
    {isEditButtonVisible && (
      <ActionButton
        dataTestId="editButton"
        actionType={ButtonType.Edit}
        onClick={onEditForm}
        className={styles.actionButton}
      />
    )}
  </div>
);

export default ViewNote;
