import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import { UpdateCpaRewardTariffTitleInput } from 'generatedGraphql';
import Error from 'ui/Error';
import { validate } from './validation';
import { useEditTariffNameModal } from './hooks/useEditTariffNameModal';

interface EditNameModalProps {
  cpaRewardTariffsId: string;
  title: string;
}

export const EditTariffNameModal: FC<EditNameModalProps> = ({
  cpaRewardTariffsId,
  title,
}) => {
  const initialValues: UpdateCpaRewardTariffTitleInput = {
    title,
    id: cpaRewardTariffsId,
  };

  const { onEditName, loading, error } = useEditTariffNameModal({
    cpaRewardTariffsId,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onEditName}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      <Form>
        {error && <Error error={error} />}
        <FormRowsWrapper>
          <div>
            <Input name="title" label="Название" maxLength={100} />
          </div>
        </FormRowsWrapper>
        <Button
          size={ButtonSize.Large}
          theme={ButtonTheme.Process}
          type="submit"
          isLoading={loading}
          disabled={loading}
        >
          Сохранить
        </Button>
      </Form>
    </Formik>
  );
};
