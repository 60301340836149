import { FC } from 'react';
import { FilterType } from 'ui/TableFilter/const';
import { FiltersDropdown } from 'ui/TableFilter/components/FiltersDropdown/FiltersDropdown';
import { AvailableFilterListItem } from 'ui/TableFilter/types';

interface SelectedFilterMarkerProps {
  isOpened: boolean;
  inputValue: string;
  filterType: FilterType;
  handleClick: (id: string) => void;
  optionsFilter?: Array<AvailableFilterListItem>;
}

export const SelectedFilterList: FC<SelectedFilterMarkerProps> = ({
  isOpened,
  inputValue,
  filterType,
  handleClick,
  optionsFilter,
}) => {
  const isInputFilter = filterType === FilterType.Input;
  const emptyText = isInputFilter ? 'Введите значение фильтра' : 'Не найден';

  const items = isInputFilter
    ? (inputValue && [{ label: inputValue, id: inputValue }]) || []
    : optionsFilter;

  if (!isOpened || !items) {
    return null;
  }

  return (
    <FiltersDropdown
      emptyText={emptyText}
      items={items}
      onClick={(id) => handleClick(id)}
    />
  );
};
