import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { RevShareCompanyReportData } from '../../queries/generated/RevShareCompanyReportData';
import { useGetRevShareCompanyReportColumns } from './hooks/useGetRevShareCompanyReportColumns';

interface RevShareCompanyReportTableProps {
  revShareCompanyReport?: Array<RevShareCompanyReportData>;
  hasTotalRow: boolean;
}

export const RevShareCompanyReportTable: FC<
  RevShareCompanyReportTableProps
> = ({ revShareCompanyReport, hasTotalRow }) => {
  const { columns } = useGetRevShareCompanyReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={revShareCompanyReport}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
