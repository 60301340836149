import { FC } from 'react';
import ClientHeader from 'commonComponents/ClientHeader';
import { usePartnerMarkers } from './hooks/usePartnerMarkers';
import { usePartnerQueries } from './hooks/usePartnerQueries';
import { usePartnerPrivileges } from './hooks/usePartnerPrivileges';
import { PartnerBasicData } from '../../../../queries/generated/PartnerBasicData';

interface PartnerHeaderProps {
  partner: PartnerBasicData;
}

export const PartnerHeader: FC<PartnerHeaderProps> = ({ partner }) => {
  const { id, name, defaultName, note } = partner;

  const {
    updatePartnerNoteHandler,
    updatePartnerNoteResult,
    updatePartnerNoteLoading,
    resetPartnerName,
  } = usePartnerQueries({ id, name, defaultName });

  const { canResetPartnerName, canUpdateNote, canViewPartnerNote } =
    usePartnerPrivileges();

  const markers = usePartnerMarkers({ partner });

  return (
    <ClientHeader
      updateNoteHandler={updatePartnerNoteHandler}
      updateNoteResult={updatePartnerNoteResult}
      updateNoteLoading={updatePartnerNoteLoading}
      noteText={note?.text}
      canUpdateNote={canUpdateNote}
      canResetClientName={canResetPartnerName}
      canViewClientNote={canViewPartnerNote}
      clientId={id}
      clientName={partner.name}
      resetClientName={resetPartnerName}
      markers={markers}
    />
  );
};
