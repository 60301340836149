import DropdownWithQuery from 'ui/DropdownWithQuery/DropdownWithQuery';
import { FC } from 'react';
import { AffiliateType } from 'generatedGraphql';
import { FieldName } from '../../const';
import { useGetReferralCodes } from './hooks/useGetReferralCodes';

interface ReferralLinkCodeDropdownProps {
  affiliateType: AffiliateType;
  affiliateId: string;
  isDisabled: boolean;
}

export const ReferralLinkCodeDropdown: FC<ReferralLinkCodeDropdownProps> = ({
  affiliateType,
  affiliateId,
  isDisabled,
}) => {
  const {
    loadReferralCodesWithThrottle,
    fetchMore,
    referralCodesOptions,
    hasNextPage,
    getReferralCodesLoading,
  } = useGetReferralCodes({ affiliateType });

  const dropdownName =
    affiliateType === AffiliateType.Company
      ? FieldName.companyReferralCode
      : FieldName.partnerReferralCode;

  return (
    <DropdownWithQuery
      disabled={isDisabled}
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMore();
          }
        },
        hasNextPage,
      }}
      name={dropdownName}
      label="Реферальный код"
      loadList={(searchText) =>
        loadReferralCodesWithThrottle(searchText, affiliateId)
      }
      loading={getReferralCodesLoading}
      searchMinLength={3}
      options={referralCodesOptions}
    />
  );
};
