import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PartnerBasicData } from '../../../../queries/generated/PartnerBasicData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnerDataVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPartnerData = { __typename: 'Query', partner: { __typename: 'Partner', defaultName: string, id: string, name: string, twoFactorEnabled: boolean, note?: { __typename: 'Note', text: string } | null } };


export const GetPartnerDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPartnerData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PartnerBasicData"}}]}}]}},...PartnerBasicData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPartnerData__
 *
 * To run a query within a React component, call `useGetPartnerData` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerData({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartnerData(baseOptions: ApolloReactHooks.QueryHookOptions<GetPartnerData, GetPartnerDataVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPartnerData, GetPartnerDataVariables>(GetPartnerDataDocument, options);
      }
export function useGetPartnerDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPartnerData, GetPartnerDataVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPartnerData, GetPartnerDataVariables>(GetPartnerDataDocument, options);
        }
export type GetPartnerDataHookResult = ReturnType<typeof useGetPartnerData>;
export type GetPartnerDataLazyQueryHookResult = ReturnType<typeof useGetPartnerDataLazyQuery>;
export type GetPartnerDataQueryResult = Apollo.QueryResult<GetPartnerData, GetPartnerDataVariables>;