import { FC } from 'react';
import { ListItem } from 'ui/TableFilter/types';
import styles from './FiltersDropdown.module.scss';

interface FiltersListProps {
  items: Array<ListItem>;
  onClick: (id: string) => void;
  emptyText?: string;
}

export const FiltersDropdown: FC<FiltersListProps> = ({
  items,
  onClick,
  emptyText,
}) => (
  <div className={styles.list}>
    {!items.length && emptyText && (
      <div className={styles.emptyText}>{emptyText}</div>
    )}
    <div>
      {items.map(({ id, label }) => (
        <div
          key={id}
          onClick={(event) => {
            event.stopPropagation();

            onClick(id);
          }}
          className={styles.listItem}
        >
          {label}
        </div>
      ))}
    </div>
  </div>
);
