import { FC } from 'react';
import { useModalManageData } from 'ui/Modal';
import { DataCard } from 'commonComponents/DataCard';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { useGetPartnerOutgoingMoneyTransfer } from './queries/generated/GetPartnerOutgoingMoneyTransfer';

interface OutgoingMoneyTransferItemProps {
  partnerId: string;
  outgoingMoneyTransferId: string;
}

export const OutgoingMoneyTransferItem: FC<OutgoingMoneyTransferItemProps> = ({
  partnerId,
  outgoingMoneyTransferId,
}) => {
  const { data, loading, error } = useGetPartnerOutgoingMoneyTransfer({
    variables: {
      id: partnerId,
      outgoingMoneyTransferId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: {
      error,
      toastId: 'get-partner-outgoing-money-transfer-error',
    },
    hasData: !!data,
  });

  if (!data) {
    return null;
  }

  const {
    createdAt,
    player: { name, email, phone, moneyTransferAccount },
    sum: { amount, currency },
  } = data.partner.outgoingMoneyTransfer;

  return (
    <DataCard
      rows={[
        {
          title: 'Номер счета',
          value: moneyTransferAccount,
        },
        {
          title: 'Имя пользователя',
          value: name,
        },
        {
          title: email ? 'Почта' : 'Телефон',
          value: email || phone,
        },
        {
          title: 'Сумма',
          value: formatSumWithCurrency(amount, currency),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
      ]}
    />
  );
};
