import { FC } from 'react';
import { SumFlowDirection } from 'generatedGraphql';
import { Currency } from 'commonTypes';
import classNames from 'classnames';
import { BonusBalance } from 'commonComponents/BonusBalance/BonusBalance';
import { formatSumAmount, formatSumWithCurrency } from 'src/utils/accounting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import styles from './FromToBalance.module.scss';

const mapDirectionToArrowStyles: Record<SumFlowDirection, string> = {
  [SumFlowDirection.Credit]: styles.creditArrow,
  [SumFlowDirection.Debit]: styles.debitArrow,
};

interface FromToBalanceProps {
  from: number;
  to: number;
  currency?: Currency;
  direction?: SumFlowDirection | null;
}

export const FromToBalance: FC<FromToBalanceProps> = ({
  from,
  to,
  currency,
  direction,
}) => {
  const toBalanceToShow = !currency ? (
    <BonusBalance amount={to} />
  ) : (
    formatSumWithCurrency(to, currency)
  );

  if (!direction) {
    return <span>{toBalanceToShow}</span>;
  }

  return (
    <div className={styles.container} data-testid="fromToBalance">
      <span className={styles.from}>{formatSumAmount(from)}</span>
      <div className={styles.arrowContainer}>
        <FontAwesomeIcon
          className={classNames(
            styles.arrow,
            mapDirectionToArrowStyles[direction]
          )}
          icon={faArrowRight}
        />
      </div>
      <span>{toBalanceToShow}</span>
    </div>
  );
};
