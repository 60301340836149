import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { CopyModalRow } from 'ui/CopyModalRow';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { CompanyAffiliateReferralLinkBasicData } from '../../queries/generated/CompanyAffiliateReferralLinkBasicData';

interface AffiliateReferralLinkItemProps {
  referralLink: CompanyAffiliateReferralLinkBasicData;
}

export const AffiliateReferralLinkItem: FC<AffiliateReferralLinkItemProps> = ({
  referralLink,
}) => {
  const {
    name,
    id: referralLinkId,
    mediaCampaign: { name: mediaCampaignName, url: mediaCampaignNameUrl },
    referralLink: referralLinkUrl,
    createdAt,
  } = referralLink;

  const rows = [
    {
      title: 'Медиа кампания',
      value: mediaCampaignName,
    },
    {
      title: 'URL медиа кампании',
      value: mediaCampaignNameUrl,
    },
    {
      title: 'Реферальная ссылка',
      value: (
        <CopyModalRow
          value={referralLinkUrl}
          toastText="Реферальная ссылка скопирована"
        />
      ),
    },
    {
      title: 'Создан',
      value: formatDate(createdAt, DateFormat.DefaultDateOld),
    },
  ];

  return (
    <DataCard
      header={{
        leftTitle: name,
        leftSubTitle: addHashTagToId(referralLinkId),
      }}
      rows={rows}
      withBorder
    />
  );
};
