import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { CompanyApplicationStatus } from 'generatedGraphql';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { useCompanyApplicationUpdate } from './hooks/useCompanyApplicationUpdate';
import styles from './ButtonsUpdateCompanyApplication.module.scss';
import { nbsp } from '../../../../../../const';
import { useAllowedButtons } from './hooks/useAllowedButtons';
import { CompanyApplicationModalSteps } from '../../../../const';

interface ButtonsUpdateCompanyApplicationProps {
  companyApplicationStatus: CompanyApplicationStatus;
  companyApplicationId: string;
  referralLinkData?: ReferralLinkData | null;
}

export const ButtonsUpdateCompanyApplication: FC<
  ButtonsUpdateCompanyApplicationProps
> = ({ companyApplicationStatus, companyApplicationId, referralLinkData }) => {
  const {
    assignCompanyApplication,
    suspendCompanyApplication,
    loading,
    acceptCompanyApplicationHandler,
  } = useCompanyApplicationUpdate({
    companyApplicationId,
    referralLinkData,
  });

  const { updateModalStep } =
    useUpdateModalStep<CompanyApplicationModalSteps>();

  const {
    hasSuspendButton,
    hasAcceptButton,
    hasAssignButton,
    hasDeclineButton,
  } = useAllowedButtons(companyApplicationStatus);

  return (
    <div className={styles.btnContainer}>
      {hasAssignButton && (
        <Button
          className={styles.btn}
          onClick={() =>
            assignCompanyApplication({
              variables: {
                input: {
                  id: companyApplicationId,
                },
              },
            })
          }
          type="submit"
          isLoading={loading}
          theme={ButtonTheme.Success}
        >
          {`Взять${nbsp}в${nbsp}работу`}
        </Button>
      )}
      {hasAcceptButton && (
        <Button
          className={styles.btn}
          onClick={acceptCompanyApplicationHandler}
          type="submit"
          isLoading={loading}
          theme={ButtonTheme.Success}
        >
          Одобрить
        </Button>
      )}
      {hasSuspendButton && (
        <Button
          className={styles.btn}
          onClick={() =>
            suspendCompanyApplication({
              variables: {
                input: {
                  id: companyApplicationId,
                },
              },
            })
          }
          type="submit"
          isLoading={loading}
          theme={ButtonTheme.Warning}
        >
          {`В${nbsp}ожидание`}
        </Button>
      )}
      {hasDeclineButton && (
        <Button
          className={styles.btn}
          onClick={() =>
            updateModalStep(CompanyApplicationModalSteps.DeclineStep)
          }
          type="submit"
          isLoading={loading}
          theme={ButtonTheme.Important}
        >
          Отклонить
        </Button>
      )}
    </div>
  );
};
