import { DataCard } from 'commonComponents/DataCard';
import { FC } from 'react';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { CopyModalRow } from 'ui/CopyModalRow';
import { getCountryISOtoDisplayName } from 'src/utils/getCountryISOtoDisplayName';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { getMediaItemCpaRewardTariffData } from 'src/helpers';
import Privilege from 'commonComponents/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { GetCompanyReferralLinkMediaItem } from '../CompanyReferralLinkMediaItem/queries/generated/GetCompanyReferralLinkMediaItem';
import { AboutReferralLinkSteps } from '../EditReferralLinkMediaItem/const';

interface ReferralLinkMediaItemDataCardProps {
  data?: GetCompanyReferralLinkMediaItem;
}

export const ReferralLinkMediaItemDataCard: FC<
  ReferralLinkMediaItemDataCardProps
> = ({ data }) => {
  const { updateModalStep } = useUpdateModalStep<AboutReferralLinkSteps>();

  if (!data) {
    return null;
  }

  const {
    createdAt,
    referralLink,
    mediaCampaign,
    mediaItemRewardTariff,
    name,
  } = data.company.referralLinkMediaItem;

  const { fallbackTariff, geolocation, baselineSum, rewardSum } =
    getMediaItemCpaRewardTariffData(mediaItemRewardTariff);

  const rows = [
    {
      title: 'Название',
      value: name,
    },
    {
      title: 'Название медиа кампании',
      value: mediaCampaign.name,
    },
    {
      title: 'URL',
      value: mediaCampaign.url,
    },
    {
      title: 'Реферальная ссылка',
      value: (
        <CopyModalRow
          value={referralLink}
          toastText="Реферальная ссылка скопирована"
        />
      ),
    },
    {
      title: 'Партнёрский тариф',
      value: mediaItemRewardTariff.rewardTariff.title,
    },
    fallbackTariff && {
      title: 'Резервный тариф',
      value: fallbackTariff.title,
    },
    geolocation && {
      title: 'Геолокация',
      value: getCountryISOtoDisplayName(geolocation),
    },
    baselineSum && {
      title: 'Baseline',
      value: formatSumWithCurrency(baselineSum.amount, baselineSum.currency),
    },
    rewardSum && {
      title: 'Вознаграждение',
      value: formatSumWithCurrency(rewardSum.amount, rewardSum.currency),
    },
    {
      title: 'Создан',
      value: formatDate(createdAt, DateFormat.DefaultDateOld),
    },
  ];

  return (
    <DataCard
      rows={rows}
      customFooter={
        <Privilege privileges={[UserPrivilege.UpdateMediaCampaign]}>
          <Button
            size={ButtonSize.Large}
            onClick={() =>
              updateModalStep(AboutReferralLinkSteps.EditReferralLinkStep)
            }
            theme={ButtonTheme.Process}
          >
            Редактировать
          </Button>
        </Privilege>
      }
    />
  );
};
