import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PartnerApplicationData } from '../../../../queries/generated/PartnerApplicationData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnerApplicationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPartnerApplication = { __typename: 'Query', partnerApplication: { __typename: 'PartnerApplication', createdAt: string, declineReason?: string | null, id: string, status: SchemaTypes.PartnerApplicationStatus, trafficSource: string, updatedAt: string, admin?: { __typename: 'Admin', firstName: string, id: string, lastName: string } | null, contactForCommunication: { __typename: 'ContactForCommunication', type: CommonType.ContactForCommunicationType, value: string }, partner: { __typename: 'Partner', email?: string | null, id: string, name: string } } };


export const GetPartnerApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPartnerApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partnerApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PartnerApplicationData"}}]}}]}},...PartnerApplicationData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPartnerApplication__
 *
 * To run a query within a React component, call `useGetPartnerApplication` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerApplication` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerApplication({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartnerApplication(baseOptions: ApolloReactHooks.QueryHookOptions<GetPartnerApplication, GetPartnerApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPartnerApplication, GetPartnerApplicationVariables>(GetPartnerApplicationDocument, options);
      }
export function useGetPartnerApplicationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPartnerApplication, GetPartnerApplicationVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPartnerApplication, GetPartnerApplicationVariables>(GetPartnerApplicationDocument, options);
        }
export type GetPartnerApplicationHookResult = ReturnType<typeof useGetPartnerApplication>;
export type GetPartnerApplicationLazyQueryHookResult = ReturnType<typeof useGetPartnerApplicationLazyQuery>;
export type GetPartnerApplicationQueryResult = Apollo.QueryResult<GetPartnerApplication, GetPartnerApplicationVariables>;