import { FC } from 'react';
import { Sum } from 'generatedGraphql';
import { formatSumWithCurrency } from 'src/utils/accounting';
import styles from './CpaTariffCell.module.scss';

interface CpaTariffCellProps {
  reward: Sum;
  baseLine: Sum;
}

export const CpaTariffCell: FC<CpaTariffCellProps> = ({ reward, baseLine }) => (
  <div className={styles.container}>
    <div className={styles.titles}>
      <span>Вознаграждение</span>
      <span>Бейслайн</span>
    </div>
    <div className={styles.values}>
      <span>{formatSumWithCurrency(reward.amount, reward.currency)}</span>
      <span>{formatSumWithCurrency(baseLine.amount, baseLine.currency)}</span>
    </div>
  </div>
);
