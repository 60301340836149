import BlockTable from 'ui/Block/BlockTable';
import { hasList } from 'ui/Block/BlockTable/utils';
import { ColumnDef } from '@tanstack/react-table';
import { useOpenReferralLinkDetailsModal } from './hooks/useOpenReferralLinkDetailsModal';

interface ReferralLinkMediaItemTableProps<T extends object> {
  isFullScreenTable: boolean;
  companyId: string;
  routeToCustomPage?: string;
  referralLinkMediaItems?: Array<T>;
  columns: Array<ColumnDef<T>>;
}

export const ReferralLinkMediaItemTable = <T extends { __typename: string }>({
  isFullScreenTable,
  companyId,
  routeToCustomPage,
  referralLinkMediaItems,
  columns,
}: ReferralLinkMediaItemTableProps<T>) => {
  const { openReferralLinkDetailsModal } =
    useOpenReferralLinkDetailsModal<T>(companyId);

  if (!hasList(referralLinkMediaItems)) {
    return null;
  }

  return (
    <BlockTable
      isFullScreenTable={isFullScreenTable}
      data={referralLinkMediaItems}
      columns={columns}
      routeToCustomPage={routeToCustomPage}
      onClickOnRow={openReferralLinkDetailsModal}
    />
  );
};
