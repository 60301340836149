import { FC } from 'react';
import { ReferralReviewResultTypeName } from 'commonTypes';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { getCountryISOtoDisplayName } from 'src/utils/getCountryISOtoDisplayName';
import { ReviewResultCellWithTooltip } from '../ReviewResultCellWithTooltip';
import { QualifiedReferralData } from '../../../../queries/generated/QualifiedReferralData';
import { DuplicateTooltipContent } from './components/DuplicateTooltipContent';

interface QualifiedReferralReviewResultProps {
  reviewResult?: QualifiedReferralData['review'];
}

export const QualifiedReferralReviewResult: FC<
  QualifiedReferralReviewResultProps
> = ({ reviewResult }) => {
  if (!reviewResult) {
    return 'Не проходил';
  }

  if (reviewResult.passed) {
    return 'Пройдена';
  }

  const reviewResultTypeName = reviewResult.__typename;

  if (reviewResultTypeName === ReferralReviewResultTypeName.Duplicate) {
    return (
      <ReviewResultCellWithTooltip title="Дубликат">
        <DuplicateTooltipContent
          account={reviewResult.paymentMethod.account}
          playerId={reviewResult.player.id}
        />
      </ReviewResultCellWithTooltip>
    );
  }

  if (reviewResultTypeName === ReferralReviewResultTypeName.Note) {
    return reviewResult.note.text;
  }

  if (reviewResultTypeName === ReferralReviewResultTypeName.Geolocation) {
    const { countryCodeActual } = reviewResult;

    return (
      <ReviewResultCellWithTooltip title="Геолокация">
        {MayBeNullCell(
          countryCodeActual && getCountryISOtoDisplayName([countryCodeActual])
        )}
      </ReviewResultCellWithTooltip>
    );
  }

  return null;
};
