import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { GeneralReportOrderBy, OrderDirection } from 'generatedGraphql';
import { Currency } from 'commonTypes';
import { useSearchContext } from 'src/contexts/SearchContext';
import {
  GetRevShareGeneralReportVariables,
  useGetRevShareGeneralReportLazyQuery,
} from '../queries/generated/GetRevShareGeneralReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetRevShareGeneralReport = () => {
  const { searchValue } = useSearchContext();
  const period = useGetPeriodOfStatistic();

  const baseVariables: GetRevShareGeneralReportVariables = {
    currency: Currency.Usd,
    first: getCountByWindowHeight(cellHeight, 0),
    period,
    ordering: {
      orderBy: GeneralReportOrderBy.AllDeposit,
      direction: OrderDirection.Desc,
    },
  };

  const [reportVariables, setReportVariables] = useState(baseVariables);

  useEffect(() => {
    setReportVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period.from, period.to]);

  const [
    loadRevShareGeneralReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetRevShareGeneralReportLazyQuery();

  const loadRevShareGeneralReportCallback = useCallback(
    () => loadRevShareGeneralReport({ variables: reportVariables }),
    [loadRevShareGeneralReport, reportVariables]
  );

  useBlockComponentState({
    loadData: loadRevShareGeneralReportCallback,
    loading,
    error,
  });

  const revShareGeneralReport = data?.revShareGeneralReport;
  const edges = revShareGeneralReport?.edges;
  const total = revShareGeneralReport?.total;
  const hasTotalRow = !!total;
  const endCursor = revShareGeneralReport?.pageInfo.endCursor;
  const hasNextPage = revShareGeneralReport?.pageInfo.hasNextPage;

  const revShareGeneralReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    revShareGeneralReportList,
    hasTotalRow,
    refetch,
    loadReport: () =>
      loadRevShareGeneralReport({
        variables: { ...reportVariables, search: searchValue },
      }),
  };
};
