import { Currency } from 'commonTypes';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetExportRevShareGeneralReportLazyQuery } from '../queries/generated/GetExportRevShareGeneralReport';
import { useExportReportHandlers } from '../../../helpers/useHandleCompleteExportReport';

export const useGetExportRevShareGeneralReport = () => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportReport,
    handleErrorExportReport,
    downloadReportFileLoading,
  } = useExportReportHandlers();

  const [exportReport, { loading: getExportReportLinkLoading }] =
    useGetExportRevShareGeneralReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportReport(data.exportRevShareGeneralReport);
      },
      onError: handleErrorExportReport,
    });

  const exportReportWithVariables = useCallback(() => {
    exportReport({
      variables: {
        currency: Currency.Usd,
        period,
      },
    });
  }, [exportReport, period]);

  return {
    exportReport: exportReportWithVariables,
    exportReportLoading:
      getExportReportLinkLoading || downloadReportFileLoading,
    exportPrivilege: UserPrivilege.ExportRevShareGeneralReport,
  };
};
