import { useParams } from 'react-router';
import { CompanyPageContainer } from '../../components/CompanyPageContainer';
import { CompanyMediaItemsBlock } from '../../components/CompanyMediaItemsBlock';

export const CompanyMediaItems = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <CompanyPageContainer companyId={id}>
      <CompanyMediaItemsBlock
        id={id}
        isFullScreenTable
        shouldReturnToPrevPage
      />
    </CompanyPageContainer>
  );
};
