import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useMediaItemModal } from './hooks/useMediaItemModal';
import { useGetMediaCampaignsColumns } from './hooks/useGetMediaCampaignsColumns';
import { MediaCampaignData } from '../../queries/generated/MediaCampaignData';

interface MediaItemsTableProps {
  mediaItems?: Array<MediaCampaignData>;
}

export const MediaItemsTable: FC<MediaItemsTableProps> = ({ mediaItems }) => {
  const openModal = useMediaItemModal();
  const { columns } = useGetMediaCampaignsColumns();

  const handleRowClick = (
    mediaCampaign: MediaCampaignData,
    initiatorId: string
  ) => {
    openModal({
      mediaCampaign,
      initiatorId,
    });
  };

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={mediaItems}
      isFullScreenTable
    />
  );
};
