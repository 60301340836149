import { FC } from 'react';
import Privilege from 'commonComponents/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import { Popup } from 'ui/Popup';
import Button, { ButtonTheme } from 'ui/Button';
import styles from './PlayerAccountActionButtons.module.scss';
import { useManagePlayerAccount } from '../../hooks/useManagePlayerAccount';

interface PlayerAccountActionButtonsProps {
  partnerId: string;
}

export const PlayerAccountActionButtons: FC<
  PlayerAccountActionButtonsProps
> = ({ partnerId }) => {
  const {
    approvePlayerAccount,
    declinePlayerAccount,
    approvePlayerAccountLoading,
    declinePlayerAccountLoading,
  } = useManagePlayerAccount(partnerId);

  return (
    <div className={styles.buttonsContainer}>
      <Privilege privileges={[UserPrivilege.ApprovePartnerPlayerAccount]}>
        <Popup
          content="Вы подтверждаете игровой аккаунт?"
          actionButtonsConfig={[
            {
              text: 'Подтвердить',
              theme: ButtonTheme.Primary,
              action: approvePlayerAccount,
            },
            {
              text: 'Отменить',
              theme: ButtonTheme.Process,
            },
          ]}
          triggerElement={
            <Button
              theme={ButtonTheme.Success}
              isLoading={approvePlayerAccountLoading}
            >
              Подтвердить
            </Button>
          }
          isActionButtonsMaxWidth
        />
      </Privilege>
      <Privilege privileges={[UserPrivilege.DeclinePartnerPlayerAccount]}>
        <Popup
          content="Вы отклоняете игровой аккаунт?"
          actionButtonsConfig={[
            {
              text: 'Подтвердить',
              theme: ButtonTheme.Primary,
              action: declinePlayerAccount,
            },
            {
              text: 'Отменить',
              theme: ButtonTheme.Process,
            },
          ]}
          triggerElement={
            <Button
              theme={ButtonTheme.Important}
              isLoading={declinePlayerAccountLoading}
            >
              Отклонить
            </Button>
          }
          isActionButtonsMaxWidth
        />
      </Privilege>
    </div>
  );
};
