import { FC } from 'react';
import { CardIcon, CardIconColor } from 'ui/Card/CardIcon';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { AffiliateCard } from '../../../AffiliateCard';
import styles from './UnEditableAffiliates.module.scss';
import { GetCompanyAffiliateApplication } from '../../../../queries/generated/GetCompanyAffiliateApplication';

interface ApprovedAffiliatesProps {
  affiliates: GetCompanyAffiliateApplication['companyAffiliateApplication']['items'];
}

export const UnEditableAffiliates: FC<ApprovedAffiliatesProps> = ({
  affiliates,
}) => (
  <div
    data-testid="unEditableAffiliatesBlock"
    className={styles.affiliatesContainer}
  >
    {affiliates.map((affiliate) => (
      <AffiliateCard
        key={affiliate.id}
        affiliate={affiliate}
        statusOrCheckbox={
          <CardIcon
            className={styles.iconContainer}
            config={{ icon: faUser, iconColor: CardIconColor.GREY }}
          />
        }
      />
    ))}
  </div>
);
