import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { generatePath } from 'react-router';
import { Route as RouteEnum } from 'src/router/routes.const';
import DocumentsList from './components/DocumentsList';

interface DocumentsBlockProps {
  id: string;
}

const DocumentsBlock: FC<DocumentsBlockProps> = ({ id }) => (
  <DocumentsList
    playerId={id}
    isFullScreenBlock={false}
    routeToCustomPage={generatePath(RouteEnum.PLAYER_DOCUMENTS, { id })}
  />
);

export default withBlockProvider(DocumentsBlock);
