import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { RevShareGeneralReportData } from '../../queries/generated/RevShareGeneralReportData';
import { useRevShareGeneralReportColumns } from './hooks/useRevShareGeneralReportColumns';

interface RevShareGeneralReportTableProps {
  report?: Array<RevShareGeneralReportData>;
  hasTotalRow?: boolean;
}

export const RevShareGeneralReportTable: FC<
  RevShareGeneralReportTableProps
> = ({ report, hasTotalRow }) => {
  const { columns } = useRevShareGeneralReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={report}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
