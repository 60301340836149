import { FC } from 'react';
import Input from 'ui/Input';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Form, Formik } from 'formik';
import FormRow from 'ui/FormRow';
import Button, { ButtonTheme } from 'ui/Button';
import Error from 'ui/Error';
import { Tooltip } from 'ui/Tooltip';
import { validate } from './validation';
import styles from '../PartnerApplicationModal/components/ButtonsUpdatePartnerApplication/ButtonsUpdatePartnerApplication.module.scss';
import { useDeclinePartnerApplicationData } from './hooks/useDeclinePartnerApplicationData';

interface DeclinePartnerApplicationModalProps {
  partnerId: string;
  partnerApplicationId: string;
  partnerName: string;
}

export const DeclinePartnerApplicationModal: FC<
  DeclinePartnerApplicationModalProps
> = ({ partnerId, partnerName, partnerApplicationId }) => {
  const partnerInputValue = `#${partnerId} ${partnerName}`;

  const { declinePartnerApplicationHandler, loading, error } =
    useDeclinePartnerApplicationData();

  const initialValues = {
    id: partnerApplicationId,
    partnerName: partnerInputValue,
    reason: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={declinePartnerApplicationHandler}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input name="partnerName" label="Партнёр" disabled />
            </FormRow>
            <FormRow isWide>
              <Input.Textarea
                name="reason"
                label="Причина отклонения"
                placeholder="Укажите причину отклонения заявки"
              />
            </FormRow>
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty ? 'Укажите причину отклонения' : undefined
            }
            placement="right"
            shouldSetWidthFitContent
          >
            <Button
              className={styles.btn}
              type="submit"
              isLoading={loading}
              disabled={!isValid || !dirty}
              theme={ButtonTheme.Important}
            >
              Отклонить
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
