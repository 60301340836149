import { FC } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { AffiliateTypeName } from 'commonTypes';
import { Route } from 'src/router/routes.const';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import SearchInput from 'ui/SearchInput';
import { STATISTIC_NO_DATA } from '../../const';
import { BlockSubHeaderAffiliateInfo } from '../../components/BlockSubHeaderAffiliateInfo';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { useGetRevShareCompanyReport } from './hooks/useGetRevShareCompanyReport';
import { RevShareCompanyReportTable } from './components/RevShareCompanyReportTable';
import {
  REV_SHARE_COMPANY_REPORT_INPUT_NAME,
  REV_SHARE_COMPANY_REPORT_SEARCH_PLACEHOLDER,
  REV_SHARE_COMPANY_STATISTIC_BLOCK_TITLE,
} from './const';
import { useGetExportCompanyRevShareReport } from './hooks/useGetExportCompanyRevShareReport';

interface RevShareCompanyReportProps {
  companyId: string;
}

const Component: FC<RevShareCompanyReportProps> = withBlockProvider(
  ({ companyId }) => {
    const {
      data,
      revShareCompanyReportList,
      loadReport,
      loading,
      refetch,
      fetchMoreCallback,
      hasNextPage,
      hasTotalRow,
      company,
    } = useGetRevShareCompanyReport(companyId);

    const exportReportConfig = useGetExportCompanyRevShareReport({
      companyId,
    });

    useBlockTableComponentState({
      data,
      fetchMoreCallback,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable: true,
    });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();

    const customPathToPrevPage = generatePathWithPeriodQueryParams({
      originalPath: Route.STATISTIC_REV_SHARE_COMPANY_PARTNERS,
      params: {
        id: companyId,
      },
    });

    const listExist = hasList(revShareCompanyReportList);

    return (
      <>
        <SearchInput
          name={REV_SHARE_COMPANY_REPORT_INPUT_NAME}
          placeholder={REV_SHARE_COMPANY_REPORT_SEARCH_PLACEHOLDER}
          onSubmit={loadReport}
        />
        <Block
          title={REV_SHARE_COMPANY_STATISTIC_BLOCK_TITLE}
          id="revShareCompanyStatistic"
          headerContent={
            <StatisticBlockHeaderContent
              hasList={listExist}
              exportReportConfig={exportReportConfig}
              handleRefetchClick={refetch}
            />
          }
          subHeader={
            company && (
              <BlockSubHeaderAffiliateInfo
                name={company.name}
                email={company.email}
                affiliateType={AffiliateTypeName.Company}
              />
            )
          }
          shrinkLoaderWrapper={false}
          emptyText={STATISTIC_NO_DATA}
          isEmpty={isListEmpty(revShareCompanyReportList)}
          shouldReturnToPrevPage
          isPermanentOpened
          customPathToPrevPage={customPathToPrevPage}
        >
          {listExist && (
            <RevShareCompanyReportTable
              revShareCompanyReport={revShareCompanyReportList}
              hasTotalRow={hasTotalRow}
            />
          )}
        </Block>
      </>
    );
  }
);

export const RevShareCompanyReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <SearchContextProvider pageType={PageTypeStorage.RevShareCompanyReport}>
      <Component companyId={id} />
    </SearchContextProvider>
  );
};
