import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { generatePath } from 'react-router';
import { Route as RouteEnum } from 'src/router/routes.const';
import { useGetFreespinsColumns } from './hooks/useGetFreespinsColumns';
import FreespinsTable from './components/FreespinsTable';

interface FreespinsBlockProps {
  id: string;
}

const FreespinsBlock: FC<FreespinsBlockProps> = ({ id }) => {
  const { columns } = useGetFreespinsColumns(false);

  return (
    <FreespinsTable
      playerId={id}
      columns={columns}
      routeToCustomPage={generatePath(RouteEnum.PLAYER_FREESPINS, { id })}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(FreespinsBlock);
