import { FC, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './TextOverflow.module.scss';

interface TextOverflowProps {
  delimiter?: string;
  lengthBeforeOffset?: number;
  text: string;
  trimStep?: number;
}

export const TextOverflow: FC<TextOverflowProps> = ({
  delimiter = '...',
  lengthBeforeOffset = 10,
  text,
  trimStep = 1,
}) => {
  const [formattedText, setFormattedText] = useState(text);
  const [isTextVisible, setTextVisibility] = useState(false);
  const textContainer = useRef<HTMLDivElement>(null);
  const [leftText, setLeftText] = useState(
    text.slice(0, Math.trunc(text.length / 2))
  );
  const [rightText, setRightText] = useState(
    text.slice(Math.trunc(text.length / 2))
  );

  const isMinimalWidthReached = useCallback(
    () => leftText.length <= trimStep || rightText.length <= trimStep,
    [leftText.length, rightText.length, trimStep]
  );

  const isOverflow = () => {
    if (textContainer.current === null) {
      return false;
    }

    return (
      textContainer.current.offsetWidth < textContainer.current.scrollWidth
    );
  };

  const isTextFit = useCallback(
    () => text.length <= lengthBeforeOffset,
    [lengthBeforeOffset, text.length]
  );

  const handleDecrease = useCallback(() => {
    let newLeftText;
    let newRightText;

    if (isMinimalWidthReached()) {
      newLeftText = leftText.slice(0, trimStep);
      newRightText = rightText.slice(-trimStep);
    } else {
      newLeftText = leftText.slice(0, leftText.length - trimStep);
      newRightText = rightText.slice(-(rightText.length - trimStep));
    }

    setLeftText(newLeftText);
    setRightText(newRightText);
    setFormattedText(newLeftText + delimiter + newRightText);
  }, [delimiter, isMinimalWidthReached, leftText, rightText, trimStep]);

  const onTextChanged = useCallback(() => {
    if (isOverflow()) {
      handleDecrease();
    } else {
      setTextVisibility(true);
    }
  }, [handleDecrease]);

  useEffect(() => {
    if (textContainer.current === null || isTextFit()) {
      setTextVisibility(true);

      return;
    }

    onTextChanged();
  }, [isTextFit, onTextChanged]);

  return (
    <div
      ref={textContainer}
      className={classnames(
        styles.textOverflow,
        isTextVisible ? styles.visible : styles.hidden
      )}
    >
      {formattedText}
    </div>
  );
};
