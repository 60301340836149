import { FC } from 'react';
import Input from 'ui/Input';
import Button, { ButtonTheme } from 'ui/Button';
import classnames from 'classnames';
import { Tooltip } from 'ui/Tooltip';
import styles from './Confirmation.module.scss';

interface ConfirmationProps {
  loading: boolean;
  label: string;
  buttonText: string;
  inputName?: string;
  className?: string;
  isValid: boolean;
  dirty: boolean;
  submitValidationText?: string;
}

export const Confirmation: FC<ConfirmationProps> = ({
  loading,
  label,
  buttonText,
  inputName = 'confirmation',
  className,
  isValid,
  dirty,
  submitValidationText = 'Необходимо ввести подтверждение',
}) => (
  <div className={classnames(className, styles.container)}>
    <div className={styles.confirmationContainer}>
      <div className={styles.inputContainer}>
        <div className={styles.label}>{label}</div>
        <Input
          autoComplete="off"
          name={inputName}
          wrapperClassName={styles.confirmationInput}
          data-testid="confirmField"
        />
      </div>
      <Tooltip
        placement="bottom"
        tooltipContent={!isValid || !dirty ? submitValidationText : undefined}
      >
        <Button
          className={styles.button}
          theme={ButtonTheme.Important}
          type="submit"
          isLoading={loading}
          disabled={!isValid || !dirty}
          data-testid="confirmButton"
        >
          {buttonText}
        </Button>
      </Tooltip>
    </div>
  </div>
);
