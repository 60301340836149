import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useNavigate } from 'react-router-dom';
import { Route } from 'src/router/routes.const';
import { CompanyListData } from '../../queries/generated/CompanyListData';
import { useGetCompaniesColumns } from './hooks/useGetCompaniesColumns';

interface CompaniesListTableProps {
  companiesList?: Array<CompanyListData>;
}

export const CompaniesListTable: FC<CompaniesListTableProps> = ({
  companiesList,
}) => {
  const { columns } = useGetCompaniesColumns();
  const navigate = useNavigate();

  const handleRowClick = (company: CompanyListData) => {
    navigate(`${Route.COMPANIES}/${company.id}`);
  };

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={companiesList}
      isFullScreenTable
    />
  );
};
