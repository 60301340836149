import { FC } from 'react';
import { CardIcon, CardIconConfig } from 'ui/Card/CardIcon';
import styles from './WithdrawalStatus.module.scss';

interface WithdrawalStatusProps {
  iconConfig: CardIconConfig;
  status: string;
}

export const WithdrawalStatus: FC<WithdrawalStatusProps> = ({
  iconConfig,
  status,
}) => (
  <div className={styles.container}>
    <CardIcon className={styles.icon} config={iconConfig} />
    <div className={styles.status}>{status}</div>
  </div>
);
