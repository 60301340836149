import { FC, useState } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { Route } from 'src/router/routes.const';
import { AffiliateTypeName, RewardTariffType } from 'commonTypes';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import SearchInput from 'ui/SearchInput';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import { STATISTIC_NO_DATA } from '../../const';
import { BlockSubHeaderAffiliateInfo } from '../../components/BlockSubHeaderAffiliateInfo';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { useGetCpaCompanyReport } from './hooks/useGetCpaCompanyReport';
import { CpaCompanyReportTable } from './components/CpaCompanyReportTable';
import {
  CPA_COMPANY_REPORT_INPUT_NAME,
  CPA_COMPANY_REPORT_SEARCH_PLACEHOLDER,
  CPA_COMPANY_STATISTIC_BLOCK_TITLE,
} from './const';
import { useGetExportCompanyCpaReport } from './hooks/useGetExportCompanyCpaReport';

interface CpaCompanyReportProps {
  companyId: string;
}

const Component: FC<CpaCompanyReportProps> = withBlockProvider(
  ({ companyId }) => {
    const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
    const {
      data,
      cpaCompanyReportList,
      loadReport,
      loading,
      refetch,
      fetchMoreCallback,
      hasNextPage,
      hasTotalRow,
      company,
    } = useGetCpaCompanyReport({
      companyId,
      isAllTimeCpa,
    });

    const exportReportConfig = useGetExportCompanyCpaReport({
      companyId,
      isAllTimeCpa,
    });

    useBlockTableComponentState({
      data,
      fetchMoreCallback,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable: true,
    });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();

    const customPathToPrevPage = generatePathWithPeriodQueryParams({
      originalPath: Route.STATISTIC_CPA_COMPANY_PARTNERS,
      params: {
        id: companyId,
      },
    });

    const listExist = hasList(cpaCompanyReportList);

    return (
      <>
        <SearchInput
          name={CPA_COMPANY_REPORT_INPUT_NAME}
          placeholder={CPA_COMPANY_REPORT_SEARCH_PLACEHOLDER}
          onSubmit={loadReport}
        />
        <Block
          title={CPA_COMPANY_STATISTIC_BLOCK_TITLE}
          id="cpaCompanyStatistic"
          headerContent={
            <StatisticBlockHeaderContent
              hasList={listExist}
              exportReportConfig={exportReportConfig}
              handleRefetchClick={refetch}
              showAllCpa={(value) => setIsAllTimeCpa(value)}
              rewardTariffType={RewardTariffType.Cpa}
            />
          }
          subHeader={
            company && (
              <BlockSubHeaderAffiliateInfo
                name={company.name}
                email={company.email}
                affiliateType={AffiliateTypeName.Company}
              />
            )
          }
          shrinkLoaderWrapper={false}
          emptyText={STATISTIC_NO_DATA}
          isEmpty={isListEmpty(cpaCompanyReportList)}
          shouldReturnToPrevPage
          isPermanentOpened
          customPathToPrevPage={customPathToPrevPage}
        >
          {listExist && (
            <CpaCompanyReportTable
              cpaCompanyReport={cpaCompanyReportList}
              hasTotalRow={hasTotalRow}
            />
          )}
        </Block>
      </>
    );
  }
);

export const CpaCompanyReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <SearchContextProvider pageType={PageTypeStorage.CpaCompanyReport}>
      <Component companyId={id} />
    </SearchContextProvider>
  );
};
