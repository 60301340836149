import { Link } from 'react-router-dom';
import { FC } from 'react';
import Button from 'ui/Button';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import styles from './TextWithLinkRow.module.scss';

interface TextWithLinkRowProps {
  title: string;
  route: string;
  isRightIconPosition?: boolean;
}

export const TextWithLinkRow: FC<TextWithLinkRowProps> = ({
  title,
  route,
  isRightIconPosition,
}) => (
  <div
    className={classnames(
      styles.container,
      isRightIconPosition && styles.rightIconPosition
    )}
  >
    <Link
      className={styles.linkWithIcon}
      to={route}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button
        linkStyled
        icon={faArrowUpRightFromSquare}
        className={styles.iconLinkButton}
      />
    </Link>
    <Link to={route} target="_blank" rel="noopener noreferrer">
      {title}
    </Link>
  </div>
);
