import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { Route } from 'src/router/routes.const';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { CpaGeneralReportData } from '../../../queries/generated/CpaGeneralReportData';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { getAffiliateInfo } from '../../../../../helpers';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../MultilineCellWithLinkWithPeriodQueryParams';

const columnHelper = createColumnHelper<CpaGeneralReportData>();

const columnsSource: ColumnsSource<CpaGeneralReportData> = [
  columnHelper.accessor(ColumnId.Affiliate, {
    id: ColumnId.Affiliate,
    header: mapColumnIdToName[ColumnId.Affiliate],
    cell: ({ row }) => {
      if (!row.original.affiliate) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name, email, typeName } = getAffiliateInfo<
        CpaGeneralReportData['affiliate']
      >(row.original.affiliate);

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={email}
          route={
            typeName === 'Partner'
              ? Route.STATISTIC_CPA_PARTNER
              : Route.STATISTIC_CPA_COMPANY_PARTNERS
          }
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Hits, {
    id: ColumnId.Hits,
    header: mapColumnIdToName[ColumnId.Hits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hits),
  }),
  columnHelper.accessor(ColumnId.Hosts, {
    id: ColumnId.Hosts,
    header: mapColumnIdToName[ColumnId.Hosts],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hosts),
  }),
  columnHelper.accessor(ColumnId.Registrations, {
    id: ColumnId.Registrations,
    header: mapColumnIdToName[ColumnId.Registrations],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.registrations),
  }),
  columnHelper.accessor(ColumnId.FirstDeposits, {
    id: ColumnId.FirstDeposits,
    header: mapColumnIdToName[ColumnId.FirstDeposits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.firstDeposits),
  }),
  columnHelper.accessor(ColumnId.HostToRegConversion, {
    id: ColumnId.HostToRegConversion,
    header: mapColumnIdToName[ColumnId.HostToRegConversion],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.hostToRegConversion),
  }),
  columnHelper.accessor(ColumnId.RegToDepConversion, {
    id: ColumnId.RegToDepConversion,
    header: mapColumnIdToName[ColumnId.RegToDepConversion],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.regToDepConversion),
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.AllDeposit, {
    id: ColumnId.AllDeposit,
    header: mapColumnIdToName[ColumnId.AllDeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.AverageDeposit, {
    id: ColumnId.AverageDeposit,
    header: mapColumnIdToName[ColumnId.AverageDeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.AllRedeposit, {
    id: ColumnId.AllRedeposit,
    header: mapColumnIdToName[ColumnId.AllRedeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Redeposits, {
    id: ColumnId.Redeposits,
    header: mapColumnIdToName[ColumnId.Redeposits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.redeposits),
  }),
  columnHelper.accessor(ColumnId.NewDeposit, {
    id: ColumnId.NewDeposit,
    header: mapColumnIdToName[ColumnId.NewDeposit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Withdrawal, {
    id: ColumnId.Withdrawal,
    header: mapColumnIdToName[ColumnId.Withdrawal],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.NewWithdrawal, {
    id: ColumnId.NewWithdrawal,
    header: mapColumnIdToName[ColumnId.NewWithdrawal],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.NewProfit, {
    id: ColumnId.NewProfit,
    header: mapColumnIdToName[ColumnId.NewProfit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Hold, {
    id: ColumnId.Hold,
    header: mapColumnIdToName[ColumnId.Hold],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.hold),
  }),
  columnHelper.accessor(ColumnId.Qualified, {
    id: ColumnId.Qualified,
    header: mapColumnIdToName[ColumnId.Qualified],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.qualified),
  }),
  columnHelper.accessor(ColumnId.NotQualified, {
    id: ColumnId.NotQualified,
    header: mapColumnIdToName[ColumnId.NotQualified],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.canceled),
  }),
  columnHelper.accessor(ColumnId.Approved, {
    id: ColumnId.Approved,
    header: mapColumnIdToName[ColumnId.Approved],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.approved),
  }),
  columnHelper.accessor(ColumnId.Rejected, {
    id: ColumnId.Rejected,
    header: mapColumnIdToName[ColumnId.Rejected],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.rejected),
  }),
  columnHelper.accessor(ColumnId.Paid, {
    id: ColumnId.Paid,
    header: mapColumnIdToName[ColumnId.Paid],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.paid),
  }),
  columnHelper.accessor(ColumnId.Unpaid, {
    id: ColumnId.Unpaid,
    header: mapColumnIdToName[ColumnId.Unpaid],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.unpaid),
  }),
  columnHelper.accessor(ColumnId.RevShare, {
    id: ColumnId.RevShare,
    header: mapColumnIdToName[ColumnId.RevShare],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.revShare),
  }),
  columnHelper.accessor(ColumnId.NGR, {
    id: ColumnId.NGR,
    header: mapColumnIdToName[ColumnId.NGR],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Bonus, {
    id: ColumnId.Bonus,
    header: mapColumnIdToName[ColumnId.Bonus],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.bonus;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Profit, {
    id: ColumnId.Profit,
    header: mapColumnIdToName[ColumnId.Profit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Upcoming, {
    id: ColumnId.Upcoming,
    header: mapColumnIdToName[ColumnId.Upcoming],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.affiliateRewardStatistic.upcoming;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.RewardPaid, {
    id: ColumnId.RewardPaid,
    header: mapColumnIdToName[ColumnId.RewardPaid],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateRewardStatistic.paid;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetCpaGeneralReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
