import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PlayerCashbackData } from './PlayerCashbackData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPlayerCashbackVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPlayerCashback = { __typename: 'Query', player: { __typename: 'Player', id: string, accounts: Array<{ __typename: 'Account', id: string, statistic: { __typename: 'AccountStatistic', creditSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, debitSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, paidCashbackSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, upcomingCashback: { __typename: 'Cashback', sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } }> } };


export const GetPlayerCashbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPlayerCashback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlayerCashbackData"}}]}}]}},...PlayerCashbackData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPlayerCashback__
 *
 * To run a query within a React component, call `useGetPlayerCashback` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerCashback` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerCashback({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerCashback(baseOptions: ApolloReactHooks.QueryHookOptions<GetPlayerCashback, GetPlayerCashbackVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPlayerCashback, GetPlayerCashbackVariables>(GetPlayerCashbackDocument, options);
      }
export function useGetPlayerCashbackLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayerCashback, GetPlayerCashbackVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPlayerCashback, GetPlayerCashbackVariables>(GetPlayerCashbackDocument, options);
        }
export type GetPlayerCashbackHookResult = ReturnType<typeof useGetPlayerCashback>;
export type GetPlayerCashbackLazyQueryHookResult = ReturnType<typeof useGetPlayerCashbackLazyQuery>;
export type GetPlayerCashbackQueryResult = Apollo.QueryResult<GetPlayerCashback, GetPlayerCashbackVariables>;