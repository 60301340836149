import React, { FC } from 'react';
import ResetClientTwoFactor from 'commonComponents/ResetClientTwoFactor';
import { useResetPlayerTwoFactor } from './useResetPlayerTwoFactor';

interface ResetPlayerTwoFactorProps {
  id: string;
  twoFactorEnabled?: boolean;
}

const ResetPlayerTwoFactor: FC<ResetPlayerTwoFactorProps> = ({
  id,
  twoFactorEnabled,
}) => {
  const { loading, onResetPlayerTwoFactor, error } = useResetPlayerTwoFactor({
    id,
  });

  return (
    <ResetClientTwoFactor
      onResetClientTwoFactor={onResetPlayerTwoFactor}
      twoFactorEnabled={twoFactorEnabled}
      isLoading={loading}
      error={error}
    />
  );
};

export default ResetPlayerTwoFactor;
