import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetExportRevShareGeneralReportVariables = SchemaTypes.Exact<{
  currency: SchemaTypes.Scalars['Currency']['input'];
  period: SchemaTypes.DatePeriodInput;
}>;


export type GetExportRevShareGeneralReport = { __typename: 'Query', exportRevShareGeneralReport: string };


export const GetExportRevShareGeneralReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetExportRevShareGeneralReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currency"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Currency"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"period"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DatePeriodInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportRevShareGeneralReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"currency"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currency"}}},{"kind":"Argument","name":{"kind":"Name","value":"period"},"value":{"kind":"Variable","name":{"kind":"Name","value":"period"}}}]}]}}]} as unknown as DocumentNode;

/**
 * __useGetExportRevShareGeneralReport__
 *
 * To run a query within a React component, call `useGetExportRevShareGeneralReport` and pass it any options that fit your needs.
 * When your component renders, `useGetExportRevShareGeneralReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportRevShareGeneralReport({
 *   variables: {
 *      currency: // value for 'currency'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetExportRevShareGeneralReport(baseOptions: ApolloReactHooks.QueryHookOptions<GetExportRevShareGeneralReport, GetExportRevShareGeneralReportVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExportRevShareGeneralReport, GetExportRevShareGeneralReportVariables>(GetExportRevShareGeneralReportDocument, options);
      }
export function useGetExportRevShareGeneralReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportRevShareGeneralReport, GetExportRevShareGeneralReportVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExportRevShareGeneralReport, GetExportRevShareGeneralReportVariables>(GetExportRevShareGeneralReportDocument, options);
        }
export type GetExportRevShareGeneralReportHookResult = ReturnType<typeof useGetExportRevShareGeneralReport>;
export type GetExportRevShareGeneralReportLazyQueryHookResult = ReturnType<typeof useGetExportRevShareGeneralReportLazyQuery>;
export type GetExportRevShareGeneralReportQueryResult = Apollo.QueryResult<GetExportRevShareGeneralReport, GetExportRevShareGeneralReportVariables>;