import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetRestrictionVariables = SchemaTypes.Exact<{
  restrictionId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetRestriction = { __typename: 'Query', restriction: { __typename: 'BadBankRestriction', canceledAt?: string | null, createdAt: string, id: string, reason?: string | null, updatedAt: string } | { __typename: 'ChargeBackRestriction', canceledAt?: string | null, createdAt: string, id: string, reason?: string | null, updatedAt: string } | { __typename: 'ReceiveGiftRestriction', bypassRestrictionIfApproved: boolean, canceledAt?: string | null, createdAt: string, id: string, reason: string, updatedAt: string } | { __typename: 'WithdrawalRestriction', bypassRestrictionIfApproved: boolean, canceledAt?: string | null, createdAt: string, id: string, reason: string, updatedAt: string } };


export const GetRestrictionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetRestriction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"restrictionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"restriction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"restrictionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canceledAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WithdrawalRestriction"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bypassRestrictionIfApproved"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ReceiveGiftRestriction"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bypassRestrictionIfApproved"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetRestriction__
 *
 * To run a query within a React component, call `useGetRestriction` and pass it any options that fit your needs.
 * When your component renders, `useGetRestriction` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestriction({
 *   variables: {
 *      restrictionId: // value for 'restrictionId'
 *   },
 * });
 */
export function useGetRestriction(baseOptions: ApolloReactHooks.QueryHookOptions<GetRestriction, GetRestrictionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRestriction, GetRestrictionVariables>(GetRestrictionDocument, options);
      }
export function useGetRestrictionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRestriction, GetRestrictionVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRestriction, GetRestrictionVariables>(GetRestrictionDocument, options);
        }
export type GetRestrictionHookResult = ReturnType<typeof useGetRestriction>;
export type GetRestrictionLazyQueryHookResult = ReturnType<typeof useGetRestrictionLazyQuery>;
export type GetRestrictionQueryResult = Apollo.QueryResult<GetRestriction, GetRestrictionVariables>;