import React, { FC } from 'react';
import ResetClientName from 'src/components/ResetClientName';
import { useResetPlayerName } from './useResetPlayerName';

interface ResetPlayerNameProps {
  id: string;
  playerName: string | undefined;
  playerDefaultName: string | undefined;
}

const ResetPlayerName: FC<ResetPlayerNameProps> = ({
  id,
  playerName,
  playerDefaultName,
}) => {
  const {
    loading: isLoading,
    onResetPlayerName,
    error,
  } = useResetPlayerName({ id });

  const isNotReset = playerName === playerDefaultName;

  if (isNotReset) {
    return (
      <div>
        У&nbsp;игрока в&nbsp;настоящий момент установлено имя аккаунта
        по&nbsp;умолчанию.
        <br /> Сброс невозможен.
      </div>
    );
  }

  return (
    <ResetClientName
      onResetClientName={onResetPlayerName}
      clientName={playerName}
      clientDefaultName={playerDefaultName}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default ResetPlayerName;
