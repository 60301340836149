import { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './Note.module.scss';
import EditNoteForm from './components/EditNoteForm';
import ViewNote from './components/ViewNote';

interface NotesProps {
  note?: string;
  updateNote: (updatedText: string) => void;
  updateResult?: object | null;
  updateNoteLoading: boolean;
  isEditButtonVisible?: boolean;
  maxNoteLength?: number;
}

const Note: FC<NotesProps> = ({
  note,
  updateNote,
  updateResult,
  updateNoteLoading,
  isEditButtonVisible,
  maxNoteLength,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const onEditForm = () => {
    setIsEditing(true);
  };

  const onGoToViewMode = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (updateResult) {
      setIsEditing(false);
    }
  }, [updateResult]);

  return (
    <div data-testid="wrapper" className={classnames(styles.wrapper)}>
      {isEditing ? (
        <EditNoteForm
          note={note}
          goToViewMode={onGoToViewMode}
          updateNote={updateNote}
          updateNoteLoading={updateNoteLoading}
          maxNoteLength={maxNoteLength}
        />
      ) : (
        <ViewNote
          note={note}
          onEditForm={onEditForm}
          isEditButtonVisible={isEditButtonVisible}
        />
      )}
    </div>
  );
};

export default Note;
