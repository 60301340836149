import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyPageContainer } from '../../components/CompanyPageContainer';
import { CompanyPageProps } from '../../types';
import AffiliatesTable from '../../components/AffiliatesBlock/components/AffiliatesTable';
import { useGetAffiliatesColumns } from '../../components/AffiliatesBlock/components/AffiliatesTable/hooks/useGetAffiliatesColumns';

const Affiliates: FC<CompanyPageProps> = ({ companyId }) => {
  const { columns } = useGetAffiliatesColumns();

  return (
    <AffiliatesTable
      companyId={companyId}
      columns={columns}
      shouldReturnToPrevPage
      isFullScreenTable
    />
  );
};

export default () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <CompanyPageContainer companyId={id}>
      <Affiliates companyId={id} />
    </CompanyPageContainer>
  );
};
