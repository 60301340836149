import { FC } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import Input from 'ui/Input';
import { Dropdown } from 'ui/Dropdown';
import { RewardTariffType } from 'commonTypes';
import { Tooltip } from 'ui/Tooltip';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { MediaCampaignDropdown } from 'commonComponents/MediaCampaignDropdown';
import { RevShareTariffDropdown } from 'commonComponents/RevShareTariffDropdown';
import { CpaTariffDropdown } from 'commonComponents/CpaTariffDropdown';
import { CreatePartnerReferralLinkValues, validate } from './validation';
import { FieldName, mapFieldNameToTitle } from './const';
import { rewardTypeOptions } from '../../../../../../helpers';
import { PartnerBasicData } from '../../../../../../queries/generated/PartnerBasicData';
import { useGenerateReferralCode } from '../../../../../../utils/hooks/useGenerateReferralCode';
import { useCreatePartnerReferralLink } from './hooks/useCreatePartnerReferralLink';

interface CreateReferralLinkFormProps {
  partner: PartnerBasicData;
  isFullScreenTable: boolean;
}

export const CreateReferralLinkForm: FC<CreateReferralLinkFormProps> = ({
  partner,
  isFullScreenTable,
}) => {
  const { referralCodeLoading, referralCode } = useGenerateReferralCode();
  const { id: partnerId, name: partnerName } = partner;

  const initialValues: CreatePartnerReferralLinkValues = {
    [FieldName.Partner]: `#${partnerId} ${partnerName}` || '',
    [FieldName.MediaCampaignId]: '',
    [FieldName.MediaItemName]: '',
    [FieldName.Code]: referralCode || null,
    [FieldName.PaymentModel]: null,
    [FieldName.CPATariffId]: '',
    [FieldName.FallbackTariffId]: '',
    [FieldName.RevShareTariffId]: '',
  };

  const {
    onCreateReferralLinkSubmitHandler,
    createPartnerReferralLinkLoading,
  } = useCreatePartnerReferralLink(isFullScreenTable);

  const onPaymentModalChangeHandler = (
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue'],
    setTouched: FormikHelpers<typeof initialValues>['setTouched']
  ) => {
    setFieldValue(FieldName.CPATariffId, '');
    setFieldValue(FieldName.FallbackTariffId, '');
    setFieldValue(FieldName.RevShareTariffId, '');
    setTouched(
      {
        [FieldName.CPATariffId]: false,
        [FieldName.FallbackTariffId]: false,
        [FieldName.RevShareTariffId]: false,
      },
      false
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) =>
        onCreateReferralLinkSubmitHandler(partnerId, values)
      }
      validate={validate}
      validateOnChange={false}
      enableReinitialize
    >
      {({ isValid, dirty, values, setFieldValue, setTouched }) => (
        <Form>
          <FormRowsWrapper>
            <FormRow isWide>
              <Input
                name={FieldName.Partner}
                label={mapFieldNameToTitle[FieldName.Partner]}
                disabled
              />
            </FormRow>
            <FormRow isWide>
              <Input
                name={FieldName.MediaItemName}
                label={mapFieldNameToTitle[FieldName.MediaItemName]}
                maxLength={50}
              />
            </FormRow>
            <FormRow isWide>
              <MediaCampaignDropdown
                name={FieldName.MediaCampaignId}
                label={mapFieldNameToTitle[FieldName.MediaCampaignId]}
              />
            </FormRow>
            <FormRow isWide>
              <Input
                name={FieldName.Code}
                label={mapFieldNameToTitle[FieldName.Code]}
                maxLength={36}
              />
            </FormRow>
            <FormRow isWide>
              <Dropdown
                onChange={() => {
                  onPaymentModalChangeHandler(setFieldValue, setTouched);
                }}
                name={FieldName.PaymentModel}
                label={mapFieldNameToTitle[FieldName.PaymentModel]}
                options={rewardTypeOptions}
              />
            </FormRow>

            {values[FieldName.PaymentModel] === RewardTariffType.Cpa && (
              <>
                <FormRow isWide>
                  <CpaTariffDropdown
                    name={FieldName.CPATariffId}
                    label={mapFieldNameToTitle[FieldName.CPATariffId]}
                  />
                </FormRow>
                <FormRow isWide>
                  <RevShareTariffDropdown
                    name={FieldName.FallbackTariffId}
                    label={mapFieldNameToTitle[FieldName.FallbackTariffId]}
                  />
                </FormRow>
              </>
            )}

            {values[FieldName.PaymentModel] === RewardTariffType.RevShare && (
              <RevShareTariffDropdown
                name={FieldName.RevShareTariffId}
                label={mapFieldNameToTitle[FieldName.RevShareTariffId]}
              />
            )}
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty ? 'Заполните все поля' : undefined
            }
            placement="right"
          >
            <Button
              size={ButtonSize.Large}
              theme={ButtonTheme.Process}
              type="submit"
              isLoading={
                createPartnerReferralLinkLoading || referralCodeLoading
              }
              disabled={!isValid || !dirty}
            >
              Создать
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
