import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import Input from 'ui/Input';
import { Confirmation } from 'ui/Confirmation';
import { useUpdateCompanyIdentities } from './hooks/useUpdateCompanyIdentities';
import { UpdateCompanyIdentitiesValues, validate } from './validation';
import { useGetCompanyIdentities } from './hooks/useGetCompanyIdentities';

interface UpdateCompanyIdentitiesProps {
  id: string;
}

export const UpdateCompanyIdentities: FC<UpdateCompanyIdentitiesProps> = ({
  id,
}) => {
  const { email } = useGetCompanyIdentities({ id });

  const {
    onUpdateCompanyIdentities,
    loading: updateCompanyIdentitiesLoading,
    error: updateCompanyIdentitiesError,
  } = useUpdateCompanyIdentities({
    id,
  });

  if (!email) {
    return null;
  }

  const initialValues: UpdateCompanyIdentitiesValues = {
    email,
    confirmation: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onUpdateCompanyIdentities(values.email);
      }}
      validate={validate}
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form>
          {updateCompanyIdentitiesError && (
            <Error error={updateCompanyIdentitiesError} />
          )}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input
                name="email"
                label="Почта"
                maxLength={50}
                data-testid="companyEmailField"
              />
            </FormRow>
          </FormRowsWrapper>
          <Confirmation
            label="Чтобы изменить почту напишите «ИЗМЕНИТЬ»"
            loading={updateCompanyIdentitiesLoading}
            buttonText="Изменить"
            isValid={isValid}
            dirty={dirty}
          />
        </Form>
      )}
    </Formik>
  );
};
