import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { CpaGeneralReportData } from '../../queries/generated/CpaGeneralReportData';
import { useGetCpaGeneralReportColumns } from './hooks/useGetCpaGeneralReportColumns';

interface CpaGeneralReportTableProps {
  report?: Array<CpaGeneralReportData>;
  hasTotalRow: boolean;
}

export const CpaGeneralReportTable: FC<CpaGeneralReportTableProps> = ({
  report,
  hasTotalRow,
}) => {
  const { columns } = useGetCpaGeneralReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={report}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
