import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetExportReferralLinkMediaItemCpaReportVariables = SchemaTypes.Exact<{
  currency: SchemaTypes.Scalars['Currency']['input'];
  id: SchemaTypes.Scalars['ID']['input'];
  period: SchemaTypes.DatePeriodInput;
  showAllTimeCpa: SchemaTypes.Scalars['Boolean']['input'];
}>;


export type GetExportReferralLinkMediaItemCpaReport = { __typename: 'Query', mediaItem: { __typename: 'ReferralLinkMediaItem', exportCpaReport: string, id: string } };


export const GetExportReferralLinkMediaItemCpaReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetExportReferralLinkMediaItemCpaReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currency"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Currency"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"period"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DatePeriodInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"showAllTimeCpa"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportCpaReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"currency"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currency"}}},{"kind":"Argument","name":{"kind":"Name","value":"period"},"value":{"kind":"Variable","name":{"kind":"Name","value":"period"}}},{"kind":"Argument","name":{"kind":"Name","value":"showAllTimeCpa"},"value":{"kind":"Variable","name":{"kind":"Name","value":"showAllTimeCpa"}}}]},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetExportReferralLinkMediaItemCpaReport__
 *
 * To run a query within a React component, call `useGetExportReferralLinkMediaItemCpaReport` and pass it any options that fit your needs.
 * When your component renders, `useGetExportReferralLinkMediaItemCpaReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportReferralLinkMediaItemCpaReport({
 *   variables: {
 *      currency: // value for 'currency'
 *      id: // value for 'id'
 *      period: // value for 'period'
 *      showAllTimeCpa: // value for 'showAllTimeCpa'
 *   },
 * });
 */
export function useGetExportReferralLinkMediaItemCpaReport(baseOptions: ApolloReactHooks.QueryHookOptions<GetExportReferralLinkMediaItemCpaReport, GetExportReferralLinkMediaItemCpaReportVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExportReferralLinkMediaItemCpaReport, GetExportReferralLinkMediaItemCpaReportVariables>(GetExportReferralLinkMediaItemCpaReportDocument, options);
      }
export function useGetExportReferralLinkMediaItemCpaReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportReferralLinkMediaItemCpaReport, GetExportReferralLinkMediaItemCpaReportVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExportReferralLinkMediaItemCpaReport, GetExportReferralLinkMediaItemCpaReportVariables>(GetExportReferralLinkMediaItemCpaReportDocument, options);
        }
export type GetExportReferralLinkMediaItemCpaReportHookResult = ReturnType<typeof useGetExportReferralLinkMediaItemCpaReport>;
export type GetExportReferralLinkMediaItemCpaReportLazyQueryHookResult = ReturnType<typeof useGetExportReferralLinkMediaItemCpaReportLazyQuery>;
export type GetExportReferralLinkMediaItemCpaReportQueryResult = Apollo.QueryResult<GetExportReferralLinkMediaItemCpaReport, GetExportReferralLinkMediaItemCpaReportVariables>;