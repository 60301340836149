import { FC } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { MediaItemsTabs } from 'commonComponents/MediaItemsTabs';
import { MediaItemTypeName } from 'commonTypes';
import { useMediaItemsReferralLink } from './hooks/useMediaItemsReferralLink';
import { MediaItemsReferralLinkTable } from './components/MediaItemsReferralLinkTable';
import { MEDIA_ITEMS_BLOCK_TITLE } from '../../const';
import { MediaItemsBlockHeaderContent } from '../MediaItemsBlockHeaderContent';
import { PostbackDisabledNotificationsBlock } from '../PostbackDisabledNotificationsBlock';
import styles from './MediaItemsReferralLink.module.scss';

interface MediaItemsReferralLinkProps {
  setMediaItemsType: (type: MediaItemTypeName) => void;
}

export const MediaItemsReferralLink: FC<MediaItemsReferralLinkProps> =
  withBlockProvider(({ setMediaItemsType }) => {
    const { refetchMediaItems, mediaItems } = useMediaItemsReferralLink();

    return (
      <Block
        title={MEDIA_ITEMS_BLOCK_TITLE}
        id="mediaItemsReferralLink"
        headerContent={
          <MediaItemsBlockHeaderContent
            handleRefetchClick={() => refetchMediaItems()}
          />
        }
        subHeader={
          <MediaItemsTabs
            currentTabId={MediaItemTypeName.ReferralLink}
            setMediaItemsType={setMediaItemsType}
          />
        }
        extendedHeaderClassname={styles.extendedHeader}
        extendedHeader={!!mediaItems && <PostbackDisabledNotificationsBlock />}
        shrinkLoaderWrapper={false}
        emptyText="Медиа элементы не найдены"
        isEmpty={isListEmpty(mediaItems)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(mediaItems) && (
          <MediaItemsReferralLinkTable mediaItemsReferralLink={mediaItems} />
        )}
      </Block>
    );
  });
