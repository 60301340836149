import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { CpaCompanyAffiliateReportData } from '../../queries/generated/CpaCompanyAffiliateReportData';
import { useGetCpaCompanyAffiliateReportColumns } from './hooks/useGetCpaCompanyAffiliateReportColumns';

interface CpaCompanyAffiliateReportTableProps {
  cpaCompanyAffiliateReport?: Array<CpaCompanyAffiliateReportData>;
  hasTotalRow: boolean;
}

export const CpaCompanyAffiliateReportTable: FC<
  CpaCompanyAffiliateReportTableProps
> = ({ cpaCompanyAffiliateReport, hasTotalRow }) => {
  const { columns } = useGetCpaCompanyAffiliateReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={cpaCompanyAffiliateReport}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
