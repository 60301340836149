import { FC } from 'react';
import { CardIcon } from 'ui/Card/CardIcon';
import { AffiliateCard } from '../../../AffiliateCard';
import styles from './ApprovedAffiliates.module.scss';
import { getIconConfig } from './helpers/getIconConfiggetIconConfig';
import { GetCompanyAffiliateApplication } from '../../../../queries/generated/GetCompanyAffiliateApplication';

interface ProcessedAffiliatesProps {
  affiliates: GetCompanyAffiliateApplication['companyAffiliateApplication']['items'];
}

export const ProcessedAffiliates: FC<ProcessedAffiliatesProps> = ({
  affiliates,
}) => (
  <div
    data-testid="approvedAffiliateBlock"
    className={styles.affiliatesContainer}
  >
    {affiliates.map((affiliate) => (
      <AffiliateCard
        key={affiliate.id}
        affiliate={affiliate}
        statusOrCheckbox={
          <CardIcon
            className={styles.iconContainer}
            config={getIconConfig(affiliate.approved)}
          />
        }
      />
    ))}
  </div>
);
