import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { DateFormat, formatDate } from '../../../../../../utils/formatDate';
import { useRevShareRewardTariffModal } from './hooks/useRevShareRewardTariffModal';

interface CpaRewardTariffsModalProps {
  revShareRewardTariffsId: string;
}

export const RevShareRewardTariffModal: FC<CpaRewardTariffsModalProps> = ({
  revShareRewardTariffsId,
}) => {
  const { revShareRewardTariff } = useRevShareRewardTariffModal({
    revShareRewardTariffsId,
  });

  if (!revShareRewardTariff) {
    return null;
  }

  return (
    <DataCard
      rows={[
        {
          title: 'Название',
          value: `RevShare ${revShareRewardTariff.percent || 0}%`,
        },
        {
          title: 'Модель оплаты',
          value: 'RevShare',
        },
        {
          title: 'Процент',
          value: `${revShareRewardTariff.percent}%`,
        },
        {
          title: 'Создан',
          value: formatDate(
            revShareRewardTariff.createdAt,
            DateFormat.FullDate
          ),
        },
      ]}
    />
  );
};
