import { FC } from 'react';
import { useParams } from 'react-router';
import { useGetWithdrawalsColumns } from '../../components/WithdrawalsBlock/hooks/useGetWithdrawalsColumns';
import WithdrawalsTable from '../../components/WithdrawalsBlock/components/WithdrawalsTable/WithdrawalsTable';
import { PlayerPageProps } from '../../types';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

const Withdrawals: FC<PlayerPageProps> = ({ playerId }) => {
  const { columns } = useGetWithdrawalsColumns(true);

  return (
    <WithdrawalsTable
      playerId={playerId}
      columns={columns}
      shouldReturnToPrevPage
      isFullScreenTable
    />
  );
};

export default () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <Withdrawals playerId={id} />
    </PlayerPageContainer>
  );
};
