import { FC } from 'react';
import { useParams } from 'react-router';
import { ExternalMoneyTransfersTable } from '../../components/ExternalMoneyTransfersBlock/components/ExternalMoneyTransfersTable';
import { useGetExternalMoneyTransfersColumns } from '../../components/ExternalMoneyTransfersBlock/components/ExternalMoneyTransfersTable/hooks/useGetExternalMoneyTransfersColumns';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

interface PlayerExternalTransfersProps {
  id: string;
}

export const Component: FC<PlayerExternalTransfersProps> = ({ id }) => {
  const { columns } = useGetExternalMoneyTransfersColumns(true);

  return (
    <ExternalMoneyTransfersTable
      id={id}
      columns={columns}
      isFullScreenTable
      shouldReturnToPrevPage
    />
  );
};

export const ExternalMoneyTransfers = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <Component id={id} />
    </PlayerPageContainer>
  );
};
