import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PartnerMediaCampaignsTable } from '../../components/PartnerMediaCampaignsBlock/components/PartnerMediaCampaignsTable';
import { PartnerPageContainer } from '../../components/PartnerPageContainer/PartnerPageContainer';
import { useGetPartnerMediaCampaignsColumns } from '../../components/PartnerMediaCampaignsBlock/hooks/useGetPartnerMediaCampaignsColumns';

interface ComponentsProps {
  partnerId: string;
}

const Components: FC<ComponentsProps> = ({ partnerId }) => {
  const { columns } = useGetPartnerMediaCampaignsColumns();

  return (
    <PartnerMediaCampaignsTable
      partnerId={partnerId}
      columns={columns}
      shouldReturnToPrevPage
      isFullScreenTable
    />
  );
};

export const PartnerMediaCampaigns = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PartnerPageContainer partnerId={id}>
      <Components partnerId={id} />
    </PartnerPageContainer>
  );
};
