import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { PaymentStatus, Sum } from 'generatedGraphql';
import { useOpenAcceptPaymentForm } from './hooks/useOpenAcceptPaymentForm';
import styles from './UpdatePaymentButtons.module.scss';
import { useDeclineWithdrawalPayment } from './hooks/useDeclineWithdrawalPayment';
import { useCheckIsUpdatePaymentButtonsVisible } from './hooks/useCheckIsUpdatePaymentButtonsVisible';

interface UpdatePaymentButtonsProps {
  paymentId: string;
  paymentStatus: PaymentStatus;
  paymentPaidSum?: Sum | null;
}

export const UpdatePaymentButtons: FC<UpdatePaymentButtonsProps> = ({
  paymentStatus,
  paymentId,
  paymentPaidSum,
}) => {
  const { handleDeclinePayment, loading } = useDeclineWithdrawalPayment();
  const { openAcceptPaymentForm } = useOpenAcceptPaymentForm();

  const { isAcceptButtonVisible, isDeclineButtonVisible } =
    useCheckIsUpdatePaymentButtonsVisible(paymentStatus);

  if (!isAcceptButtonVisible && !isDeclineButtonVisible) {
    return null;
  }

  return (
    <div className={styles.btnContainer}>
      {isAcceptButtonVisible && (
        <Button
          onClick={() =>
            openAcceptPaymentForm({
              paymentId,
              paymentPaidSum,
            })
          }
          disabled={loading}
          theme={ButtonTheme.Success}
        >
          Выполнить
        </Button>
      )}
      {isDeclineButtonVisible && (
        <Button
          onClick={() => handleDeclinePayment({ id: paymentId })}
          isLoading={loading}
          theme={ButtonTheme.Important}
        >
          Отклонить
        </Button>
      )}
    </div>
  );
};
