import React, { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { Form, Formik } from 'formik';
import Error from 'ui/Error';
import { ApolloError } from '@apollo/client';
import styles from './UnDeletion.module.scss';
import { formatDate } from '../../utils/formatDate';

interface UnDeletionProps {
  error?: ApolloError;
  isLoading: boolean;
  signInRestrictionReason?: string;
  deletedAt?: string | null;
  onUnDeleted: () => void;
  isWillDeleted: boolean;
}

const UnDeletion: FC<UnDeletionProps> = ({
  signInRestrictionReason,
  deletedAt,
  onUnDeleted,
  error,
  isLoading,
  isWillDeleted,
}) => {
  let title: string;

  if (isWillDeleted) {
    title = `Игрок будет удален ${deletedAt && formatDate(deletedAt)}`;
  } else {
    title = `Игрок удален`;
  }

  return (
    <Formik initialValues={[]} onSubmit={onUnDeleted} enableReinitialize>
      <Form>
        {error && <Error error={error} />}
        <div className={styles.content}>
          <h2>{title}</h2>
          {signInRestrictionReason && (
            <div className={styles.reason}>{signInRestrictionReason}</div>
          )}
        </div>
        {isWillDeleted && (
          <Button
            theme={ButtonTheme.Important}
            type="submit"
            isLoading={isLoading}
          >
            Отменить
          </Button>
        )}
      </Form>
    </Formik>
  );
};

export default UnDeletion;
