import { FC } from 'react';
import Error from 'ui/Error';
import { MessageBlockWithButton } from 'commonComponents/MessageBlockWithButton';
import { ModalItemsList } from 'ui/Modal/components/ModalItemsList';
import { useGetAffiliateReferralLink } from './hooks/useGetAffiliateReferralLink';
import { AffiliateReferralLinkItem } from './components/AffiliateReferralLinkItem';

interface AffiliateReferralLinksListProps {
  companyId: string;
  companyAffiliateId: string;
}

export const AffiliateReferralLinksList: FC<
  AffiliateReferralLinksListProps
> = ({ companyId, companyAffiliateId }) => {
  const { referralLinks, loading, error, loadMore, isListEmpty } =
    useGetAffiliateReferralLink({
      companyId,
      companyAffiliateId,
    });

  if (error) {
    return <Error error={error} />;
  }

  return (
    <ModalItemsList
      topBlock={
        isListEmpty && (
          <MessageBlockWithButton message="Медиа элементы не найдены" />
        )
      }
      itemsListContent={referralLinks?.edges.map(({ node }) => (
        <AffiliateReferralLinkItem referralLink={node} key={node.id} />
      ))}
      loading={loading}
      paginationConfig={{
        loadMore,
        hasNextPage: !!referralLinks?.pageInfo.hasNextPage,
      }}
    />
  );
};
