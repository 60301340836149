import { FC, useContext, useEffect } from 'react';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { DataCard } from 'commonComponents/DataCard';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { CopyModalRow } from 'ui/CopyModalRow';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { useGetQualifiedReferralConfirmationSetting } from './queries/generated/GetQualifiedReferralConfirmationSetting';
import { getAffiliateNameTitle } from './helpers';
import { getAffiliateInfo } from '../QualifiedReferralConfirmationSettingsTable/helpers';
import {
  qualifiedReferralConfirmationSettingTypeMap,
  UpdateConfirmationSettingSteps,
} from '../../const';
import { ConfirmationSettingModalButtons } from '../ConfirmationSettingModalButtons';
import { UpdateQualifiedReferralConfirmationSetting } from '../UpdateQualifiedReferralConfirmationSetting';

interface QualifiedReferralConfirmationSettingItemProps {
  confirmationSettingId: string;
}

export const QualifiedReferralConfirmationSettingItem: FC<
  QualifiedReferralConfirmationSettingItemProps
> = ({ confirmationSettingId }) => {
  const { state: modalState, dispatch: modalDispatch } =
    useContext(ModalContext);

  const { data, loading, error } = useGetQualifiedReferralConfirmationSetting({
    variables: {
      id: confirmationSettingId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: {
      error,
      toastId: 'get-qualified-referral-confirmation-setting-error',
    },
    hasData: !!data,
  });

  useEffect(() => {
    if (modalState.step === UpdateConfirmationSettingSteps.UpdateStep) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: `Редактирование настройки подтверждения`,
          subTitle: addHashTagToId(confirmationSettingId),
        },
      });
    } else {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: addHashTagToId(confirmationSettingId),
          subTitle: 'Настройки подтверждения',
        },
      });
    }
  }, [confirmationSettingId, modalDispatch, modalState.step]);

  if (!data) {
    return null;
  }

  const { createdAt, type, code, comment, updatedAt, affiliate } =
    data.qualifiedReferralConfirmationSetting;

  const { email } = getAffiliateInfo(affiliate);

  if (modalState.step === UpdateConfirmationSettingSteps.UpdateStep) {
    return (
      <UpdateQualifiedReferralConfirmationSetting
        confirmationSettingId={confirmationSettingId}
        comment={comment}
        settingType={type}
      />
    );
  }

  return (
    <DataCard
      rows={[
        {
          title: 'Тип настройки',
          value: qualifiedReferralConfirmationSettingTypeMap[type],
        },
        {
          title: getAffiliateNameTitle(affiliate),
          value: affiliate.name,
        },
        email && {
          title: 'Почта',
          value: email,
        },
        code && {
          title: 'Реферальный код',
          value: (
            <CopyModalRow value={code} toastText="Реферальный код скопирован" />
          ),
        },
        {
          title: 'Комментарий',
          value: comment,
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
        {
          title: 'Обновлен',
          value: formatDate(updatedAt, DateFormat.FullDate),
        },
      ]}
      customFooter={
        <ConfirmationSettingModalButtons
          confirmationSettingId={confirmationSettingId}
        />
      }
    />
  );
};
