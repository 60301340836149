import React, { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { useButtonsUpdatePayment } from './useButtonsUpdatePayment';
import styles from './ButtonsUpdatePayment.module.scss';
import { WithdrawalModalSteps } from '../../types';
import { PaymentData } from '../../queries/generated/PaymentData';

interface ButtonsUpdatePaymentProps {
  payment: PaymentData;
  isAcceptButtonVisible?: boolean;
  isDeclineButtonVisible?: boolean;
}

const ButtonsUpdatePayment: FC<ButtonsUpdatePaymentProps> = ({
  payment,
  isAcceptButtonVisible,
  isDeclineButtonVisible,
}) => {
  const { updateModalStep, onDeclinePayment, loading } =
    useButtonsUpdatePayment(payment);

  if (!isAcceptButtonVisible && !isDeclineButtonVisible) {
    return null;
  }

  const paymentId = payment.id;
  const paymentPaidSum = payment.sum;

  return (
    <div className={styles.btnContainer}>
      {isAcceptButtonVisible && (
        <Button
          className={styles.btn}
          onClick={() =>
            updateModalStep({
              step: WithdrawalModalSteps.AcceptPayment,
              paymentId,
              paymentPaidSum,
            })
          }
          isLoading={loading}
          theme={ButtonTheme.Success}
        >
          Выполнить
        </Button>
      )}
      {isDeclineButtonVisible && (
        <Button
          className={styles.btn}
          onClick={() => onDeclinePayment({ id: paymentId })}
          isLoading={loading}
          theme={ButtonTheme.Important}
        >
          Отклонить
        </Button>
      )}
    </div>
  );
};

export default ButtonsUpdatePayment;
