import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetGenerateReferralLinkCodeVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type GetGenerateReferralLinkCode = { __typename: 'Query', generateReferralLinkCode: any };


export const GetGenerateReferralLinkCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetGenerateReferralLinkCode"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateReferralLinkCode"}}]}}]} as unknown as DocumentNode;

/**
 * __useGetGenerateReferralLinkCode__
 *
 * To run a query within a React component, call `useGetGenerateReferralLinkCode` and pass it any options that fit your needs.
 * When your component renders, `useGetGenerateReferralLinkCode` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenerateReferralLinkCode({
 *   variables: {
 *   },
 * });
 */
export function useGetGenerateReferralLinkCode(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGenerateReferralLinkCode, GetGenerateReferralLinkCodeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGenerateReferralLinkCode, GetGenerateReferralLinkCodeVariables>(GetGenerateReferralLinkCodeDocument, options);
      }
export function useGetGenerateReferralLinkCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGenerateReferralLinkCode, GetGenerateReferralLinkCodeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGenerateReferralLinkCode, GetGenerateReferralLinkCodeVariables>(GetGenerateReferralLinkCodeDocument, options);
        }
export type GetGenerateReferralLinkCodeHookResult = ReturnType<typeof useGetGenerateReferralLinkCode>;
export type GetGenerateReferralLinkCodeLazyQueryHookResult = ReturnType<typeof useGetGenerateReferralLinkCodeLazyQuery>;
export type GetGenerateReferralLinkCodeQueryResult = Apollo.QueryResult<GetGenerateReferralLinkCode, GetGenerateReferralLinkCodeVariables>;