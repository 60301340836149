import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PostbackReferralLinkData } from './PostbackReferralLinkData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPostbackReferralLinkVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPostbackReferralLink = { __typename: 'Query', mediaItem: { __typename: 'ReferralLinkMediaItem', createdAt: string, id: string, name: string, referralLink: string, mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string }, mediaItemRewardTariff: { __typename: 'MediaItemCpaRewardTariff', rewardTariff: { __typename: 'CpaRewardTariff', id: string, title: string } } | { __typename: 'MediaItemRevShareRewardTariff', rewardTariff: { __typename: 'RevShareRewardTariff', id: string, title: string } } } };


export const GetPostbackReferralLinkDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPostbackReferralLink"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PostbackReferralLinkData"}}]}}]}},...PostbackReferralLinkData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPostbackReferralLink__
 *
 * To run a query within a React component, call `useGetPostbackReferralLink` and pass it any options that fit your needs.
 * When your component renders, `useGetPostbackReferralLink` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostbackReferralLink({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPostbackReferralLink(baseOptions: ApolloReactHooks.QueryHookOptions<GetPostbackReferralLink, GetPostbackReferralLinkVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPostbackReferralLink, GetPostbackReferralLinkVariables>(GetPostbackReferralLinkDocument, options);
      }
export function useGetPostbackReferralLinkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPostbackReferralLink, GetPostbackReferralLinkVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPostbackReferralLink, GetPostbackReferralLinkVariables>(GetPostbackReferralLinkDocument, options);
        }
export type GetPostbackReferralLinkHookResult = ReturnType<typeof useGetPostbackReferralLink>;
export type GetPostbackReferralLinkLazyQueryHookResult = ReturnType<typeof useGetPostbackReferralLinkLazyQuery>;
export type GetPostbackReferralLinkQueryResult = Apollo.QueryResult<GetPostbackReferralLink, GetPostbackReferralLinkVariables>;