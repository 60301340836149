import { FC, ReactElement } from 'react';
import classnames from 'classnames';
import styles from './ClientDataRow.module.scss';

interface ClientDataRowProps {
  title: string;
  value?: string | ReactElement | null;
  alignRightValue?: ReactElement | string | null;
  className?: string;
  titleColumnMinWidth?: number;
}

export const ClientDataRow: FC<ClientDataRowProps> = ({
  title,
  value,
  alignRightValue,
  className,
  titleColumnMinWidth,
}) => (
  <div className={classnames(styles.row, className)}>
    <div
      className={styles.title}
      style={{ minWidth: `${titleColumnMinWidth}px` }}
    >
      {title}:
    </div>
    {value && <div className={styles.value}>{value}</div>}
    {!value && <div className={styles.noValue}>Нет данных</div>}
    {alignRightValue}
  </div>
);
