import React, { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useParams } from 'react-router';
import { RestrictionTypeName } from 'commonTypes';
import RestrictionModal from '../components/RestrictionModal/RestrictionModal';
import { LastRestriction } from '../types';
import {
  getRestrictionIconByType,
  getStatusAndIconConfig,
  restrictionTypeMap,
} from '../helpers';
import { RestrictionsData } from '../queries/generated/RestrictionsData';
import { RestrictionsBasicData } from '../queries/generated/RestrictionsBasicData';

type RestrictionType =
  | LastRestriction
  | RestrictionsData
  | RestrictionsBasicData;

interface OpenRestrictionProps<T extends RestrictionType> {
  restriction: T;
  initiatorId?: string;
}

interface PlayerData<T extends RestrictionType> {
  openRestrictionItem: (data: OpenRestrictionProps<T>) => void;
}

export const useRestrictionOpenModal = <
  T extends RestrictionType
>(): PlayerData<T> => {
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const { id: playerId } = useParams();

  const openRestrictionItem = useCallback(
    ({ restriction, initiatorId }: OpenRestrictionProps<T>) => {
      if (!playerId) {
        return;
      }

      const typeName = restriction.__typename;
      const title = restrictionTypeMap[typeName];
      const iconType = getRestrictionIconByType(
        typeName as RestrictionTypeName
      );
      const { id } = restriction;
      const {
        iconConfig: { iconColor },
      } = getStatusAndIconConfig(restriction);

      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title,
          iconConfig: { icon: iconType, iconColor },
          subTitle: `#${id}`,
          initiatorId,
          content: <RestrictionModal playerId={playerId} restrictionId={id} />,
        },
      });
    },
    [modalDispatch, playerId]
  );

  return {
    openRestrictionItem,
  };
};
