import { FC, PropsWithChildren } from 'react';
import { ClientDataBlock } from 'commonComponents/ClientDataBlock/ClientDataBlock';
import { withBlockProvider } from 'ui/Block/store';
import { useCompanyData } from './hooks/useCompanyData';

const TITLE_COLUMN_MIN_WIDTH = 175;

interface CompanyDataBlockProps {
  id: string;
}

const Component: FC<PropsWithChildren<CompanyDataBlockProps>> = ({ id }) => {
  const { refetchCompanyData, clientData } = useCompanyData({ id });

  return (
    <ClientDataBlock
      blockId="companyData"
      title="Данные компании"
      onUpdate={refetchCompanyData}
      data={clientData}
      titleColumnMinWidth={TITLE_COLUMN_MIN_WIDTH}
    />
  );
};

export const CompanyDataBlock = withBlockProvider(Component);
