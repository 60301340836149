import { FC } from 'react';
import Button, { LinkButtonTheme } from 'ui/Button';
import styles from './Footer.module.scss';

interface FooterProps {
  onReset: () => void;
  onSubmit: () => void;
}

export const Footer: FC<FooterProps> = ({ onReset, onSubmit }) => (
  <div className={styles.footerContainer}>
    <Button theme={LinkButtonTheme.Important} linkStyled onClick={onReset}>
      Сбросить
    </Button>
    <Button linkStyled onClick={onSubmit}>
      Применить
    </Button>
  </div>
);
