import { FC } from 'react';
import { useModalManageData } from 'ui/Modal';
import { DataCard } from 'commonComponents/DataCard';
import { useGetSignInRestriction } from '../../queries/generated/GetSignInRestriction';
import { DateFormat, formatDate } from '../../../../../../utils/formatDate';
import { nbsp, signInRestrictionStatusMap } from '../../../../../../const';
import { getStatusConfig } from '../SignInRestrictionHistoryTable/helpers';
import styles from './SignInRestrictionHistoryModal.module.scss';

interface SignInRestrictionHistoryModalProps {
  signInRestrictionId: string;
}

export const SignInRestrictionHistoryModal: FC<
  SignInRestrictionHistoryModalProps
> = ({ signInRestrictionId }) => {
  const { data, loading, error } = useGetSignInRestriction({
    variables: {
      signInRestrictionId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-sign-in-restriction-error' },
    hasData: !!data,
  });

  if (!data?.signInRestriction) {
    return null;
  }

  const { reason, lockedAt, expiredAt, status } = data.signInRestriction;

  return (
    <>
      <div className={styles.text}>{reason}</div>
      <DataCard
        statusConfig={
          status && {
            status: signInRestrictionStatusMap[status],
            iconConfig: getStatusConfig(status)[1],
          }
        }
        rows={[
          {
            title: 'Создана',
            value: formatDate(lockedAt, DateFormat.FullDate),
          },
          {
            title: 'Окончание',
            value: expiredAt && formatDate(expiredAt, DateFormat.FullDate),
            emptyText: `Без${nbsp}даты окончания`,
          },
        ]}
      />
    </>
  );
};
