import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetExportCpaGeneralReportVariables = SchemaTypes.Exact<{
  currency: SchemaTypes.Scalars['Currency']['input'];
  period: SchemaTypes.DatePeriodInput;
  showAllTimeCpa: SchemaTypes.Scalars['Boolean']['input'];
}>;


export type GetExportCpaGeneralReport = { __typename: 'Query', exportCpaGeneralReport: string };


export const GetExportCpaGeneralReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetExportCpaGeneralReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currency"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Currency"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"period"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DatePeriodInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"showAllTimeCpa"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportCpaGeneralReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"currency"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currency"}}},{"kind":"Argument","name":{"kind":"Name","value":"period"},"value":{"kind":"Variable","name":{"kind":"Name","value":"period"}}},{"kind":"Argument","name":{"kind":"Name","value":"showAllTimeCpa"},"value":{"kind":"Variable","name":{"kind":"Name","value":"showAllTimeCpa"}}}]}]}}]} as unknown as DocumentNode;

/**
 * __useGetExportCpaGeneralReport__
 *
 * To run a query within a React component, call `useGetExportCpaGeneralReport` and pass it any options that fit your needs.
 * When your component renders, `useGetExportCpaGeneralReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportCpaGeneralReport({
 *   variables: {
 *      currency: // value for 'currency'
 *      period: // value for 'period'
 *      showAllTimeCpa: // value for 'showAllTimeCpa'
 *   },
 * });
 */
export function useGetExportCpaGeneralReport(baseOptions: ApolloReactHooks.QueryHookOptions<GetExportCpaGeneralReport, GetExportCpaGeneralReportVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExportCpaGeneralReport, GetExportCpaGeneralReportVariables>(GetExportCpaGeneralReportDocument, options);
      }
export function useGetExportCpaGeneralReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportCpaGeneralReport, GetExportCpaGeneralReportVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExportCpaGeneralReport, GetExportCpaGeneralReportVariables>(GetExportCpaGeneralReportDocument, options);
        }
export type GetExportCpaGeneralReportHookResult = ReturnType<typeof useGetExportCpaGeneralReport>;
export type GetExportCpaGeneralReportLazyQueryHookResult = ReturnType<typeof useGetExportCpaGeneralReportLazyQuery>;
export type GetExportCpaGeneralReportQueryResult = Apollo.QueryResult<GetExportCpaGeneralReport, GetExportCpaGeneralReportVariables>;