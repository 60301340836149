import {
  FC,
  ReactNode,
  SyntheticEvent,
  useState,
  PropsWithChildren,
} from 'react';
import classnames from 'classnames';
import { Collapse as ReactCollapse } from 'react-collapse';
import { CardIconConfig } from 'ui/Card/CardIcon';
import { CardHeader, HeaderContent } from 'ui/Card/CardHeader';
import CardStatus from 'ui/Card/CardStatus';
import { CardDataList, DataList } from 'ui/Card/CardDataList';
import { ProgressBar, ProgressBarProps } from 'ui/ProgressBar';
import CardInfo from 'ui/Card/CardInfo';
import styles from './Card.module.scss';

export interface CardTag {
  id: string;
  content: string;
}

interface CardProps {
  headerContent?: HeaderContent;
  customHeaderContent?: ReactNode;
  tags?: Array<CardTag>;
  info?: Array<string | JSX.Element>;
  comment?: string | JSX.Element;
  dataList?: Array<DataList>;
  actions?: ReactNode;
  statusColor?: CardStatusColor;
  statusText?: string;
  iconConfig?: CardIconConfig;
  progressBarConfig?: ProgressBarProps;
}

export enum CardStatusColor {
  GREY = 'grey',
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
}

export const Card: FC<PropsWithChildren<CardProps>> = ({
  headerContent,
  customHeaderContent,
  tags,
  info,
  comment,
  dataList,
  actions,
  statusColor,
  statusText,
  iconConfig,
  progressBarConfig,
  children,
}) => {
  const [opened, setOpened] = useState(false);

  const onMouseUp = (e: SyntheticEvent): void => {
    if (
      !window.getSelection()?.toString() &&
      !(e.target as HTMLElement).closest('button') &&
      (children || dataList?.length || statusText || actions)
    ) {
      setOpened(!opened);
    }
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div
        className={classnames(
          styles.wrapper,
          children ||
            dataList?.length ||
            statusText ||
            actions ||
            progressBarConfig
            ? styles.wrapperOpenable
            : ''
        )}
        role="button"
        onMouseUp={onMouseUp}
      >
        <CardHeader
          headerContent={headerContent}
          customHeaderContent={customHeaderContent}
          iconConfig={iconConfig}
        />
        {statusText || dataList?.length || actions || progressBarConfig ? (
          <ReactCollapse
            isOpened={opened}
            theme={{
              collapse: styles.headerCollapseWrapper,
              content: styles.headerCollapseContent,
            }}
          >
            {statusText && (
              <CardStatus statusColor={statusColor} statusText={statusText} />
            )}
            {progressBarConfig && <ProgressBar {...progressBarConfig} />}
            {!!dataList?.length && <CardDataList dataList={dataList} />}
            <div className={styles.actions}>{actions}</div>
          </ReactCollapse>
        ) : null}
        {tags && tags.length && (
          <div className={styles.tagsContainer}>
            {tags.map(({ content, id }) => (
              <div key={id} className={styles.tag}>
                {content}
              </div>
            ))}
          </div>
        )}
        {info && <CardInfo info={info} statusColor={statusColor} />}
        {comment && <div className={styles.comment}>{comment}</div>}
      </div>
      {children && (
        <ReactCollapse
          isOpened={opened}
          theme={{
            collapse: styles.childrenCollapseWrapper,
            content: styles.childrenCollapseContent,
          }}
        >
          {opened && <div>{children}</div>}
        </ReactCollapse>
      )}
    </>
  );
};
