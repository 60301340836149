import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetLastRestrictionsCreateFormVariables = SchemaTypes.Exact<{
  playerId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetLastRestrictionsCreateForm = { __typename: 'Query', player: { __typename: 'Player', id: string, lastRestrictions: { __typename: 'LastRestrictions', badBankRestriction?: { __typename: 'BadBankRestriction', canceledAt?: string | null, id: string } | null, chargeBackRestriction?: { __typename: 'ChargeBackRestriction', canceledAt?: string | null, id: string } | null } } };


export const GetLastRestrictionsCreateFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLastRestrictionsCreateForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastRestrictions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"badBankRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canceledAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"chargeBackRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canceledAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetLastRestrictionsCreateForm__
 *
 * To run a query within a React component, call `useGetLastRestrictionsCreateForm` and pass it any options that fit your needs.
 * When your component renders, `useGetLastRestrictionsCreateForm` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastRestrictionsCreateForm({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetLastRestrictionsCreateForm(baseOptions: ApolloReactHooks.QueryHookOptions<GetLastRestrictionsCreateForm, GetLastRestrictionsCreateFormVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLastRestrictionsCreateForm, GetLastRestrictionsCreateFormVariables>(GetLastRestrictionsCreateFormDocument, options);
      }
export function useGetLastRestrictionsCreateFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLastRestrictionsCreateForm, GetLastRestrictionsCreateFormVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLastRestrictionsCreateForm, GetLastRestrictionsCreateFormVariables>(GetLastRestrictionsCreateFormDocument, options);
        }
export type GetLastRestrictionsCreateFormHookResult = ReturnType<typeof useGetLastRestrictionsCreateForm>;
export type GetLastRestrictionsCreateFormLazyQueryHookResult = ReturnType<typeof useGetLastRestrictionsCreateFormLazyQuery>;
export type GetLastRestrictionsCreateFormQueryResult = Apollo.QueryResult<GetLastRestrictionsCreateForm, GetLastRestrictionsCreateFormVariables>;