import { FC, InputHTMLAttributes, useRef } from 'react';
import classnames from 'classnames';
import { FormFieldWrapper } from 'ui/FormFieldWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import styles from './InputSearch.module.scss';
import { useInputManagement } from '../hooks/useInputManagement';
import { CommonProps } from '../types';

type OriginalInputAttributes = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'name'
>;

type InputSearchProps = Omit<CommonProps, 'label' | 'inputSize'> &
  OriginalInputAttributes;

export const InputSearch: FC<InputSearchProps> = ({
  name,
  wrapperClassName,
  inputClassName,
  autoFocus,
  disabled,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { handleChange, handleKeyDown, field, touched, error } =
    useInputManagement({ name, inputRef, autoFocus, onChange });

  return (
    <FormFieldWrapper
      name={name}
      wrapperClassName={classnames(
        wrapperClassName,
        styles.searchWrapper,
        field.value && styles.searchWrapperNotEmpty
      )}
    >
      <FontAwesomeIcon
        className={styles.searchIcon}
        data-testid="searchIcon"
        icon={faSearch}
      />
      <input
        className={classnames(
          inputClassName,
          styles.searchInput,
          error && (touched || field.value) && styles.error
        )}
        value={field.value || ''}
        onKeyDown={handleKeyDown}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={field.onBlur}
        name={field.name}
        disabled={disabled}
        ref={inputRef}
        {...rest}
      />
    </FormFieldWrapper>
  );
};
