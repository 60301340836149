import { FC } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { AffiliateTypeName } from 'commonTypes';
import SearchInput from 'ui/SearchInput';
import { Route } from 'src/router/routes.const';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import { RevSharePartnerReportTable } from './components/RevSharePartnerReportTable';
import { useGetRevSharePartnerReport } from './hooks/useGetRevSharePartnerReport';
import { STATISTIC_NO_DATA } from '../../const';
import { BlockSubHeaderAffiliateInfo } from '../../components/BlockSubHeaderAffiliateInfo';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { useGetExportPartnerRevShareReport } from './hooks/useGetExportPartnerRevShareReport';
import {
  REV_SHARE_PARTNER_REPORT_INPUT_NAME,
  REV_SHARE_PARTNER_REPORT_SEARCH_PLACEHOLDER,
  REV_SHARE_PARTNER_STATISTIC_BLOCK_TITLE,
} from './const';

interface RevSharePartnerReportProps {
  partnerId: string;
}
const Component: FC<RevSharePartnerReportProps> = withBlockProvider(
  ({ partnerId }) => {
    const { refetch, revSharePartnerReport, partner, hasTotalRow, loadReport } =
      useGetRevSharePartnerReport(partnerId);

    const exportReportConfig = useGetExportPartnerRevShareReport({
      partnerId,
    });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();

    const customPathToPrevPage = generatePathWithPeriodQueryParams({
      originalPath: Route.STATISTIC,
    });

    const listExist = hasList(revSharePartnerReport);

    return (
      <>
        <SearchInput
          name={REV_SHARE_PARTNER_REPORT_INPUT_NAME}
          placeholder={REV_SHARE_PARTNER_REPORT_SEARCH_PLACEHOLDER}
          onSubmit={loadReport}
        />
        <Block
          title={REV_SHARE_PARTNER_STATISTIC_BLOCK_TITLE}
          id="revSharePartnerStatistic"
          headerContent={
            <StatisticBlockHeaderContent
              hasList={listExist}
              exportReportConfig={exportReportConfig}
              handleRefetchClick={refetch}
            />
          }
          subHeader={
            partner && (
              <BlockSubHeaderAffiliateInfo
                name={partner.name}
                email={partner.email}
                affiliateType={AffiliateTypeName.Partner}
              />
            )
          }
          shrinkLoaderWrapper={false}
          emptyText={STATISTIC_NO_DATA}
          isEmpty={isListEmpty(revSharePartnerReport)}
          shouldReturnToPrevPage
          isPermanentOpened
          customPathToPrevPage={customPathToPrevPage}
        >
          {listExist && (
            <RevSharePartnerReportTable
              revSharePartnerReport={revSharePartnerReport}
              hasTotalRow={hasTotalRow}
            />
          )}
        </Block>
      </>
    );
  }
);

export const RevSharePartnerReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <SearchContextProvider pageType={PageTypeStorage.RevSharePartnerReport}>
      <Component partnerId={id} />
    </SearchContextProvider>
  );
};
