import { FC } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import Input from 'ui/Input';
import { Confirmation } from 'ui/Confirmation';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { ApolloError } from '@apollo/client';
import {
  radioButtonExpired,
  radioButtonSignInRestrictionType,
  SignInRestrictionType,
} from 'commonComponents/Ban/consts';
import { DatePickerWithInput } from 'src/ui/DatePickerWithInput';
import { RadioButton } from 'ui/RadioButton';
import { CreateSingInRestriction } from './types';
import styles from './Ban.module.scss';
import { validate } from './validation';
import { nbsp } from '../../const';

interface BanProps {
  onCreateSingInRestriction: ({
    expiredAt,
    expired,
    reason,
    signInRestrictionType,
  }: CreateSingInRestriction) => void;
  error?: ApolloError;
  isLoading: boolean;
}

const Ban: FC<BanProps> = ({ onCreateSingInRestriction, error, isLoading }) => {
  const initialValues = {
    expiredAt: null,
    reason: '',
    signInRestrictionType: SignInRestrictionType.NotProtected,
    expired: 'infinity',
    confirmation: '',
  };

  const handleClickSignInRestrictionType = (
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue']
  ) => {
    setFieldValue('expiredAt', null);
    setFieldValue('expired', 'infinity');
    setFieldValue('signInRestrictionType', SignInRestrictionType.Protected);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onCreateSingInRestriction}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ values, setFieldValue, isValid, dirty }) => {
        const canChooseDate =
          values.signInRestrictionType !== SignInRestrictionType.Protected;

        return (
          <Form>
            {error && <Error error={error} />}
            <FormRowsWrapper>
              <div className={styles.titleCheckbox}>Тип блокировки</div>
              <FormRow isWide>
                <RadioButton
                  name={radioButtonSignInRestrictionType}
                  value={SignInRestrictionType.NotProtected}
                >
                  Обычная
                </RadioButton>
              </FormRow>
              <FormRow isWide>
                <RadioButton
                  name={radioButtonSignInRestrictionType}
                  value={SignInRestrictionType.Protected}
                  onChange={() =>
                    handleClickSignInRestrictionType(setFieldValue)
                  }
                >{`Без${nbsp}права на${nbsp}восстановление`}</RadioButton>
              </FormRow>
              <FormRow isWide>
                <Input.Textarea
                  placeholder="Укажите причину"
                  name="reason"
                  label="Причина"
                />
              </FormRow>
              <div className={styles.titleCheckbox}>
                Дата автоматической разблокировки
              </div>
              <FormRow isWide>
                <RadioButton
                  name={radioButtonExpired}
                  onChange={() => {
                    setFieldValue('expiredAt', null);
                  }}
                  value="infinity"
                  disabled={!canChooseDate}
                >
                  Пока не разблокируют
                </RadioButton>
              </FormRow>
              <FormRow isWide>
                <RadioButton
                  name={radioButtonExpired}
                  value="withDate"
                  disabled={!canChooseDate}
                >
                  С&nbsp;указанием даты
                </RadioButton>
              </FormRow>
              <FormRow isWide>
                <div className={styles.datePicker}>
                  {/* TODO fix error in validation */}
                  <DatePickerWithInput
                    onChange={(value) => {
                      setFieldValue('expiredAt', value);
                    }}
                    disabled={values.expired === 'infinity' || !canChooseDate}
                    name="expiredAt"
                    label="Дата окончания блокировки"
                    minDate={new Date()}
                  />
                </div>
              </FormRow>
              <Confirmation
                label="Чтобы заблокировать игрока, напишите «ЗАБЛОКИРОВАТЬ»"
                loading={isLoading}
                buttonText="Заблокировать"
                isValid={isValid}
                dirty={dirty}
                submitValidationText="Необходимо корректно заполнить все поля"
              />
            </FormRowsWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Ban;
