import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { MediaCampaignData } from '../../../../queries/generated/MediaCampaignData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetMediaCampaignVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetMediaCampaign = { __typename: 'Query', mediaCampaign: { __typename: 'MediaCampaign', createdAt: string, id: string, name: string, url: string } };


export const GetMediaCampaignDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMediaCampaign"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaCampaign"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaCampaignData"}}]}}]}},...MediaCampaignData.definitions]} as unknown as DocumentNode;

/**
 * __useGetMediaCampaign__
 *
 * To run a query within a React component, call `useGetMediaCampaign` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaCampaign` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaCampaign({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaCampaign(baseOptions: ApolloReactHooks.QueryHookOptions<GetMediaCampaign, GetMediaCampaignVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMediaCampaign, GetMediaCampaignVariables>(GetMediaCampaignDocument, options);
      }
export function useGetMediaCampaignLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaCampaign, GetMediaCampaignVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMediaCampaign, GetMediaCampaignVariables>(GetMediaCampaignDocument, options);
        }
export type GetMediaCampaignHookResult = ReturnType<typeof useGetMediaCampaign>;
export type GetMediaCampaignLazyQueryHookResult = ReturnType<typeof useGetMediaCampaignLazyQuery>;
export type GetMediaCampaignQueryResult = Apollo.QueryResult<GetMediaCampaign, GetMediaCampaignVariables>;