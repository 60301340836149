import { useCallback } from 'react';
import { round } from 'lodash-es';
import styles from './styles/StickyHeader.module.scss';

export interface HeaderConfig {
  title: string;
  width: number;
  textAlign?: string;
}

interface Value {
  getWidth: (config: Array<HeaderConfig>) => string;
  changeHeaderStyles: (obj: changeHeaderStylesProps) => void;
  getBaseLeftDistance: (tableContainer: HTMLDivElement | null) => number;
}

interface changeHeaderStylesProps {
  headerTable: HTMLTableRowElement | null;
  header: HTMLDivElement | null;
}

let multiplier: number;
const animationY = 40;
let headerInitialTop: number | undefined;

export const useStickyHeader = (): Value => {
  const getWidth = (config: Array<HeaderConfig>): string => {
    const mainLayout = document?.querySelector('#mainLayout');
    let width =
      (mainLayout &&
        parseInt(getComputedStyle(mainLayout)?.paddingLeft, 10) +
          parseInt(getComputedStyle(mainLayout)?.paddingRight, 10)) ||
      0;

    config.forEach((item) => {
      width += item.width;
    });

    return `${width}px`;
  };

  const changeHeaderStyles = useCallback(
    ({ header, headerTable }: changeHeaderStylesProps): void => {
      if (!headerInitialTop) {
        headerInitialTop = headerTable?.getBoundingClientRect().top;
      }

      const playerHeaderHeight =
        document.querySelector('#header')?.clientHeight || 0;

      if (!header) return;
      multiplier = headerInitialTop
        ? round(
            Math.min(
              Math.max(
                window.scrollY + playerHeaderHeight - headerInitialTop,
                0
              ),
              animationY
            ) / animationY,
            2
          )
        : 0;

      const defaultHeaderTable = headerTable;
      const stickyHeader = header;

      if (multiplier > 0) {
        header.classList.add(styles.sticky);
        if (defaultHeaderTable) {
          defaultHeaderTable.style.opacity = '0';
        }
      } else {
        header.classList.remove(styles.sticky);
        if (defaultHeaderTable) {
          defaultHeaderTable.style.opacity = '1';
        }
      }

      stickyHeader.style.background = `rgba(255, 255, 255, ${multiplier})`;
    },
    []
  );

  const getBaseLeftDistance = (
    tableContainer: HTMLDivElement | null
  ): number => {
    const mainLayout = document?.querySelector('#mainLayout');

    const paddingLeft =
      (mainLayout && parseInt(getComputedStyle(mainLayout).paddingLeft, 10)) ||
      0;
    const initialLeft = tableContainer?.getBoundingClientRect().left || 0;

    return initialLeft - paddingLeft;
  };

  return {
    getWidth,
    changeHeaderStyles,
    getBaseLeftDistance,
  };
};
