import { FC } from 'react';
import { PromoCodeBonusAction } from 'generatedGraphql';
import { KeyValueRow } from 'ui/KeyValueRow';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { nbsp } from 'src/const';
import { PromoCodeActionBlockContainer } from '../PromoCodeActionBlockContainer';

interface BonusActionBlockProps {
  bonusAction: PromoCodeBonusAction;
}

export const BonusActionBlock: FC<BonusActionBlockProps> = ({
  bonusAction,
}) => (
  <PromoCodeActionBlockContainer title={`Связь с${nbsp}бонусом`}>
    <>
      <KeyValueRow
        title="Сумма"
        value={formatSumWithCurrency(
          bonusAction.amount.amount,
          bonusAction.amount.currency
        )}
      />
      <KeyValueRow title="Вейджер" value={bonusAction.wager} />
      <KeyValueRow title="Кэшаут" value={bonusAction.cashout} />
      <KeyValueRow
        title="Минимальный баланс"
        value={
          bonusAction.minAccountBalanceAmount &&
          formatSumWithCurrency(
            bonusAction.minAccountBalanceAmount.amount,
            bonusAction.minAccountBalanceAmount.currency
          )
        }
      />
    </>
  </PromoCodeActionBlockContainer>
);
