import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GenerateReferralLinkCodeVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type GenerateReferralLinkCode = { __typename: 'Query', generateReferralLinkCode: any };


export const GenerateReferralLinkCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GenerateReferralLinkCode"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateReferralLinkCode"}}]}}]} as unknown as DocumentNode;

/**
 * __useGenerateReferralLinkCode__
 *
 * To run a query within a React component, call `useGenerateReferralLinkCode` and pass it any options that fit your needs.
 * When your component renders, `useGenerateReferralLinkCode` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateReferralLinkCode({
 *   variables: {
 *   },
 * });
 */
export function useGenerateReferralLinkCode(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateReferralLinkCode, GenerateReferralLinkCodeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GenerateReferralLinkCode, GenerateReferralLinkCodeVariables>(GenerateReferralLinkCodeDocument, options);
      }
export function useGenerateReferralLinkCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateReferralLinkCode, GenerateReferralLinkCodeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GenerateReferralLinkCode, GenerateReferralLinkCodeVariables>(GenerateReferralLinkCodeDocument, options);
        }
export type GenerateReferralLinkCodeHookResult = ReturnType<typeof useGenerateReferralLinkCode>;
export type GenerateReferralLinkCodeLazyQueryHookResult = ReturnType<typeof useGenerateReferralLinkCodeLazyQuery>;
export type GenerateReferralLinkCodeQueryResult = Apollo.QueryResult<GenerateReferralLinkCode, GenerateReferralLinkCodeVariables>;