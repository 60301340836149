import { FC } from 'react';
import { generatePath } from 'react-router';
import { Route } from 'src/router/routes.const';
import { RouterLink } from 'ui/RouterLink';
import { RouterLinkSize } from 'ui/RouterLink/const';
import { AffiliatePlayerAccountStatus } from 'generatedGraphql';
import { GetPartnerAdditionalData } from '../../queries/generated/GetPartnerAdditionalData';
import { PlayerAccountActionButtons } from '../PlayerAccountActionButtons';

interface PlayerAccountManagementProps {
  playerAccount: GetPartnerAdditionalData['partner']['playerAccount'];
  partnerId: string;
}

export const PlayerAccountManagement: FC<PlayerAccountManagementProps> = ({
  playerAccount,
  partnerId,
}) => {
  if (!playerAccount) return null;

  const { player, status } = playerAccount;

  const routeToPlayer = generatePath(Route.PLAYER, { id: player.id });

  return (
    <>
      <RouterLink path={routeToPlayer} size={RouterLinkSize.Large}>
        {player.name} ({player.email || player.phone})
      </RouterLink>
      {status === AffiliatePlayerAccountStatus.Pending && (
        <PlayerAccountActionButtons partnerId={partnerId} />
      )}
    </>
  );
};
