import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPromoCodeMediaItemByTokenVariables = SchemaTypes.Exact<{
  token: SchemaTypes.Scalars['String']['input'];
}>;


export type GetPromoCodeMediaItemByToken = { __typename: 'Query', promoCodeMediaItemByToken: { __typename: 'PromoCodeMediaItem', id: string } };


export const GetPromoCodeMediaItemByTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPromoCodeMediaItemByToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"promoCodeMediaItemByToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetPromoCodeMediaItemByToken__
 *
 * To run a query within a React component, call `useGetPromoCodeMediaItemByToken` and pass it any options that fit your needs.
 * When your component renders, `useGetPromoCodeMediaItemByToken` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromoCodeMediaItemByToken({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetPromoCodeMediaItemByToken(baseOptions: ApolloReactHooks.QueryHookOptions<GetPromoCodeMediaItemByToken, GetPromoCodeMediaItemByTokenVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPromoCodeMediaItemByToken, GetPromoCodeMediaItemByTokenVariables>(GetPromoCodeMediaItemByTokenDocument, options);
      }
export function useGetPromoCodeMediaItemByTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPromoCodeMediaItemByToken, GetPromoCodeMediaItemByTokenVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPromoCodeMediaItemByToken, GetPromoCodeMediaItemByTokenVariables>(GetPromoCodeMediaItemByTokenDocument, options);
        }
export type GetPromoCodeMediaItemByTokenHookResult = ReturnType<typeof useGetPromoCodeMediaItemByToken>;
export type GetPromoCodeMediaItemByTokenLazyQueryHookResult = ReturnType<typeof useGetPromoCodeMediaItemByTokenLazyQuery>;
export type GetPromoCodeMediaItemByTokenQueryResult = Apollo.QueryResult<GetPromoCodeMediaItemByToken, GetPromoCodeMediaItemByTokenVariables>;