import { FC, useMemo } from 'react';
import classnames from 'classnames';
import { CardStatusColor } from 'ui/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faClock,
  faExclamationCircle,
  faSyncAlt,
} from '@fortawesome/pro-solid-svg-icons';
import styles from './CardStatus.module.scss';
import capitalize from '../../../utils/capitalize';

interface CardStatusProps {
  statusColor?: CardStatusColor;
  statusText: string;
}

const getIcon = (statusColor: CardStatusColor) => {
  switch (statusColor) {
    case CardStatusColor.GREEN:
      return faCheckCircle;
    case CardStatusColor.GREY:
      return faSyncAlt;
    case CardStatusColor.RED:
      return faExclamationCircle;
    case CardStatusColor.YELLOW:
      return faClock;
    default:
      return null;
  }
};

const CardStatus: FC<CardStatusProps> = ({
  statusColor = CardStatusColor.GREY,
  statusText,
}) => {
  const icon = useMemo(() => getIcon(statusColor), [statusColor]);

  return (
    <div
      className={classnames(
        styles.status,
        styles[`status${capitalize(statusColor)}`]
      )}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{statusText}</span>
    </div>
  );
};

export default CardStatus;
