import * as SchemaTypes from '../../../../../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { MediaCampaignForPromoCodeData } from '../../../../../CreatePromoCodeFormBaseStep/queries/generated/MediaCampaignForPromoCodeData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetMediaCampaignForPromoCodeVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetMediaCampaignForPromoCode = { __typename: 'Query', mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string } };


export const GetMediaCampaignForPromoCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMediaCampaignForPromoCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaCampaign"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaCampaignForPromoCodeData"}}]}}]}},...MediaCampaignForPromoCodeData.definitions]} as unknown as DocumentNode;

/**
 * __useGetMediaCampaignForPromoCode__
 *
 * To run a query within a React component, call `useGetMediaCampaignForPromoCode` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaCampaignForPromoCode` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaCampaignForPromoCode({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaCampaignForPromoCode(baseOptions: ApolloReactHooks.QueryHookOptions<GetMediaCampaignForPromoCode, GetMediaCampaignForPromoCodeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMediaCampaignForPromoCode, GetMediaCampaignForPromoCodeVariables>(GetMediaCampaignForPromoCodeDocument, options);
      }
export function useGetMediaCampaignForPromoCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaCampaignForPromoCode, GetMediaCampaignForPromoCodeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMediaCampaignForPromoCode, GetMediaCampaignForPromoCodeVariables>(GetMediaCampaignForPromoCodeDocument, options);
        }
export type GetMediaCampaignForPromoCodeHookResult = ReturnType<typeof useGetMediaCampaignForPromoCode>;
export type GetMediaCampaignForPromoCodeLazyQueryHookResult = ReturnType<typeof useGetMediaCampaignForPromoCodeLazyQuery>;
export type GetMediaCampaignForPromoCodeQueryResult = Apollo.QueryResult<GetMediaCampaignForPromoCode, GetMediaCampaignForPromoCodeVariables>;