import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useOpenModal } from '../../hooks/useOpenModal';
import { WithdrawalListData } from '../../queries/generated/WithdrawalListData';
import { useGetWithdrawalsColumns } from './hooks/useGetWithdrawalsColumns';

interface MediaItemsTableProps {
  withdrawals?: Array<WithdrawalListData>;
}

export const WithdrawalsTable: FC<MediaItemsTableProps> = ({ withdrawals }) => {
  const { columns } = useGetWithdrawalsColumns();
  const { openModal } = useOpenModal();

  return (
    <BlockTable
      onClickOnRow={(withdrawal, initiatorId) =>
        openModal({ withdrawal, initiatorId })
      }
      columns={columns}
      data={withdrawals}
      isFullScreenTable
    />
  );
};
