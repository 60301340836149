import { FC, useState } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { Route } from 'src/router/routes.const';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { RewardTariffType } from 'commonTypes';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import SearchInput from 'ui/SearchInput';
import { STATISTIC_NO_DATA } from '../../const';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { BlockSubHeaderCompanyAffiliateInfo } from '../../components/BlockSubHeaderCompanyAffiliateInfo';
import { useGetCpaCompanyAffiliateReport } from './hooks/useGetCpaCompanyAffiliateReport';
import { CpaCompanyAffiliateReportTable } from './components/CpaCompanyAffiliateReportTable';
import {
  CPA_COMPANY_AFFILIATE_REPORT_INPUT_NAME,
  CPA_COMPANY_AFFILIATE_REPORT_SEARCH_PLACEHOLDER,
  CPA_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE,
} from './const';
import { useGetExportCompanyAffiliateCpaReport } from './hooks/useGetExportCompanyAffiliateCpaReport';

interface CpaCompanyAffiliateReportProps {
  companyId: string;
  affiliateId: string;
}

const Component: FC<CpaCompanyAffiliateReportProps> = withBlockProvider(
  ({ companyId, affiliateId }) => {
    const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
    const {
      data,
      loadReport,
      cpaCompanyAffiliateReportList,
      loading,
      refetch,
      fetchMoreCallback,
      hasNextPage,
      hasTotalRow,
      affiliate,
    } = useGetCpaCompanyAffiliateReport({
      companyId,
      affiliateId,
      isAllTimeCpa,
    });

    const exportReportConfig = useGetExportCompanyAffiliateCpaReport({
      companyId,
      affiliateId,
      isAllTimeCpa,
    });

    useBlockTableComponentState({
      data,
      fetchMoreCallback,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable: true,
    });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();

    const customPathToPrevPage = generatePathWithPeriodQueryParams({
      originalPath: Route.STATISTIC_CPA_COMPANY_PARTNERS,
      params: {
        id: companyId,
      },
    });

    const listExist = hasList(cpaCompanyAffiliateReportList);

    return (
      <>
        <SearchInput
          name={CPA_COMPANY_AFFILIATE_REPORT_INPUT_NAME}
          placeholder={CPA_COMPANY_AFFILIATE_REPORT_SEARCH_PLACEHOLDER}
          onSubmit={loadReport}
        />
        <Block
          title={CPA_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE}
          id="cpaCompanyAffiliateStatistic"
          headerContent={
            <StatisticBlockHeaderContent
              hasList={listExist}
              exportReportConfig={exportReportConfig}
              handleRefetchClick={refetch}
              rewardTariffType={RewardTariffType.Cpa}
              showAllCpa={(value) => setIsAllTimeCpa(value)}
            />
          }
          subHeader={
            affiliate && (
              <BlockSubHeaderCompanyAffiliateInfo
                companyName={affiliate.company.name}
                affiliateName={affiliate.name}
              />
            )
          }
          shrinkLoaderWrapper={false}
          emptyText={STATISTIC_NO_DATA}
          isEmpty={isListEmpty(cpaCompanyAffiliateReportList)}
          shouldReturnToPrevPage
          isPermanentOpened
          customPathToPrevPage={customPathToPrevPage}
        >
          {listExist && (
            <CpaCompanyAffiliateReportTable
              cpaCompanyAffiliateReport={cpaCompanyAffiliateReportList}
              hasTotalRow={hasTotalRow}
            />
          )}
        </Block>
      </>
    );
  }
);

export const CpaCompanyAffiliateReport = () => {
  const { companyId, affiliateId } = useParams();

  if (!companyId || !affiliateId) {
    return null;
  }

  return (
    <SearchContextProvider pageType={PageTypeStorage.CpaCompanyAffiliateReport}>
      <Component companyId={companyId} affiliateId={affiliateId} />
    </SearchContextProvider>
  );
};
