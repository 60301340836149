import { FC } from 'react';
import { SuspectState } from 'generatedGraphql';
import { SuspiciousCell } from 'ui/Table/components/SuspiciousCell';
import { nbsp } from 'src/const';
import { useSuspiciousReferralActions } from './hooks/useSuspiciousReferralActions';

interface SuspiciousCellWrapperProps {
  id: string;
  suspectState: SuspectState;
}

export const SuspiciousCellWrapper: FC<SuspiciousCellWrapperProps> = ({
  id,
  suspectState,
}) => {
  const {
    suspectReferral,
    unsuspectReferral,
    suspectLoading,
    unsuspectLoading,
  } = useSuspiciousReferralActions({ id });

  return (
    <SuspiciousCell
      tooltipSuspectMessage={`Заблокировать для${nbsp}массовых действий`}
      tooltipUnsuspectMessage={`Разблокировать для${nbsp}массовых действий`}
      suspectState={suspectState}
      suspectActionConfig={{ action: suspectReferral, loading: suspectLoading }}
      unsuspectActionConfig={{
        action: unsuspectReferral,
        loading: unsuspectLoading,
      }}
    />
  );
};
