import { FC } from 'react';
import { useParams } from 'react-router';
import DocumentsList from '../../components/DocumentsBlock/components/DocumentsList';
import { PlayerPageProps } from '../../types';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

const Documents: FC<PlayerPageProps> = ({ playerId }) => (
  <DocumentsList playerId={playerId} isFullScreenBlock shouldReturnToPrevPage />
);

export default () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <Documents playerId={id} />
    </PlayerPageContainer>
  );
};
