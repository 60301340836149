import { useContext } from 'react';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { UpdateMediaCampaignInput } from 'generatedGraphql';
import { toast } from 'react-toastify';
import errorToast from 'ui/Toast/ErrorToast';
import { ApolloError } from '@apollo/client';
import { useUpdateMediaCampaign } from '../queries/generated/UpdateMediaCampaign';

interface UseEditMediaCampaignModalProps {
  mediaCampaignId: string;
}

export const useEditMediaCampaignModal = ({
  mediaCampaignId,
}: UseEditMediaCampaignModalProps) => {
  const { dispatch } = useContext(ModalContext);

  const handleCompleted = () => {
    toast.success('Медиа кампания изменена');

    dispatch({
      type: ModalActions.Close,
    });
  };

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Медиа кампания не изменена',
      text: error.message,
      toastId: 'edit-media-campaign-error',
    });

    dispatch({
      type: ModalActions.Close,
    });
  };

  const [updateMediaCampaignMutation, { error, loading, data }] =
    useUpdateMediaCampaign({
      onCompleted: handleCompleted,
      onError: handleError,
    });

  const editMediaCampaign = async (values: UpdateMediaCampaignInput) => {
    await updateMediaCampaignMutation({
      variables: {
        input: {
          id: mediaCampaignId,
          name: values.name,
          url: values.url,
        },
      },
    });
  };

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'edit-media-campaign-name-error' },
    hasData: !!data,
  });

  return { editMediaCampaign, loading, error };
};
