import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { RevShareRewardTariffData } from './RevShareRewardTariffData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetRevShareRewardTariffVariables = SchemaTypes.Exact<{
  revShareRewardTariffsId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetRevShareRewardTariff = { __typename: 'Query', revShareRewardTariff: { __typename: 'RevShareRewardTariff', createdAt: string, id: string, percent: any } };


export const GetRevShareRewardTariffDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetRevShareRewardTariff"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"revShareRewardTariffsId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revShareRewardTariff"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"revShareRewardTariffsId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RevShareRewardTariffData"}}]}}]}},...RevShareRewardTariffData.definitions]} as unknown as DocumentNode;

/**
 * __useGetRevShareRewardTariff__
 *
 * To run a query within a React component, call `useGetRevShareRewardTariff` and pass it any options that fit your needs.
 * When your component renders, `useGetRevShareRewardTariff` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevShareRewardTariff({
 *   variables: {
 *      revShareRewardTariffsId: // value for 'revShareRewardTariffsId'
 *   },
 * });
 */
export function useGetRevShareRewardTariff(baseOptions: ApolloReactHooks.QueryHookOptions<GetRevShareRewardTariff, GetRevShareRewardTariffVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRevShareRewardTariff, GetRevShareRewardTariffVariables>(GetRevShareRewardTariffDocument, options);
      }
export function useGetRevShareRewardTariffLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRevShareRewardTariff, GetRevShareRewardTariffVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRevShareRewardTariff, GetRevShareRewardTariffVariables>(GetRevShareRewardTariffDocument, options);
        }
export type GetRevShareRewardTariffHookResult = ReturnType<typeof useGetRevShareRewardTariff>;
export type GetRevShareRewardTariffLazyQueryHookResult = ReturnType<typeof useGetRevShareRewardTariffLazyQuery>;
export type GetRevShareRewardTariffQueryResult = Apollo.QueryResult<GetRevShareRewardTariff, GetRevShareRewardTariffVariables>;