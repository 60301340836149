import { FC, useContext, useMemo } from 'react';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { nbsp } from 'src/const';
import { useTournamentRebuys } from './hooks/useTournamentRebuys';
import { TournamentRebuysTable } from './components/TournamentRebuysTable';

interface TournamentRebuysBlockProps {
  id: string;
  shouldReturnToPrevPage?: boolean;
  isFullScreenTable?: boolean;
}

const Component: FC<TournamentRebuysBlockProps> = ({
  id: playerId,
  shouldReturnToPrevPage = false,
  isFullScreenTable = false,
}) => {
  const { state } = useContext(BlockContext);

  const {
    tournamentRebuysData,
    tournamentRebuysError,
    tournamentRebuysLoading,
    tournamentRebuysFetchMore,
    tournamentRebuysRefetch,
  } = useTournamentRebuys({
    isFullScreenTable,
    playerId,
  });

  useBlockComponentState({
    loading: tournamentRebuysLoading,
    error: tournamentRebuysError,
  });

  useBlockTableComponentState({
    data: tournamentRebuysData,
    fetchMoreCallback: tournamentRebuysFetchMore,
    refetch: tournamentRebuysRefetch,
    loading: tournamentRebuysLoading,
    hasNextPage: tournamentRebuysData?.pageInfo.hasNextPage,
    isFullScreenTable,
  });

  const tournamentRebuys = useMemo(
    () => tournamentRebuysData?.edges.map(({ node }) => node),
    [tournamentRebuysData]
  );

  return (
    <Block
      title={`Докупы в${nbsp}турнирах`}
      id="playerTournamentRebuys"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => tournamentRebuysRefetch()}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Игрок не совершал операций"
      isEmpty={isListEmpty(tournamentRebuys)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(tournamentRebuys) && (
        <TournamentRebuysTable
          playerId={playerId}
          tournamentRebuys={tournamentRebuys}
          isFullScreenTable={isFullScreenTable}
        />
      )}
    </Block>
  );
};

export const TournamentRebuysBlock = withBlockProvider(Component);
