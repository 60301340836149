import { FC } from 'react';
import { useParams } from 'react-router';
import { PartnerPageContainer } from '../../components/PartnerPageContainer/PartnerPageContainer';
import { OutgoingMoneyTransfersTable } from '../../components/OutgoingMoneyTransfersBlock/components/OutgoingMoneyTransfersTable';
import { useGetOutgoingMoneyTransfersColumns } from '../../components/OutgoingMoneyTransfersBlock/components/OutgoingMoneyTransfersTable/hooks/useGetOutgoingMoneyTransfersColumns';

interface CompanyMediaItemsProps {
  partnerId: string;
}

export const Component: FC<CompanyMediaItemsProps> = ({ partnerId }) => {
  const { columns } = useGetOutgoingMoneyTransfersColumns(true);

  return (
    <OutgoingMoneyTransfersTable
      partnerId={partnerId}
      columns={columns}
      isFullScreenTable
      shouldReturnToPrevPage
    />
  );
};

export const OutgoingMoneyTransfers = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PartnerPageContainer partnerId={id}>
      <Component partnerId={id} />
    </PartnerPageContainer>
  );
};
