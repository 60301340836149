import { useContext } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import { BlockContext } from 'ui/Block/store';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import BlockTable from 'ui/Block/BlockTable';
import { ColumnDef } from '@tanstack/react-table';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetOutgoingMoneyTransfers } from './hooks/useGetOutgoingMoneyTransfers';
import { useOpenOutgoingMoneyTransferModal } from './hooks/useOpenOutgoingMoneyTransferModal';

interface TransfersTableProps<T extends object> {
  companyId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

export const OutgoingMoneyTransfersTable = <T extends { __typename: string }>({
  companyId,
  columns,
  shouldReturnToPrevPage,
  routeToCustomPage,
  isFullScreenTable,
}: TransfersTableProps<T>) => {
  const { state } = useContext(BlockContext);
  const { openModal } = useOpenOutgoingMoneyTransferModal<T>();

  const {
    loadOutgoingMoneyTransfers,
    outgoingMoneyTransfersList,
    loading,
    error,
    hasNextPage,
    fetchMoreCallback,
    refetch,
    data,
  } = useGetOutgoingMoneyTransfers<T>({
    companyId,
    isFullScreenTable,
  });

  useBlockComponentState({
    loadData: loadOutgoingMoneyTransfers,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    refetch,
  });

  return (
    <Block
      title="Исходящие переводы"
      id="companyOutgoingMoneyTransfersBlock"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => refetch()}
        />
      }
      isEmpty={isListEmpty(outgoingMoneyTransfersList)}
      emptyText="Переводы отсутствуют"
      shrinkLoaderWrapper={false}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(outgoingMoneyTransfersList) && (
        <BlockTable
          isFullScreenTable={isFullScreenTable}
          data={outgoingMoneyTransfersList}
          columns={columns}
          routeToCustomPage={routeToCustomPage}
          onClickOnRow={openModal}
        />
      )}
    </Block>
  );
};
