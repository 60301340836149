import Input, { InputSize } from 'ui/Input';
import classnames from 'classnames';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useField } from 'formik';
import { LoadMoreStatus } from 'commonComponents/LoadMoreStatus';
import { Option } from '../../types';
import styles from './DropdownList.module.scss';
import { useInfiniteScroll } from '../../../../utils/hooks/useInfiniteScroll';
import { getSearchWarningText } from './helpers';
import {
  DROPDOWN_SEARCH,
  END_OF_LIST_TEXT,
  SEARCH_INPUT_PLACEHOLDER,
} from './const';

interface DropdownListProps {
  onSearchInList?: (value: string) => void;
  isCheckbox?: boolean;
  fetchMoreConfig?: {
    fetchMore?: () => void;
    hasNextPage?: boolean;
  };
  loading?: boolean;
  emptyListText: string;
  searchMinLength?: number;
  isValidationOnSubmitCalled: boolean;
  name: string;
  onChange?: (value: string | Array<string>) => void;
  setOpened: Dispatch<SetStateAction<boolean>>;
  checkedOptionsIds: Array<string>;
  setCheckedOptionsIds: Dispatch<SetStateAction<Array<string>>>;
  options: Array<Option<string>> | null;
}

export const DropdownList: FC<DropdownListProps> = ({
  onSearchInList,
  isCheckbox,
  fetchMoreConfig,
  loading,
  emptyListText,
  searchMinLength,
  isValidationOnSubmitCalled,
  name,
  onChange,
  setOpened,
  checkedOptionsIds,
  setCheckedOptionsIds,
  options,
}) => {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { touched } = meta;

  const DROPDOWN_SEARCH_FIELD_NAME = `${name}_${DROPDOWN_SEARCH}`;

  const [fieldInput] = useField(DROPDOWN_SEARCH_FIELD_NAME);
  const { setValue, setTouched } = helpers;

  const loadTrigger = useInfiniteScroll(fetchMoreConfig?.fetchMore);

  const setFormValue = useCallback(
    (val: string | Array<string>) => {
      const isDropdownFieldEmpty = Array.isArray(val) && val.length === 0;

      if (!touched) {
        setTouched(true, false);
      }

      // Reset touched param for hide error if value is empty (before validation on submit)
      if (isCheckbox && isDropdownFieldEmpty && !isValidationOnSubmitCalled) {
        setTouched(false, false);
      }

      setValue(val, true);
      if (onChange) {
        onChange(val);
      }
    },
    [
      isCheckbox,
      isValidationOnSubmitCalled,
      onChange,
      setTouched,
      setValue,
      touched,
    ]
  );

  const onOptionSelect = useCallback(
    (optionId: string) => {
      setFormValue(optionId);
      setOpened(false);
    },
    [setFormValue, setOpened]
  );

  const onCheckBoxClick = useCallback(
    (optionId: string) => {
      let newCheckedOptionsIds = [...checkedOptionsIds];

      if (checkedOptionsIds.includes(optionId)) {
        newCheckedOptionsIds = checkedOptionsIds.filter(
          (id: string) => id !== optionId
        );
      } else {
        newCheckedOptionsIds.push(optionId);
      }

      setCheckedOptionsIds(newCheckedOptionsIds);
      setFormValue(newCheckedOptionsIds);
    },
    [checkedOptionsIds, setCheckedOptionsIds, setFormValue]
  );

  const getCheckBoxOptions = (): Array<Option> | null =>
    options &&
    options.map((option: Option) => ({
      id: option.id,
      label: (
        <>
          <div
            className={classnames(
              styles.checkboxControl,
              checkedOptionsIds.includes(option.id) &&
                styles.checkboxControlChecked
            )}
          >
            {checkedOptionsIds.includes(option.id) && (
              <FontAwesomeIcon icon={faCheck} />
            )}
          </div>
          {option.label}
        </>
      ),
    }));

  const dropdownOptions: Array<Option> | null = isCheckbox
    ? getCheckBoxOptions()
    : options;

  const isRenderSearchWarningText =
    searchMinLength &&
    (fieldInput.value?.length < searchMinLength ||
      !fieldInput.value ||
      dropdownOptions === null) &&
    onSearchInList &&
    !loading;

  const isEndOfNotEmptyList =
    !fetchMoreConfig?.hasNextPage && !!dropdownOptions?.length;

  const isListWithFetchLoading = fetchMoreConfig && loading;

  const isOptionsListEmpty = !dropdownOptions || dropdownOptions.length === 0;
  const isEmptyListTextVisible =
    !isRenderSearchWarningText && !loading && isOptionsListEmpty;

  return (
    <div className={styles.menu}>
      {onSearchInList && (
        <Input
          name={DROPDOWN_SEARCH_FIELD_NAME}
          placeholder={SEARCH_INPUT_PLACEHOLDER}
          onChange={onSearchInList}
          wrapperClassName={styles.searchInputWrapper}
          inputSize={InputSize.Small}
          autoFocus
        />
      )}
      {isRenderSearchWarningText ? (
        <div className={styles.searchWarningText}>
          {getSearchWarningText(searchMinLength)}
        </div>
      ) : (
        dropdownOptions?.map((option: Option) => (
          <div
            className={classnames(
              styles.option,
              !isCheckbox && value === option.id && styles.selectedOption,
              isCheckbox && styles.checkboxOption
            )}
            key={option.id}
            role={isCheckbox ? 'menuitemcheckbox' : 'option'}
            onClick={() =>
              isCheckbox
                ? onCheckBoxClick(option.id)
                : onOptionSelect(option.id)
            }
          >
            {option.label}
            {option.labelRight && (
              <span className={styles.labelRight}>{option.labelRight}</span>
            )}
          </div>
        ))
      )}
      {loadTrigger}
      {fetchMoreConfig &&
        !isRenderSearchWarningText &&
        (isEndOfNotEmptyList || isListWithFetchLoading) && (
          <LoadMoreStatus
            className={styles.dropdownLoader}
            fetchMore={fetchMoreConfig.fetchMore}
            isLoading={loading}
            isEndOfList={isEndOfNotEmptyList}
            messages={{
              complete: END_OF_LIST_TEXT,
            }}
          />
        )}
      {isEmptyListTextVisible && (
        <div className={styles.searchWarningText}>{emptyListText}</div>
      )}
    </div>
  );
};
