import { FC, useCallback } from 'react';
import { useInfiniteScroll } from 'src/utils/hooks/useInfiniteScroll';
import Error from 'ui/Error/Error';
import { LoadMoreStatus } from 'commonComponents/LoadMoreStatus';
import { AccountEventGroupItem } from './components/AccountEventGroupItem';
import { useGetAccountEventGroup } from '../../queries/generated/GetAccountEventGroup';

const EVENTS_PER_PAGE = 10;

interface AccountEventGroupModalProps {
  eventGroupId: string;
}

export const AccountEventGroupModal: FC<AccountEventGroupModalProps> = ({
  eventGroupId,
}) => {
  const { data, fetchMore, loading, error } = useGetAccountEventGroup({
    variables: {
      groupId: eventGroupId,
      first: EVENTS_PER_PAGE,
    },
  });

  const events = data?.accountEventGroup.events;

  const loadMore = useCallback(() => {
    if (!events?.pageInfo.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        after: events.pageInfo.endCursor,
        first: EVENTS_PER_PAGE,
        groupId: eventGroupId,
      },
    });
  }, [events, eventGroupId, fetchMore]);

  const loadTrigger = useInfiniteScroll(loadMore);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      {events?.edges.length && (
        <>
          {events.edges.map(({ node }) => (
            <AccountEventGroupItem event={node} key={node.id} />
          ))}
          {loadTrigger}
        </>
      )}

      {/* todo: add Error state handling */}
      <LoadMoreStatus
        fetchMore={loadMore}
        isLoading={loading}
        isEndOfList={!events?.pageInfo.hasNextPage}
        messages={{
          complete: 'Вы достигли конца списка',
        }}
      />
    </>
  );
};
