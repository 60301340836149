import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { DepositData } from '../../../../queries/generated/DepositData';
import { DepositBasicData } from '../../../../queries/generated/DepositBasicData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetDepositVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
  viewPaymentMethodAccount: SchemaTypes.Scalars['Boolean']['input'];
}>;


export type GetDeposit = { __typename: 'Query', deposit: { __typename: 'Deposit', paymentProvider: CommonType.PaymentProvider, updatedAt: string, createdAt: string, id: string, paymentMethodName: CommonType.DepositPaymentMethodName, status: SchemaTypes.DepositStatus, paymentMethod?: { __typename: 'PaymentMethod', account?: string, id: string, maskedAccount?: string, name: CommonType.PaymentMethodName } | null, paidSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, wager?: { __typename: 'Wager', id: string, status: SchemaTypes.WagerStatus, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, wonSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null } };


export const GetDepositDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDeposit"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"viewPaymentMethodAccount"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deposit"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DepositData"}}]}}]}},...DepositData.definitions,...DepositBasicData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetDeposit__
 *
 * To run a query within a React component, call `useGetDeposit` and pass it any options that fit your needs.
 * When your component renders, `useGetDeposit` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeposit({
 *   variables: {
 *      id: // value for 'id'
 *      viewPaymentMethodAccount: // value for 'viewPaymentMethodAccount'
 *   },
 * });
 */
export function useGetDeposit(baseOptions: ApolloReactHooks.QueryHookOptions<GetDeposit, GetDepositVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeposit, GetDepositVariables>(GetDepositDocument, options);
      }
export function useGetDepositLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeposit, GetDepositVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeposit, GetDepositVariables>(GetDepositDocument, options);
        }
export type GetDepositHookResult = ReturnType<typeof useGetDeposit>;
export type GetDepositLazyQueryHookResult = ReturnType<typeof useGetDepositLazyQuery>;
export type GetDepositQueryResult = Apollo.QueryResult<GetDeposit, GetDepositVariables>;