import { FC } from 'react';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useModalManageData } from 'ui/Modal';
import { playerPaymentNameMap, withdrawalStatusMap } from 'src/const';
import { WithdrawalStatus } from 'generatedGraphql';
import {
  BoldSumWithWarningIconRowByCompare,
  DataCard,
} from 'commonComponents/DataCard';
import { getWithdrawalStatusIconConfig } from 'src/helpers';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useAuth } from 'commonComponents/Auth';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { TextWithLinkRow } from 'src/components/DataCard/components/TextWithLinkRow';
import { useGetWithdrawalItem } from './queries/generated/GetWithdrawalItem';
import { getActorTypeByTypeName, getRouteByTypeName } from '../../helpers';
import { UpdateWithdrawalButtons } from '../UpdateWithdrawalButtons';

interface WithdrawalItemProps {
  withdrawalId: string;
}

const WithdrawalItem: FC<WithdrawalItemProps> = ({ withdrawalId }) => {
  const { privileges } = useAuth();

  const { data, loading, error } = useGetWithdrawalItem({
    variables: {
      id: withdrawalId,
      viewPaymentMethodAccount: privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewPaymentmethodAccount],
      }),
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-withdrawal-full-page-error' },
    hasData: !!data,
  });

  if (!data) {
    return null;
  }

  const {
    createdAt,
    paymentMethod: {
      account: accountName,
      name: paymentMethodName,
      maskedAccount: maskedAccountName,
      bank,
    },
    sum,
    paidSum,
    admin,
    status,
    updatedAt,
    actor,
    account,
  } = data.withdrawal;

  const getStatusText = () => {
    if (status === WithdrawalStatus.InProgress && admin) {
      return `${withdrawalStatusMap[status]}: ${admin.firstName} ${admin.lastName}`;
    }

    return withdrawalStatusMap[status];
  };

  const nameActorTitle =
    actor.__typename === 'Company' ? 'Название' : 'Имя пользователя';

  return (
    <DataCard
      statusConfig={{
        status: getStatusText(),
        iconConfig: getWithdrawalStatusIconConfig(status),
      }}
      rows={[
        {
          title: nameActorTitle,
          value: (
            <TextWithLinkRow
              title={actor.name}
              route={getRouteByTypeName(actor)}
            />
          ),
        },
        {
          title: 'Тип аккаунта',
          value: getActorTypeByTypeName(actor),
        },
        {
          title: 'Счёт',
          value:
            // todo When task https://eyeconweb.atlassian.net/browse/APN-906 will be resolved - remove display by condition
            account &&
            formatSumWithCurrency(
              account.balance.amount,
              account.balance.currency
            ),
        },
        {
          title: 'Метод',
          value: playerPaymentNameMap[paymentMethodName] || paymentMethodName,
        },
        {
          title: 'Номер счета',
          value: accountName || maskedAccountName,
        },
        !!bank && {
          title: 'Банк',
          value: bank,
        },
        {
          title: 'Сумма',
          value: formatSumWithCurrency(sum.amount, sum.currency),
        },
        !!paidSum && {
          title: 'Фактическая сумма',
          value: (
            <BoldSumWithWarningIconRowByCompare
              comparedSum={sum}
              renderedSum={paidSum}
            />
          ),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
        updatedAt && {
          title: 'Обновлен',
          value: formatDate(updatedAt, DateFormat.FullDate),
        },
      ]}
      customFooter={<UpdateWithdrawalButtons withdrawal={data.withdrawal} />}
    />
  );
};

export default WithdrawalItem;
