import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { CompanyAffiliateApplicationsData } from '../../queries/generated/CompanyAffiliateApplicationsData';
import { useOpenCompanyAffiliateApplicationModal } from '../../hooks/useOpenCompanyAffiliateApplicationModal';
import { useGetCompanyAffiliateApplicationsColumns } from './hooks/useGetCompanyAffiliateApplicationsColumns';

interface CompanyAffiliateApplicationsTableProps {
  companyAffiliateApplications?: Array<CompanyAffiliateApplicationsData>;
}

export const CompanyAffiliateApplicationsTable: FC<
  CompanyAffiliateApplicationsTableProps
> = ({ companyAffiliateApplications }) => {
  const { columns } = useGetCompanyAffiliateApplicationsColumns();
  const openModal = useOpenCompanyAffiliateApplicationModal();

  return (
    <BlockTable
      onClickOnRow={(companyAffiliateApplication, initiatorId) =>
        openModal({
          companyAffiliateApplication,
          initiatorId,
        })
      }
      columns={columns}
      data={companyAffiliateApplications}
      isFullScreenTable
    />
  );
};
