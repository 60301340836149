import {
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  isValidElement,
  useEffect,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth, AuthStatuses, UserPrivilege } from '../components/Auth';
import { PrivilegeAccessType } from './types';
import { privateDefaultPath } from './routes.const';

const isReactElement = (node: ReactNode): node is ReactElement =>
  isValidElement(node);

interface AuthWrapperProps {
  privileges?: Array<UserPrivilege>;
  accessType?: PrivilegeAccessType;
}

export const AuthWrapper: FC<PropsWithChildren<AuthWrapperProps>> = ({
  children,
  privileges,
  accessType,
}) => {
  const auth = useAuth();
  const { authStatus } = auth.user;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus !== AuthStatuses.Authenticated) {
      navigate('/login', { state: { from: location }, replace: true });

      return;
    }

    if (
      accessType === PrivilegeAccessType.Some &&
      !auth.privileges.isSomeGranted({ privileges })
    ) {
      navigate(privateDefaultPath, { replace: true });

      return;
    }

    if (
      accessType === PrivilegeAccessType.All &&
      !auth.privileges.areEveryGranted({ privileges })
    ) {
      navigate(privateDefaultPath, { replace: true });
    }
  }, [authStatus, navigate, location, accessType, auth.privileges, privileges]);

  if (authStatus !== AuthStatuses.Authenticated || !isReactElement(children)) {
    return null;
  }

  return children;
};
