import { FC } from 'react';

interface AddTwoLinedSubTitleProps {
  firstValue?: string | null;
  secondValue?: string | null;
}

export const AddTwoLinedSubTitle: FC<AddTwoLinedSubTitleProps> = ({
  firstValue,
  secondValue,
}) => {
  const hasBrTag = firstValue && secondValue;

  return (
    <>
      {firstValue}
      {hasBrTag && <br />}
      {secondValue}
    </>
  );
};
