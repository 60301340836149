import { FC } from 'react';
import { Form, Formik } from 'formik';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { v4 as uuidv4 } from 'uuid';
import Error from 'ui/Error';
import { AffiliatesValues } from './types';
import { NewAffiliateForm } from './components/NewAffiliateForm';
import { useAddNewAffiliateItem } from './hooks/useAddNewAffiliateItem';
import { useAddAffiliatesMutation } from './hooks/useAddAffiliatesMutation';
import { validate } from './validation';
import styles from './AddAffiliatesForm.module.scss';

const MAX_AFFILIATE_COUNT = 50;

interface AddAffiliatesFormProps {
  companyId: string;
}

export const AddAffiliatesForm: FC<AddAffiliatesFormProps> = ({
  companyId,
}) => {
  const initialValues: AffiliatesValues = {
    affiliates: [{ id: uuidv4(), name: 'Partner#1' }],
  };

  const { addNewAffiliateItem } = useAddNewAffiliateItem();

  const { handleSubmit, addAffiliatesLoading, addAffiliatesError } =
    useAddAffiliatesMutation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values, companyId)}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, setFieldValue }) => (
        <Form>
          {addAffiliatesError && <Error error={addAffiliatesError} />}
          <ul className={styles.affiliatesList}>
            {values.affiliates.map(({ id, name, note }, index) => (
              <li className={styles.affiliateItem} key={id}>
                <NewAffiliateForm
                  count={values.affiliates.length}
                  name={name}
                  note={note}
                  affiliateIndex={index}
                />
              </li>
            ))}
          </ul>
          {values.affiliates.length < MAX_AFFILIATE_COUNT && (
            <Button
              className={styles.addAffiliateBtn}
              size={ButtonSize.Large}
              onClick={() => {
                addNewAffiliateItem(values, setFieldValue);
              }}
            >
              Добавить еще партнера
            </Button>
          )}
          <Button
            type="submit"
            theme={ButtonTheme.Primary}
            isLoading={addAffiliatesLoading}
          >
            Добавить
          </Button>
        </Form>
      )}
    </Formik>
  );
};
