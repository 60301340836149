import { FC, ReactNode } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { CompanyAffiliateApplicationItem } from 'generatedGraphql';
import styles from './AffiliateCard.module.scss';

interface AffiliateCardProps {
  affiliate: Omit<CompanyAffiliateApplicationItem, 'mediaItemsCount'>;
  statusOrCheckbox: ReactNode;
  onEditAffiliate?: (id: string) => void;
  onResetAffiliateStatus?: (id: string) => void;
}

export const AffiliateCard: FC<AffiliateCardProps> = ({
  affiliate,
  statusOrCheckbox,
  onEditAffiliate,
  onResetAffiliateStatus,
}) => (
  <div className={styles.container}>
    <div className={styles.statusOrCheckboxContainer}>{statusOrCheckbox}</div>
    <div className={styles.header}>
      <div className={styles.name}>{affiliate.name}</div>
      {onEditAffiliate && (
        <ActionButton
          dataTestId="editAffiliate"
          actionType={ButtonType.Edit}
          onClick={() => onEditAffiliate(affiliate.id)}
        />
      )}
    </div>
    {/* need to grid */}
    <div />
    {(onResetAffiliateStatus || affiliate.note) && (
      <div className={styles.content}>
        {affiliate.note && (
          <div className={styles.note}>{affiliate.note.text}</div>
        )}
        {onResetAffiliateStatus && (
          <Button
            className={styles.resetBtn}
            size={ButtonSize.Small}
            theme={ButtonTheme.Important}
            onClick={() => onResetAffiliateStatus(affiliate.id)}
          >
            Сбросить
          </Button>
        )}
      </div>
    )}
  </div>
);
