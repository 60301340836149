import React, { FC } from 'react';
import Note from 'commonComponents/Note';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import classnames from 'classnames';
import { Marker } from 'commonComponents/ClientHeader/types';
import useClientHeader from 'commonComponents/ClientHeader/useClientHeader';
import styles from './styles/ClientHeader.module.scss';

interface ClientHeaderProps {
  canUpdateNote: boolean;
  canViewClientNote: boolean;
  canResetClientName: boolean;
  updateNoteResult?: object | null;
  updateNoteHandler: (value: string) => void;
  updateNoteLoading: boolean;
  noteText?: string;
  clientName?: string;
  clientId: string;
  resetClientName?: () => void;
  markers: Array<Marker> | null;
}

const ClientHeader: FC<ClientHeaderProps> = ({
  canUpdateNote,
  canViewClientNote,
  canResetClientName,
  updateNoteResult,
  updateNoteHandler,
  updateNoteLoading,
  noteText,
  clientName,
  clientId,
  resetClientName,
  markers,
}) => {
  const { heightHeader, headerRef } = useClientHeader();

  return (
    <>
      {!!heightHeader && (
        <div
          style={{ height: `${heightHeader}px` }}
          className={styles.fakeHeader}
        />
      )}
      <div
        id="header"
        className={classnames(styles.header, !!heightHeader && styles.fixed)}
        ref={headerRef}
        data-testid="page-header"
      >
        <div className={styles.nameContainer}>
          {clientName && (
            <h1 className={styles.name}>
              {clientName}&nbsp;
              <span className={styles.playerId}>{`#${clientId}`}</span>
            </h1>
          )}
          {resetClientName && canResetClientName && (
            <ActionButton
              className={styles.editBtn}
              dataId="editBtn"
              onClick={resetClientName}
              actionType={ButtonType.Edit}
            />
          )}
        </div>
        {markers && (
          <div className={styles.markersUncoloredWrapper} data-id="uncolored">
            {markers.map(
              ({ id, content, isHidden }) =>
                !isHidden && (
                  <div
                    key={id}
                    className={classnames(styles.marker, styles[id])}
                  >
                    {content}
                  </div>
                )
            )}
          </div>
        )}
      </div>
      <div />
      {markers && (
        <div className={styles.markersWrapper}>
          {markers.map(({ id, content, isHidden, onClick, isDisabled }) => {
            const clickable = onClick ? 'clickable' : '';

            return (
              !isHidden && (
                <button
                  type="button"
                  disabled={isDisabled}
                  key={id}
                  className={classnames(
                    styles.marker,
                    styles[id],
                    styles[clickable],
                    isDisabled && styles.disabled
                  )}
                  onClick={onClick}
                >
                  {content}
                </button>
              )
            );
          })}
        </div>
      )}
      {canViewClientNote && (
        <div className={styles.note}>
          <Note
            note={noteText}
            updateResult={updateNoteResult}
            updateNote={updateNoteHandler}
            updateNoteLoading={updateNoteLoading}
            isEditButtonVisible={canUpdateNote}
            maxNoteLength={1000}
          />
        </div>
      )}
    </>
  );
};

export default ClientHeader;
