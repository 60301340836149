import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';
import styles from './DownloadDocumentButton.module.scss';

interface DownloadDocumentButtonProps {
  imgUrl: string;
}

const DownloadDocumentButton: FC<DownloadDocumentButtonProps> = ({
  imgUrl,
}) => {
  const onClick = () => {
    window.open(imgUrl);
  };

  return (
    <button type="button" className={styles.downloadButton} onClick={onClick}>
      <FontAwesomeIcon icon={faCloudArrowDown} className={styles.icon} />
    </button>
  );
};

export default DownloadDocumentButton;
