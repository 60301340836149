import { FC } from 'react';
import { Tooltip } from 'ui/Tooltip';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SuspectState } from 'generatedGraphql';
import { mapSuspectStateToClass, mapSuspectStateToIcon } from './const';
import styles from './SuspectStateButton.module.scss';

interface SuspectStateButtonProps {
  suspectState: SuspectState;
  action?: () => void;
  tooltipMessage?: string;
  disabled: boolean;
}

export const SuspectStateButton: FC<SuspectStateButtonProps> = ({
  suspectState,
  action,
  disabled,
  tooltipMessage,
}) => (
  <Tooltip tooltipContent={tooltipMessage} placement="top-end">
    <button
      disabled={disabled}
      onClick={(event) => {
        if (action) {
          event.stopPropagation();
          action();
        }
      }}
      className={classnames(
        styles.button,
        mapSuspectStateToClass[suspectState]
      )}
      data-testid={`suspect-state-button-${suspectState.toLowerCase()}`}
      type="button"
    >
      <FontAwesomeIcon icon={mapSuspectStateToIcon[suspectState]} size="lg" />
    </button>
  </Tooltip>
);
