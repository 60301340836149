import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { CpaRewardTariffData } from '../../../../queries/generated/CpaRewardTariffData';
import { useRewardTariffsTable } from './hooks/useRewardTariffsTable';
import { useGetCpaRewardTariffsColumns } from './hooks/useGetCpaRewardTariffsColumns';

interface CpaRewardTariffsTableProps {
  cpaRewardTariffs?: Array<CpaRewardTariffData>;
}

export const CpaRewardTariffsTable: FC<CpaRewardTariffsTableProps> = ({
  cpaRewardTariffs,
}) => {
  const { handleRowClick } = useRewardTariffsTable();
  const { columns } = useGetCpaRewardTariffsColumns();

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={cpaRewardTariffs}
      isFullScreenTable
    />
  );
};
