import { FC, ReactNode } from 'react';
import { CardIcon, CardIconConfig } from 'ui/Card/CardIcon';
import classnames from 'classnames';
import styles from './CardHeader.module.scss';

export interface HeaderContent {
  leftHeader?: string | JSX.Element | null;
  leftSubHeader?: string | JSX.Element | null;
  rightHeader?: string | JSX.Element | null;
  rightSubHeader?: string | JSX.Element | null;
}

interface CardHeaderProps {
  headerContent?: HeaderContent;
  customHeaderContent?: ReactNode;
  iconConfig?: CardIconConfig;
}

export const CardHeader: FC<CardHeaderProps> = ({
  headerContent,
  customHeaderContent,
  iconConfig,
}) => (
  <div className={styles.headerContentContainer}>
    {iconConfig && <CardIcon config={iconConfig} className={styles.cardIcon} />}
    {customHeaderContent ||
      (headerContent && (
        <div className={styles.headerContent}>
          <div className={styles.headerColumn}>
            <div className={styles.header}>{headerContent.leftHeader}</div>
            {headerContent.leftSubHeader && (
              <div className={styles.leftSubHeader}>
                {headerContent.leftSubHeader}
              </div>
            )}
          </div>
          <div
            className={classnames(
              styles.headerColumn,
              styles.headerRightColumn
            )}
          >
            {headerContent.rightHeader && (
              <div className={styles.header}>{headerContent.rightHeader}</div>
            )}
            {headerContent.rightSubHeader && (
              <div className={styles.rightSubHeader}>
                {headerContent.rightSubHeader}
              </div>
            )}
          </div>
        </div>
      ))}
  </div>
);
