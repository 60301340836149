import { DataCard } from 'commonComponents/DataCard';
import { FC } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { RewardTariffType } from 'commonTypes';
import { ReferralLinkData } from '../../types';
import { rewardTariffMap } from '../../../../const';

interface ReferralLinkProps {
  referralLinkData: ReferralLinkData;
  onEditButtonClick: () => void;
}

export const ReferralLink: FC<ReferralLinkProps> = ({
  referralLinkData,
  onEditButtonClick,
}) => {
  const tariffType =
    referralLinkData.cpaTariff && referralLinkData.revShareTariff
      ? rewardTariffMap[RewardTariffType.Cpa]
      : rewardTariffMap[RewardTariffType.RevShare];

  const tariffTitle =
    referralLinkData.cpaTariff?.title || referralLinkData.revShareTariff.title;

  const reserveTariff =
    referralLinkData.cpaTariff &&
    referralLinkData.revShareTariff &&
    referralLinkData.revShareTariff.title;

  return (
    <DataCard
      withBorder
      header={{
        leftTitle: referralLinkData.referralLinkName,
        leftSubTitle: referralLinkData.code,
        rightTitle: (
          <ActionButton
            actionType={ButtonType.Edit}
            onClick={onEditButtonClick}
          />
        ),
      }}
      rows={[
        {
          title: 'Модель оплаты',
          value: tariffType,
        },
        {
          title: 'Партнёрский тариф',
          value: tariffTitle,
        },
        reserveTariff && {
          title: 'Резервный тариф',
          value: reserveTariff,
        },
      ]}
    />
  );
};
