import React, { FC } from 'react';
import ResetClientName from 'src/components/ResetClientName';
import { useResetPartnerName } from './hooks/useResetPartnerName';

interface ResetPartnerNameProps {
  partnerId: string;
  partnerName?: string;
  partnerDefaultName?: string;
}

export const ResetPartnerName: FC<ResetPartnerNameProps> = ({
  partnerId,
  partnerName,
  partnerDefaultName,
}) => {
  const {
    loading: isLoading,
    onResetPartnerName,
    error,
  } = useResetPartnerName({ partnerId });

  const isNotReset = partnerName === partnerDefaultName;

  if (isNotReset) {
    return (
      <div>
        У&nbsp;партнера в&nbsp;настоящий момент установлено имя аккаунта
        по&nbsp;умолчанию.
        <br /> Сброс невозможен.
      </div>
    );
  }

  return (
    <ResetClientName
      onResetClientName={onResetPartnerName}
      clientName={partnerName}
      clientDefaultName={partnerDefaultName}
      isLoading={isLoading}
      error={error}
    />
  );
};
