import { FC } from 'react';
import Button, { ButtonSize } from 'ui/Button';
import { useNavigate } from 'react-router-dom';
import { Route } from 'src/router/routes.const';
import styles from './DuplicateTooltipContent.module.scss';

interface DuplicateTooltipContentProps {
  account: string;
  playerId: string;
}

export const DuplicateTooltipContent: FC<DuplicateTooltipContentProps> = ({
  account,
  playerId,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.duplicateTitle}>Дубликат счета</div>
      <div className={styles.duplicateAccount}>{account}</div>
      <Button
        size={ButtonSize.Small}
        onClick={() => navigate(`${Route.PLAYERS}/${playerId}`)}
      >
        Перейти к&nbsp;дубликату
      </Button>
    </>
  );
};
