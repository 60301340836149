import { FC } from 'react';
import { Form, Formik } from 'formik';
import Error from 'ui/Error';
import Button, { ButtonTheme } from 'ui/Button';
import { AcceptPaymentInput, Sum } from 'generatedGraphql';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import { Tooltip } from 'ui/Tooltip';
import styles from './AcceptPaymentForm.module.scss';
import { validate } from './validation';
import { useAcceptPayment } from './hooks/useAcceptPayment';
import { AcceptWithdrawalPaymentFields } from './const';

interface AcceptPaymentFormProps {
  id: string;
  paymentPaidSum: Sum;
}

export const AcceptPaymentForm: FC<AcceptPaymentFormProps> = ({
  id,
  paymentPaidSum,
}) => {
  const { acceptPayment, error, loading } = useAcceptPayment();
  const initialValues: AcceptPaymentInput = {
    id,
    [AcceptWithdrawalPaymentFields.Amount]: paymentPaidSum.amount,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={acceptPayment}
      enableReinitialize
      validate={(values) => validate(values, paymentPaidSum)}
      validateOnChange={false}
    >
      {({ isValid, dirty, getFieldProps }) => {
        const isFieldEmpty = !getFieldProps(
          AcceptWithdrawalPaymentFields.Amount
        ).value;
        const isSubmitDisabled = !isValid || (isFieldEmpty && !dirty);

        return (
          <Form>
            {error && <Error error={error} />}
            <FormRowsWrapper>
              <div className={styles.inputContainer}>
                <Input.Sum
                  name={AcceptWithdrawalPaymentFields.Amount}
                  label="Фактическая сумма"
                  currency={paymentPaidSum.currency}
                />
              </div>
            </FormRowsWrapper>
            <Tooltip
              tooltipContent={
                isSubmitDisabled
                  ? 'Необходимо ввести фактическую сумму'
                  : undefined
              }
              placement="right"
            >
              <Button
                data-testid="acceptPaymentButton"
                className={styles.submitButton}
                theme={ButtonTheme.Success}
                type="submit"
                isLoading={loading}
                disabled={isSubmitDisabled}
              >
                Выполнить
              </Button>
            </Tooltip>
          </Form>
        );
      }}
    </Formik>
  );
};
