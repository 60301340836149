import { FC } from 'react';
import { get } from 'lodash-es';
import { useParams } from 'react-router';
import BonusesTable from '../../components/BonusesBlock/components/BonusesTable';
import { useGetPlayerReceiveGiftRestriction } from '../../components/BonusesBlock/queries/generated/GetPlayerReceiveGiftRestriction';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';
import { useGetBonusesColumns } from '../../components/BonusesBlock/hooks/useGetBonusesColumns';

interface BonusesProps {
  playerId: string;
}

const Bonuses: FC<BonusesProps> = ({ playerId }) => {
  const { columns } = useGetBonusesColumns(true);

  const { data } = useGetPlayerReceiveGiftRestriction({
    variables: {
      playerId,
    },
  });

  const receiveGiftRestriction = get(data, [
    'player',
    'lastRestrictions',
    'receiveGiftRestriction',
  ]);

  return (
    <BonusesTable
      playerId={playerId}
      columns={columns}
      shouldReturnToPrevPage
      isFullScreenTable
      receiveGiftRestriction={receiveGiftRestriction}
    />
  );
};

export default () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <Bonuses playerId={id} />
    </PlayerPageContainer>
  );
};
