import { FC } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { Route } from 'src/router/routes.const';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import SearchInput from 'ui/SearchInput';
import { STATISTIC_NO_DATA } from '../../const';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { useGetRevShareCompanyAffiliateReport } from './hooks/useGetRevShareCompanyAffiliateReport';
import { RevShareCompanyAffiliateReportTable } from './components/RevShareCompanyAffiliateReportTable';
import { BlockSubHeaderCompanyAffiliateInfo } from '../../components/BlockSubHeaderCompanyAffiliateInfo';
import {
  REV_SHARE_COMPANY_AFFILIATE_REPORT_INPUT_NAME,
  REV_SHARE_COMPANY_AFFILIATE_REPORT_SEARCH_PLACEHOLDER,
  REV_SHARE_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE,
} from './const';
import { useGetExportCompanyAffiliateRevShareReport } from './hooks/useGetExportCompanyAffiliateRevShareReport';

interface RevShareCompanyAffiliateReportProps {
  companyId: string;
  affiliateId: string;
}

const Component: FC<RevShareCompanyAffiliateReportProps> = withBlockProvider(
  ({ companyId, affiliateId }) => {
    const {
      data,
      revShareCompanyAffiliateReportList,
      loadReport,
      loading,
      refetch,
      fetchMoreCallback,
      hasNextPage,
      hasTotalRow,
      affiliate,
    } = useGetRevShareCompanyAffiliateReport(companyId, affiliateId);

    const exportReportConfig = useGetExportCompanyAffiliateRevShareReport({
      companyId,
      affiliateId,
    });

    useBlockTableComponentState({
      data,
      fetchMoreCallback,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable: true,
    });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();

    const customPathToPrevPage = generatePathWithPeriodQueryParams({
      originalPath: Route.STATISTIC_REV_SHARE_COMPANY_PARTNERS,
      params: {
        id: companyId,
      },
    });

    const listExist = hasList(revShareCompanyAffiliateReportList);

    return (
      <>
        <SearchInput
          name={REV_SHARE_COMPANY_AFFILIATE_REPORT_INPUT_NAME}
          placeholder={REV_SHARE_COMPANY_AFFILIATE_REPORT_SEARCH_PLACEHOLDER}
          onSubmit={loadReport}
        />
        <Block
          title={REV_SHARE_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE}
          id="revShareCompanyAffiliateStatistic"
          headerContent={
            <StatisticBlockHeaderContent
              hasList={listExist}
              exportReportConfig={exportReportConfig}
              handleRefetchClick={refetch}
            />
          }
          subHeader={
            affiliate && (
              <BlockSubHeaderCompanyAffiliateInfo
                companyName={affiliate.company.name}
                affiliateName={affiliate.name}
              />
            )
          }
          shrinkLoaderWrapper={false}
          emptyText={STATISTIC_NO_DATA}
          isEmpty={isListEmpty(revShareCompanyAffiliateReportList)}
          shouldReturnToPrevPage
          isPermanentOpened
          customPathToPrevPage={customPathToPrevPage}
        >
          {listExist && (
            <RevShareCompanyAffiliateReportTable
              revShareCompanyAffiliateReport={
                revShareCompanyAffiliateReportList
              }
              hasTotalRow={hasTotalRow}
            />
          )}
        </Block>
      </>
    );
  }
);

export const RevShareCompanyAffiliateReport = () => {
  const { companyId, affiliateId } = useParams();

  if (!companyId || !affiliateId) {
    return null;
  }

  return (
    <SearchContextProvider
      pageType={PageTypeStorage.RevShareCompanyAffiliateReport}
    >
      <Component companyId={companyId} affiliateId={affiliateId} />
    </SearchContextProvider>
  );
};
