import {
  cloneElement,
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import ClickOutside, { useClickOutside } from 'ui/ClickOutside';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import classnames from 'classnames';
import { bodyScrollLocker } from 'src/utils/bodyScrollLocker';
import { closeModalWithTimeout } from './helpers/closeModalWithTimeout';
import styles from './Popup.module.scss';

interface ActionButtonConfig {
  text: string;
  theme: ButtonTheme;
  action?: () => void;
  size?: ButtonSize;
  className?: string;
}

interface PopupProps {
  content: string | ReactElement;
  triggerElement: ReactElement;
  actionButtonsConfig: Array<ActionButtonConfig>;
  isActionButtonsMaxWidth?: boolean;
}

export const Popup: FC<PopupProps> = ({
  content,
  triggerElement,
  actionButtonsConfig,
  isActionButtonsMaxWidth,
}) => {
  const [isOpened, setOpened] = useState(false);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const onClickOutside = useClickOutside(setOpened);

  const clonedTrigger = cloneElement(triggerElement, {
    onClick: () => setOpened(true),
  });

  useEffect(() => {
    if (isOpened) {
      bodyScrollLocker.lock(true);
    } else {
      bodyScrollLocker.unLock();
    }
  }, [isOpened]);

  return (
    <>
      {clonedTrigger}
      {isOpened &&
        createPortal(
          <div
            className={classnames(styles.overlay)}
            data-testid="popupOverlay"
            ref={overlayRef}
          >
            <ClickOutside
              callback={(isClickedOutside) => {
                if (isClickedOutside) {
                  closeModalWithTimeout(
                    overlayRef,
                    onClickOutside,
                    isClickedOutside
                  );
                }
              }}
            >
              <div className={styles.popupContainer}>
                <p className={styles.content}>{content}</p>
                <div className={styles.actionButtons}>
                  {actionButtonsConfig.map(
                    ({
                      text,
                      theme,
                      size = ButtonSize.Large,
                      action,
                      className,
                    }) => (
                      <Button
                        key={text}
                        theme={theme}
                        size={size}
                        onMouseUp={() => {
                          if (action) {
                            action();
                          }
                        }}
                        onClick={() =>
                          closeModalWithTimeout(overlayRef, setOpened, false)
                        }
                        className={classnames(
                          className,
                          isActionButtonsMaxWidth && styles.buttonMaxWidth
                        )}
                      >
                        {text}
                      </Button>
                    )
                  )}
                </div>
              </div>
            </ClickOutside>
          </div>,
          document.body
        )}
    </>
  );
};
