import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { Currency } from 'commonTypes';
import { OrderDirection, AffiliateReportOrderBy } from 'generatedGraphql';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useSearchContext } from 'src/contexts/SearchContext';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import {
  useGetRevShareCompanyAffiliateReportLazyQuery,
  GetRevShareCompanyAffiliateReportVariables,
} from '../queries/generated/GetRevShareCompanyAffiliateReport';

const cellHeight = Heights.defaultCellHeight;

export const useGetRevShareCompanyAffiliateReport = (
  companyId: string,
  affiliateId: string
) => {
  const period = useGetPeriodOfStatistic();

  const { searchValue } = useSearchContext();

  const baseVariables: GetRevShareCompanyAffiliateReportVariables = {
    affiliateId,
    companyId,
    currency: Currency.Usd,
    first: getCountByWindowHeight(cellHeight, 0),
    ordering: {
      orderBy: AffiliateReportOrderBy.AllDeposit,
      direction: OrderDirection.Desc,
    },
    period,
  };

  const [reportVariables, setReportVariables] = useState(baseVariables);

  useEffect(() => {
    setReportVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period.to, period.from]);

  const [
    loadRevShareCompanyAffiliateReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetRevShareCompanyAffiliateReportLazyQuery();

  const loadRevShareCompanyAffiliateReportCallback = useCallback(
    () => loadRevShareCompanyAffiliateReport({ variables: reportVariables }),
    [loadRevShareCompanyAffiliateReport, reportVariables]
  );

  useBlockComponentState({
    loadData: loadRevShareCompanyAffiliateReportCallback,
    loading,
    error,
  });

  const revShareReport = data?.company.affiliate.revShareReport;
  const edges = revShareReport?.edges;
  const total = revShareReport?.total;
  const hasTotalRow = !!total;
  const endCursor = revShareReport?.pageInfo.endCursor;
  const hasNextPage = revShareReport?.pageInfo.hasNextPage;

  const revShareCompanyAffiliateReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  return {
    data,
    loadReport: () =>
      loadRevShareCompanyAffiliateReport({
        variables: { ...reportVariables, search: searchValue },
      }),
    loading,
    error,
    refetch,
    fetchMoreCallback,
    revShareCompanyAffiliateReportList,
    hasNextPage,
    hasTotalRow,
    affiliate: data?.company.affiliate,
  };
};
