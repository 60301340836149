import { FC, ReactElement, useState } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { DataCardProps } from 'commonComponents/DataCard/types';

type DataCardWithDropdownHeader = DataCardProps['header'] & {
  closedDropdownLeftSubTitle?: string | ReactElement;
};

interface DataCardWithDropdownProps extends DataCardProps {
  header?: DataCardWithDropdownHeader;
}

export const DataCardWithDropdown: FC<DataCardWithDropdownProps> = ({
  header,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const leftSubTitle =
    header?.closedDropdownLeftSubTitle && !isOpen
      ? header?.closedDropdownLeftSubTitle
      : header?.leftSubTitle;

  return (
    <DataCard
      {...rest}
      header={{
        leftTitle: header?.leftTitle,
        leftSubTitle,
        rightTitle: header?.rightTitle,
      }}
      dropDownConfig={{
        onTitleClick: () => {
          setIsOpen(!isOpen);
        },
        isOpen,
      }}
    />
  );
};
