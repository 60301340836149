import { Currency } from 'commonTypes';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetExportReferralLinkMediaItemRevShareReportLazyQuery } from '../queries/generated/GetExportReferralLinkMediaItemRevShareReport';
import { useExportReportHandlers } from '../../../helpers/useHandleCompleteExportReport';

interface UseGetExportReferralLinkMediaItemRevShareReportParams {
  referralLinkId: string;
}

export const useGetExportReferralLinkMediaItemRevShareReport = ({
  referralLinkId,
}: UseGetExportReferralLinkMediaItemRevShareReportParams) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportReport,
    handleErrorExportReport,
    downloadReportFileLoading,
  } = useExportReportHandlers();

  const [exportReport, { loading: getExportReportLinkLoading }] =
    useGetExportReferralLinkMediaItemRevShareReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportReport(data.mediaItem.exportRevShareReport);
      },
      onError: handleErrorExportReport,
    });

  const exportReportWithVariables = useCallback(() => {
    exportReport({
      variables: {
        id: referralLinkId,
        currency: Currency.Usd,
        period,
      },
    });
  }, [exportReport, period, referralLinkId]);

  return {
    exportReport: exportReportWithVariables,
    exportReportLoading:
      getExportReportLinkLoading || downloadReportFileLoading,
    exportPrivilege: UserPrivilege.ExportReferralLinkMediaItemRevShareReport,
  };
};
