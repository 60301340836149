import { FC, useContext } from 'react';
import { HeaderActionButtonsWrapper } from 'ui/Block/BlockTable/components/HeaderActionButtonsWrapper';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { BlockContext } from 'ui/Block/store';
import { ApolloQueryResult } from '@apollo/client';
import { UserPrivilege } from 'generatedUserPrivilege';
import { RewardTariffType } from 'commonTypes';
import { Popup } from 'ui/Popup';
import { ButtonTheme } from 'ui/Button';
import Privilege from 'commonComponents/Privilege';
import { useAcceptAllRewards } from './hooks/useAcceptAllRewards';
import { getAcceptAllRewardsPopupMessage } from './helpers';

interface RewardsHeaderActionsProps {
  refetch: () => Promise<ApolloQueryResult<unknown>>;
  rewardTariffType: RewardTariffType;
}

export const RewardsHeaderActions: FC<RewardsHeaderActionsProps> = ({
  refetch,
  rewardTariffType,
}) => {
  const { state } = useContext(BlockContext);

  const { acceptAllRewardsAction, loading } =
    useAcceptAllRewards(rewardTariffType);

  const actionButtonsConfig = [
    {
      text: 'Подтвердить',
      theme: ButtonTheme.Primary,
      action: acceptAllRewardsAction,
    },
    {
      text: 'Отменить',
      theme: ButtonTheme.Process,
    },
  ];

  return (
    <HeaderActionButtonsWrapper>
      <Privilege privileges={[UserPrivilege.AcceptAllRewards]}>
        <Popup
          content={getAcceptAllRewardsPopupMessage(rewardTariffType)}
          actionButtonsConfig={actionButtonsConfig}
          triggerElement={
            <ActionButton
              actionType={ButtonType.CheckDouble}
              disabled={state.isLoading || loading}
              dataTestId="acceptAllRewards"
            />
          }
          isActionButtonsMaxWidth
        />
      </Privilege>

      <ActionButton
        actionType={ButtonType.Update}
        disabled={state.isLoading}
        onClick={() => refetch()}
      />
    </HeaderActionButtonsWrapper>
  );
};
