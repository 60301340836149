import { FC, useContext } from 'react';
import Block from 'ui/Block';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import Error from 'ui/Error';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import { ProgressBar, ProgressBarColor, ProgressBarView } from 'ui/ProgressBar';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { TooltipQuestionMark } from 'ui/TooltipQuestionMark';
import { Currency } from 'commonTypes';
import { formatSumWithCurrency } from 'src/utils/accounting';
import styles from './styles/AnalyticsBlock.module.scss';
import { useGetAnalyticsMultiChartsData } from './hooks/useGetAnalyticsMultiChartsData';
import LimitHeader from './components/LimitHeader';
import { useGetPlayerAnalyticsLazyQuery } from './queries/generated/GetPlayerAnalytics';
import MultiChart from './components/MultiChart';
import { formatSumToProfit, getLimitsValues } from './helpers/helpers';

const MULTI_CHART_FIXED_CURRENCY = Currency.Usd;

interface Props {
  id: string;
}

const AnalyticsBlock: FC<Props> = ({ id }) => {
  const [loadData, { loading, error, data, refetch }] =
    useGetPlayerAnalyticsLazyQuery({
      variables: {
        id,
        currency: MULTI_CHART_FIXED_CURRENCY,
      },
      fetchPolicy: 'network-only',
    });

  const { state } = useContext(BlockContext);

  const withdrawalLimits = data?.player.withdrawalLimits;
  const statistic = data?.player.statistic;

  const limitsValues = getLimitsValues(withdrawalLimits, statistic);

  const { betsWinsChartData, depositsWithdrawalsChartData } =
    useGetAnalyticsMultiChartsData(data);

  const refetchData = () => {
    refetch?.();
  };

  useBlockComponentState({
    loadData,
    loading,
    error,
  });

  if (error) {
    return <Error error={error} />;
  }

  const isEmptyList = statistic && !statistic?.hasTransactions;

  return (
    <Block
      title="Аналитика"
      id="analytics"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={refetchData}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Игрок не совершал денежных операций"
      isEmpty={isEmptyList}
    >
      {!!statistic?.hasTransactions && (
        <div className={styles.container}>
          <div className={styles.limits}>
            <div className={styles.dailyLimit}>
              <ProgressBar
                viewType={ProgressBarView.Limit}
                header={
                  <LimitHeader
                    label="Суточный лимит на&nbsp;вывод"
                    limitAmount={limitsValues.dailyLimit}
                    withdrawalsAmount={limitsValues.dailySum}
                    limitCurrency={limitsValues.currency}
                  />
                }
                startTitle={`Выводы: ${formatSumWithCurrency(
                  limitsValues.dailySum,
                  limitsValues.currency
                )}`}
                endTitle={`${formatSumWithCurrency(
                  limitsValues.dailyLimit,
                  limitsValues.currency
                )}/день`}
                percent={limitsValues.dailyProgress}
                barColor={ProgressBarColor.Red}
              />
            </div>
            <div className={styles.monthlyLimit}>
              <ProgressBar
                viewType={ProgressBarView.Limit}
                header={
                  <LimitHeader
                    label="Криптовалютный лимит на&nbsp;вывод"
                    limitAmount={limitsValues.monthlyCryptoLimit}
                    withdrawalsAmount={limitsValues.monthlyCryptoSum}
                    limitCurrency={limitsValues.currency}
                  />
                }
                startTitle={`Выводы: ${formatSumWithCurrency(
                  limitsValues.monthlyCryptoSum,
                  limitsValues.currency
                )}`}
                endTitle={`${formatSumWithCurrency(
                  limitsValues.monthlyCryptoLimit,
                  limitsValues.currency
                )}/месяц`}
                percent={limitsValues.monthlyProgress}
                barColor={ProgressBarColor.Orange}
              />
            </div>
          </div>
          <div className={styles.multiChartsContainer}>
            <div className={styles.multiCharts}>
              <div className={styles.chartDepositsWithdrawals}>
                <div className={styles.chartTitle}>Депозиты и выводы</div>
                <MultiChart
                  data={depositsWithdrawalsChartData}
                  firstTitle="Депозиты"
                  secondTitle="Выводы"
                  firstBarColor={ProgressBarColor.Green}
                  secondBarColor={ProgressBarColor.Red}
                />
              </div>
              <div className={styles.chartBetsWins}>
                <div className={styles.chartTitle}>Ставки и выигрыши</div>
                <MultiChart
                  data={betsWinsChartData}
                  firstTitle="Ставки"
                  secondTitle="Выигрыши"
                  firstBarColor={ProgressBarColor.Orange}
                  secondBarColor={ProgressBarColor.Indigo}
                />
              </div>
            </div>
            <div className={styles.profit}>
              <span className={styles.profitValue}>
                {`Прибыль игрока: ${formatSumToProfit(
                  limitsValues.profit,
                  limitsValues.currency
                )}`}
              </span>
              &nbsp;
              <TooltipQuestionMark tooltipContent="По&nbsp;формуле: Выводы − Депозиты" />
            </div>
          </div>
        </div>
      )}
    </Block>
  );
};

export default withBlockProvider(AnalyticsBlock);
