import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { MediaItemTypeName } from 'commonTypes';
import { MediaItemsTabs } from 'commonComponents/MediaItemsTabs';
import { MEDIA_ITEMS_BLOCK_TITLE } from '../../const';
import { MediaItemsPromoCodeTable } from './components/MediaItemsPromoCodeTable';
import { useGetMediaItemsPromoCode } from './hooks/useGetMediaItemsPromoCode';
import { MediaItemsBlockHeaderContent } from '../MediaItemsBlockHeaderContent';
import { PostbackDisabledNotificationsBlock } from '../PostbackDisabledNotificationsBlock';
import styles from './MediaItemsPromoCode.module.scss';

interface MediaItemsPromoCodeProps {
  setMediaItemsType: (type: MediaItemTypeName) => void;
}

export const MediaItemsPromoCode: FC<MediaItemsPromoCodeProps> =
  withBlockProvider(({ setMediaItemsType }) => {
    const { mediaItemsPromoCode, refetchMediaItemsPromoCode } =
      useGetMediaItemsPromoCode();

    return (
      <Block
        title={MEDIA_ITEMS_BLOCK_TITLE}
        id="mediaItemsPromoCode"
        headerContent={
          <MediaItemsBlockHeaderContent
            handleRefetchClick={() => refetchMediaItemsPromoCode()}
          />
        }
        subHeader={
          <MediaItemsTabs
            currentTabId={MediaItemTypeName.PromoCode}
            setMediaItemsType={setMediaItemsType}
          />
        }
        extendedHeaderClassname={styles.extendedHeader}
        extendedHeader={
          !!mediaItemsPromoCode && <PostbackDisabledNotificationsBlock />
        }
        shrinkLoaderWrapper={false}
        emptyText="Нет медиа элементов"
        isEmpty={isListEmpty(mediaItemsPromoCode)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(mediaItemsPromoCode) && (
          <MediaItemsPromoCodeTable mediaItemsPromoCode={mediaItemsPromoCode} />
        )}
      </Block>
    );
  });
