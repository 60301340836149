import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PlayerAccountStatisticData } from './PlayerAccountStatisticData';
import { PlayerAccountStatisticFixedCurrencyData } from './PlayerAccountStatisticFixedCurrencyData';
import { PlayerStatisticData } from './PlayerStatisticData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPlayerAnalyticsVariables = SchemaTypes.Exact<{
  currency?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Currency']['input']>;
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPlayerAnalytics = { __typename: 'Query', player: { __typename: 'Player', id: string, accounts: Array<{ __typename: 'Account', id: string, statistic: { __typename: 'AccountStatistic', betSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, depositSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, winSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, withdrawalSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, statisticWithFixedCurrency: { __typename: 'AccountStatistic', betSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, depositSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, winSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, withdrawalSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } }>, statistic: { __typename: 'PlayerStatistic', hasTransactions: boolean, dailyWithdrawalSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, monthlyCryptoWithdrawalSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, profit: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, withdrawalLimits: { __typename: 'WithdrawalLimits', dailyLimit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, monthlyCryptoLimit: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } };


export const GetPlayerAnalyticsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPlayerAnalytics"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currency"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Currency"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlayerAccountStatisticData"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlayerAccountStatisticFixedCurrencyData"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlayerStatisticData"}}]}}]}},...PlayerAccountStatisticData.definitions,...PlayerAccountStatisticFixedCurrencyData.definitions,...PlayerStatisticData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPlayerAnalytics__
 *
 * To run a query within a React component, call `useGetPlayerAnalytics` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerAnalytics` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerAnalytics({
 *   variables: {
 *      currency: // value for 'currency'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerAnalytics(baseOptions: ApolloReactHooks.QueryHookOptions<GetPlayerAnalytics, GetPlayerAnalyticsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPlayerAnalytics, GetPlayerAnalyticsVariables>(GetPlayerAnalyticsDocument, options);
      }
export function useGetPlayerAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayerAnalytics, GetPlayerAnalyticsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPlayerAnalytics, GetPlayerAnalyticsVariables>(GetPlayerAnalyticsDocument, options);
        }
export type GetPlayerAnalyticsHookResult = ReturnType<typeof useGetPlayerAnalytics>;
export type GetPlayerAnalyticsLazyQueryHookResult = ReturnType<typeof useGetPlayerAnalyticsLazyQuery>;
export type GetPlayerAnalyticsQueryResult = Apollo.QueryResult<GetPlayerAnalytics, GetPlayerAnalyticsVariables>;