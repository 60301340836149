import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { Route as RouteEnum } from 'src/router/routes.const';
import { generatePath } from 'react-router';
import { PartnerMediaCampaignsTable } from './components/PartnerMediaCampaignsTable';
import { useGetPartnerMediaCampaignsColumns } from './hooks/useGetPartnerMediaCampaignsColumns';

interface PartnerMediaCampaignsBlockProps {
  id: string;
}

export const PartnerMediaCampaignsBlock: FC<PartnerMediaCampaignsBlockProps> =
  withBlockProvider(({ id }) => {
    const { columns } = useGetPartnerMediaCampaignsColumns();

    return (
      <PartnerMediaCampaignsTable
        partnerId={id}
        columns={columns}
        routeToCustomPage={generatePath(RouteEnum.PARTNER_MEDIA_CAMPAIGNS, {
          id,
        })}
        isFullScreenTable={false}
      />
    );
  });
