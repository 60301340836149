import { formatSumWithCurrency } from 'src/utils/accounting';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { Route } from 'src/router/routes.const';
import { columnsIds, ColumnId, mapColumnIdToName } from '../const';
import { CpaPartnerReportData } from '../../../queries/generated/CpaPartnerReportData';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../../components/MultilineCellWithLinkWithPeriodQueryParams';

const columnHelper = createColumnHelper<CpaPartnerReportData>();

const columnsSource: ColumnsSource<CpaPartnerReportData> = [
  columnHelper.accessor(ColumnId.ReferralCode, {
    id: ColumnId.ReferralCode,
    header: mapColumnIdToName[ColumnId.ReferralCode],
    cell: ({ row }) => {
      if (!row.original.mediaItem) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name } = row.original.mediaItem;

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={addHashTagToId(id)}
          route={Route.STATISTIC_CPA_REFERRAL_LINK}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Hits, {
    id: ColumnId.Hits,
    header: mapColumnIdToName[ColumnId.Hits],
    cell: ({ row }) => {
      const { hits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hits);
    },
  }),
  columnHelper.accessor(ColumnId.Hosts, {
    id: ColumnId.Hosts,
    header: mapColumnIdToName[ColumnId.Hosts],
    cell: ({ row }) => {
      const { hosts } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hosts);
    },
  }),
  columnHelper.accessor(ColumnId.Registrations, {
    id: ColumnId.Registrations,
    header: mapColumnIdToName[ColumnId.Registrations],
    cell: ({ row }) => {
      const { registrations } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(registrations);
    },
  }),
  columnHelper.accessor(ColumnId.FirstDeposits, {
    id: ColumnId.FirstDeposits,
    header: mapColumnIdToName[ColumnId.FirstDeposits],
    cell: ({ row }) => {
      const { firstDeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(firstDeposits);
    },
  }),
  columnHelper.accessor(ColumnId.HostToRegConversion, {
    id: ColumnId.HostToRegConversion,
    header: mapColumnIdToName[ColumnId.HostToRegConversion],
    cell: ({ row }) => {
      const { hostToRegConversion } = row.original.marketingStatistic;

      return addPercentToValue(hostToRegConversion);
    },
  }),
  columnHelper.accessor(ColumnId.RegToDepConversion, {
    id: ColumnId.RegToDepConversion,
    header: mapColumnIdToName[ColumnId.RegToDepConversion],
    cell: ({ row }) => {
      const { regToDepConversion } = row.original.marketingStatistic;

      return addPercentToValue(regToDepConversion);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.AllDeposit, {
    id: ColumnId.AllDeposit,
    header: mapColumnIdToName[ColumnId.AllDeposit],
    cell: ({ row }) => {
      const { allDeposit } = row.original.playerStatistic;

      return formatSumWithCurrency(allDeposit.amount, allDeposit.currency);
    },
  }),
  columnHelper.accessor(ColumnId.AverageDeposit, {
    id: ColumnId.AverageDeposit,
    header: mapColumnIdToName[ColumnId.AverageDeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.AllRedeposit, {
    id: ColumnId.AllRedeposit,
    header: mapColumnIdToName[ColumnId.AllRedeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Redeposits, {
    id: ColumnId.Redeposits,
    header: mapColumnIdToName[ColumnId.Redeposits],
    cell: ({ row }) => {
      const { redeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(redeposits);
    },
  }),
  columnHelper.accessor(ColumnId.NewDeposit, {
    id: ColumnId.NewDeposit,
    header: mapColumnIdToName[ColumnId.NewDeposit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Withdrawal, {
    id: ColumnId.Withdrawal,
    header: mapColumnIdToName[ColumnId.Withdrawal],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.NewWithdrawal, {
    id: ColumnId.NewWithdrawal,
    header: mapColumnIdToName[ColumnId.NewWithdrawal],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.NewProfit, {
    id: ColumnId.NewProfit,
    header: mapColumnIdToName[ColumnId.NewProfit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Hold, {
    id: ColumnId.Hold,
    header: mapColumnIdToName[ColumnId.Hold],
    cell: ({ row }) => {
      const { hold } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(hold);
    },
  }),
  columnHelper.accessor(ColumnId.Qualified, {
    id: ColumnId.Qualified,
    header: mapColumnIdToName[ColumnId.Qualified],
    cell: ({ row }) => {
      const { qualified } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(qualified);
    },
  }),
  columnHelper.accessor(ColumnId.NotQualified, {
    id: ColumnId.NotQualified,
    header: mapColumnIdToName[ColumnId.NotQualified],
    cell: ({ row }) => {
      const { canceled } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(canceled);
    },
  }),
  columnHelper.accessor(ColumnId.Approved, {
    id: ColumnId.Approved,
    header: mapColumnIdToName[ColumnId.Approved],
    cell: ({ row }) => {
      const { approved } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(approved);
    },
  }),
  columnHelper.accessor(ColumnId.Rejected, {
    id: ColumnId.Rejected,
    header: mapColumnIdToName[ColumnId.Rejected],
    cell: ({ row }) => {
      const { rejected } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(rejected);
    },
  }),
  columnHelper.accessor(ColumnId.Paid, {
    id: ColumnId.Paid,
    header: mapColumnIdToName[ColumnId.Paid],
    cell: ({ row }) => {
      const { paid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(paid);
    },
  }),
  columnHelper.accessor(ColumnId.Unpaid, {
    id: ColumnId.Unpaid,
    header: mapColumnIdToName[ColumnId.Unpaid],
    cell: ({ row }) => {
      const { unpaid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(unpaid);
    },
  }),
  columnHelper.accessor(ColumnId.RevShare, {
    id: ColumnId.RevShare,
    header: mapColumnIdToName[ColumnId.RevShare],
    cell: ({ row }) => {
      const { revShare } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(revShare);
    },
  }),
  columnHelper.accessor(ColumnId.NGR, {
    id: ColumnId.NGR,
    header: mapColumnIdToName[ColumnId.NGR],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Profit, {
    id: ColumnId.Profit,
    header: mapColumnIdToName[ColumnId.Profit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Upcoming, {
    id: ColumnId.Upcoming,
    header: mapColumnIdToName[ColumnId.Upcoming],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.affiliateRewardStatistic.upcoming;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.RewardPaid, {
    id: ColumnId.RewardPaid,
    header: mapColumnIdToName[ColumnId.RewardPaid],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateRewardStatistic.paid;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetCpaPartnerReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
