import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { useModalManageData } from 'ui/Modal';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { CopyModalRow } from 'ui/CopyModalRow';
import { useGetPostbackReferralLink } from './queries/generated/GetPostbackReferralLink';

interface DisabledPostbackReferralLinkProps {
  referralLinkId: string;
}

export const DisabledPostbackReferralLink: FC<
  DisabledPostbackReferralLinkProps
> = ({ referralLinkId }) => {
  const { data, loading, error } = useGetPostbackReferralLink({
    variables: {
      id: referralLinkId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: {
      toastId: 'get-postback-referral-link-error',
      error,
    },
    hasData: !!data,
  });

  if (!data) {
    return null;
  }

  const {
    name: referralLinkName,
    mediaCampaign,
    mediaItemRewardTariff,
    createdAt,
    referralLink,
  } = data.mediaItem;

  const { url, name: mediaCampaignName } = mediaCampaign;

  return (
    <DataCard
      rows={[
        {
          title: 'Название медиа элемента',
          value: referralLinkName,
        },
        {
          title: 'Название медиа кампании',
          value: mediaCampaignName,
        },
        {
          title: 'URL медиа кампании',
          value: url,
        },
        {
          title: 'Тип элемента',
          value: 'Ссылка',
        },
        {
          title: 'Партнёрский тариф',
          value: mediaItemRewardTariff.rewardTariff.title,
        },
        {
          title: 'Реферальная ссылка',
          value: (
            <CopyModalRow
              value={referralLink}
              toastText="Реферальная ссылка скопирована"
            />
          ),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.DefaultDateOld),
        },
      ]}
    />
  );
};
