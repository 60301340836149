import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { MediaItemPromoCodeData } from '../../../../../../../../queries/generated/MediaItemPromoCodeData';
import { MediaItemPromoCodeBasicData } from '../../../../../../../../queries/generated/MediaItemPromoCodeBasicData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetMediaItemPromoCodeVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetMediaItemPromoCode = { __typename: 'Query', promoCodeMediaItem: { __typename: 'PromoCodeMediaItem', createdAt: string, id: string, updatedAt: string, usageType: SchemaTypes.PromoCodeUsageType, promoCode: { __typename: 'PromoCode', id: string, token: string, bonusAction?: { __typename: 'PromoCodeBonusAction', cashout?: number | null, wager?: number | null, amount: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, minAccountBalanceAmount?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null } | null, freespinAction?: { __typename: 'PromoCodeFreespinAction', bonusWager?: number | null, count: number, currency: CommonType.Currency, game: { __typename: 'Game', id: string, name: string }, minAccountBalanceAmount?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null } | null, activation?: { __typename: 'PromoCodeActivation', activated: number, limit: number } | null }, mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string }, referralLink?: { __typename: 'ReferralLinkMediaItem', code: any, id: string, referralLink: string } | null } };


export const GetMediaItemPromoCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMediaItemPromoCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"promoCodeMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaItemPromoCodeData"}}]}}]}},...MediaItemPromoCodeData.definitions,...MediaItemPromoCodeBasicData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetMediaItemPromoCode__
 *
 * To run a query within a React component, call `useGetMediaItemPromoCode` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaItemPromoCode` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaItemPromoCode({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaItemPromoCode(baseOptions: ApolloReactHooks.QueryHookOptions<GetMediaItemPromoCode, GetMediaItemPromoCodeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMediaItemPromoCode, GetMediaItemPromoCodeVariables>(GetMediaItemPromoCodeDocument, options);
      }
export function useGetMediaItemPromoCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMediaItemPromoCode, GetMediaItemPromoCodeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMediaItemPromoCode, GetMediaItemPromoCodeVariables>(GetMediaItemPromoCodeDocument, options);
        }
export type GetMediaItemPromoCodeHookResult = ReturnType<typeof useGetMediaItemPromoCode>;
export type GetMediaItemPromoCodeLazyQueryHookResult = ReturnType<typeof useGetMediaItemPromoCodeLazyQuery>;
export type GetMediaItemPromoCodeQueryResult = Apollo.QueryResult<GetMediaItemPromoCode, GetMediaItemPromoCodeVariables>;