import { FC, ReactElement } from 'react';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { CompanyPageContent } from '../CompanyPageContent';
import { PageTypeStorage } from '../../../../utils/storage';
import { CompaniesSearchInput } from '../CompaniesSearchInput/CompaniesSearchInput';

interface CompanyPageContainerProps {
  children: ReactElement;
  companyId: string;
  shouldProvideBlock?: boolean;
}

export const CompanyPageContainer: FC<CompanyPageContainerProps> = ({
  companyId,
  shouldProvideBlock = true,
  children,
}) => (
  <SearchContextProvider
    shouldUpdateUrl={false}
    pageType={PageTypeStorage.CompanyPage}
  >
    <>
      <CompaniesSearchInput />
      <CompanyPageContent
        companyId={companyId}
        shouldProvideBlock={shouldProvideBlock}
      >
        {children}
      </CompanyPageContent>
    </>
  </SearchContextProvider>
);
