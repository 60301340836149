import { Currency } from 'commonTypes';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetExportPartnerCpaReportLazyQuery } from '../queries/generated/GetExportPartnerCpaReport';
import { useExportReportHandlers } from '../../../helpers/useHandleCompleteExportReport';

interface UseGetExportPartnerCpaReportParams {
  partnerId: string;
  isAllTimeCpa: boolean;
}

export const useGetExportPartnerCpaReport = ({
  partnerId,
  isAllTimeCpa,
}: UseGetExportPartnerCpaReportParams) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportReport,
    handleErrorExportReport,
    downloadReportFileLoading,
  } = useExportReportHandlers();

  const [exportReport, { loading: getExportReportLinkLoading }] =
    useGetExportPartnerCpaReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportReport(data.partner.exportCpaReport);
      },
      onError: handleErrorExportReport,
    });

  const exportReportWithVariables = useCallback(() => {
    exportReport({
      variables: {
        id: partnerId,
        currency: Currency.Usd,
        period,
        showAllTimeCpa: isAllTimeCpa,
      },
    });
  }, [exportReport, isAllTimeCpa, partnerId, period]);

  return {
    exportReport: exportReportWithVariables,
    exportReportLoading:
      getExportReportLinkLoading || downloadReportFileLoading,
    exportPrivilege: UserPrivilege.ExportPartnerCpaReport,
  };
};
