import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { generatePath } from 'react-router';
import { Route as RouteEnum } from 'src/router/routes.const';
import { useGetTransfersColumns } from './hooks/useGetTransfersColumns';
import TransfersTable from './components/TransfersTable';

interface TransfersBlockProps {
  id: string;
}

const TransfersBlock: FC<TransfersBlockProps> = ({ id: playerId }) => {
  const { columns } = useGetTransfersColumns(false);

  return (
    <TransfersTable
      playerId={playerId}
      columns={columns}
      routeToCustomPage={generatePath(RouteEnum.PLAYER_TRANSFERS, {
        id: playerId,
      })}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(TransfersBlock);
