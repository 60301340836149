import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { TextWithLinkRow } from 'commonComponents/DataCard/components/TextWithLinkRow';
import Button from 'ui/Button';
import { TextOverflow } from 'ui/TextOverflow';
import { GetPostbackDisabledNotificationData } from '../../queries/generated/GetPostbackDisabledNotificationData';
import { postbackEventMap, postbackMethodMap } from './const';
import { getRouteByTypeName, getOwnerNameByTypeName } from './helpers';
import { getOwnerTypeByTypeName } from './helpers/getOwnerTypeByTypeName';
import styles from './PostbackDisabledNotification.module.scss';
import { useOpenDisabledPostbackReferralLinkStep } from './hooks/useOpenDisabledPostbackReferralLinkStep';
import { UpdatePostbackDisabledNotificationButtons } from '../UpdatePostbackDisabledNotificationButtons';

interface PostbackDisabledNotificationProps {
  disabledPostback: GetPostbackDisabledNotificationData;
  postbackDisabledNotificationsId: string;
  setReferralLinkId: (referralLinkId: string) => void;
}

export const PostbackDisabledNotification: FC<
  PostbackDisabledNotificationProps
> = ({
  disabledPostback,
  setReferralLinkId,
  postbackDisabledNotificationsId,
}) => {
  const openDisabledPostbackReferralLinkStep =
    useOpenDisabledPostbackReferralLinkStep();

  const { event, method, url, referralLinkMediaItem } = disabledPostback;
  const { owner, name, id: referralLinkMediaItemId } = referralLinkMediaItem;

  return (
    <DataCard
      header={{
        leftTitle: postbackEventMap[event],
      }}
      withBorder
      rows={[
        {
          title: 'Метод',
          value: postbackMethodMap[method],
        },
        {
          title: 'URL',
          value: (
            <div className={styles.urlContainer}>
              <TextOverflow text={url} />
            </div>
          ),
        },
        {
          title: getOwnerTypeByTypeName(owner),
          value: (
            <TextWithLinkRow
              isRightIconPosition
              title={getOwnerNameByTypeName(owner)}
              route={getRouteByTypeName(owner)}
            />
          ),
        },
        {
          title: 'Медиа элемент',
          value: (
            <Button
              onClick={() => {
                openDisabledPostbackReferralLinkStep(referralLinkMediaItemId);
                setReferralLinkId(referralLinkMediaItemId);
              }}
              linkStyled
            >
              {name}
            </Button>
          ),
        },
      ]}
      customFooter={
        <UpdatePostbackDisabledNotificationButtons
          postbackDisabledNotificationId={postbackDisabledNotificationsId}
          postbackId={disabledPostback.id}
        />
      }
    />
  );
};
