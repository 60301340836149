import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { DateFormat, formatDate } from '../../../../../../utils/formatDate';
import { getCountryISOtoDisplayName } from '../../../../../../utils/getCountryISOtoDisplayName';
import { formatSumWithCurrency } from '../../../../../../utils/accounting';
import { EditTariffNameModal } from '../EditTariffNameModal/EditTariffNameModal';
import { useCpaRewardTariffModal } from './hooks/useCpaRewardTariffModal';
import { CpaRewardTariffsModalSteps } from './const';

interface CpaRewardTariffsModalProps {
  cpaRewardTariffsId: string;
}

export const CpaRewardTariffModal: FC<CpaRewardTariffsModalProps> = ({
  cpaRewardTariffsId,
}) => {
  const { goToEditName, cpaRewardTariff, step } = useCpaRewardTariffModal({
    cpaRewardTariffsId,
  });

  if (!cpaRewardTariff) {
    return null;
  }

  const { title, rewardSum, countries, createdAt, baselineSum } =
    cpaRewardTariff;

  if (step === CpaRewardTariffsModalSteps.EditNameStep) {
    return (
      <EditTariffNameModal
        cpaRewardTariffsId={cpaRewardTariffsId}
        title={title}
      />
    );
  }

  return (
    <DataCard
      customFooter={
        <div>
          <Button
            size={ButtonSize.Large}
            onClick={goToEditName}
            theme={ButtonTheme.Process}
          >
            Редактировать название
          </Button>
        </div>
      }
      rows={[
        {
          title: 'Название',
          value: title,
        },
        {
          title: 'Модель оплаты',
          value: 'CPA',
        },
        {
          title: 'Геолокация',
          value: getCountryISOtoDisplayName(countries),
        },
        {
          title: 'Baseline',
          value: formatSumWithCurrency(
            baselineSum.amount,
            baselineSum.currency
          ),
        },
        {
          title: 'Вознаграждение',
          value: formatSumWithCurrency(rewardSum.amount, rewardSum.currency),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
      ]}
    />
  );
};
