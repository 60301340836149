import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { CompanyApplicationData } from '../../../../queries/generated/CompanyApplicationData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyApplicationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetCompanyApplication = { __typename: 'Query', companyApplication: { __typename: 'CompanyApplication', companyEmail: string, companyName: string, createdAt: string, declineReason?: string | null, id: string, status: SchemaTypes.CompanyApplicationStatus, trafficSource?: string | null, updatedAt: string, website?: string | null, admin?: { __typename: 'Admin', firstName: string, id: string, lastName: string } | null, contactForCommunication: { __typename: 'ContactForCommunication', type: CommonType.ContactForCommunicationType, value: string } } };


export const GetCompanyApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CompanyApplicationData"}}]}}]}},...CompanyApplicationData.definitions]} as unknown as DocumentNode;

/**
 * __useGetCompanyApplication__
 *
 * To run a query within a React component, call `useGetCompanyApplication` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyApplication` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyApplication({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyApplication(baseOptions: ApolloReactHooks.QueryHookOptions<GetCompanyApplication, GetCompanyApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCompanyApplication, GetCompanyApplicationVariables>(GetCompanyApplicationDocument, options);
      }
export function useGetCompanyApplicationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyApplication, GetCompanyApplicationVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCompanyApplication, GetCompanyApplicationVariables>(GetCompanyApplicationDocument, options);
        }
export type GetCompanyApplicationHookResult = ReturnType<typeof useGetCompanyApplication>;
export type GetCompanyApplicationLazyQueryHookResult = ReturnType<typeof useGetCompanyApplicationLazyQuery>;
export type GetCompanyApplicationQueryResult = Apollo.QueryResult<GetCompanyApplication, GetCompanyApplicationVariables>;