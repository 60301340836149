import { FC } from 'react';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { useModalManageData } from 'ui/Modal';
import { DataCard } from 'commonComponents/DataCard';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { nbsp, twoEmDash } from 'src/const';
import { useGetFreespin } from './queries/generated/GetFreespin';
import { getFreespinStatusIconConfig } from '../../utils';
import { freespinStatusMap } from '../../const';

interface FreespinItemProps {
  freespinId: string;
}

const FreespinItem: FC<FreespinItemProps> = ({ freespinId }) => {
  const { data, loading, error } = useGetFreespin({
    variables: {
      id: freespinId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: {
      error,
      toastId: 'get-freespin-error',
    },
    hasData: !!data,
  });

  if (!data) {
    return null;
  }

  const {
    count,
    currency,
    createdAt,
    game: { name },
    status,
    promoCode,
    updatedAt,
    bonusWager,
    minAccountBalance,
  } = data.freespin;

  return (
    <DataCard
      statusConfig={{
        status: freespinStatusMap[status],
        iconConfig: getFreespinStatusIconConfig(status),
      }}
      rows={[
        promoCode?.token && {
          title: 'Помокод',
          value: promoCode.token,
        },
        {
          title: 'Слот',
          value: name,
        },
        {
          title: 'Количество',
          value: count,
        },
        {
          title: 'Валюта',
          value: currency,
        },
        {
          title: 'Вейджер',
          value: MayBeNullCell(bonusWager, twoEmDash),
        },
        minAccountBalance && {
          title: `Минимальный баланс для${nbsp}активации`,
          value: formatSumWithCurrency(
            minAccountBalance.amount,
            minAccountBalance.currency
          ),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
        updatedAt && {
          title: 'Обновлен',
          value: formatDate(updatedAt, DateFormat.FullDate),
        },
      ]}
    />
  );
};

export default FreespinItem;
