import { FC, useContext, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import {
  ModalActions,
  ModalContext,
  TabsWrapper,
  useModalManageData,
} from 'ui/Modal';
import styles from './CompanyAffiliateApplicationModal.module.scss';
import { useGetCompanyAffiliateApplication } from './queries/generated/GetCompanyAffiliateApplication';
import { useTabsSidebar } from './hooks/useTabsSidebar';
import {
  AffiliateInApproveProcessStatus,
  AffiliatesInProgress,
  CompanyAffiliateApplicationContext,
  setAffiliatesInProgressAction,
  withCompanyAffiliateApplicationProvider,
} from './store';
import { CompanyAffiliateApplicationModalSteps } from './const';
import { EditPartnerCompanyAffiliatesApplication } from './components/EditPartnerCompanyAffiliate';

interface CompanyAffiliateApplicationModalProps {
  companyAffiliateApplicationId: string;
}

const Component: FC<CompanyAffiliateApplicationModalProps> = ({
  companyAffiliateApplicationId,
}) => {
  const { data, error, loading } = useGetCompanyAffiliateApplication({
    variables: { id: companyAffiliateApplicationId },
  });

  const { state: modalState, dispatch: modalDispatch } =
    useContext(ModalContext);

  const { state, dispatch } = useContext(CompanyAffiliateApplicationContext);

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-company-affiliate-application' },
    hasData: !!data,
  });

  const tabsData = useTabsSidebar({
    companyAffiliateApplication: data?.companyAffiliateApplication,
  });

  useEffect(() => {
    if (
      modalState.step ===
      CompanyAffiliateApplicationModalSteps.EditPartnerCompanyAffiliate
    ) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: `Редактирование партнёра`,
          subTitle: undefined,
        },
      });
    } else {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: `#${companyAffiliateApplicationId}`,
          subTitle: 'Заявка партнёра компании',
        },
      });
    }
  }, [companyAffiliateApplicationId, modalDispatch, modalState.step]);

  const affiliatesInProgressState = useMemo(
    () => ({ ...state.affiliatesInProgress }),
    [state.affiliatesInProgress]
  );

  useEffect(() => {
    if (!state.affiliatesInProgress && tabsData) {
      const affiliatesInProgress: AffiliatesInProgress = {
        ...affiliatesInProgressState,
      };

      data?.companyAffiliateApplication.items.forEach((affiliate) => {
        affiliatesInProgress[affiliate.id] =
          AffiliateInApproveProcessStatus.InProcess;
      });

      dispatch(setAffiliatesInProgressAction({ affiliatesInProgress }));
    }
  }, [
    affiliatesInProgressState,
    data?.companyAffiliateApplication.items,
    dispatch,
    state,
    tabsData,
  ]);

  if (!tabsData) {
    return null;
  }

  if (
    modalState.step ===
    CompanyAffiliateApplicationModalSteps.EditPartnerCompanyAffiliate
  ) {
    return (
      <EditPartnerCompanyAffiliatesApplication
        companyAffiliateApplicationId={companyAffiliateApplicationId}
      />
    );
  }

  return (
    <TabsWrapper
      tabsWrapper={classnames(tabsData.activeTab === 1 && styles.tabsWrapper)}
      tabs={tabsData.tabs}
      activeTabDefault={tabsData.activeTabDefault}
    />
  );
};

export const CompanyAffiliateApplicationModal =
  withCompanyAffiliateApplicationProvider(Component);
