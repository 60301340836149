import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { CompanyReferralLinkMediaItemDetailsData } from './CompanyReferralLinkMediaItemDetailsData';
import { AffiliateReferralLinkMediaItemRewardTariffData } from '../../../../../../../../queries/generated/AffiliateReferralLinkMediaItemRewardTariffData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyReferralLinkMediaItemVariables = SchemaTypes.Exact<{
  companyId: SchemaTypes.Scalars['ID']['input'];
  referralLinkMediaItemId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetCompanyReferralLinkMediaItem = { __typename: 'Query', company: { __typename: 'Company', id: string, referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem', createdAt: string, id: string, name: string, referralLink: string, mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string }, mediaItemRewardTariff: { __typename: 'MediaItemCpaRewardTariff', fallbackRewardTariff: { __typename: 'RevShareRewardTariff', id: string, title: string }, rewardTariff: { __typename: 'CpaRewardTariff', countries: Array<any>, id: string, title: string, baselineSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, rewardSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } | { __typename: 'MediaItemRevShareRewardTariff', rewardTariff: { __typename: 'RevShareRewardTariff', id: string, title: string } } } } };


export const GetCompanyReferralLinkMediaItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyReferralLinkMediaItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"referralLinkMediaItemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"referralLinkMediaItemId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CompanyReferralLinkMediaItemDetailsData"}}]}}]}}]}},...CompanyReferralLinkMediaItemDetailsData.definitions,...AffiliateReferralLinkMediaItemRewardTariffData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetCompanyReferralLinkMediaItem__
 *
 * To run a query within a React component, call `useGetCompanyReferralLinkMediaItem` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyReferralLinkMediaItem` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyReferralLinkMediaItem({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      referralLinkMediaItemId: // value for 'referralLinkMediaItemId'
 *   },
 * });
 */
export function useGetCompanyReferralLinkMediaItem(baseOptions: ApolloReactHooks.QueryHookOptions<GetCompanyReferralLinkMediaItem, GetCompanyReferralLinkMediaItemVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCompanyReferralLinkMediaItem, GetCompanyReferralLinkMediaItemVariables>(GetCompanyReferralLinkMediaItemDocument, options);
      }
export function useGetCompanyReferralLinkMediaItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyReferralLinkMediaItem, GetCompanyReferralLinkMediaItemVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCompanyReferralLinkMediaItem, GetCompanyReferralLinkMediaItemVariables>(GetCompanyReferralLinkMediaItemDocument, options);
        }
export type GetCompanyReferralLinkMediaItemHookResult = ReturnType<typeof useGetCompanyReferralLinkMediaItem>;
export type GetCompanyReferralLinkMediaItemLazyQueryHookResult = ReturnType<typeof useGetCompanyReferralLinkMediaItemLazyQuery>;
export type GetCompanyReferralLinkMediaItemQueryResult = Apollo.QueryResult<GetCompanyReferralLinkMediaItem, GetCompanyReferralLinkMediaItemVariables>;