import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetExportCompanyRevShareReportVariables = SchemaTypes.Exact<{
  currency: SchemaTypes.Scalars['Currency']['input'];
  id: SchemaTypes.Scalars['ID']['input'];
  period: SchemaTypes.DatePeriodInput;
}>;


export type GetExportCompanyRevShareReport = { __typename: 'Query', company: { __typename: 'Company', exportRevShareReport: string, id: string } };


export const GetExportCompanyRevShareReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetExportCompanyRevShareReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currency"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Currency"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"period"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DatePeriodInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportRevShareReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"currency"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currency"}}},{"kind":"Argument","name":{"kind":"Name","value":"period"},"value":{"kind":"Variable","name":{"kind":"Name","value":"period"}}}]},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetExportCompanyRevShareReport__
 *
 * To run a query within a React component, call `useGetExportCompanyRevShareReport` and pass it any options that fit your needs.
 * When your component renders, `useGetExportCompanyRevShareReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportCompanyRevShareReport({
 *   variables: {
 *      currency: // value for 'currency'
 *      id: // value for 'id'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetExportCompanyRevShareReport(baseOptions: ApolloReactHooks.QueryHookOptions<GetExportCompanyRevShareReport, GetExportCompanyRevShareReportVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExportCompanyRevShareReport, GetExportCompanyRevShareReportVariables>(GetExportCompanyRevShareReportDocument, options);
      }
export function useGetExportCompanyRevShareReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportCompanyRevShareReport, GetExportCompanyRevShareReportVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExportCompanyRevShareReport, GetExportCompanyRevShareReportVariables>(GetExportCompanyRevShareReportDocument, options);
        }
export type GetExportCompanyRevShareReportHookResult = ReturnType<typeof useGetExportCompanyRevShareReport>;
export type GetExportCompanyRevShareReportLazyQueryHookResult = ReturnType<typeof useGetExportCompanyRevShareReportLazyQuery>;
export type GetExportCompanyRevShareReportQueryResult = Apollo.QueryResult<GetExportCompanyRevShareReport, GetExportCompanyRevShareReportVariables>;