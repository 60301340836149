import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { CpaRewardTariffData } from './CpaRewardTariffData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCpaRewardTariffVariables = SchemaTypes.Exact<{
  cpaRewardTariffsId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetCpaRewardTariff = { __typename: 'Query', cpaRewardTariff: { __typename: 'CpaRewardTariff', countries: Array<any>, createdAt: string, id: string, title: string, baselineSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, rewardSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } };


export const GetCpaRewardTariffDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCpaRewardTariff"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cpaRewardTariffsId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cpaRewardTariff"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cpaRewardTariffsId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CpaRewardTariffData"}}]}}]}},...CpaRewardTariffData.definitions]} as unknown as DocumentNode;

/**
 * __useGetCpaRewardTariff__
 *
 * To run a query within a React component, call `useGetCpaRewardTariff` and pass it any options that fit your needs.
 * When your component renders, `useGetCpaRewardTariff` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCpaRewardTariff({
 *   variables: {
 *      cpaRewardTariffsId: // value for 'cpaRewardTariffsId'
 *   },
 * });
 */
export function useGetCpaRewardTariff(baseOptions: ApolloReactHooks.QueryHookOptions<GetCpaRewardTariff, GetCpaRewardTariffVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCpaRewardTariff, GetCpaRewardTariffVariables>(GetCpaRewardTariffDocument, options);
      }
export function useGetCpaRewardTariffLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCpaRewardTariff, GetCpaRewardTariffVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCpaRewardTariff, GetCpaRewardTariffVariables>(GetCpaRewardTariffDocument, options);
        }
export type GetCpaRewardTariffHookResult = ReturnType<typeof useGetCpaRewardTariff>;
export type GetCpaRewardTariffLazyQueryHookResult = ReturnType<typeof useGetCpaRewardTariffLazyQuery>;
export type GetCpaRewardTariffQueryResult = Apollo.QueryResult<GetCpaRewardTariff, GetCpaRewardTariffVariables>;