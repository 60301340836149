import { FC } from 'react';
import { Dropdown } from 'ui/Dropdown';
import { useGetMediaCampaigns } from './useGetMediaCampaigns';

interface MediaCampaignDropdownProps {
  name: string;
  label: string;
  disabled?: boolean;
  onChange?: (value: string | Array<string>) => void;
}

export const MediaCampaignDropdown: FC<MediaCampaignDropdownProps> = ({
  name,
  label,
  disabled,
  onChange,
}) => {
  const {
    mediaCampaignOptions,
    loading,
    hasNextPage,
    fetchMore,
    error,
    handleSearchInList,
  } = useGetMediaCampaigns();

  return (
    <Dropdown
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMore();
          }
        },
        hasNextPage,
      }}
      onSearchInList={handleSearchInList}
      loading={loading}
      loadingError={error}
      name={name}
      label={label}
      options={mediaCampaignOptions}
      disabled={disabled}
      onChange={onChange}
    />
  );
};
