import { FC, useCallback, useContext } from 'react';
import { PartnerApplicationStatus } from 'generatedGraphql';
import {
  ReferralLinkBlock,
  ReferralLinkData,
} from 'commonComponents/ReferralLinkBlock';
import { ModalActions, ModalContext } from 'ui/Modal';
import { ButtonsUpdatePartnerApplication } from '../ButtonsUpdatePartnerApplication';
import { PartnerApplicationModalSteps } from '../../../../const';

interface PartnerApplicationFooterProps {
  partnerApplicationStatus: PartnerApplicationStatus;
  partnerApplicationId: string;
  referralLinkData: ReferralLinkData | null;
}

export const PartnerApplicationFooter: FC<PartnerApplicationFooterProps> = ({
  partnerApplicationStatus,
  partnerApplicationId,
  referralLinkData,
}) => {
  const { dispatch } = useContext(ModalContext);

  const goToCreateReferralLinkStep = useCallback(() => {
    dispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: PartnerApplicationModalSteps.CreateReferralLink,
      },
    });
  }, [dispatch]);

  const goToUpdateReferralLinkStep = useCallback(() => {
    dispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: PartnerApplicationModalSteps.UpdateReferralLink,
      },
    });
  }, [dispatch]);

  return (
    <div>
      {partnerApplicationStatus === PartnerApplicationStatus.InProgress && (
        <ReferralLinkBlock
          referralLinkData={referralLinkData}
          onEditButtonClick={goToUpdateReferralLinkStep}
          onCreateButtonClick={goToCreateReferralLinkStep}
        />
      )}
      <ButtonsUpdatePartnerApplication
        partnerApplicationStatus={partnerApplicationStatus}
        partnerApplicationId={partnerApplicationId}
        referralLinkDate={referralLinkData}
      />
    </div>
  );
};
