import { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import Error from 'ui/Error';
import { Confirmation } from 'ui/Confirmation';
import { ApolloError } from '@apollo/client';
import { generatePath } from 'react-router';
import { RouterLink } from 'ui/RouterLink';
import { RouterLinkSize } from 'ui/RouterLink/const';
import { Route } from 'src/router/routes.const';
import { FieldName } from './const';
import { validate } from './validation';
import styles from './DeclinePlayerAccount.module.scss';

interface DeclinePlayerAccountProps {
  onDeclinePlayerAccount: () => void;
  playerId: string;
  playerName: string;
  playerContactInfo?: string | null;
  isLoading: boolean;
  error?: ApolloError;
}

export const DeclinePlayerAccount: FC<DeclinePlayerAccountProps> = ({
  onDeclinePlayerAccount,
  playerId,
  playerName,
  playerContactInfo,
  isLoading,
  error,
}) => {
  const [initialValues] = useState({
    [FieldName.Confirmation]: '',
  });

  const routeToPlayer = generatePath(Route.PLAYER, { id: playerId });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onDeclinePlayerAccount}
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <RouterLink
            path={routeToPlayer}
            size={RouterLinkSize.Large}
            withIcon={false}
            className={styles.link}
          >
            {playerName} ({playerContactInfo})
          </RouterLink>
          <Confirmation
            label="Чтобы сбросить игровой аккаунт напишите «СБРОСИТЬ»"
            loading={isLoading}
            buttonText="Сбросить"
            isValid={isValid}
            dirty={dirty}
          />
        </Form>
      )}
    </Formik>
  );
};
