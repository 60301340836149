import { FC, useMemo } from 'react';
import classnames from 'classnames';
import { CardStatusColor } from 'ui/Card/CardDataList/CardDataList';
import styles from './CardInfo.module.scss';
import capitalize from '../../../utils/capitalize';
import generateUniqueKeys from '../../../utils/uniqueKeys';

interface CardInfoProps {
  info: Array<string | JSX.Element>;
  statusColor?: CardStatusColor;
}

const CardInfo: FC<CardInfoProps> = ({ info, statusColor }) => {
  const infoWithKeys = useMemo(() => info && generateUniqueKeys(info), [info]);

  return (
    <div className={styles.info}>
      {statusColor && (
        <div
          className={classnames(
            styles.status,
            styles[`statusColor${capitalize(statusColor)}`]
          )}
        />
      )}
      {infoWithKeys.map(
        ({ payload, id }) => payload && <span key={id}>{payload}</span>
      )}
    </div>
  );
};

export default CardInfo;
