import { FC } from 'react';
import classNames from 'classnames';
import {
  BonusBalance,
  BonusBalanceIconSize,
} from 'commonComponents/BonusBalance/BonusBalance';
import styles from './SumTitle.module.scss';
import { AccountEventData } from '../../../../queries/fragments/generated/AccountEventData';
import { getSumWithSign } from '../../../../helpers';
import { getEventBonusBalance, mapDirectionToClassName } from './helpers';

interface SumTitleProps {
  event: AccountEventData;
}

export const SumTitle: FC<SumTitleProps> = ({ event }) => {
  const bonusBalanceData = getEventBonusBalance(event);
  const billingBalanceData = event.billingBalance;

  const billingBalance = getSumWithSign({
    amount: billingBalanceData.delta.sum.amount,
    currency: billingBalanceData.delta.sum.currency,
    direction: billingBalanceData.delta.direction,
  });

  const bonusBalance =
    bonusBalanceData &&
    getSumWithSign({
      amount: bonusBalanceData.delta.sum.amount,
      direction: bonusBalanceData.delta.direction,
    });

  return (
    <div className={styles.container} data-testid="sumTitle">
      {billingBalanceData.from.amount !== billingBalanceData.to.amount && (
        <span
          className={classNames(
            styles.balance,
            billingBalanceData.delta.direction &&
              mapDirectionToClassName[billingBalanceData.delta.direction]
          )}
        >
          {billingBalance}
        </span>
      )}
      {bonusBalance && bonusBalanceData && (
        <span
          className={classNames(
            styles.balance,
            bonusBalanceData.delta.direction &&
              mapDirectionToClassName[bonusBalanceData.delta.direction]
          )}
        >
          <BonusBalance
            amount={bonusBalance}
            setDefaultIconColor={false}
            iconSize={BonusBalanceIconSize.Big}
          />
        </span>
      )}
    </div>
  );
};
