import { FC, useCallback, useContext } from 'react';
import { CompanyApplicationStatus } from 'generatedGraphql';
import {
  ReferralLinkBlock,
  ReferralLinkData,
} from 'commonComponents/ReferralLinkBlock';
import { ModalActions, ModalContext } from 'ui/Modal';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import { ButtonsUpdateCompanyApplication } from '../ButtonsUpdateCompanyApplication';
import { CompanyApplicationModalSteps } from '../../../../const';

interface CompanyApplicationFooterProps {
  companyApplicationStatus: CompanyApplicationStatus;
  companyApplicationId: string;
  referralLinkData: ReferralLinkData | null;
}

export const CompanyApplicationFooter: FC<CompanyApplicationFooterProps> = ({
  companyApplicationStatus,
  companyApplicationId,
  referralLinkData,
}) => {
  const { dispatch } = useContext(ModalContext);

  const goToCreateReferralLinkStep = useCallback(() => {
    dispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: CompanyApplicationModalSteps.CreateReferralLink,
      },
    });
  }, [dispatch]);

  const goToUpdateReferralLink = useCallback(() => {
    dispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: CompanyApplicationModalSteps.UpdateReferralLink,
      },
    });
  }, [dispatch]);

  return (
    <Privilege
      privileges={[
        UserPrivilege.AcceptCompanyApplication,
        UserPrivilege.DeclineCompanyApplication,
        UserPrivilege.SuspendCompanyApplication,
      ]}
    >
      {companyApplicationStatus === CompanyApplicationStatus.InProgress && (
        <ReferralLinkBlock
          referralLinkData={referralLinkData}
          onEditButtonClick={goToUpdateReferralLink}
          onCreateButtonClick={goToCreateReferralLinkStep}
        />
      )}
      <ButtonsUpdateCompanyApplication
        companyApplicationStatus={companyApplicationStatus}
        companyApplicationId={companyApplicationId}
        referralLinkData={referralLinkData}
      />
    </Privilege>
  );
};
