import { FC } from 'react';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import mayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import { CopyButton } from 'ui/CopyButton';
import { twoEmDash } from 'src/const';
import styles from './PlayerIdentityCellWithCopy.module.scss';

interface PlayerIdentityCellWithCopyProps {
  name: string;
  email?: string | null;
  phone?: string | null;
}

export const PlayerIdentityCellWithCopy: FC<
  PlayerIdentityCellWithCopyProps
> = ({ name, email, phone }) => {
  const contact = email || phone;
  const contactToCopy = contact ? ` (${contact})` : '';
  const valueToCopy = `${name}${contactToCopy}`;

  return (
    <div className={styles.container}>
      <MultilineCellWithIcon
        title={name}
        subTitle={mayBeNullCell(contact, twoEmDash)}
      />
      <CopyButton
        value={valueToCopy}
        toastMessage="Данные игрока скопированы"
        className={styles.copyButton}
      />
    </div>
  );
};
