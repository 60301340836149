import { FC, useState } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { Route } from 'src/router/routes.const';
import { AffiliateTypeName, RewardTariffType } from 'commonTypes';
import SearchInput from 'ui/SearchInput';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import { CpaPartnerReportTable } from './components/CpaPartnerReportTable';
import { useGetCpaPartnerReport } from './hooks/useGetCpaPartnerReport';
import { STATISTIC_NO_DATA } from '../../const';
import { BlockSubHeaderAffiliateInfo } from '../../components/BlockSubHeaderAffiliateInfo';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import {
  CPA_PARTNER_REPORT_INPUT_NAME,
  CPA_PARTNER_REPORT_SEARCH_PLACEHOLDER,
  CPA_PARTNER_STATISTIC_BLOCK_TITLE,
} from './const';
import { useGetExportPartnerCpaReport } from './hooks/useGetExportPartnerCpaReport';

interface CpaPartnerReportProps {
  partnerId: string;
}

const Component: FC<CpaPartnerReportProps> = withBlockProvider(
  ({ partnerId }) => {
    const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
    const { cpaPartnerReport, partner, refetch, hasTotalRow, loadReport } =
      useGetCpaPartnerReport({ partnerId, isAllTimeCpa });

    const exportReportConfig = useGetExportPartnerCpaReport({
      partnerId,
      isAllTimeCpa,
    });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();

    const customPathToPrevPage = generatePathWithPeriodQueryParams({
      originalPath: Route.STATISTIC,
    });

    const listExist = hasList(cpaPartnerReport);

    return (
      <>
        <SearchInput
          name={CPA_PARTNER_REPORT_INPUT_NAME}
          placeholder={CPA_PARTNER_REPORT_SEARCH_PLACEHOLDER}
          onSubmit={loadReport}
        />
        <Block
          title={CPA_PARTNER_STATISTIC_BLOCK_TITLE}
          id="cpaPartnerStatistic"
          headerContent={
            <StatisticBlockHeaderContent
              hasList={listExist}
              exportReportConfig={exportReportConfig}
              handleRefetchClick={refetch}
              rewardTariffType={RewardTariffType.Cpa}
              showAllCpa={(value) => setIsAllTimeCpa(value)}
            />
          }
          subHeader={
            partner && (
              <BlockSubHeaderAffiliateInfo
                name={partner.name}
                email={partner.email}
                affiliateType={AffiliateTypeName.Partner}
              />
            )
          }
          shrinkLoaderWrapper={false}
          emptyText={STATISTIC_NO_DATA}
          isEmpty={isListEmpty(cpaPartnerReport)}
          shouldReturnToPrevPage
          isPermanentOpened
          customPathToPrevPage={customPathToPrevPage}
        >
          {listExist && (
            <CpaPartnerReportTable
              cpaPartnerReport={cpaPartnerReport}
              hasTotalRow={hasTotalRow}
            />
          )}
        </Block>
      </>
    );
  }
);

export const CpaPartnerReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <SearchContextProvider pageType={PageTypeStorage.CPAPartnerReport}>
      <Component partnerId={id} />
    </SearchContextProvider>
  );
};
