import { FC } from 'react';
import { useParams } from 'react-router';
import RestrictionsTable from '../../components/RestrictionsBlock/components/RestrictionsTable';
import { useGetRestrictionsHistoryColumns } from '../../components/RestrictionsBlock/hooks/useGetRestrictionsHistoryColumns';
import { PlayerPageProps } from '../../types';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

const Restrictions: FC<PlayerPageProps> = ({ playerId }) => {
  const { columns } = useGetRestrictionsHistoryColumns(true);

  return (
    <RestrictionsTable
      playerId={playerId}
      columns={columns}
      shouldReturnToPrevPage
      isFullScreenTable
    />
  );
};

export default () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <Restrictions playerId={id} />
    </PlayerPageContainer>
  );
};
