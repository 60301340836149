import { FC } from 'react';
import { useParams } from 'react-router';
import FreespinsTable from '../../components/FreespinsBlock/components/FreespinsTable';
import { useGetFreespinsColumns } from '../../components/FreespinsBlock/hooks/useGetFreespinsColumns';
import { PlayerPageProps } from '../../types';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

const Freespins: FC<PlayerPageProps> = ({ playerId }) => {
  const { columns } = useGetFreespinsColumns(true);

  return (
    <FreespinsTable
      playerId={playerId}
      columns={columns}
      shouldReturnToPrevPage
      isFullScreenTable
    />
  );
};

export default () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <Freespins playerId={id} />
    </PlayerPageContainer>
  );
};
