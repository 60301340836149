import { FC, useContext, useState } from 'react';
import { DropdownOptionsList } from 'ui/DropdownOptionsList';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { DropdownOptionsPosition } from 'ui/DropdownOptionsList/const';
import { BlockContext } from 'ui/Block/store';
import styles from './MediaItemsBlockHeaderContent.module.scss';
import { CreateMediaElementOptionsIds } from './const';
import { useGetCreateMediaItemAction } from './hooks/useGetCreateMediaItemAction';
import { useGetCreateMediaItemOptions } from './hooks/useGetCreateMediaItemOptions';
import { useGetCreateMediaItemPrivileges } from '../../hooks/useGetCreateMediaItemPrivileges';

interface MediaItemsBlockHeaderContentProps {
  handleRefetchClick: () => void;
}

export const MediaItemsBlockHeaderContent: FC<
  MediaItemsBlockHeaderContentProps
> = ({ handleRefetchClick }) => {
  const { state } = useContext(BlockContext);
  const [showOptionsList, setShowOptionsList] = useState(false);
  const { mapOptionToAction } = useGetCreateMediaItemAction();
  const { canCreateReferralLink, canCreatePromoCode } =
    useGetCreateMediaItemPrivileges();
  const { creationMediaItemOptions } = useGetCreateMediaItemOptions();

  const canCreateMediaItem = canCreateReferralLink || canCreatePromoCode;

  return (
    <div className={styles.headerContent}>
      <DropdownOptionsList
        options={creationMediaItemOptions}
        onOptionSelect={(id) =>
          mapOptionToAction[id as CreateMediaElementOptionsIds]()
        }
        triggerElement={
          canCreateMediaItem && (
            <ActionButton
              actionType={ButtonType.Add}
              onClick={() => setShowOptionsList(!showOptionsList)}
              hidden={!state.isOpened}
              disabled={state.isLoading}
            />
          )
        }
        isOpened={showOptionsList}
        setIsOpened={setShowOptionsList}
        position={DropdownOptionsPosition.Right}
      />
      <ActionButton
        actionType={ButtonType.Update}
        hidden={!state.isOpened}
        disabled={state.isLoading}
        onClick={handleRefetchClick}
      />
    </div>
  );
};
