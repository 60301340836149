import { FC } from 'react';
import { ProgressBar } from 'ui/ProgressBar';
import styles from './ProgressBarWithLimit.module.scss';

interface ProgressBarWithLimitProps {
  activated: number;
  limit: number;
}

export const ProgressBarWithLimit: FC<ProgressBarWithLimitProps> = ({
  activated,
  limit,
}) => (
  <>
    <div className={styles.progress}>{`${activated}/${limit}`}</div>
    <ProgressBar percent={(activated / limit) * 100} />
  </>
);
