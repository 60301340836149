import { FC } from 'react';
import { ReferralLinkData } from './types';
import { ReferralLink } from './components/ReferralLink';
import { CreateReferralLinkBlock } from './components/CreateReferralLinkBlock';
import styles from './ReferralLinkBlock.module.scss';

interface ReferralLinkBlockProps {
  referralLinkData: ReferralLinkData | null;
  onEditButtonClick: () => void;
  onCreateButtonClick: () => void;
}

export const ReferralLinkBlock: FC<ReferralLinkBlockProps> = ({
  referralLinkData,
  onCreateButtonClick,
  onEditButtonClick,
}) => (
  <div className={styles.footer}>
    {referralLinkData ? (
      <ReferralLink
        onEditButtonClick={onEditButtonClick}
        referralLinkData={referralLinkData}
      />
    ) : (
      <CreateReferralLinkBlock onCreateButtonClick={onCreateButtonClick} />
    )}
  </div>
);
