import { FC, useContext, useEffect } from 'react';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import Input from 'ui/Input';
import { Tooltip } from 'ui/Tooltip';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { useFormikContext } from 'formik';
import { nbsp } from 'src/const';
import { ModalContext } from 'ui/Modal';
import { INVALID_INTEGER_CHARS } from 'src/utils/blockInvalidChars';
import { CompanyAffiliateReferralLinksDropdown } from 'commonComponents/CompanyAffiliateReferralLinksDropdown';
import { Dropdown } from 'ui/Dropdown';
import { suppressSymbols, transformValueToUppercase } from 'ui/Input/utils';
import { FieldName, mapCreatePromoCodeFieldToTitle } from '../../const';
import { CreateAffiliatePromoCodeValues } from '../../types';
import { useGoToNextStep } from './hooks/useGoToNextStep';
import { promoCodeUsageTypeOptions } from './const';
import { AffiliatePromoCodeItemSteps } from '../../../AffiliatePromoCodeItem/const';
import { useValidatePromoCodeToken } from './hooks/useValidatePromoCodeToken';
import { useGetMediaCampaignByReferralLinkUrl } from './hooks/useGetMediaCampaignByReferralLinkUrl';

interface CreateAffiliatePromoCodeFormBaseStepProps {
  companyId: string;
  companyAffiliateId: string;
}

export const CreateAffiliatePromoCodeFormBaseStep: FC<
  CreateAffiliatePromoCodeFormBaseStepProps
> = ({ companyId, companyAffiliateId }) => {
  const { state } = useContext(ModalContext);
  const { isValid, values, dirty, validateForm, setFieldValue } =
    useFormikContext<CreateAffiliatePromoCodeValues>();
  const { validatePromoCodeToken, validatePromoCodeTokenLoading } =
    useValidatePromoCodeToken();
  const { goToConnectionWithFreespinsAndBonusStep } = useGoToNextStep();

  // Get media campaign info by referral link for prefill fields in confirm step
  useGetMediaCampaignByReferralLinkUrl();

  const shouldValidateForResetErrorsFromSecondStep =
    state.previousStep ===
      AffiliatePromoCodeItemSteps.CreatePromoCodeConnection &&
    (values[FieldName.ConnectionWithFreespins] ||
      values[FieldName.ConnectionWithBonus]);

  useEffect(() => {
    if (shouldValidateForResetErrorsFromSecondStep) {
      validateForm();
    }
  }, [
    shouldValidateForResetErrorsFromSecondStep,
    state.previousStep,
    validateForm,
  ]);

  return (
    <>
      <FormRowsWrapper>
        <FormRow isWide>
          <Input
            name={FieldName.Partner}
            label={mapCreatePromoCodeFieldToTitle[FieldName.Partner]}
            disabled
          />
        </FormRow>
        <FormRow isWide>
          <Input.Number
            name={FieldName.ActivationLimit}
            label={mapCreatePromoCodeFieldToTitle[FieldName.ActivationLimit]}
            placeholder={`Введите количество от${nbsp}1 до${nbsp}9999`}
            invalidChars={INVALID_INTEGER_CHARS}
            customMaxLength={4}
          />
        </FormRow>
        <FormRow isWide>
          <Input
            name={FieldName.Token}
            label={mapCreatePromoCodeFieldToTitle[FieldName.Token]}
            onInput={transformValueToUppercase}
            onKeyDown={suppressSymbols}
            maxLength={36}
            onBlur={validatePromoCodeToken}
            disabled={validatePromoCodeTokenLoading}
          />
        </FormRow>
        <FormRow isWide>
          <CompanyAffiliateReferralLinksDropdown
            name={FieldName.ReferralLinkId}
            label={mapCreatePromoCodeFieldToTitle[FieldName.ReferralLinkId]}
            companyId={companyId}
            companyAffiliateId={companyAffiliateId}
            onChange={(referralLinkUrl) => {
              setFieldValue(FieldName.ReferralLinkUrl, referralLinkUrl);
            }}
          />
        </FormRow>
        <FormRow isWide>
          <Dropdown
            name={FieldName.Usage}
            label={mapCreatePromoCodeFieldToTitle[FieldName.Usage]}
            options={promoCodeUsageTypeOptions}
          />
        </FormRow>
      </FormRowsWrapper>
      <Tooltip
        tooltipContent={!isValid || !dirty ? 'Заполните все поля' : undefined}
        placement="right"
      >
        <Button
          size={ButtonSize.Large}
          theme={ButtonTheme.Process}
          disabled={!dirty}
          onClick={goToConnectionWithFreespinsAndBonusStep}
        >
          Далее
        </Button>
      </Tooltip>
    </>
  );
};
