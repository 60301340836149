import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { UpdateCpaRewardTariffTitleInput } from 'generatedGraphql';
import { toast } from 'react-toastify';
import { useUpdateCpaRewardTariffTitle } from '../queries/generated/UpdateCpaRewardTariffTitle';

interface UseEditTariffNameModalProps {
  cpaRewardTariffsId: string;
}

export const useEditTariffNameModal = ({
  cpaRewardTariffsId,
}: UseEditTariffNameModalProps) => {
  const [updateTariffName, { error, loading, data }] =
    useUpdateCpaRewardTariffTitle();

  const { dispatch } = useContext(ModalContext);

  const onEditName = async (values: UpdateCpaRewardTariffTitleInput) => {
    await updateTariffName({
      variables: {
        input: {
          id: cpaRewardTariffsId,
          title: values.title,
        },
      },
    });
  };

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'edit-tariff-name-error' },
    hasData: !!data,
  });

  useEffect(() => {
    if (data) {
      dispatch({ type: ModalActions.Close });
      toast.success('Тариф изменён');
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch({ type: ModalActions.Close });
      toast.success('Тариф не изменён');
    }
  }, [error, dispatch]);

  return { onEditName, loading, error };
};
