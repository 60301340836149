import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { generatePath } from 'react-router';
import { Route as RouteEnum } from 'src/router/routes.const';
import RestrictionsTable from './components/RestrictionsTable';
import { useGetRestrictionsHistoryColumns } from './hooks/useGetRestrictionsHistoryColumns';

interface RestrictionsBlockProps {
  id: string;
}

const RestrictionsBlock: FC<RestrictionsBlockProps> = ({ id }) => {
  const { columns } = useGetRestrictionsHistoryColumns(false);

  return (
    <RestrictionsTable
      playerId={id}
      columns={columns}
      routeToCustomPage={generatePath(RouteEnum.PLAYER_RESTRICTIONS, { id })}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(RestrictionsBlock);
