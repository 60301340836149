import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyMediaCampaignsTable } from '../../components/CompanyMediaCampaignsBlock/components/CompanyMediaCampaignsTable';
import { CompanyPageContainer } from '../../components/CompanyPageContainer';
import { useGetCompanyMediaCampaignsColumns } from '../../components/CompanyMediaCampaignsBlock/components/CompanyMediaCampaignsTable/hooks/useGetCompanyMediaCampaignsColumns';

interface ComponentProps {
  companyId: string;
}

const Component: FC<ComponentProps> = ({ companyId }) => {
  const { columns } = useGetCompanyMediaCampaignsColumns();

  return (
    <CompanyMediaCampaignsTable
      companyId={companyId}
      columns={columns}
      shouldReturnToPrevPage
      isFullScreenTable
    />
  );
};

export const CompanyMediaCampaigns = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <CompanyPageContainer companyId={id}>
      <Component companyId={id} />
    </CompanyPageContainer>
  );
};
