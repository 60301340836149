import { FC, PropsWithChildren, ReactElement } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import Error from 'ui/Error';
import { CompanyHeader } from '../CompanyHeader';
import { useGetCompanyData } from '../../queries/generated/GetCompanyData';

interface CompanyPageContentProps {
  children: ReactElement;
  companyId: string;
  shouldProvideBlock?: boolean;
}

export const CompanyPageContent: FC<CompanyPageContentProps> = ({
  companyId,
  shouldProvideBlock,
  children,
}) => {
  let ChildrenWithHOCs: FC<PropsWithChildren<object>> | undefined;

  if (shouldProvideBlock) {
    ChildrenWithHOCs = withBlockProvider(() => children);
  }

  const { data, loading, error } = useGetCompanyData({
    variables: {
      id: companyId,
    },
  });

  const companyData = data?.company;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!companyData) {
    return null;
  }

  return (
    <>
      <CompanyHeader company={companyData} />
      {ChildrenWithHOCs ? <ChildrenWithHOCs /> : children}
    </>
  );
};
