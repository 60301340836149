import React, { HTMLProps } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { useTooltipState } from '../hooks/useTooltipState';

interface TooltipTriggerProps extends HTMLProps<HTMLElement> {
  shouldSetWidthFitContent?: boolean;
}

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  TooltipTriggerProps
>(({ children, shouldSetWidthFitContent = true, ...props }, propRef) => {
  const state = useTooltipState();
  const ref = useMergeRefs([state.refs.setReference, propRef]);

  return (
    <div
      style={{ width: shouldSetWidthFitContent ? 'fit-content' : undefined }}
      role="button"
      tabIndex={0}
      ref={ref}
      {...state.getReferenceProps(props)}
    >
      {children}
    </div>
  );
});
