import { FC } from 'react';
import { MenuGroupItemData, MenuItemData } from 'commonComponents/Menu/types';
import classnames from 'classnames';
import { MenuGroupItemsList } from './components/MenuGroupItemsList';
import { useGetMenuGroups } from './hooks/useGetMenuGroups';
import { useMenuState } from '../../hooks';
import styles from './MenuRoutes.module.scss';

interface MenuRoutesProps {
  links: Array<MenuItemData | MenuGroupItemData>;
  className?: string;
  subMenuClassName?: string;
}

export const MenuRoutes: FC<MenuRoutesProps> = ({
  links,
  className,
  subMenuClassName,
}) => {
  const {
    menuState,
    toggleMenuDropdown,
    openMenuDropdownItem,
    selectMenuItem,
  } = useMenuState(links);

  const { topLinks, bottomLinks } = useGetMenuGroups(links);

  return (
    <div className={classnames(className, styles.menuItemsWrapper)}>
      <div>
        <MenuGroupItemsList
          links={topLinks}
          selectMenuItem={selectMenuItem}
          menuState={menuState}
          openMenuDropdownItem={openMenuDropdownItem}
          toggleMenuDropdown={toggleMenuDropdown}
          subMenuClassName={subMenuClassName}
        />
      </div>
      <div>
        <MenuGroupItemsList
          links={bottomLinks}
          selectMenuItem={selectMenuItem}
          menuState={menuState}
          openMenuDropdownItem={openMenuDropdownItem}
          toggleMenuDropdown={toggleMenuDropdown}
          subMenuClassName={subMenuClassName}
        />
      </div>
    </div>
  );
};
