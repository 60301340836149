import DropdownWithQuery from 'ui/DropdownWithQuery/DropdownWithQuery';
import { FC, useRef } from 'react';
import { throttle } from 'lodash-es';
import { GameEdge } from 'generatedGraphql';
import { useGetGamesLazyQuery } from '../../queries/generated/GetGames';

interface GameDropdownProps {
  name: string;
  label: string;
  searchMinLength?: number;
  onChange?: (gameId: string | Array<string>, gameName?: string) => void;
  defaultValue?: string;
}

export const GameDropdown: FC<GameDropdownProps> = ({
  name,
  label,
  searchMinLength,
  onChange,
  defaultValue,
}) => {
  const [loadGames, { data: getGamesResult, loading: getGamesLoading }] =
    useGetGamesLazyQuery();

  const loadGamesWithThrottle = useRef(
    throttle(async (inputString: string) => {
      await loadGames({
        variables: {
          search: inputString,
          first: 10,
        },
      });
    }, 1000)
  ).current;

  const gameOptions = getGamesResult?.games.edges.map(
    ({ node: { id, name: gameName } }: GameEdge) => ({
      id,
      label: gameName,
    })
  );

  const onChangeHandler = (gameId: string | Array<string>) => {
    if (onChange) {
      const currentGame = gameOptions?.find((game) => game.id === gameId);

      onChange(gameId, currentGame?.label);
    }
  };

  return (
    <DropdownWithQuery
      name={name}
      label={label}
      defaultValue={defaultValue}
      loadList={loadGamesWithThrottle}
      loading={getGamesLoading}
      searchMinLength={searchMinLength}
      options={gameOptions}
      onChange={onChangeHandler}
    />
  );
};
