import { FC } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useMatch } from 'react-router';
import { Route } from '../../../../../../router/routes.const';
import styles from './SubItem.module.scss';

interface SubItemProps {
  route?: Route;
  title: string;
  onClick: () => void;
}

export const SubItem: FC<SubItemProps> = ({ route, title, onClick }) => {
  const isMatch = useMatch(`${route}/*`);

  if (!route) {
    return (
      <div
        onClick={onClick}
        className={classnames(styles.groupItem, isMatch && styles.active)}
      >
        <div className={styles.containerTreeLeaf}>
          <div className={styles.treeLeaf} />
        </div>
        <div>{title}</div>
      </div>
    );
  }

  return (
    <Link
      onClick={onClick}
      className={classnames(styles.groupItem, isMatch && styles.active)}
      key={route}
      to={route}
    >
      <div className={styles.containerTreeLeaf}>
        <div className={styles.treeLeaf} />
      </div>
      <div>{title}</div>
    </Link>
  );
};
