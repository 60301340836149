import { FC } from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import styles from './CardIcon.module.scss';
import capitalize from '../../../utils/capitalize';

export enum CardIconColor {
  BLUE = 'blue',
  BLACK = 'black',
  BLUE_GREY = 'blueGrey',
  GREEN = 'green',
  GREY = 'grey',
  ORANGE = 'orange',
  RED = 'red',
  VIOLET = 'violet',
  YELLOW = 'yellow',
  PINK = 'pink',
  INDIGO = 'indigo',
  PURPLE = 'purple',
  TRANSPARENT = 'transparent',
}

export interface CardIconConfig extends FontAwesomeIconProps {
  iconColor?: CardIconColor;
}

interface CardIconProps {
  config: CardIconConfig;
}

export const CardIcon: FC<
  CardIconProps & React.HTMLAttributes<HTMLElement>
> = ({
  config: { icon, iconColor = CardIconColor.GREY, ...restIconConfig },
  className,
}) => (
  <div className={classnames(styles.iconContainer, className)}>
    <FontAwesomeIcon
      icon={icon}
      className={classnames(
        'icon',
        iconColor && styles[`iconColor${capitalize(iconColor)}`]
      )}
      {...restIconConfig}
    />
  </div>
);
