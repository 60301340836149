import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Storage, PageTypeStorage } from 'src/utils/storage';
import { useSearchParams } from 'react-router-dom';
import { QUERY_PARAM_KEY } from './const';

interface SearchContextValue {
  searchValue: string;
  prevSearchValue: string;
  setSearchValue: (value: string) => void;
  saveSearch: (value: string) => void;
}

const defaultValue: SearchContextValue = {
  searchValue: '',
  prevSearchValue: '',
  setSearchValue: () => {},
  saveSearch: () => {},
};
const SearchContext = createContext<SearchContextValue>(defaultValue);

interface SearchContextProviderProps {
  children: ReactElement;
  pageType: PageTypeStorage;
  shouldUpdateUrl?: boolean;
}

export const SearchContextProvider: FC<SearchContextProviderProps> = ({
  children,
  pageType,
  shouldUpdateUrl = true,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newParams = useMemo(
    () => new URLSearchParams(searchParams),
    [searchParams]
  );

  const { searchListStorage } = Storage;

  const searchParamsFromStorage = searchListStorage.getSearchParams(pageType);
  const searchParamsFromURL = searchParams.get(QUERY_PARAM_KEY);
  const prevSearchValue = searchParamsFromURL || searchParamsFromStorage || '';

  const [searchValue, setSearchValue] = useState(prevSearchValue);

  const saveSearch = useCallback(
    (value: string) => {
      searchListStorage.setSearchParams(value, pageType);

      if (!shouldUpdateUrl) {
        return;
      }

      if (value) {
        newParams.set(QUERY_PARAM_KEY, value);
        setSearchParams(newParams);
      } else if (newParams.size) {
        newParams.delete(QUERY_PARAM_KEY);
        setSearchParams(newParams);
      }
    },
    [newParams, pageType, searchListStorage, setSearchParams, shouldUpdateUrl]
  );

  useEffect(() => {
    saveSearch(prevSearchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      saveSearch,
      prevSearchValue,
    }),
    [searchValue, saveSearch, prevSearchValue]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);
