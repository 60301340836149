import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { ClientDataBlock } from 'commonComponents/ClientDataBlock/ClientDataBlock';
import { usePlayerData } from './usePlayerData';
import { useGetPlayerDataConfig } from './useGetPlayerDataConfig';

interface PlayerProps {
  id: string;
}

const PlayerDataBlock: FC<PlayerProps> = ({ id }: PlayerProps) => {
  const { data, onEdit, refetchPlayerData } = usePlayerData({ id });

  const clientData = useGetPlayerDataConfig(data, onEdit);

  return (
    <ClientDataBlock
      blockId="playerData"
      title="Данные игрока"
      onUpdate={refetchPlayerData}
      data={clientData}
      titleColumnMinWidth={146}
    />
  );
};

export default withBlockProvider(PlayerDataBlock);
