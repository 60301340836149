import React, { FC } from 'react';
import { ClientData } from 'commonComponents/ClientDataBlock/types';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import { ClientDataRow } from 'commonComponents/ClientDataBlock/components/ClientDataRow/ClientDataRow';
import styles from './ClientDataBlockContent.module.scss';

interface ClientDataBlockContentProps {
  clientData: ClientData;
  titleColumnMinWidth?: number;
}

export const ClientDataBlockContent: FC<ClientDataBlockContentProps> = ({
  clientData,
  titleColumnMinWidth,
}) => (
  <div className={styles.wrapper}>
    {clientData.map(({ title, isEditButtonVisible, onEdit, rows }) => (
      <div key={title} className={styles.block}>
        <div className={styles.titleContainer}>
          <div className={styles.blockTitle}>{title}</div>
          {isEditButtonVisible && onEdit && (
            <ActionButton
              actionType={ButtonType.Edit}
              onClick={onEdit}
              type="submit"
              className={styles.actionButton}
            />
          )}
        </div>
        {rows.map((row) => {
          if (!row) {
            return null;
          }

          const { title: rowTitle, value, alignRightValue } = row;

          return (
            <ClientDataRow
              key={rowTitle}
              title={rowTitle}
              value={value}
              alignRightValue={alignRightValue}
              titleColumnMinWidth={titleColumnMinWidth}
            />
          );
        })}
      </div>
    ))}
  </div>
);
