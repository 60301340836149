import * as CommonType from '../common'
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * One of:
   * - BONUS
   * - DEPOSIT
   * - GAME
   * - JACKPOT
   * - MONEY_TRANSFER
   * - TOURNAMENT
   * - WITHDRAWAL
   */
  AccountEventGroupType: { input: CommonType.AccountEventGroupType; output: CommonType.AccountEventGroupType; }
  /**
   * One of:
   * - ROLE_ANALYST
   * - ROLE_CASHIER
   * - ROLE_CASHIER_LEAD
   * - ROLE_CASHIER_PLUS
   * - ROLE_CONTENT_MANAGER
   * - ROLE_PARTNER_SECURITY
   * - ROLE_PARTNER_SUPPORT
   * - ROLE_PARTNER_SUPPORT_LEAD
   * - ROLE_PARTNER_SUPPORT_PLUS
   * - ROLE_PAYMENT_SYSTEM_MANAGER
   * - ROLE_SECURITY
   * - ROLE_SECURITY_LEAD
   * - ROLE_SUPER_ADMIN
   * - ROLE_SUPPORT
   * - ROLE_SUPPORT_LEAD
   * - ROLE_SUPPORT_LEAD_TOURNAMENT
   * - ROLE_SUPPORT_PLUS
   * - ROLE_PROVIDER_MANAGER
   */
  AdminRole: { input: CommonType.AdminRole; output: CommonType.AdminRole; }
  /** Bank payment method */
  Bank: { input: any; output: any; }
  /**
   * One of:
   * - ACTIVATED
   * - WIN
   * - CANCELLED
   * - EXPIRED
   */
  BonusAccountEventType: { input: CommonType.BonusAccountEventType; output: CommonType.BonusAccountEventType; }
  /**
   * One of:
   * - SKYPE
   * - TELEGRAM
   * - WHATSAPP
   * - OTHER
   */
  ContactForCommunicationType: { input: CommonType.ContactForCommunicationType; output: CommonType.ContactForCommunicationType; }
  /**
   * Country-code is uppercase ISO 3166 two-letter code (alpha-2)
   * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes)
   * Example: AQ
   */
  CountryCode: { input: any; output: any; }
  /**
   * One of:
   * - USD
   * - RUB
   * - EUR
   * - UAH
   * - KZT
   * - BRL
   * - TRY
   * - MXN
   * - PLN
   * - HUF
   * - BGN
   * - SEK
   * - JPY
   * - KRW
   * - AUD
   * - NOK
   * - RON
   * - BYN
   * - CAD
   * - UZS
   * - AZN
   * - XXX
   */
  Currency: { input: CommonType.Currency; output: CommonType.Currency; }
  /**
   * Any string
   * Example: 9f06ce7cdca9ed9c074d33c4c9
   */
  Cursor: { input: string; output: string; }
  /**
   * YYYY-MM-DD
   * Example: 2021-03-22
   */
  Date: { input: string; output: string; }
  /**
   * YYYY-MM-DDTHH:mm:ssZ
   * Example: 2021-02-04T12:17:51Z
   */
  DateTime: { input: string; output: string; }
  /**
   * One of:
   * - SUCCESS
   */
  DepositAccountEventType: { input: CommonType.DepositAccountEventType; output: CommonType.DepositAccountEventType; }
  /**
   * One of:
   * - depositId
   * - status
   * - paymentMethodName
   * - paymentProvider
   * - accountNumber
   */
  DepositFilterMember: { input: CommonType.DepositFilterMember; output: CommonType.DepositFilterMember; }
  /**
   * One of:
   * - BANK_CARD
   * - BANK_CARD_FORM
   * - CEPBANK
   * - BOLETO
   * - PIX
   * - MEXICO_ONLINE_BANKING
   * - OXXO
   * - P2P
   * - SKRILL
   * - NETELLER
   * - RAPID_TRANSFER
   * - MUCH_BETTER
   * - EPARATR
   * - JETON
   * - PIASTRIX
   * - MONETIX
   * - MOBILE
   * - HIZLIQR
   * - FASTER_PAYMENTS_SYSTEM
   * - BITCOIN
   * - ETHEREUM
   * - LITECOIN
   * - TETHER
   * - TETHER_TRC20
   * - QIWI
   * - YANDEX_MONEY
   * - WEBMONEY
   * - APPLE_PAY
   * - ONLINE_BRAZIL_BANKS
   * - LOTERICA
   * - CODE
   * - MKAREKOD
   * - BLOOMZED_WALLET
   * - BINANCE
   * - BINANCE_ERC20
   * - TRON
   */
  DepositPaymentMethodName: { input: CommonType.DepositPaymentMethodName; output: CommonType.DepositPaymentMethodName; }
  /**
   * One of:
   * - WIN
   * - BET
   */
  GameAccountEventType: { input: CommonType.GameAccountEventType; output: CommonType.GameAccountEventType; }
  /**
   * One of:
   * - WIN
   */
  JackpotAccountEventType: { input: CommonType.JackpotAccountEventType; output: CommonType.JackpotAccountEventType; }
  /**
   * One of:
   * - de
   * - en
   * - en-CA
   * - es-MX
   * - fr-CA
   * - kk
   * - pl
   * - pt-BR
   * - ru
   * - tr
   */
  Locale: { input: CommonType.Locale; output: CommonType.Locale; }
  /**
   * One of:
   * - INCOMING
   */
  MoneyTransferAccountEventType: { input: CommonType.MoneyTransferAccountEventType; output: CommonType.MoneyTransferAccountEventType; }
  /**
   * One of:
   * - type
   */
  MoneyTransferFilterMember: { input: CommonType.MoneyTransferFilterMember; output: CommonType.MoneyTransferFilterMember; }
  /**
   * One of:
   * - BANK_CARD
   * - BINANCE
   * - BITCOIN
   * - BLOOMZED_WALLET
   * - BOLETO
   * - CEPBANK
   * - EPARATR
   * - ETHEREUM
   * - JETON
   * - LITECOIN
   * - LOTERICA
   * - MONETIX
   * - MUCH_BETTER
   * - NETELLER
   * - ONLINE_BRAZIL_BANKS
   * - PIASTRIX
   * - PIX
   * - QIWI
   * - RAPID_TRANSFER
   * - SKRILL
   * - TETHER
   * - TETHER_TRC20
   * - TRON
   * - WEBMONEY
   * - YANDEX_MONEY
   */
  PaymentMethodName: { input: CommonType.PaymentMethodName; output: CommonType.PaymentMethodName; }
  /**
   * One of:
   * - BTECH
   * - CJMM
   * - COINS_PAID
   * - ECOMMPAY
   * - ECOMMPAY_LAT_AM
   * - GATE_EXPRESS
   * - INTERKASSA
   * - JETON
   * - MALDOPAY
   * - MPAY
   * - MUCH_BETTER
   * - NETELLER
   * - PAYCOS
   * - PAYCOS_CARD
   * - PAYCOS_CODE
   * - PAYCOS_WEB_CARD
   * - PIASTRIX
   * - PIASTRIX_TRANSFER
   * - QIWI
   * - QPAYMENTS
   * - QPAYMENTS.CARD_RU
   * - QPAYMENTS.CARD_WORLD
   * - RAPID_TRANSFER_SKRILL
   * - SKRILL.USD_EEA
   * - SKRILL.USD_ROW
   * - TOSSACOIN
   * - UNLIMINT
   * - XCHANGE
   * - XCHANGE_FUNDRY
   */
  PaymentProcessor: { input: CommonType.PaymentProcessor; output: CommonType.PaymentProcessor; }
  /**
   * One of:
   * - APPLE_PAY_CORE_ECOMMPAY_PAYMENT_PAGE
   * - BAD_BANK
   * - BINANCE_BTECH
   * - BINANCE_COINS_PAID_INVOICELESS
   * - BINANCE_ERC20_BTECH
   * - BITCOIN_BTECH
   * - BITCOIN_COINS_PAID
   * - BITCOIN_COINS_PAID_INVOICELESS
   * - BITCOIN_PIASTRIX
   * - BLOOMZED_WALLET_ECOMMPAY_PAYMENT_PAGE
   * - BOLETO_ECOMMPAY_PAYMENT_PAGE
   * - CARD_ECOMMPAY_PAYMENT_PAGE
   * - CARD_PARTNER_ECOMMPAY_PAYMENT_PAGE
   * - CARD_PAYCOS_PAYMENT_PAGE
   * - CARD_PAYCOS_WEB_PAYMENT_PAGE
   * - CARD_PIASTRIX
   * - CARD_QPAYMENTS_GATE
   * - CEPBANK_ECOMMPAY_PAYMENT_PAGE
   * - CHARGEBACK
   * - CJMM
   * - CODE_PAYCOS_PAYMENT_PAGE
   * - ECOMMPAY_GATE
   * - EPARATR_MALDOPAY_PAYMENT_PAGE
   * - ETHEREUM_BTECH
   * - ETHEREUM_COINS_PAID
   * - ETHEREUM_COINS_PAID_INVOICELESS
   * - ETHEREUM_PIASTRIX
   * - FASTER_PAYMENTS_SYSTEM_ECOMMPAY_PAYMENT_PAGE
   * - FASTER_PAYMENTS_SYSTEM_PIASTRIX
   * - GATE_EXPRESS
   * - GATE_EXPRESS_PAYMENT_PAGE
   * - HIZLIQR_ECOMMPAY_PAYMENT_PAGE
   * - INTERKASSA
   * - JETON
   * - KASSIFY
   * - LITECOIN_BTECH
   * - LITECOIN_COINS_PAID
   * - LITECOIN_COINS_PAID_INVOICELESS
   * - LOTERICA_ECOMMPAY_PAYMENT_PAGE
   * - MC_QPAYMENTS_GATE
   * - MEXICO_ONLINE_BANKING_ECOMMPAY_PAYMENT_PAGE
   * - MKAREKOD_MALDOPAY_PAYMENT_PAGE
   * - MOBILE_ECOMMPAY_PAYMENT_PAGE
   * - MONETIX_WALLET_ECOMMPAY_PAYMENT_PAGE
   * - MPAY_GATE
   * - MPAY_PAYMENT_PAGE
   * - MUCH_BETTER
   * - NETELLER
   * - ONLINE_BRAZIL_BANKS_ECOMMPAY_PAYMENT_PAGE
   * - OXXO_ECOMMPAY_PAYMENT_PAGE
   * - P2P_PAYCOS_PAYMENT_PAGE
   * - PAYCOS_GATE
   * - PAYCOS_WEB_GATE
   * - PIASTRIX
   * - PIX_ECOMMPAY_PAYMENT_PAGE
   * - QIWI
   * - QIWI_ECOMMPAY_PAYMENT_PAGE
   * - QIWI_KZ_ECOMMPAY_PAYMENT_PAGE
   * - QIWI_PIASTRIX
   * - QPAYMENTS_FORM
   * - RAPID_TRANSFER_SKRILL
   * - SKRILL
   * - TETHER_BTECH
   * - TETHER_COINS_PAID
   * - TETHER_COINS_PAID_INVOICELESS
   * - TETHER_PIASTRIX
   * - TETHER_TRC20_BTECH
   * - TETHER_TRC20_COINS_PAID
   * - TETHER_TRC20_COINS_PAID_INVOICELESS
   * - TOSSACOIN
   * - TRON_BTECH
   * - TRON_COINS_PAID_INVOICELESS
   * - UNLIMINT_GATE
   * - WEBMONEY_LIGHT_ECOMMPAY_PAYMENT_PAGE
   * - XCHANGE_GATE
   * - YANDEX_MONEY_PIASTRIX
   */
  PaymentProvider: { input: CommonType.PaymentProvider; output: CommonType.PaymentProvider; }
  /**
   * Integer value from 0 to 100
   * Example: 77
   */
  Percent: { input: any; output: any; }
  /**
   * One of:
   * - NOOB
   * - GAMBLER
   * - BRONZE
   * - SILVER
   * - GOLD
   * - PLATINUM
   */
  PlayerRank: { input: CommonType.PlayerRank; output: CommonType.PlayerRank; }
  /**
   * Any string code (UUID version 4 by default)
   * Example: 3d49fa11-6a99-499d-9d5b-9ba6cb1c325a
   */
  ReferralLinkCode: { input: any; output: any; }
  /**
   * One of:
   * - REBUY
   * - WIN
   */
  TournamentAccountEventType: { input: CommonType.TournamentAccountEventType; output: CommonType.TournamentAccountEventType; }
  /**
   * A file in a multipart request
   * Depends on the environment, see in your mutation
   */
  Upload: { input: any; output: any; }
  /**
   * String
   * Example: https://example.com/img/vavada_logo.jpg
   */
  Url: { input: string; output: string; }
  /** Using for showing that type without any data */
  Void: { input: any; output: any; }
  /**
   * One of:
   * - CREATED
   * - CANCELLED
   * - DECLINED
   */
  WithdrawalAccountEventType: { input: CommonType.WithdrawalAccountEventType; output: CommonType.WithdrawalAccountEventType; }
  /**
   * One of:
   * - withdrawalId
   * - status
   * - paymentMethodName
   */
  WithdrawalFilterMember: { input: CommonType.WithdrawalFilterMember; output: CommonType.WithdrawalFilterMember; }
  /**
   * YYYY-MM
   * Example: 2021-02
   */
  YearMonth: { input: any; output: any; }
};

export type AcceptAllRewardsInput = {
  tariffType: RewardTariffType;
};

export type AcceptAllRewardsPayload = {
  __typename?: 'AcceptAllRewardsPayload';
  void?: Maybe<Scalars['Void']['output']>;
};

export type AcceptCompanyAffiliateApplicationInput = {
  affiliates: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type AcceptCompanyAffiliateApplicationPayload = {
  __typename?: 'AcceptCompanyAffiliateApplicationPayload';
  companyAffiliateApplication: CompanyAffiliateApplication;
};

export type AcceptCompanyApplicationInput = {
  id: Scalars['ID']['input'];
  referralLinkMediaItem?: InputMaybe<CreateCompanyApplicationReferralLinkMediaItemInput>;
};

export type AcceptCompanyApplicationPayload = {
  __typename?: 'AcceptCompanyApplicationPayload';
  companyApplication: CompanyApplication;
};

export type AcceptDepositInput = {
  amount: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
};

export type AcceptDepositPayload = {
  __typename?: 'AcceptDepositPayload';
  deposit: Deposit;
};

export type AcceptPartnerApplicationInput = {
  id: Scalars['ID']['input'];
  /** @deprecated Use `referralLinkMediaItem` field. */
  referralLink?: InputMaybe<CreateReferralLinkInput>;
  referralLinkMediaItem?: InputMaybe<CreatePartnerApplicationReferralLinkMediaItemInput>;
};

export type AcceptPartnerApplicationPayload = {
  __typename?: 'AcceptPartnerApplicationPayload';
  partnerApplication: PartnerApplication;
};

export type AcceptPaymentInput = {
  amount: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
};

export type AcceptPaymentPayload = {
  __typename?: 'AcceptPaymentPayload';
  payment: Payment;
};

export type AcceptRewardInput = {
  amount: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
};

export type AcceptRewardPayload = {
  __typename?: 'AcceptRewardPayload';
  reward: Reward;
};

export type AccessToRevShareRewardTariff = {
  __typename?: 'AccessToRevShareRewardTariff';
  accessible: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Account = {
  __typename?: 'Account';
  active: Scalars['Boolean']['output'];
  balance: Sum;
  bonusBalance?: Maybe<Sum>;
  eventGroups: AccountEventGroupConnection;
  id: Scalars['ID']['output'];
  statistic: AccountStatistic;
};


export type AccountEventGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AccountStatisticArgs = {
  currency?: InputMaybe<Scalars['Currency']['input']>;
};

export type AccountEventConnection = {
  __typename?: 'AccountEventConnection';
  edges: Array<AccountEventEdge>;
  pageInfo: PageInfo;
};

export type AccountEventEdge = {
  __typename?: 'AccountEventEdge';
  cursor: Scalars['Cursor']['output'];
  node: AccountEventInterface;
};

export type AccountEventGroup = {
  __typename?: 'AccountEventGroup';
  billingSummary: BalanceChange;
  bonusSummary?: Maybe<BalanceChange>;
  createdAt: Scalars['DateTime']['output'];
  eventCount: Scalars['Int']['output'];
  events: AccountEventConnection;
  id: Scalars['ID']['output'];
  type: Scalars['AccountEventGroupType']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type AccountEventGroupEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountEventGroupConnection = {
  __typename?: 'AccountEventGroupConnection';
  edges: Array<AccountEventGroupEdge>;
  pageInfo: PageInfo;
};

export type AccountEventGroupEdge = {
  __typename?: 'AccountEventGroupEdge';
  cursor: Scalars['Cursor']['output'];
  node: AccountEventGroup;
};

export type AccountEventInterface = {
  billingBalance: BalanceChange;
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type AccountStatistic = {
  __typename?: 'AccountStatistic';
  betSum: Sum;
  bonusSum: Sum;
  creditSum: Sum;
  debitSum: Sum;
  depositSum: Sum;
  /** @deprecated Use `upcomingCashback.sum` field. */
  nextCashbackSum: Sum;
  paidCashbackSum: Sum;
  upcomingCashback: Cashback;
  winSum: Sum;
  withdrawalSum: Sum;
};

export type AddCompanyAffiliatesInput = {
  affiliates: Array<CompanyAffiliateInput>;
  companyId: Scalars['ID']['input'];
};

export type AddCompanyAffiliatesPayload = {
  __typename?: 'AddCompanyAffiliatesPayload';
  affiliates: Array<CompanyAffiliate>;
};

export type Admin = {
  __typename?: 'Admin';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  roles: Array<Scalars['AdminRole']['output']>;
  withdrawals: WithdrawalConnection;
};


export type AdminWithdrawalsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WithdrawalStatus>;
};

export type AdminConnection = {
  __typename?: 'AdminConnection';
  edges: Array<AdminEdge>;
  pageInfo: PageInfo;
};

export type AdminEdge = {
  __typename?: 'AdminEdge';
  cursor: Scalars['Cursor']['output'];
  node: Admin;
};

export type Affiliate = Company | Partner;

export type AffiliateInput = {
  id: Scalars['ID']['input'];
  type: AffiliateType;
};

export type AffiliateOutgoingMoneyTransfer = {
  __typename?: 'AffiliateOutgoingMoneyTransfer';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  player: Player;
  sum: Sum;
};

export type AffiliateOutgoingMoneyTransferConnection = {
  __typename?: 'AffiliateOutgoingMoneyTransferConnection';
  edges: Array<AffiliateOutgoingMoneyTransferEdge>;
  pageInfo: PageInfo;
};

export type AffiliateOutgoingMoneyTransferEdge = {
  __typename?: 'AffiliateOutgoingMoneyTransferEdge';
  cursor: Scalars['Cursor']['output'];
  node: AffiliateOutgoingMoneyTransfer;
};

export type AffiliatePlayerAccount = {
  __typename?: 'AffiliatePlayerAccount';
  player: Player;
  status: AffiliatePlayerAccountStatus;
};

export enum AffiliatePlayerAccountStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING'
}

export enum AffiliateReportOrderBy {
  AllDeposit = 'ALL_DEPOSIT',
  Profit = 'PROFIT',
  Registrations = 'REGISTRATIONS',
  UpcomingReward = 'UPCOMING_REWARD',
  Withdrawal = 'WITHDRAWAL'
}

export type AffiliateReportOrderingInput = {
  direction: OrderDirection;
  orderBy: AffiliateReportOrderBy;
};

export type AffiliateRewardStatistic = {
  __typename?: 'AffiliateRewardStatistic';
  paid: Sum;
  upcoming: Sum;
};

export enum AffiliateType {
  Company = 'COMPANY',
  Partner = 'PARTNER'
}

export type ApproveAllQualifiedReferralsPayload = {
  __typename?: 'ApproveAllQualifiedReferralsPayload';
  void?: Maybe<Scalars['Void']['output']>;
};

export type ApproveCompanyPlayerAccountInput = {
  companyId: Scalars['ID']['input'];
};

export type ApproveCompanyPlayerAccountPayload = {
  __typename?: 'ApproveCompanyPlayerAccountPayload';
  company: Company;
};

export type ApprovePartnerPlayerAccountInput = {
  partnerId: Scalars['ID']['input'];
};

export type ApprovePartnerPlayerAccountPayload = {
  __typename?: 'ApprovePartnerPlayerAccountPayload';
  partner: Partner;
};

export type ApproveQualifiedReferralInput = {
  id: Scalars['ID']['input'];
};

export type ApproveQualifiedReferralPayload = {
  __typename?: 'ApproveQualifiedReferralPayload';
  referral: QualifiedReferral;
};

export type AssignCompanyAffiliateApplicationInput = {
  id: Scalars['ID']['input'];
};

export type AssignCompanyAffiliateApplicationPayload = {
  __typename?: 'AssignCompanyAffiliateApplicationPayload';
  companyAffiliateApplication: CompanyAffiliateApplication;
};

export type AssignCompanyApplicationInput = {
  id: Scalars['ID']['input'];
};

export type AssignCompanyApplicationPayload = {
  __typename?: 'AssignCompanyApplicationPayload';
  companyApplication: CompanyApplication;
};

export type AssignPartnerApplicationInput = {
  id: Scalars['ID']['input'];
};

export type AssignPartnerApplicationPayload = {
  __typename?: 'AssignPartnerApplicationPayload';
  partnerApplication: PartnerApplication;
};

export type AssignWithdrawalInput = {
  id: Scalars['ID']['input'];
};

export type AssignWithdrawalPayload = {
  __typename?: 'AssignWithdrawalPayload';
  withdrawal: Withdrawal;
};

export type BadBankRestriction = RestrictionInterface & {
  __typename?: 'BadBankRestriction';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type BalanceChange = {
  __typename?: 'BalanceChange';
  delta: SumFlow;
  from: Sum;
  to: Sum;
};

export type BonusAccountEvent = AccountEventInterface & {
  __typename?: 'BonusAccountEvent';
  billingBalance: BalanceChange;
  bonusBalance: BalanceChange;
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['BonusAccountEventType']['output'];
};

export type BonusConfig = {
  __typename?: 'BonusConfig';
  cashout?: Maybe<Scalars['Int']['output']>;
  minAccountBalance?: Maybe<Sum>;
  winConditions?: Maybe<BonusWinConditions>;
};

export type BonusConfigInput = {
  cashout?: InputMaybe<Scalars['Int']['input']>;
  currency: Scalars['Currency']['input'];
  minAccountBalanceAmount?: InputMaybe<Scalars['Float']['input']>;
  wager?: InputMaybe<Scalars['Int']['input']>;
};

export type BonusConnection = {
  __typename?: 'BonusConnection';
  edges: Array<BonusEdge>;
  pageInfo: PageInfo;
};

export type BonusEdge = {
  __typename?: 'BonusEdge';
  cursor: Scalars['Cursor']['output'];
  node: BonusInterface;
};

export type BonusInput = {
  amount: SumInput;
  bonusConfig?: InputMaybe<BonusConfigInput>;
};

export type BonusInterface = {
  balance: Sum;
  config: BonusConfig;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  status: BonusStatus;
  sum: Sum;
  updatedAt: Scalars['DateTime']['output'];
  winSum?: Maybe<Sum>;
};

export enum BonusStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
  Won = 'WON'
}

export type BonusWinConditions = {
  __typename?: 'BonusWinConditions';
  betSum: Sum;
  requiredBetSum: Sum;
  wager?: Maybe<Scalars['Int']['output']>;
};

export type CancelBadBankRestrictionInput = {
  playerId: Scalars['ID']['input'];
};

export type CancelBadBankRestrictionPayload = {
  __typename?: 'CancelBadBankRestrictionPayload';
  badBankRestriction: BadBankRestriction;
};

export type CancelChargeBackRestrictionInput = {
  playerId: Scalars['ID']['input'];
};

export type CancelChargeBackRestrictionPayload = {
  __typename?: 'CancelChargeBackRestrictionPayload';
  chargeBackRestriction: ChargeBackRestriction;
};

export type CancelGiftBonusInput = {
  id: Scalars['ID']['input'];
};

export type CancelGiftBonusPayload = {
  __typename?: 'CancelGiftBonusPayload';
  bonus: GiftBonus;
};

export type CancelReceiveGiftRestrictionInput = {
  playerId: Scalars['ID']['input'];
};

export type CancelReceiveGiftRestrictionPayload = {
  __typename?: 'CancelReceiveGiftRestrictionPayload';
  lastReceiveGiftRestrictionLockedAt: Scalars['DateTime']['output'];
  receiveGiftRestriction?: Maybe<ReceiveGiftRestriction>;
};

export type CancelSignInRestrictionInput = {
  playerId: Scalars['ID']['input'];
};

export type CancelSignInRestrictionPayload = {
  __typename?: 'CancelSignInRestrictionPayload';
  signInRestriction?: Maybe<SignInRestriction>;
};

export type CancelWithdrawalRestrictionInput = {
  playerId: Scalars['ID']['input'];
};

export type CancelWithdrawalRestrictionPayload = {
  __typename?: 'CancelWithdrawalRestrictionPayload';
  withdrawalRestriction: WithdrawalRestriction;
};

export type CashTournament = TournamentInterface & {
  __typename?: 'CashTournament';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Cashback = {
  __typename?: 'Cashback';
  rewardPeriod: Scalars['YearMonth']['output'];
  sum: Sum;
};

export type CashbackBonus = BonusInterface & {
  __typename?: 'CashbackBonus';
  balance: Sum;
  config: BonusConfig;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  rewardPeriod: Scalars['YearMonth']['output'];
  status: BonusStatus;
  sum: Sum;
  updatedAt: Scalars['DateTime']['output'];
  winSum?: Maybe<Sum>;
};

export type ChargeBackRestriction = RestrictionInterface & {
  __typename?: 'ChargeBackRestriction';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Company = {
  __typename?: 'Company';
  affiliate: CompanyAffiliate;
  affiliates: CompanyAffiliateConnection;
  contactForCommunication: ContactForCommunication;
  cpaCompanyPartnerReport: CpaCompanyPartnerReportConnection;
  cpaReport: CpaAffiliateReportConnection;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  exportCpaCompanyPartnerReport: Scalars['Url']['output'];
  exportCpaReport: Scalars['Url']['output'];
  exportRevShareCompanyPartnerReport: Scalars['Url']['output'];
  exportRevShareReport: Scalars['Url']['output'];
  id: Scalars['ID']['output'];
  mediaCampaigns: MediaCampaignConnection;
  name: Scalars['String']['output'];
  note?: Maybe<Note>;
  outgoingMoneyTransfer: AffiliateOutgoingMoneyTransfer;
  outgoingMoneyTransfers: AffiliateOutgoingMoneyTransferConnection;
  playerAccount?: Maybe<AffiliatePlayerAccount>;
  promoCodeMediaItem: PromoCodeMediaItem;
  promoCodeMediaItems: PromoCodeMediaItemConnection;
  qualifiedReferralConfirmationSettings: QualifiedReferralConfirmationSettingConnection;
  referralLinkMediaItem: ReferralLinkMediaItem;
  referralLinkMediaItems: ReferralLinkMediaItemConnection;
  revShareCompanyPartnerReport: RevShareCompanyPartnerReportConnection;
  revShareReport: RevShareAffiliateReportConnection;
  trafficSource?: Maybe<Scalars['String']['output']>;
  twoFactorEnabled: Scalars['Boolean']['output'];
  website?: Maybe<Scalars['Url']['output']>;
};


export type CompanyAffiliateArgs = {
  id: Scalars['ID']['input'];
};


export type CompanyAffiliatesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyCpaCompanyPartnerReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: CompanyPartnerReportOrderingInput;
  period: DatePeriodInput;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type CompanyCpaReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: AffiliateReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type CompanyExportCpaCompanyPartnerReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type CompanyExportCpaReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type CompanyExportRevShareCompanyPartnerReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
};


export type CompanyExportRevShareReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyMediaCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyOutgoingMoneyTransferArgs = {
  id: Scalars['ID']['input'];
};


export type CompanyOutgoingMoneyTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyPromoCodeMediaItemArgs = {
  id: Scalars['ID']['input'];
};


export type CompanyPromoCodeMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyQualifiedReferralConfirmationSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyReferralLinkMediaItemArgs = {
  id: Scalars['ID']['input'];
};


export type CompanyReferralLinkMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyRevShareCompanyPartnerReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: GeneralReportOrderingInput;
  period: DatePeriodInput;
};


export type CompanyRevShareReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: AffiliateReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyAffiliate = {
  __typename?: 'CompanyAffiliate';
  /** @deprecated Should not be used in the `Company` context. Moved to `CompanyAffiliateApplicationItem`. */
  approved: Scalars['Boolean']['output'];
  company: Company;
  cpaReport: CpaAffiliateReportConnection;
  createdAt: Scalars['DateTime']['output'];
  exportCpaReport: Scalars['Url']['output'];
  exportRevShareReport: Scalars['Url']['output'];
  id: Scalars['ID']['output'];
  mediaItemsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Note>;
  promoCodeMediaItem: PromoCodeMediaItem;
  promoCodeMediaItems: PromoCodeMediaItemConnection;
  referralLinkMediaItems: ReferralLinkMediaItemConnection;
  revShareReport: RevShareAffiliateReportConnection;
};


export type CompanyAffiliateCpaReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: AffiliateReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type CompanyAffiliateExportCpaReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type CompanyAffiliateExportRevShareReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CompanyAffiliatePromoCodeMediaItemArgs = {
  id: Scalars['ID']['input'];
};


export type CompanyAffiliatePromoCodeMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAffiliateReferralLinkMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAffiliateRevShareReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: AffiliateReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyAffiliateApplication = {
  __typename?: 'CompanyAffiliateApplication';
  admin?: Maybe<Admin>;
  /** @deprecated Use `items` instead. */
  affiliates: Array<CompanyAffiliate>;
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  editable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  items: Array<CompanyAffiliateApplicationItem>;
  statistic: CompanyAffiliateApplicationStatistic;
  status: CompanyAffiliateApplicationStatus;
  suspendable: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyAffiliateApplicationConnection = {
  __typename?: 'CompanyAffiliateApplicationConnection';
  edges: Array<CompanyAffiliateApplicationEdge>;
  pageInfo: PageInfo;
};

export type CompanyAffiliateApplicationEdge = {
  __typename?: 'CompanyAffiliateApplicationEdge';
  cursor: Scalars['Cursor']['output'];
  node: CompanyAffiliateApplication;
};

export type CompanyAffiliateApplicationItem = {
  __typename?: 'CompanyAffiliateApplicationItem';
  approved: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Note>;
};

export type CompanyAffiliateApplicationStatistic = {
  __typename?: 'CompanyAffiliateApplicationStatistic';
  approved: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CompanyAffiliateApplicationStatus {
  Active = 'ACTIVE',
  Declined = 'DECLINED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type CompanyAffiliateConnection = {
  __typename?: 'CompanyAffiliateConnection';
  edges: Array<CompanyAffiliateEdge>;
  pageInfo: PageInfo;
};

export type CompanyAffiliateEdge = {
  __typename?: 'CompanyAffiliateEdge';
  cursor: Scalars['Cursor']['output'];
  node: CompanyAffiliate;
};

export type CompanyAffiliateInput = {
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyApplication = {
  __typename?: 'CompanyApplication';
  admin?: Maybe<Admin>;
  /** @deprecated Use `companyName` and `companyEmail` fields directly. */
  company: Company;
  companyEmail: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  contactForCommunication: ContactForCommunication;
  createdAt: Scalars['DateTime']['output'];
  declineReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: CompanyApplicationStatus;
  suspendable: Scalars['Boolean']['output'];
  trafficSource?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  website?: Maybe<Scalars['Url']['output']>;
};

export type CompanyApplicationConnection = {
  __typename?: 'CompanyApplicationConnection';
  edges: Array<CompanyApplicationEdge>;
  pageInfo: PageInfo;
};

export type CompanyApplicationEdge = {
  __typename?: 'CompanyApplicationEdge';
  cursor: Scalars['Cursor']['output'];
  node: CompanyApplication;
};

export enum CompanyApplicationStatus {
  Active = 'ACTIVE',
  Declined = 'DECLINED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<CompanyEdge>;
  pageInfo: PageInfo;
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['Cursor']['output'];
  node: Company;
};

export enum CompanyPartnerReportOrderBy {
  AllDeposit = 'ALL_DEPOSIT',
  Profit = 'PROFIT',
  Registrations = 'REGISTRATIONS',
  UpcomingReward = 'UPCOMING_REWARD',
  Withdrawal = 'WITHDRAWAL'
}

export type CompanyPartnerReportOrderingInput = {
  direction: OrderDirection;
  orderBy: CompanyPartnerReportOrderBy;
};

export type ConfirmCompanyAffiliateApplicationInput = {
  /** @deprecated Use `items` instead. */
  affiliates?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  items?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ConfirmCompanyAffiliateApplicationPayload = {
  __typename?: 'ConfirmCompanyAffiliateApplicationPayload';
  companyAffiliateApplication: CompanyAffiliateApplication;
};

export type ContactForCommunication = {
  __typename?: 'ContactForCommunication';
  /** @deprecated Use `value` field. */
  contact: Scalars['String']['output'];
  /** @deprecated Use `type` field. */
  method: Scalars['String']['output'];
  type: Scalars['ContactForCommunicationType']['output'];
  value: Scalars['String']['output'];
};

export type CpaAffiliateReportConnection = {
  __typename?: 'CpaAffiliateReportConnection';
  edges: Array<CpaAffiliateReportRowEdge>;
  pageInfo: PageInfo;
  total?: Maybe<CpaAffiliateReportTotal>;
};

export type CpaAffiliateReportRow = {
  __typename?: 'CpaAffiliateReportRow';
  adminFee: Sum;
  affiliateRewardStatistic: AffiliateRewardStatistic;
  marketingStatistic: MarketingStatistic;
  mediaItem: MediaItemInterface;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: CpaPlayerStatistic;
  playerStatusStatistic: CpaPlayerStatusStatistic;
};

export type CpaAffiliateReportRowEdge = {
  __typename?: 'CpaAffiliateReportRowEdge';
  cursor: Scalars['Cursor']['output'];
  node: CpaAffiliateReportRow;
};

export type CpaAffiliateReportTotal = {
  __typename?: 'CpaAffiliateReportTotal';
  adminFee: Sum;
  affiliateRewardStatistic: AffiliateRewardStatistic;
  marketingStatistic: MarketingStatistic;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: CpaPlayerStatistic;
  playerStatusStatistic: CpaPlayerStatusStatistic;
};

export type CpaCompanyPartnerReportCompanyRow = {
  __typename?: 'CpaCompanyPartnerReportCompanyRow';
  affiliateRewardStatistic: AffiliateRewardStatistic;
  company: Company;
  marketingStatistic: MarketingStatistic;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: CpaPlayerStatistic;
  playerStatusStatistic: CpaPlayerStatusStatistic;
};

export type CpaCompanyPartnerReportConnection = {
  __typename?: 'CpaCompanyPartnerReportConnection';
  company?: Maybe<CpaCompanyPartnerReportCompanyRow>;
  edges: Array<CpaCompanyPartnerReportRowEdge>;
  pageInfo: PageInfo;
  total?: Maybe<CpaCompanyPartnerReportTotal>;
};

export type CpaCompanyPartnerReportRow = {
  __typename?: 'CpaCompanyPartnerReportRow';
  affiliateRewardStatistic: AffiliateRewardStatistic;
  companyPartner: CompanyAffiliate;
  marketingStatistic: MarketingStatistic;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: CpaPlayerStatistic;
  playerStatusStatistic: CpaPlayerStatusStatistic;
};

export type CpaCompanyPartnerReportRowEdge = {
  __typename?: 'CpaCompanyPartnerReportRowEdge';
  cursor: Scalars['Cursor']['output'];
  node: CpaCompanyPartnerReportRow;
};

export type CpaCompanyPartnerReportTotal = {
  __typename?: 'CpaCompanyPartnerReportTotal';
  affiliateRewardStatistic: AffiliateRewardStatistic;
  marketingStatistic: MarketingStatistic;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: CpaPlayerStatistic;
  playerStatusStatistic: CpaPlayerStatusStatistic;
};

export type CpaGeneralReportConnection = {
  __typename?: 'CpaGeneralReportConnection';
  edges: Array<CpaGeneralReportRowEdge>;
  pageInfo: PageInfo;
  total?: Maybe<CpaGeneralReportTotal>;
};

export type CpaGeneralReportRow = {
  __typename?: 'CpaGeneralReportRow';
  affiliate: Affiliate;
  affiliateRewardStatistic: AffiliateRewardStatistic;
  marketingStatistic: MarketingStatistic;
  /** @deprecated Use the `affiliate` field instead. */
  partner: Partner;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: CpaPlayerStatistic;
  playerStatusStatistic: CpaPlayerStatusStatistic;
};

export type CpaGeneralReportRowEdge = {
  __typename?: 'CpaGeneralReportRowEdge';
  cursor: Scalars['Cursor']['output'];
  node: CpaGeneralReportRow;
};

export type CpaGeneralReportTotal = {
  __typename?: 'CpaGeneralReportTotal';
  affiliateRewardStatistic: AffiliateRewardStatistic;
  marketingStatistic: MarketingStatistic;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: CpaPlayerStatistic;
  playerStatusStatistic: CpaPlayerStatusStatistic;
};

export type CpaMediaItemReportConnection = {
  __typename?: 'CpaMediaItemReportConnection';
  edges: Array<CpaMediaItemReportRowEdge>;
  pageInfo: PageInfo;
  total?: Maybe<CpaMediaItemReportTotal>;
};

export type CpaMediaItemReportRow = {
  __typename?: 'CpaMediaItemReportRow';
  affiliateRewardStatistic: AffiliateRewardStatistic;
  cpaRewardTariffBaseline: Sum;
  cpaRewardTariffReward: Sum;
  deposit: Sum;
  financialIndicators: FinancialIndicators;
  firstDeposit: Scalars['Boolean']['output'];
  player: Player;
  redeposit: Sum;
  redeposits: Scalars['Int']['output'];
  referralStatus: ReferralStatus;
  withdrawal: Sum;
};

export type CpaMediaItemReportRowEdge = {
  __typename?: 'CpaMediaItemReportRowEdge';
  cursor: Scalars['Cursor']['output'];
  node: CpaMediaItemReportRow;
};

export type CpaMediaItemReportTotal = {
  __typename?: 'CpaMediaItemReportTotal';
  affiliateRewardStatistic: AffiliateRewardStatistic;
  cpaRewardTariffBaseline: Sum;
  cpaRewardTariffReward: Sum;
  deposit: Sum;
  financialIndicators: FinancialIndicators;
  redeposit: Sum;
  redeposits: Scalars['Int']['output'];
  withdrawal: Sum;
};

export type CpaPlayerStatistic = {
  __typename?: 'CpaPlayerStatistic';
  allDeposit: Sum;
  allRedeposit: Sum;
  averageDeposit: Sum;
  bonus: Sum;
  financialIndicators: FinancialIndicators;
  withdrawal: Sum;
};

export type CpaPlayerStatusStatistic = {
  __typename?: 'CpaPlayerStatusStatistic';
  approved: Scalars['Int']['output'];
  canceled: Scalars['Int']['output'];
  hold: Scalars['Int']['output'];
  paid: Scalars['Int']['output'];
  qualified: Scalars['Int']['output'];
  rejected: Scalars['Int']['output'];
  revShare: Scalars['Int']['output'];
  unpaid: Scalars['Int']['output'];
};

export type CpaRewardTariff = RewardTariffInterface & {
  __typename?: 'CpaRewardTariff';
  baselineSum: Sum;
  countries: Array<Scalars['CountryCode']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  rewardSum: Sum;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CpaRewardTariffConnection = {
  __typename?: 'CpaRewardTariffConnection';
  edges: Array<CpaRewardTariffEdge>;
  pageInfo: PageInfo;
};

export type CpaRewardTariffEdge = {
  __typename?: 'CpaRewardTariffEdge';
  cursor: Scalars['Cursor']['output'];
  node: CpaRewardTariff;
};

export type CreateAdminInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  passwordSetUri: Scalars['String']['input'];
  roles: Array<Scalars['AdminRole']['input']>;
};

export type CreateAdminPayload = {
  __typename?: 'CreateAdminPayload';
  admin: Admin;
};

export type CreateBadBankRestrictionInput = {
  playerId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBadBankRestrictionPayload = {
  __typename?: 'CreateBadBankRestrictionPayload';
  badBankRestriction: BadBankRestriction;
};

export type CreateChargeBackRestrictionInput = {
  playerId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type CreateChargeBackRestrictionPayload = {
  __typename?: 'CreateChargeBackRestrictionPayload';
  chargeBackRestriction: ChargeBackRestriction;
};

export type CreateCompanyAffiliatePromoCodeMediaItemInput = {
  activationLimit?: InputMaybe<Scalars['Int']['input']>;
  bonus?: InputMaybe<BonusInput>;
  companyAffiliateId: Scalars['ID']['input'];
  freespin?: InputMaybe<FreespinInput>;
  referralLinkMediaItem: CreatePromoCodeMediaItemReferralLinkMediaItemInput;
  token: Scalars['String']['input'];
  usageType: PromoCodeUsageType;
};

export type CreateCompanyAffiliatePromoCodeMediaItemPayload = {
  __typename?: 'CreateCompanyAffiliatePromoCodeMediaItemPayload';
  promoCodeMediaItem: PromoCodeMediaItem;
};

export type CreateCompanyAffiliateReferralLinkMediaItemInput = {
  companyAffiliateId: Scalars['ID']['input'];
  referralLinkMediaItem: ReferralLinkMediaItemInput;
};

export type CreateCompanyAffiliateReferralLinkMediaItemPayload = {
  __typename?: 'CreateCompanyAffiliateReferralLinkMediaItemPayload';
  referralLinkMediaItem: ReferralLinkMediaItem;
};

export type CreateCompanyApplicationReferralLinkMediaItemInput = {
  code: Scalars['ReferralLinkCode']['input'];
  mediaCampaignId: Scalars['ID']['input'];
  mediaItemRewardTariff: MediaItemRewardTariffInput;
  name: Scalars['String']['input'];
};

export type CreateCompanyReferralLinkMediaItemInput = {
  companyId: Scalars['ID']['input'];
  referralLinkMediaItem: ReferralLinkMediaItemInput;
};

export type CreateCompanyReferralLinkMediaItemPayload = {
  __typename?: 'CreateCompanyReferralLinkMediaItemPayload';
  referralLinkMediaItem: ReferralLinkMediaItem;
};

export type CreateCpaRewardTariffInput = {
  baselineSum: SumInput;
  countries: Array<Scalars['CountryCode']['input']>;
  rewardSum: SumInput;
  title: Scalars['String']['input'];
};

export type CreateCpaRewardTariffPayload = {
  __typename?: 'CreateCpaRewardTariffPayload';
  cpaRewardTariff: CpaRewardTariff;
};

export type CreateDocumentInput = {
  fileId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDocumentsInput = {
  items: Array<CreateDocumentInput>;
  playerId: Scalars['ID']['input'];
};

export type CreateDocumentsPayload = {
  __typename?: 'CreateDocumentsPayload';
  documents: Array<Document>;
};

export type CreateFreespinInput = {
  bonusWager?: InputMaybe<Scalars['Int']['input']>;
  count: Scalars['Int']['input'];
  currency: Scalars['Currency']['input'];
  gameId: Scalars['ID']['input'];
  minAccountBalanceAmount?: InputMaybe<Scalars['Float']['input']>;
  playerId: Scalars['ID']['input'];
};

export type CreateFreespinPayload = {
  __typename?: 'CreateFreespinPayload';
  freespin: Freespin;
};

export type CreateGiftBonusInput = {
  amount: Scalars['Float']['input'];
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  bonusConfig: BonusConfigInput;
  playerId: Scalars['ID']['input'];
};

export type CreateGiftBonusPayload = {
  __typename?: 'CreateGiftBonusPayload';
  bonus: GiftBonus;
};

export type CreateMediaCampaignInput = {
  name: Scalars['String']['input'];
  url: Scalars['Url']['input'];
};

export type CreateMediaCampaignPayload = {
  __typename?: 'CreateMediaCampaignPayload';
  mediaCampaign: MediaCampaign;
};

export type CreateMessageInput = {
  playerId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type CreateMessagePayload = {
  __typename?: 'CreateMessagePayload';
  message: Message;
};

export type CreatePartnerApplicationReferralLinkMediaItemInput = {
  code: Scalars['ReferralLinkCode']['input'];
  mediaCampaignId?: InputMaybe<Scalars['ID']['input']>;
  mediaItemRewardTariff: MediaItemRewardTariffInput;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePartnerReferralLinkMediaItemInput = {
  partnerId: Scalars['ID']['input'];
  referralLinkMediaItem: ReferralLinkMediaItemInput;
};

export type CreatePartnerReferralLinkMediaItemPayload = {
  __typename?: 'CreatePartnerReferralLinkMediaItemPayload';
  referralLinkMediaItem: ReferralLinkMediaItem;
};

export type CreatePaymentInput = {
  bypassRestrictionIfApproved?: InputMaybe<Scalars['Boolean']['input']>;
  bypassWithdrawalLimitIfApproved?: InputMaybe<Scalars['Boolean']['input']>;
  paymentProcessor: Scalars['PaymentProcessor']['input'];
  sum: SumInput;
  withdrawalId: Scalars['ID']['input'];
};

export type CreatePaymentPayload = {
  __typename?: 'CreatePaymentPayload';
  payment: Payment;
};

export type CreatePromoCodeMediaItemInput = {
  activationLimit?: InputMaybe<Scalars['Int']['input']>;
  bonus?: InputMaybe<BonusInput>;
  freespin?: InputMaybe<FreespinInput>;
  mediaCampaignId?: InputMaybe<Scalars['ID']['input']>;
  referralLinkMediaItem?: InputMaybe<CreatePromoCodeMediaItemReferralLinkMediaItemInput>;
  token: Scalars['String']['input'];
  usageType: PromoCodeUsageType;
};

export type CreatePromoCodeMediaItemPayload = {
  __typename?: 'CreatePromoCodeMediaItemPayload';
  promoCodeMediaItem: PromoCodeMediaItem;
};

export type CreatePromoCodeMediaItemReferralLinkMediaItemInput = {
  id: Scalars['ID']['input'];
};

export type CreateQualifiedReferralConfirmationSettingInput = {
  affiliate: AffiliateInput;
  code?: InputMaybe<Scalars['ReferralLinkCode']['input']>;
  comment: Scalars['String']['input'];
  type: QualifiedReferralConfirmationSettingType;
};

export type CreateQualifiedReferralConfirmationSettingPayload = {
  __typename?: 'CreateQualifiedReferralConfirmationSettingPayload';
  qualifiedReferralConfirmationSetting: QualifiedReferralConfirmationSetting;
};

export type CreateReceiveGiftRestrictionInput = {
  bypassRestrictionIfApproved: Scalars['Boolean']['input'];
  playerId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type CreateReceiveGiftRestrictionPayload = {
  __typename?: 'CreateReceiveGiftRestrictionPayload';
  receiveGiftRestriction: ReceiveGiftRestriction;
};

export type CreateReferralLinkInput = {
  code: Scalars['ReferralLinkCode']['input'];
  cpaTariffId?: InputMaybe<Scalars['ID']['input']>;
  partnerId: Scalars['ID']['input'];
  revShareTariffId: Scalars['ID']['input'];
};

export type CreateReferralLinkMediaItemInput = {
  code: Scalars['ReferralLinkCode']['input'];
  mediaCampaignId?: InputMaybe<Scalars['ID']['input']>;
  mediaItemRewardTariff: MediaItemRewardTariffInput;
  name: Scalars['String']['input'];
  partner: MediaItemPartnerInput;
};

export type CreateReferralLinkMediaItemPayload = {
  __typename?: 'CreateReferralLinkMediaItemPayload';
  referralLinkMediaItem: ReferralLinkMediaItem;
};

export type CreateRevShareRewardTariffInput = {
  percent: Scalars['Percent']['input'];
};

export type CreateRevShareRewardTariffPayload = {
  __typename?: 'CreateRevShareRewardTariffPayload';
  revShareRewardTariff: RevShareRewardTariff;
};

export type CreateSignInRestrictionInput = {
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  playerId: Scalars['ID']['input'];
  protected: Scalars['Boolean']['input'];
  reason: Scalars['String']['input'];
};

export type CreateSignInRestrictionPayload = {
  __typename?: 'CreateSignInRestrictionPayload';
  signInRestriction: SignInRestriction;
};

export type CreateWithdrawalRestrictionInput = {
  bypassRestrictionIfApproved: Scalars['Boolean']['input'];
  playerId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type CreateWithdrawalRestrictionPayload = {
  __typename?: 'CreateWithdrawalRestrictionPayload';
  withdrawalRestriction: WithdrawalRestriction;
};

export type DatePeriodInput = {
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
};

export type DeclineCompanyAffiliateApplicationInput = {
  id: Scalars['ID']['input'];
};

export type DeclineCompanyAffiliateApplicationPayload = {
  __typename?: 'DeclineCompanyAffiliateApplicationPayload';
  companyAffiliateApplication: CompanyAffiliateApplication;
};

export type DeclineCompanyApplicationInput = {
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type DeclineCompanyApplicationPayload = {
  __typename?: 'DeclineCompanyApplicationPayload';
  companyApplication: CompanyApplication;
};

export type DeclineCompanyPlayerAccountInput = {
  companyId: Scalars['ID']['input'];
};

export type DeclineCompanyPlayerAccountPayload = {
  __typename?: 'DeclineCompanyPlayerAccountPayload';
  company: Company;
};

export type DeclinePartnerApplicationInput = {
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type DeclinePartnerApplicationPayload = {
  __typename?: 'DeclinePartnerApplicationPayload';
  partnerApplication: PartnerApplication;
};

export type DeclinePartnerPlayerAccountInput = {
  partnerId: Scalars['ID']['input'];
};

export type DeclinePartnerPlayerAccountPayload = {
  __typename?: 'DeclinePartnerPlayerAccountPayload';
  partner: Partner;
};

export type DeclinePaymentInput = {
  id: Scalars['ID']['input'];
};

export type DeclinePaymentPayload = {
  __typename?: 'DeclinePaymentPayload';
  payment: Payment;
};

export type DeclineRewardInput = {
  id: Scalars['ID']['input'];
};

export type DeclineRewardPayload = {
  __typename?: 'DeclineRewardPayload';
  reward: Reward;
};

export type DeclineWithdrawalInput = {
  id: Scalars['ID']['input'];
};

export type DeclineWithdrawalPayload = {
  __typename?: 'DeclineWithdrawalPayload';
  withdrawal: Withdrawal;
};

export type DeleteAdminInput = {
  id: Scalars['ID']['input'];
};

export type DeleteAdminPayload = {
  __typename?: 'DeleteAdminPayload';
  id: Scalars['ID']['output'];
};

export type DeleteAllPostbackDisabledNotificationsPayload = {
  __typename?: 'DeleteAllPostbackDisabledNotificationsPayload';
  void?: Maybe<Scalars['Void']['output']>;
};

export type DeleteDocumentInput = {
  id: Scalars['ID']['input'];
};

export type DeleteDocumentPayload = {
  __typename?: 'DeleteDocumentPayload';
  id: Scalars['ID']['output'];
};

export type DeleteMessageInput = {
  id: Scalars['ID']['input'];
};

export type DeleteMessagePayload = {
  __typename?: 'DeleteMessagePayload';
  id: Scalars['ID']['output'];
};

export type DeletePlayerInput = {
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type DeletePlayerPayload = {
  __typename?: 'DeletePlayerPayload';
  player: Player;
};

export type DeletePostbackDisabledNotificationInput = {
  id: Scalars['ID']['input'];
};

export type DeletePostbackDisabledNotificationPayload = {
  __typename?: 'DeletePostbackDisabledNotificationPayload';
  void?: Maybe<Scalars['Void']['output']>;
};

export type DeleteQualifiedReferralConfirmationSettingInput = {
  id: Scalars['ID']['input'];
};

export type DeleteQualifiedReferralConfirmationSettingPayload = {
  __typename?: 'DeleteQualifiedReferralConfirmationSettingPayload';
  void?: Maybe<Scalars['Void']['output']>;
};

export type Deposit = {
  __typename?: 'Deposit';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  paidSum?: Maybe<Sum>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodName: Scalars['DepositPaymentMethodName']['output'];
  paymentProvider: Scalars['PaymentProvider']['output'];
  status: DepositStatus;
  sum: Sum;
  updatedAt: Scalars['DateTime']['output'];
  wager?: Maybe<Wager>;
};

export type DepositAccountEvent = AccountEventInterface & {
  __typename?: 'DepositAccountEvent';
  billingBalance: BalanceChange;
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['DepositAccountEventType']['output'];
};

export type DepositConnection = {
  __typename?: 'DepositConnection';
  edges: Array<DepositEdge>;
  pageInfo: PageInfo;
};

export type DepositEdge = {
  __typename?: 'DepositEdge';
  cursor: Scalars['Cursor']['output'];
  node: Deposit;
};

export type DepositFilterInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  depositId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethodName?: InputMaybe<Scalars['DepositPaymentMethodName']['input']>;
  paymentProvider?: InputMaybe<Scalars['PaymentProvider']['input']>;
  status?: InputMaybe<DepositStatus>;
};

export enum DepositStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  PresumablyDeclined = 'PRESUMABLY_DECLINED',
  Unknown = 'UNKNOWN'
}

export type DisablePlayerInternalOutgoingMoneyTransfersInput = {
  id: Scalars['ID']['input'];
};

export type DisablePlayerInternalOutgoingMoneyTransfersPayload = {
  __typename?: 'DisablePlayerInternalOutgoingMoneyTransfersPayload';
  player: Player;
};

export type Document = {
  __typename?: 'Document';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['Url']['output'];
  note?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl: Scalars['Url']['output'];
};


export type DocumentThumbnailImageUrlArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  edges: Array<DocumentEdge>;
  pageInfo: PageInfo;
};

export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  cursor: Scalars['Cursor']['output'];
  node: Document;
};

export type DuplicatePaymentMethodReferralReviewResult = ReferralReviewResultInterface & {
  __typename?: 'DuplicatePaymentMethodReferralReviewResult';
  passed: Scalars['Boolean']['output'];
  paymentMethod: PaymentMethod;
  player: Player;
};

export type EnableAllPostbacksPayload = {
  __typename?: 'EnableAllPostbacksPayload';
  void?: Maybe<Scalars['Void']['output']>;
};

export type EnablePlayerInternalOutgoingMoneyTransfersInput = {
  id: Scalars['ID']['input'];
};

export type EnablePlayerInternalOutgoingMoneyTransfersPayload = {
  __typename?: 'EnablePlayerInternalOutgoingMoneyTransfersPayload';
  player: Player;
};

export type EnablePostbackInput = {
  id: Scalars['ID']['input'];
};

export type EnablePostbackPayload = {
  __typename?: 'EnablePostbackPayload';
  void?: Maybe<Scalars['Void']['output']>;
};

export type ExternalMoneyTransfer = {
  __typename?: 'ExternalMoneyTransfer';
  affiliate: Affiliate;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sum: Sum;
};

export type ExternalMoneyTransferConnection = {
  __typename?: 'ExternalMoneyTransferConnection';
  edges: Array<ExternalMoneyTransferEdge>;
  pageInfo: PageInfo;
};

export type ExternalMoneyTransferEdge = {
  __typename?: 'ExternalMoneyTransferEdge';
  cursor: Scalars['Cursor']['output'];
  node: ExternalMoneyTransfer;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID']['output'];
};

export type FinancialIndicators = {
  __typename?: 'FinancialIndicators';
  ngr: Sum;
  profit: Sum;
};

export type FirstDepositBonus = BonusInterface & {
  __typename?: 'FirstDepositBonus';
  balance: Sum;
  config: BonusConfig;
  createdAt: Scalars['DateTime']['output'];
  deposit: Deposit;
  id: Scalars['ID']['output'];
  status: BonusStatus;
  sum: Sum;
  updatedAt: Scalars['DateTime']['output'];
  winSum?: Maybe<Sum>;
};

export type Freespin = {
  __typename?: 'Freespin';
  bonusWager?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdAutomatically: Scalars['Boolean']['output'];
  currency: Scalars['Currency']['output'];
  game: Game;
  id: Scalars['ID']['output'];
  minAccountBalance?: Maybe<Sum>;
  promoCode?: Maybe<PromoCode>;
  status: FreespinStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type FreespinBonus = BonusInterface & {
  __typename?: 'FreespinBonus';
  balance: Sum;
  config: BonusConfig;
  createdAt: Scalars['DateTime']['output'];
  freespin?: Maybe<Freespin>;
  id: Scalars['ID']['output'];
  status: BonusStatus;
  sum: Sum;
  updatedAt: Scalars['DateTime']['output'];
  winSum?: Maybe<Sum>;
};

export type FreespinConnection = {
  __typename?: 'FreespinConnection';
  edges: Array<FreespinEdge>;
  pageInfo: PageInfo;
};

export type FreespinEdge = {
  __typename?: 'FreespinEdge';
  cursor: Scalars['Cursor']['output'];
  node: Freespin;
};

export type FreespinInput = {
  bonusWager?: InputMaybe<Scalars['Int']['input']>;
  count: Scalars['Int']['input'];
  currency: Scalars['Currency']['input'];
  gameId: Scalars['ID']['input'];
  minAccountBalanceAmount?: InputMaybe<SumInput>;
};

export enum FreespinStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
  Won = 'WON'
}

export type FreespinTournament = TournamentInterface & {
  __typename?: 'FreespinTournament';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Game = {
  __typename?: 'Game';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GameAccountEvent = AccountEventInterface & {
  __typename?: 'GameAccountEvent';
  billingBalance: BalanceChange;
  bonusBalance?: Maybe<BalanceChange>;
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  game: Game;
  id: Scalars['ID']['output'];
  type: Scalars['GameAccountEventType']['output'];
};

export type GameConnection = {
  __typename?: 'GameConnection';
  edges: Array<GameEdge>;
  pageInfo: PageInfo;
};

export type GameEdge = {
  __typename?: 'GameEdge';
  cursor: Scalars['Cursor']['output'];
  node: Game;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum GeneralReportOrderBy {
  AllDeposit = 'ALL_DEPOSIT',
  Profit = 'PROFIT',
  Registrations = 'REGISTRATIONS',
  UpcomingReward = 'UPCOMING_REWARD',
  Withdrawal = 'WITHDRAWAL'
}

export type GeneralReportOrderingInput = {
  direction: OrderDirection;
  orderBy: GeneralReportOrderBy;
};

export type GeolocationMismatchReferralReviewResult = ReferralReviewResultInterface & {
  __typename?: 'GeolocationMismatchReferralReviewResult';
  /** @deprecated Use `countryCodeActual` instead. */
  countryCode: Scalars['CountryCode']['output'];
  countryCodeActual?: Maybe<Scalars['CountryCode']['output']>;
  passed: Scalars['Boolean']['output'];
};

export type GiftBonus = BonusInterface & {
  __typename?: 'GiftBonus';
  admin?: Maybe<Admin>;
  balance: Sum;
  cancellable: Scalars['Boolean']['output'];
  config: BonusConfig;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  status: BonusStatus;
  sum: Sum;
  updatedAt: Scalars['DateTime']['output'];
  winSum?: Maybe<Sum>;
};

export type Jackpot = {
  __typename?: 'Jackpot';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type JackpotAccountEvent = AccountEventInterface & {
  __typename?: 'JackpotAccountEvent';
  billingBalance: BalanceChange;
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  jackpot: Jackpot;
  type: Scalars['JackpotAccountEventType']['output'];
};

export type LastRestrictions = {
  __typename?: 'LastRestrictions';
  badBankRestriction?: Maybe<BadBankRestriction>;
  chargeBackRestriction?: Maybe<ChargeBackRestriction>;
  receiveGiftRestriction?: Maybe<ReceiveGiftRestriction>;
  withdrawalRestriction?: Maybe<WithdrawalRestriction>;
};

export type MarkedNoteReferralReviewResult = ReferralReviewResultInterface & {
  __typename?: 'MarkedNoteReferralReviewResult';
  note: Note;
  passed: Scalars['Boolean']['output'];
};

export type MarketingStatistic = {
  __typename?: 'MarketingStatistic';
  firstDeposits: Scalars['Int']['output'];
  hits: Scalars['Int']['output'];
  hostToRegConversion: Scalars['Percent']['output'];
  hosts: Scalars['Int']['output'];
  redeposits: Scalars['Int']['output'];
  regToDepConversion: Scalars['Percent']['output'];
  registrations: Scalars['Int']['output'];
};

export type MediaCampaign = {
  __typename?: 'MediaCampaign';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  url: Scalars['Url']['output'];
};

export type MediaCampaignConnection = {
  __typename?: 'MediaCampaignConnection';
  edges: Array<MediaCampaignEdge>;
  pageInfo: PageInfo;
};

export type MediaCampaignEdge = {
  __typename?: 'MediaCampaignEdge';
  cursor: Scalars['Cursor']['output'];
  node: MediaCampaign;
};

export type MediaItemConnection = {
  __typename?: 'MediaItemConnection';
  edges: Array<MediaItemEdge>;
  pageInfo: PageInfo;
};

export type MediaItemCpaRewardTariff = MediaItemRewardTariffInterface & {
  __typename?: 'MediaItemCpaRewardTariff';
  fallbackRewardTariff: RevShareRewardTariff;
  rewardTariff: CpaRewardTariff;
};

export type MediaItemCpaRewardTariffInput = {
  fallbackRewardTariffId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type MediaItemEdge = {
  __typename?: 'MediaItemEdge';
  cursor: Scalars['Cursor']['output'];
  node: MediaItemInterface;
};

export type MediaItemInterface = {
  affiliate: Affiliate;
  cpaReport: CpaMediaItemReportConnection;
  createdAt: Scalars['DateTime']['output'];
  exportCpaReport: Scalars['Url']['output'];
  exportRevShareReport: Scalars['Url']['output'];
  id: Scalars['ID']['output'];
  mediaCampaign: MediaCampaign;
  mediaItemRewardTariff: MediaItemRewardTariffInterface;
  name: Scalars['String']['output'];
  /** @deprecated Use MediaItemInterface.affiliate. */
  partner: Partner;
  revShareReport: RevShareMediaItemReportConnection;
};


export type MediaItemInterfaceCpaReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: MediaItemReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type MediaItemInterfaceExportCpaReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type MediaItemInterfaceExportRevShareReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type MediaItemInterfaceRevShareReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: MediaItemReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type MediaItemPartnerInput = {
  partnerId: Scalars['ID']['input'];
};

export enum MediaItemReportOrderBy {
  AllDeposit = 'ALL_DEPOSIT',
  UpcomingReward = 'UPCOMING_REWARD',
  Withdrawal = 'WITHDRAWAL'
}

export type MediaItemReportOrderingInput = {
  direction: OrderDirection;
  orderBy: MediaItemReportOrderBy;
};

export type MediaItemRevShareRewardTariff = MediaItemRewardTariffInterface & {
  __typename?: 'MediaItemRevShareRewardTariff';
  rewardTariff: RevShareRewardTariff;
};

export type MediaItemRevShareRewardTariffInput = {
  id: Scalars['ID']['input'];
};

export type MediaItemRewardTariffInput = {
  cpaRewardTariff?: InputMaybe<MediaItemCpaRewardTariffInput>;
  revShareRewardTariff?: InputMaybe<MediaItemRevShareRewardTariffInput>;
};

export type MediaItemRewardTariffInterface = {
  rewardTariff: RewardTariffInterface;
};

export type Message = {
  __typename?: 'Message';
  author: Admin;
  createdAt: Scalars['DateTime']['output'];
  deletable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['Cursor']['output'];
  node: Message;
};

export type MoneyTransfer = {
  __typename?: 'MoneyTransfer';
  counterparty: Player;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sum: Sum;
  type: MoneyTransferType;
  wager?: Maybe<Wager>;
};

export type MoneyTransferAccountEvent = AccountEventInterface & {
  __typename?: 'MoneyTransferAccountEvent';
  billingBalance: BalanceChange;
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['MoneyTransferAccountEventType']['output'];
};

export type MoneyTransferConnection = {
  __typename?: 'MoneyTransferConnection';
  edges: Array<MoneyTransferEdge>;
  pageInfo: PageInfo;
};

export type MoneyTransferEdge = {
  __typename?: 'MoneyTransferEdge';
  cursor: Scalars['Cursor']['output'];
  node: MoneyTransfer;
};

export type MoneyTransferFilterInput = {
  type?: InputMaybe<MoneyTransferType>;
};

export enum MoneyTransferType {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptAllRewards: AcceptAllRewardsPayload;
  /** @deprecated Use `confirmCompanyAffiliateApplication` instead. */
  acceptCompanyAffiliateApplication: AcceptCompanyAffiliateApplicationPayload;
  acceptCompanyApplication: AcceptCompanyApplicationPayload;
  acceptDeposit: AcceptDepositPayload;
  acceptPartnerApplication: AcceptPartnerApplicationPayload;
  acceptPayment: AcceptPaymentPayload;
  acceptReward: AcceptRewardPayload;
  addCompanyAffiliates: AddCompanyAffiliatesPayload;
  approveAllQualifiedReferrals: ApproveAllQualifiedReferralsPayload;
  approveCompanyPlayerAccount: ApproveCompanyPlayerAccountPayload;
  approvePartnerPlayerAccount: ApprovePartnerPlayerAccountPayload;
  approveQualifiedReferral: ApproveQualifiedReferralPayload;
  assignCompanyAffiliateApplication: AssignCompanyAffiliateApplicationPayload;
  assignCompanyApplication: AssignCompanyApplicationPayload;
  assignPartnerApplication: AssignPartnerApplicationPayload;
  assignWithdrawal: AssignWithdrawalPayload;
  cancelBadBankRestriction: CancelBadBankRestrictionPayload;
  cancelChargeBackRestriction: CancelChargeBackRestrictionPayload;
  cancelGiftBonus: CancelGiftBonusPayload;
  cancelReceiveGiftRestriction: CancelReceiveGiftRestrictionPayload;
  cancelSignInRestriction: CancelSignInRestrictionPayload;
  cancelWithdrawalRestriction: CancelWithdrawalRestrictionPayload;
  confirmCompanyAffiliateApplication: ConfirmCompanyAffiliateApplicationPayload;
  createAdmin: CreateAdminPayload;
  createBadBankRestriction: CreateBadBankRestrictionPayload;
  createChargeBackRestriction: CreateChargeBackRestrictionPayload;
  createCompanyAffiliatePromoCodeMediaItem: CreateCompanyAffiliatePromoCodeMediaItemPayload;
  createCompanyAffiliateReferralLinkMediaItem: CreateCompanyAffiliateReferralLinkMediaItemPayload;
  createCompanyReferralLinkMediaItem: CreateCompanyReferralLinkMediaItemPayload;
  createCpaRewardTariff: CreateCpaRewardTariffPayload;
  createDocuments: CreateDocumentsPayload;
  createFreespin: CreateFreespinPayload;
  createGiftBonus: CreateGiftBonusPayload;
  createMediaCampaign: CreateMediaCampaignPayload;
  createMessage: CreateMessagePayload;
  createPartnerReferralLinkMediaItem: CreatePartnerReferralLinkMediaItemPayload;
  createPayment: CreatePaymentPayload;
  createPromoCodeMediaItem: CreatePromoCodeMediaItemPayload;
  createQualifiedReferralConfirmationSetting: CreateQualifiedReferralConfirmationSettingPayload;
  createReceiveGiftRestriction: CreateReceiveGiftRestrictionPayload;
  /** @deprecated Use createCompanyReferralLinkMediaItem or createPartnerReferralLinkMediaItem. */
  createReferralLinkMediaItem: CreateReferralLinkMediaItemPayload;
  createRevShareRewardTariff: CreateRevShareRewardTariffPayload;
  createSingInRestriction: CreateSignInRestrictionPayload;
  createWithdrawalRestriction: CreateWithdrawalRestrictionPayload;
  /** @deprecated Use `confirmCompanyAffiliateApplication` instead. */
  declineCompanyAffiliateApplication: DeclineCompanyAffiliateApplicationPayload;
  declineCompanyApplication: DeclineCompanyApplicationPayload;
  declineCompanyPlayerAccount: DeclineCompanyPlayerAccountPayload;
  declinePartnerApplication: DeclinePartnerApplicationPayload;
  declinePartnerPlayerAccount: DeclinePartnerPlayerAccountPayload;
  declinePayment: DeclinePaymentPayload;
  declineReward: DeclineRewardPayload;
  declineWithdrawal: DeclineWithdrawalPayload;
  deleteAdmin: DeleteAdminPayload;
  deleteAllPostbackDisabledNotifications: DeleteAllPostbackDisabledNotificationsPayload;
  deleteDocument: DeleteDocumentPayload;
  deleteMessage: DeleteMessagePayload;
  deletePlayer: DeletePlayerPayload;
  deletePostbackDisabledNotification: DeletePostbackDisabledNotificationPayload;
  deleteQualifiedReferralConfirmationSetting: DeleteQualifiedReferralConfirmationSettingPayload;
  disablePlayerInternalOutgoingMoneyTransfers: DisablePlayerInternalOutgoingMoneyTransfersPayload;
  enableAllPostbacks: EnableAllPostbacksPayload;
  enablePlayerInternalOutgoingMoneyTransfers: EnablePlayerInternalOutgoingMoneyTransfersPayload;
  enablePostback: EnablePostbackPayload;
  rejectAllQualifiedReferrals: RejectAllQualifiedReferralsPayload;
  rejectQualifiedReferral: RejectQualifiedReferralPayload;
  resetAdminPassword: ResetAdminPasswordPayload;
  resetCompanyTwoFactor: ResetCompanyTwoFactorPayload;
  resetPartnerName: ResetPartnerNamePayload;
  resetPartnerTwoFactor: ResetPartnerTwoFactorPayload;
  resetPlayerName: ResetPlayerNamePayload;
  resetPlayerTwoFactor: ResetPlayerTwoFactorPayload;
  revertDepositToPresumablyDeclined: RevertDepositToPresumablyDeclinedPayload;
  suspectQualifiedReferral: SuspectQualifiedReferralPayload;
  suspectReward: SuspectRewardPayload;
  suspendCompanyAffiliateApplication: SuspendCompanyAffiliateApplicationPayload;
  suspendCompanyApplication: SuspendCompanyApplicationPayload;
  suspendPartnerApplication: SuspendPartnerApplicationPayload;
  suspendWithdrawal: SuspendWithdrawalPayload;
  undeletePlayer: UndeletePlayerPayload;
  unsuspectQualifiedReferral: UnsuspectQualifiedReferralPayload;
  unsuspectReward: UnsuspectRewardPayload;
  updateAccessToPartnerRevShareRewardTariffs: UpdateAccessToPartnerRevShareRewardTariffsPayload;
  updateAdmin: UpdateAdminPayload;
  /** @deprecated Use `updateCompanyAffiliateApplicationItem` instead. */
  updateCompanyAffiliateApplication: UpdateCompanyAffiliateApplicationPayload;
  updateCompanyAffiliateApplicationItem: UpdateCompanyAffiliateApplicationItemPayload;
  /** @deprecated New field `website` is inconsistent with contact information. Use `updateCompanyPersonalData` mutation */
  updateCompanyContactForCommunication: UpdateCompanyContactForCommunicationPayload;
  updateCompanyIdentities: UpdateCompanyIdentitiesPayload;
  updateCompanyName: UpdateCompanyNamePayload;
  updateCompanyNote: UpdateCompanyNotePayload;
  updateCompanyPersonalData: UpdateCompanyPersonalDataPayload;
  updateCpaRewardTariffTitle: UpdateCpaRewardTariffTitlePayload;
  updateDocumentNote: UpdateDocumentNotePayload;
  updateMediaCampaign: UpdateMediaCampaignPayload;
  updatePartnerIdentities: UpdatePartnerIdentitiesPayload;
  updatePartnerNote: UpdatePartnerNotePayload;
  updatePlayerIdentities: UpdatePlayerIdentitiesPayload;
  updatePlayerNote: UpdatePlayerNotePayload;
  updatePlayerRank: UpdatePlayerRankPayload;
  updateQualifiedReferralConfirmationSetting: UpdateQualifiedReferralConfirmationSettingPayload;
  updateReferralLinkMediaItemName: UpdateReferralLinkMediaItemNamePayload;
  uploadFile: UploadFilePayload;
};


export type MutationAcceptAllRewardsArgs = {
  input: AcceptAllRewardsInput;
};


export type MutationAcceptCompanyAffiliateApplicationArgs = {
  input: AcceptCompanyAffiliateApplicationInput;
};


export type MutationAcceptCompanyApplicationArgs = {
  input: AcceptCompanyApplicationInput;
};


export type MutationAcceptDepositArgs = {
  input: AcceptDepositInput;
};


export type MutationAcceptPartnerApplicationArgs = {
  input: AcceptPartnerApplicationInput;
};


export type MutationAcceptPaymentArgs = {
  input: AcceptPaymentInput;
};


export type MutationAcceptRewardArgs = {
  input: AcceptRewardInput;
};


export type MutationAddCompanyAffiliatesArgs = {
  input: AddCompanyAffiliatesInput;
};


export type MutationApproveCompanyPlayerAccountArgs = {
  input: ApproveCompanyPlayerAccountInput;
};


export type MutationApprovePartnerPlayerAccountArgs = {
  input: ApprovePartnerPlayerAccountInput;
};


export type MutationApproveQualifiedReferralArgs = {
  input: ApproveQualifiedReferralInput;
};


export type MutationAssignCompanyAffiliateApplicationArgs = {
  input: AssignCompanyAffiliateApplicationInput;
};


export type MutationAssignCompanyApplicationArgs = {
  input: AssignCompanyApplicationInput;
};


export type MutationAssignPartnerApplicationArgs = {
  input: AssignPartnerApplicationInput;
};


export type MutationAssignWithdrawalArgs = {
  input: AssignWithdrawalInput;
};


export type MutationCancelBadBankRestrictionArgs = {
  input: CancelBadBankRestrictionInput;
};


export type MutationCancelChargeBackRestrictionArgs = {
  input: CancelChargeBackRestrictionInput;
};


export type MutationCancelGiftBonusArgs = {
  input: CancelGiftBonusInput;
};


export type MutationCancelReceiveGiftRestrictionArgs = {
  input: CancelReceiveGiftRestrictionInput;
};


export type MutationCancelSignInRestrictionArgs = {
  input: CancelSignInRestrictionInput;
};


export type MutationCancelWithdrawalRestrictionArgs = {
  input: CancelWithdrawalRestrictionInput;
};


export type MutationConfirmCompanyAffiliateApplicationArgs = {
  input: ConfirmCompanyAffiliateApplicationInput;
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationCreateBadBankRestrictionArgs = {
  input: CreateBadBankRestrictionInput;
};


export type MutationCreateChargeBackRestrictionArgs = {
  input: CreateChargeBackRestrictionInput;
};


export type MutationCreateCompanyAffiliatePromoCodeMediaItemArgs = {
  input: CreateCompanyAffiliatePromoCodeMediaItemInput;
};


export type MutationCreateCompanyAffiliateReferralLinkMediaItemArgs = {
  input: CreateCompanyAffiliateReferralLinkMediaItemInput;
};


export type MutationCreateCompanyReferralLinkMediaItemArgs = {
  input: CreateCompanyReferralLinkMediaItemInput;
};


export type MutationCreateCpaRewardTariffArgs = {
  input: CreateCpaRewardTariffInput;
};


export type MutationCreateDocumentsArgs = {
  input: CreateDocumentsInput;
};


export type MutationCreateFreespinArgs = {
  input: CreateFreespinInput;
};


export type MutationCreateGiftBonusArgs = {
  input: CreateGiftBonusInput;
};


export type MutationCreateMediaCampaignArgs = {
  input: CreateMediaCampaignInput;
};


export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};


export type MutationCreatePartnerReferralLinkMediaItemArgs = {
  input: CreatePartnerReferralLinkMediaItemInput;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreatePromoCodeMediaItemArgs = {
  input: CreatePromoCodeMediaItemInput;
};


export type MutationCreateQualifiedReferralConfirmationSettingArgs = {
  input: CreateQualifiedReferralConfirmationSettingInput;
};


export type MutationCreateReceiveGiftRestrictionArgs = {
  input: CreateReceiveGiftRestrictionInput;
};


export type MutationCreateReferralLinkMediaItemArgs = {
  input: CreateReferralLinkMediaItemInput;
};


export type MutationCreateRevShareRewardTariffArgs = {
  input: CreateRevShareRewardTariffInput;
};


export type MutationCreateSingInRestrictionArgs = {
  input: CreateSignInRestrictionInput;
};


export type MutationCreateWithdrawalRestrictionArgs = {
  input: CreateWithdrawalRestrictionInput;
};


export type MutationDeclineCompanyAffiliateApplicationArgs = {
  input: DeclineCompanyAffiliateApplicationInput;
};


export type MutationDeclineCompanyApplicationArgs = {
  input: DeclineCompanyApplicationInput;
};


export type MutationDeclineCompanyPlayerAccountArgs = {
  input: DeclineCompanyPlayerAccountInput;
};


export type MutationDeclinePartnerApplicationArgs = {
  input: DeclinePartnerApplicationInput;
};


export type MutationDeclinePartnerPlayerAccountArgs = {
  input: DeclinePartnerPlayerAccountInput;
};


export type MutationDeclinePaymentArgs = {
  input: DeclinePaymentInput;
};


export type MutationDeclineRewardArgs = {
  input: DeclineRewardInput;
};


export type MutationDeclineWithdrawalArgs = {
  input: DeclineWithdrawalInput;
};


export type MutationDeleteAdminArgs = {
  input: DeleteAdminInput;
};


export type MutationDeleteDocumentArgs = {
  input: DeleteDocumentInput;
};


export type MutationDeleteMessageArgs = {
  input: DeleteMessageInput;
};


export type MutationDeletePlayerArgs = {
  input: DeletePlayerInput;
};


export type MutationDeletePostbackDisabledNotificationArgs = {
  input: DeletePostbackDisabledNotificationInput;
};


export type MutationDeleteQualifiedReferralConfirmationSettingArgs = {
  input: DeleteQualifiedReferralConfirmationSettingInput;
};


export type MutationDisablePlayerInternalOutgoingMoneyTransfersArgs = {
  input: DisablePlayerInternalOutgoingMoneyTransfersInput;
};


export type MutationEnablePlayerInternalOutgoingMoneyTransfersArgs = {
  input: EnablePlayerInternalOutgoingMoneyTransfersInput;
};


export type MutationEnablePostbackArgs = {
  input: EnablePostbackInput;
};


export type MutationRejectQualifiedReferralArgs = {
  input: RejectQualifiedReferralInput;
};


export type MutationResetAdminPasswordArgs = {
  input: ResetAdminPasswordInput;
};


export type MutationResetCompanyTwoFactorArgs = {
  input: ResetCompanyTwoFactorInput;
};


export type MutationResetPartnerNameArgs = {
  input: ResetPartnerNameInput;
};


export type MutationResetPartnerTwoFactorArgs = {
  input: ResetPartnerTwoFactorInput;
};


export type MutationResetPlayerNameArgs = {
  input: ResetPlayerNameInput;
};


export type MutationResetPlayerTwoFactorArgs = {
  input: ResetPlayerTwoFactorInput;
};


export type MutationRevertDepositToPresumablyDeclinedArgs = {
  input: RevertDepositToPresumablyDeclinedInput;
};


export type MutationSuspectQualifiedReferralArgs = {
  input: SuspectQualifiedReferralInput;
};


export type MutationSuspectRewardArgs = {
  input: SuspectRewardInput;
};


export type MutationSuspendCompanyAffiliateApplicationArgs = {
  input: SuspendCompanyAffiliateApplicationInput;
};


export type MutationSuspendCompanyApplicationArgs = {
  input: SuspendCompanyApplicationInput;
};


export type MutationSuspendPartnerApplicationArgs = {
  input: SuspendPartnerApplicationInput;
};


export type MutationSuspendWithdrawalArgs = {
  input: SuspendWithdrawalInput;
};


export type MutationUndeletePlayerArgs = {
  input: UndeletePlayerInput;
};


export type MutationUnsuspectQualifiedReferralArgs = {
  input: UnsuspectQualifiedReferralInput;
};


export type MutationUnsuspectRewardArgs = {
  input: UnsuspectRewardInput;
};


export type MutationUpdateAccessToPartnerRevShareRewardTariffsArgs = {
  input: UpdateAccessToPartnerRevShareRewardTariffsInput;
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationUpdateCompanyAffiliateApplicationArgs = {
  input: UpdateCompanyAffiliateApplicationInput;
};


export type MutationUpdateCompanyAffiliateApplicationItemArgs = {
  input: UpdateCompanyAffiliateApplicationItemInput;
};


export type MutationUpdateCompanyContactForCommunicationArgs = {
  input: UpdateCompanyContactForCommunicationInput;
};


export type MutationUpdateCompanyIdentitiesArgs = {
  input: UpdateCompanyIdentitiesInput;
};


export type MutationUpdateCompanyNameArgs = {
  input: UpdateCompanyNameInput;
};


export type MutationUpdateCompanyNoteArgs = {
  input: UpdateCompanyNoteInput;
};


export type MutationUpdateCompanyPersonalDataArgs = {
  input: UpdateCompanyPersonalDataInput;
};


export type MutationUpdateCpaRewardTariffTitleArgs = {
  input: UpdateCpaRewardTariffTitleInput;
};


export type MutationUpdateDocumentNoteArgs = {
  input: UpdateDocumentNoteInput;
};


export type MutationUpdateMediaCampaignArgs = {
  input: UpdateMediaCampaignInput;
};


export type MutationUpdatePartnerIdentitiesArgs = {
  input: UpdatePartnerIdentitiesInput;
};


export type MutationUpdatePartnerNoteArgs = {
  input: UpdatePartnerNoteInput;
};


export type MutationUpdatePlayerIdentitiesArgs = {
  input: UpdatePlayerIdentitiesInput;
};


export type MutationUpdatePlayerNoteArgs = {
  input: UpdatePlayerNoteInput;
};


export type MutationUpdatePlayerRankArgs = {
  input: UpdatePlayerRankInput;
};


export type MutationUpdateQualifiedReferralConfirmationSettingArgs = {
  input: UpdateQualifiedReferralConfirmationSettingInput;
};


export type MutationUpdateReferralLinkMediaItemNameArgs = {
  input: UpdateReferralLinkMediaItemNameInput;
};


export type MutationUploadFileArgs = {
  input: UploadFileInput;
};

export type Note = {
  __typename?: 'Note';
  text: Scalars['String']['output'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type Partner = {
  __typename?: 'Partner';
  accessToRevShareRewardTariffs: Array<AccessToRevShareRewardTariff>;
  contactForCommunication: ContactForCommunication;
  cpaReport: CpaAffiliateReportConnection;
  cpaRewardTariffs: PartnerCpaRewardTariffConnection;
  createdAt: Scalars['DateTime']['output'];
  defaultName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emailConfirmedAt?: Maybe<Scalars['Date']['output']>;
  exportCpaReport: Scalars['Url']['output'];
  exportRevShareReport: Scalars['Url']['output'];
  id: Scalars['ID']['output'];
  lastVisited?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['Locale']['output']>;
  mediaCampaigns: MediaCampaignConnection;
  name: Scalars['String']['output'];
  note?: Maybe<Note>;
  outgoingMoneyTransfer: AffiliateOutgoingMoneyTransfer;
  outgoingMoneyTransfers: AffiliateOutgoingMoneyTransferConnection;
  phone?: Maybe<Scalars['String']['output']>;
  phoneConfirmedAt?: Maybe<Scalars['Date']['output']>;
  playerAccount?: Maybe<AffiliatePlayerAccount>;
  promoCodeMediaItem: PromoCodeMediaItem;
  promoCodeMediaItems: PromoCodeMediaItemConnection;
  qualifiedReferralConfirmationSettings: QualifiedReferralConfirmationSettingConnection;
  referralLinkMediaItem: ReferralLinkMediaItem;
  referralLinkMediaItems: ReferralLinkMediaItemConnection;
  revShareReport: RevShareAffiliateReportConnection;
  revShareRewardTariffs: PartnerRevShareRewardTariffConnection;
  rewardTariffTypesInUse: Array<RewardTariffType>;
  trafficSource?: Maybe<Scalars['String']['output']>;
  twoFactorEnabled: Scalars['Boolean']['output'];
};


export type PartnerCpaReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: AffiliateReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type PartnerCpaRewardTariffsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PartnerExportCpaReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type PartnerExportRevShareReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type PartnerMediaCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PartnerOutgoingMoneyTransferArgs = {
  id: Scalars['ID']['input'];
};


export type PartnerOutgoingMoneyTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PartnerPromoCodeMediaItemArgs = {
  id: Scalars['ID']['input'];
};


export type PartnerPromoCodeMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PartnerQualifiedReferralConfirmationSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PartnerReferralLinkMediaItemArgs = {
  id: Scalars['ID']['input'];
};


export type PartnerReferralLinkMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type PartnerRevShareReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: AffiliateReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type PartnerRevShareRewardTariffsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerApplication = {
  __typename?: 'PartnerApplication';
  admin?: Maybe<Admin>;
  contactForCommunication: ContactForCommunication;
  createdAt: Scalars['DateTime']['output'];
  declineReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  partner: Partner;
  status: PartnerApplicationStatus;
  trafficSource: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PartnerApplicationConnection = {
  __typename?: 'PartnerApplicationConnection';
  edges: Array<PartnerApplicationEdge>;
  pageInfo: PageInfo;
};

export type PartnerApplicationEdge = {
  __typename?: 'PartnerApplicationEdge';
  cursor: Scalars['Cursor']['output'];
  node: PartnerApplication;
};

export enum PartnerApplicationStatus {
  Active = 'ACTIVE',
  Declined = 'DECLINED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type PartnerConnection = {
  __typename?: 'PartnerConnection';
  edges: Array<PartnerEdge>;
  pageInfo: PageInfo;
};

export type PartnerCpaRewardTariffConnection = {
  __typename?: 'PartnerCpaRewardTariffConnection';
  edges: Array<PartnerCpaRewardTariffEdge>;
  pageInfo: PageInfo;
};

export type PartnerCpaRewardTariffEdge = {
  __typename?: 'PartnerCpaRewardTariffEdge';
  cursor: Scalars['Cursor']['output'];
  node: CpaRewardTariff;
};

export type PartnerEdge = {
  __typename?: 'PartnerEdge';
  cursor: Scalars['Cursor']['output'];
  node: Partner;
};

export type PartnerRevShareRewardTariffConnection = {
  __typename?: 'PartnerRevShareRewardTariffConnection';
  edges: Array<PartnerRevShareRewardTariffEdge>;
  pageInfo: PageInfo;
};

export type PartnerRevShareRewardTariffEdge = {
  __typename?: 'PartnerRevShareRewardTariffEdge';
  cursor: Scalars['Cursor']['output'];
  node: RevShareRewardTariff;
};

export type Payment = {
  __typename?: 'Payment';
  admin?: Maybe<Admin>;
  createdAt: Scalars['DateTime']['output'];
  error?: Maybe<PaymentError>;
  externalPaymentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  paidSum?: Maybe<Sum>;
  processor: Scalars['PaymentProcessor']['output'];
  status: PaymentStatus;
  sum: Sum;
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  edges: Array<PaymentEdge>;
  pageInfo: PageInfo;
};

export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  cursor: Scalars['Cursor']['output'];
  node: Payment;
};

export type PaymentError = {
  __typename?: 'PaymentError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  account: Scalars['String']['output'];
  bank?: Maybe<Scalars['Bank']['output']>;
  crypto: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  maskedAccount: Scalars['String']['output'];
  name: Scalars['PaymentMethodName']['output'];
};

export type PaymentMethodConnection = {
  __typename?: 'PaymentMethodConnection';
  edges: Array<PaymentMethodEdge>;
  pageInfo: PageInfo;
};

export type PaymentMethodEdge = {
  __typename?: 'PaymentMethodEdge';
  cursor: Scalars['Cursor']['output'];
  node: PaymentMethod;
};

export enum PaymentStatus {
  Accepted = 'ACCEPTED',
  Awaiting = 'AWAITING',
  Complete = 'COMPLETE',
  Declined = 'DECLINED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  PartiallyAccepted = 'PARTIALLY_ACCEPTED',
  PartiallyPaid = 'PARTIALLY_PAID',
  Pending = 'PENDING',
  PresumablyFailed = 'PRESUMABLY_FAILED',
  Unknown = 'UNKNOWN'
}

export type PersonalBonus = BonusInterface & {
  __typename?: 'PersonalBonus';
  balance: Sum;
  cashout?: Maybe<Scalars['Int']['output']>;
  config: BonusConfig;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  minAccountBalanceAmount?: Maybe<Scalars['Float']['output']>;
  status: BonusStatus;
  sum: Sum;
  updatedAt: Scalars['DateTime']['output'];
  wager?: Maybe<Scalars['Int']['output']>;
  winSum?: Maybe<Sum>;
};

export type Player = {
  __typename?: 'Player';
  accounts: Array<Account>;
  activeAccountCurrency: Scalars['Currency']['output'];
  affiliate?: Maybe<Affiliate>;
  affiliateAccount?: Maybe<Affiliate>;
  availableDepositFilters: Array<Scalars['DepositFilterMember']['output']>;
  availableMoneyTransferFilters: Array<Scalars['MoneyTransferFilterMember']['output']>;
  availableWithdrawalFilters: Array<Scalars['WithdrawalFilterMember']['output']>;
  birthday?: Maybe<Scalars['Date']['output']>;
  bonuses: BonusConnection;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  defaultName: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deposits: DepositConnection;
  documents: DocumentConnection;
  email?: Maybe<Scalars['String']['output']>;
  emailConfirmedAt?: Maybe<Scalars['Date']['output']>;
  externalMoneyTransfer: ExternalMoneyTransfer;
  externalMoneyTransfers: ExternalMoneyTransferConnection;
  firstName?: Maybe<Scalars['String']['output']>;
  freespins: FreespinConnection;
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  internalOutgoingMoneyTransfersEnabled: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  lastRestrictions: LastRestrictions;
  lastVisited?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['Locale']['output']>;
  messages: MessageConnection;
  middleName?: Maybe<Scalars['String']['output']>;
  moneyTransferAccount?: Maybe<Scalars['String']['output']>;
  moneyTransfers: MoneyTransferConnection;
  name: Scalars['String']['output'];
  note?: Maybe<Note>;
  paymentMethods: PaymentMethodConnection;
  phone?: Maybe<Scalars['String']['output']>;
  phoneConfirmedAt?: Maybe<Scalars['Date']['output']>;
  rank: Scalars['PlayerRank']['output'];
  rankInProgress: Scalars['PlayerRank']['output'];
  restrictions: RestrictionConnection;
  signInRestriction?: Maybe<SignInRestriction>;
  signInRestrictions: SignInRestrictionConnection;
  state: PlayerState;
  statistic: PlayerStatistic;
  tournamentRebuys: TournamentRebuyConnection;
  trusted: Scalars['Boolean']['output'];
  twoFactorEnabled: Scalars['Boolean']['output'];
  withdrawalLimits: WithdrawalLimits;
  withdrawals: WithdrawalConnection;
};


export type PlayerAvailableDepositFiltersArgs = {
  usedFilters: Array<Scalars['DepositFilterMember']['input']>;
};


export type PlayerAvailableMoneyTransferFiltersArgs = {
  usedFilters: Array<Scalars['MoneyTransferFilterMember']['input']>;
};


export type PlayerAvailableWithdrawalFiltersArgs = {
  usedFilters: Array<Scalars['WithdrawalFilterMember']['input']>;
};


export type PlayerBonusesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerDepositsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<DepositFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerExternalMoneyTransferArgs = {
  id: Scalars['ID']['input'];
};


export type PlayerExternalMoneyTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerFreespinsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerMoneyTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<MoneyTransferFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerRestrictionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerSignInRestrictionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerTournamentRebuysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PlayerWithdrawalLimitsArgs = {
  currency?: Scalars['Currency']['input'];
};


export type PlayerWithdrawalsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<WithdrawalFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PlayerConnection = {
  __typename?: 'PlayerConnection';
  edges: Array<PlayerEdge>;
  pageInfo: PageInfo;
};

export type PlayerEdge = {
  __typename?: 'PlayerEdge';
  cursor: Scalars['Cursor']['output'];
  node: Player;
};

export enum PlayerState {
  Active = 'ACTIVE',
  Banned = 'BANNED',
  Deleted = 'DELETED',
  WillBeDeleted = 'WILL_BE_DELETED'
}

export type PlayerStatistic = {
  __typename?: 'PlayerStatistic';
  dailyWithdrawalSum: Sum;
  hasTransactions: Scalars['Boolean']['output'];
  monthlyCryptoWithdrawalSum: Sum;
  profit: Sum;
};

export type Postback = {
  __typename?: 'Postback';
  event: PostbackEvent;
  id: Scalars['ID']['output'];
  method: PostbackMethod;
  referralLinkMediaItem: ReferralLinkMediaItem;
  url: Scalars['Url']['output'];
};

export type PostbackDisabledNotification = {
  __typename?: 'PostbackDisabledNotification';
  id: Scalars['ID']['output'];
  postback: Postback;
};

export type PostbackDisabledNotificationConnection = {
  __typename?: 'PostbackDisabledNotificationConnection';
  edges: Array<PostbackDisabledNotificationEdge>;
  pageInfo: PageInfo;
};

export type PostbackDisabledNotificationEdge = {
  __typename?: 'PostbackDisabledNotificationEdge';
  cursor: Scalars['Cursor']['output'];
  node: PostbackDisabledNotification;
};

export enum PostbackEvent {
  FirstDeposit = 'FIRST_DEPOSIT',
  QualificationCheck = 'QUALIFICATION_CHECK',
  Registration = 'REGISTRATION',
  Reward = 'REWARD'
}

export enum PostbackMethod {
  Get = 'GET',
  Post = 'POST'
}

export type PromoCode = {
  __typename?: 'PromoCode';
  activation?: Maybe<PromoCodeActivation>;
  bonusAction?: Maybe<PromoCodeBonusAction>;
  freespinAction?: Maybe<PromoCodeFreespinAction>;
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
};

export type PromoCodeActivation = {
  __typename?: 'PromoCodeActivation';
  activated: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
};

export type PromoCodeBonus = BonusInterface & {
  __typename?: 'PromoCodeBonus';
  balance: Sum;
  config: BonusConfig;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  promoCode: PromoCode;
  status: BonusStatus;
  sum: Sum;
  updatedAt: Scalars['DateTime']['output'];
  winSum?: Maybe<Sum>;
};

export type PromoCodeBonusAction = {
  __typename?: 'PromoCodeBonusAction';
  amount: Sum;
  cashout?: Maybe<Scalars['Int']['output']>;
  minAccountBalanceAmount?: Maybe<Sum>;
  wager?: Maybe<Scalars['Int']['output']>;
};

export type PromoCodeFreespinAction = {
  __typename?: 'PromoCodeFreespinAction';
  bonusWager?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  currency: Scalars['Currency']['output'];
  game: Game;
  minAccountBalanceAmount?: Maybe<Sum>;
};

export type PromoCodeMediaItem = {
  __typename?: 'PromoCodeMediaItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mediaCampaign: MediaCampaign;
  promoCode: PromoCode;
  referralLink?: Maybe<ReferralLinkMediaItem>;
  updatedAt: Scalars['DateTime']['output'];
  usageType: PromoCodeUsageType;
};

export type PromoCodeMediaItemConnection = {
  __typename?: 'PromoCodeMediaItemConnection';
  edges: Array<PromoCodeMediaItemEdge>;
  pageInfo: PageInfo;
};

export type PromoCodeMediaItemEdge = {
  __typename?: 'PromoCodeMediaItemEdge';
  cursor: Scalars['Cursor']['output'];
  node: PromoCodeMediaItem;
};

export enum PromoCodeUsageType {
  AffiliatedPlayersOnly = 'AFFILIATED_PLAYERS_ONLY',
  AllPlayers = 'ALL_PLAYERS',
  NewPlayersOnly = 'NEW_PLAYERS_ONLY',
  Unknown = 'UNKNOWN'
}

export type QualifiedReferral = {
  __typename?: 'QualifiedReferral';
  affiliate: Affiliate;
  code: Scalars['ReferralLinkCode']['output'];
  createdAt: Scalars['DateTime']['output'];
  editable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  player: Player;
  review?: Maybe<ReferralReviewResultInterface>;
  status: QualifiedReferralStatus;
  suspectState: SuspectState;
  tariff: CpaRewardTariff;
  updatedAt: Scalars['DateTime']['output'];
};

export type QualifiedReferralConfirmationSetting = {
  __typename?: 'QualifiedReferralConfirmationSetting';
  affiliate: Affiliate;
  code?: Maybe<Scalars['ReferralLinkCode']['output']>;
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  type: QualifiedReferralConfirmationSettingType;
  updatedAt: Scalars['DateTime']['output'];
};

export type QualifiedReferralConfirmationSettingConnection = {
  __typename?: 'QualifiedReferralConfirmationSettingConnection';
  edges: Array<QualifiedReferralConfirmationSettingEdge>;
  pageInfo: PageInfo;
};

export type QualifiedReferralConfirmationSettingEdge = {
  __typename?: 'QualifiedReferralConfirmationSettingEdge';
  cursor: Scalars['Cursor']['output'];
  node: QualifiedReferralConfirmationSetting;
};

export enum QualifiedReferralConfirmationSettingType {
  AutomaticConfirmationVerified = 'AUTOMATIC_CONFIRMATION_VERIFIED',
  AutomaticConfirmationWithoutVerification = 'AUTOMATIC_CONFIRMATION_WITHOUT_VERIFICATION'
}

export type QualifiedReferralConnection = {
  __typename?: 'QualifiedReferralConnection';
  edges: Array<QualifiedReferralEdge>;
  pageInfo: PageInfo;
};

export type QualifiedReferralEdge = {
  __typename?: 'QualifiedReferralEdge';
  cursor: Scalars['Cursor']['output'];
  node: QualifiedReferral;
};

export enum QualifiedReferralStatus {
  Approved = 'APPROVED',
  AutoApproved = 'AUTO_APPROVED',
  Moderation = 'MODERATION',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Review = 'REVIEW',
  Unknown = 'UNKNOWN'
}

export type Query = {
  __typename?: 'Query';
  account: Account;
  accountEventGroup: AccountEventGroup;
  admin: Admin;
  admins: AdminConnection;
  bonus: BonusInterface;
  companies: CompanyConnection;
  company: Company;
  companyAffiliateApplication: CompanyAffiliateApplication;
  companyAffiliateApplications: CompanyAffiliateApplicationConnection;
  companyApplication: CompanyApplication;
  companyApplications: CompanyApplicationConnection;
  convertCurrency: Sum;
  countries: Array<Scalars['CountryCode']['output']>;
  cpaGeneralReport: CpaGeneralReportConnection;
  cpaRewardTariff: CpaRewardTariff;
  cpaRewardTariffs: CpaRewardTariffConnection;
  deposit: Deposit;
  exportCpaGeneralReport: Scalars['Url']['output'];
  exportRevShareGeneralReport: Scalars['Url']['output'];
  freespin: Freespin;
  games: GameConnection;
  generateReferralLinkCode: Scalars['ReferralLinkCode']['output'];
  mediaCampaign: MediaCampaign;
  mediaCampaigns: MediaCampaignConnection;
  mediaItem: MediaItemInterface;
  mediaItems: MediaItemConnection;
  message: Message;
  moneyTransfer: MoneyTransfer;
  partner: Partner;
  partnerApplication: PartnerApplication;
  partnerApplications: PartnerApplicationConnection;
  partners: PartnerConnection;
  paymentProcessors: Array<Scalars['PaymentProcessor']['output']>;
  player: Player;
  players: PlayerConnection;
  postbackDisabledNotifications: PostbackDisabledNotificationConnection;
  promoCodeMediaItem: PromoCodeMediaItem;
  promoCodeMediaItemByToken: PromoCodeMediaItem;
  promoCodeMediaItems: PromoCodeMediaItemConnection;
  qualifiedReferral: QualifiedReferral;
  qualifiedReferralConfirmationSetting: QualifiedReferralConfirmationSetting;
  qualifiedReferralConfirmationSettings: QualifiedReferralConfirmationSettingConnection;
  qualifiedReferrals: QualifiedReferralConnection;
  referralLinkMediaItemByUrl: ReferralLinkMediaItem;
  restriction: RestrictionInterface;
  revShareGeneralReport: RevShareGeneralReportConnection;
  revShareRewardTariff: RevShareRewardTariff;
  revShareRewardTariffs: RevShareRewardTariffConnection;
  reward: Reward;
  rewards: RewardConnection;
  signInRestriction: SignInRestriction;
  tournamentRebuy: TournamentRebuy;
  withdrawal: Withdrawal;
  withdrawals: WithdrawalConnection;
};


export type QueryAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAccountEventGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdminArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdminsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};


export type QueryBonusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyAffiliateApplicationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyAffiliateApplicationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCompanyApplicationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyApplicationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryConvertCurrencyArgs = {
  from: SumInput;
  to: Scalars['Currency']['input'];
};


export type QueryCpaGeneralReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: GeneralReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type QueryCpaRewardTariffArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCpaRewardTariffsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDepositArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportCpaGeneralReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type QueryExportRevShareGeneralReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFreespinArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGamesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};


export type QueryMediaCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMediaCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMediaItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMessageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMoneyTransferArgs = {
  currentPlayerId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type QueryPartnerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPartnerApplicationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPartnerApplicationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPartnersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};


export type QueryPlayerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlayersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};


export type QueryPostbackDisabledNotificationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPromoCodeMediaItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPromoCodeMediaItemByTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryPromoCodeMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQualifiedReferralArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQualifiedReferralConfirmationSettingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQualifiedReferralConfirmationSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQualifiedReferralsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReferralLinkMediaItemByUrlArgs = {
  url: Scalars['Url']['input'];
};


export type QueryRestrictionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRevShareGeneralReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: GeneralReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRevShareRewardTariffArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRevShareRewardTariffsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRewardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRewardsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  tariffType: RewardTariffType;
};


export type QuerySignInRestrictionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTournamentRebuyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWithdrawalArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWithdrawalsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WithdrawalStatus>;
};

export type ReceiveGiftRestriction = RestrictionInterface & {
  __typename?: 'ReceiveGiftRestriction';
  bypassRestrictionIfApproved: Scalars['Boolean']['output'];
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lockedAt: Scalars['DateTime']['output'];
  reason: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ReferralLinkMediaItem = MediaItemInterface & {
  __typename?: 'ReferralLinkMediaItem';
  /** @deprecated Use ReferralLinkMediaItem.owner. */
  affiliate: Affiliate;
  code: Scalars['ReferralLinkCode']['output'];
  cpaReport: CpaMediaItemReportConnection;
  createdAt: Scalars['DateTime']['output'];
  exportCpaReport: Scalars['Url']['output'];
  exportRevShareReport: Scalars['Url']['output'];
  id: Scalars['ID']['output'];
  mediaCampaign: MediaCampaign;
  mediaItemRewardTariff: MediaItemRewardTariffInterface;
  name: Scalars['String']['output'];
  owner: ReferralLinkMediaItemOwner;
  /** @deprecated Use ReferralLinkMediaItem.owner. */
  partner: Partner;
  postbackCount: Scalars['Int']['output'];
  referralLink: Scalars['Url']['output'];
  revShareReport: RevShareMediaItemReportConnection;
};


export type ReferralLinkMediaItemCpaReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: MediaItemReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type ReferralLinkMediaItemExportCpaReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
  showAllTimeCpa: Scalars['Boolean']['input'];
};


export type ReferralLinkMediaItemExportRevShareReportArgs = {
  currency: Scalars['Currency']['input'];
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type ReferralLinkMediaItemRevShareReportArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  currency: Scalars['Currency']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordering: MediaItemReportOrderingInput;
  period: DatePeriodInput;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ReferralLinkMediaItemConnection = {
  __typename?: 'ReferralLinkMediaItemConnection';
  edges: Array<ReferralLinkMediaItemEdge>;
  pageInfo: PageInfo;
};

export type ReferralLinkMediaItemEdge = {
  __typename?: 'ReferralLinkMediaItemEdge';
  cursor: Scalars['Cursor']['output'];
  node: ReferralLinkMediaItem;
};

export type ReferralLinkMediaItemInput = {
  code: Scalars['ReferralLinkCode']['input'];
  mediaCampaignId: Scalars['ID']['input'];
  mediaItemRewardTariff: MediaItemRewardTariffInput;
  name: Scalars['String']['input'];
};

export type ReferralLinkMediaItemOwner = Company | CompanyAffiliate | Partner;

export type ReferralReviewResultInterface = {
  passed: Scalars['Boolean']['output'];
};

export enum ReferralStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Hold = 'HOLD',
  Paid = 'PAID',
  Qualified = 'QUALIFIED',
  Rejected = 'REJECTED',
  RevShare = 'REV_SHARE',
  Unpaid = 'UNPAID'
}

export type RegisteredPlayerStatistic = {
  __typename?: 'RegisteredPlayerStatistic';
  newDeposit: Sum;
  newProfit: Sum;
  newWithdrawal: Sum;
};

export type RejectAllQualifiedReferralsPayload = {
  __typename?: 'RejectAllQualifiedReferralsPayload';
  void?: Maybe<Scalars['Void']['output']>;
};

export type RejectQualifiedReferralInput = {
  id: Scalars['ID']['input'];
};

export type RejectQualifiedReferralPayload = {
  __typename?: 'RejectQualifiedReferralPayload';
  referral: QualifiedReferral;
};

export type ResetAdminPasswordInput = {
  id: Scalars['ID']['input'];
  passwordSetUri: Scalars['String']['input'];
};

export type ResetAdminPasswordPayload = {
  __typename?: 'ResetAdminPasswordPayload';
  admin: Admin;
};

export type ResetCompanyTwoFactorInput = {
  id: Scalars['ID']['input'];
};

export type ResetCompanyTwoFactorPayload = {
  __typename?: 'ResetCompanyTwoFactorPayload';
  company: Company;
};

export type ResetPartnerNameInput = {
  partnerId: Scalars['ID']['input'];
};

export type ResetPartnerNamePayload = {
  __typename?: 'ResetPartnerNamePayload';
  partner: Partner;
};

export type ResetPartnerTwoFactorInput = {
  partnerId: Scalars['ID']['input'];
};

export type ResetPartnerTwoFactorPayload = {
  __typename?: 'ResetPartnerTwoFactorPayload';
  partner: Partner;
};

export type ResetPlayerNameInput = {
  id: Scalars['ID']['input'];
};

export type ResetPlayerNamePayload = {
  __typename?: 'ResetPlayerNamePayload';
  player: Player;
};

export type ResetPlayerTwoFactorInput = {
  id: Scalars['ID']['input'];
};

export type ResetPlayerTwoFactorPayload = {
  __typename?: 'ResetPlayerTwoFactorPayload';
  player: Player;
};

export type RestrictionConnection = {
  __typename?: 'RestrictionConnection';
  edges: Array<RestrictionEdge>;
  pageInfo: PageInfo;
};

export type RestrictionEdge = {
  __typename?: 'RestrictionEdge';
  cursor: Scalars['Cursor']['output'];
  node: RestrictionInterface;
};

export type RestrictionInterface = {
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RevShareAffiliateReportConnection = {
  __typename?: 'RevShareAffiliateReportConnection';
  edges: Array<RevShareAffiliateReportRowEdge>;
  pageInfo: PageInfo;
  total?: Maybe<RevShareAffiliateReportTotal>;
};

export type RevShareAffiliateReportRow = {
  __typename?: 'RevShareAffiliateReportRow';
  adminFee: Sum;
  affiliateUpcomingReward: Sum;
  marketingStatistic: MarketingStatistic;
  mediaItem: MediaItemInterface;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: RevSharePlayerStatistic;
};

export type RevShareAffiliateReportRowEdge = {
  __typename?: 'RevShareAffiliateReportRowEdge';
  cursor: Scalars['Cursor']['output'];
  node: RevShareAffiliateReportRow;
};

export type RevShareAffiliateReportTotal = {
  __typename?: 'RevShareAffiliateReportTotal';
  adminFee: Sum;
  affiliateUpcomingReward: Sum;
  marketingStatistic: MarketingStatistic;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: RevSharePlayerStatistic;
};

export type RevShareCompanyPartnerReportCompanyRow = {
  __typename?: 'RevShareCompanyPartnerReportCompanyRow';
  adminFee: Sum;
  affiliateUpcomingReward: Sum;
  company: Company;
  marketingStatistic: MarketingStatistic;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: RevSharePlayerStatistic;
};

export type RevShareCompanyPartnerReportConnection = {
  __typename?: 'RevShareCompanyPartnerReportConnection';
  company?: Maybe<RevShareCompanyPartnerReportCompanyRow>;
  edges: Array<RevShareCompanyPartnerReportRowEdge>;
  pageInfo: PageInfo;
  total?: Maybe<RevShareCompanyPartnerReportTotal>;
};

export type RevShareCompanyPartnerReportRow = {
  __typename?: 'RevShareCompanyPartnerReportRow';
  adminFee: Sum;
  affiliateUpcomingReward: Sum;
  companyPartner: CompanyAffiliate;
  marketingStatistic: MarketingStatistic;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: RevSharePlayerStatistic;
};

export type RevShareCompanyPartnerReportRowEdge = {
  __typename?: 'RevShareCompanyPartnerReportRowEdge';
  cursor: Scalars['Cursor']['output'];
  node: RevShareCompanyPartnerReportRow;
};

export type RevShareCompanyPartnerReportTotal = {
  __typename?: 'RevShareCompanyPartnerReportTotal';
  adminFee: Sum;
  affiliateUpcomingReward: Sum;
  marketingStatistic: MarketingStatistic;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: RevSharePlayerStatistic;
};

export type RevShareGeneralReportConnection = {
  __typename?: 'RevShareGeneralReportConnection';
  edges: Array<RevShareGeneralReportRowEdge>;
  pageInfo: PageInfo;
  total?: Maybe<RevShareGeneralReportTotal>;
};

export type RevShareGeneralReportRow = {
  __typename?: 'RevShareGeneralReportRow';
  adminFee: Sum;
  affiliate: Affiliate;
  affiliateRewardStatistic: AffiliateRewardStatistic;
  marketingStatistic: MarketingStatistic;
  /** @deprecated Use the `affiliate` field instead. */
  partner: Partner;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: RevSharePlayerStatistic;
};

export type RevShareGeneralReportRowEdge = {
  __typename?: 'RevShareGeneralReportRowEdge';
  cursor: Scalars['Cursor']['output'];
  node: RevShareGeneralReportRow;
};

export type RevShareGeneralReportTotal = {
  __typename?: 'RevShareGeneralReportTotal';
  adminFee: Sum;
  affiliateRewardStatistic: AffiliateRewardStatistic;
  marketingStatistic: MarketingStatistic;
  playerRegisteredStatistic: RegisteredPlayerStatistic;
  playerStatistic: RevSharePlayerStatistic;
};

export type RevShareMediaItemReportConnection = {
  __typename?: 'RevShareMediaItemReportConnection';
  edges: Array<RevShareMediaItemReportRowEdge>;
  pageInfo: PageInfo;
  total?: Maybe<RevShareMediaItemReportTotal>;
};

export type RevShareMediaItemReportRow = {
  __typename?: 'RevShareMediaItemReportRow';
  adminFee: Sum;
  affiliateUpcomingReward: Sum;
  firstDeposit: Scalars['Boolean']['output'];
  player: Player;
  playerStatistic: RevSharePlayerStatistic;
  redeposits: Scalars['Int']['output'];
};

export type RevShareMediaItemReportRowEdge = {
  __typename?: 'RevShareMediaItemReportRowEdge';
  cursor: Scalars['Cursor']['output'];
  node: RevShareMediaItemReportRow;
};

export type RevShareMediaItemReportTotal = {
  __typename?: 'RevShareMediaItemReportTotal';
  adminFee: Sum;
  affiliateRewardStatistic: AffiliateRewardStatistic;
  affiliateUpcomingReward: Sum;
  playerStatistic: RevSharePlayerStatistic;
  redeposits: Scalars['Int']['output'];
};

export type RevSharePlayerStatistic = {
  __typename?: 'RevSharePlayerStatistic';
  allDeposit: Sum;
  allRedeposit: Sum;
  averageDeposit: Sum;
  bonus: Sum;
  depositFee: Sum;
  financialIndicators: FinancialIndicators;
  incomingMoneyTransfer: Sum;
  jackpot: Sum;
  tournamentPrize: Sum;
  withdrawal: Sum;
  withdrawalFee: Sum;
};

export type RevShareRewardTariff = RewardTariffInterface & {
  __typename?: 'RevShareRewardTariff';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  percent: Scalars['Percent']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RevShareRewardTariffConnection = {
  __typename?: 'RevShareRewardTariffConnection';
  edges: Array<RevShareRewardTariffEdge>;
  pageInfo: PageInfo;
};

export type RevShareRewardTariffEdge = {
  __typename?: 'RevShareRewardTariffEdge';
  cursor: Scalars['Cursor']['output'];
  node: RevShareRewardTariff;
};

export type RevertDepositToPresumablyDeclinedInput = {
  id: Scalars['ID']['input'];
};

export type RevertDepositToPresumablyDeclinedPayload = {
  __typename?: 'RevertDepositToPresumablyDeclinedPayload';
  deposit: Deposit;
};

export type Reward = {
  __typename?: 'Reward';
  affiliate: Affiliate;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  paidSum?: Maybe<Sum>;
  status: RewardStatus;
  sum: Sum;
  suspectState: SuspectState;
  tariffType: RewardTariffType;
  updatedAt: Scalars['DateTime']['output'];
};

export type RewardConnection = {
  __typename?: 'RewardConnection';
  edges: Array<RewardEdge>;
  pageInfo: PageInfo;
};

export type RewardEdge = {
  __typename?: 'RewardEdge';
  cursor: Scalars['Cursor']['output'];
  node: Reward;
};

export enum RewardStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type RewardTariffInterface = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum RewardTariffType {
  Cpa = 'CPA',
  RevShare = 'REV_SHARE'
}

export type SignInRestriction = {
  __typename?: 'SignInRestriction';
  cancellable: Scalars['Boolean']['output'];
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lockedAt: Scalars['DateTime']['output'];
  protected: Scalars['Boolean']['output'];
  reason: Scalars['String']['output'];
  status: SignInRestrictionStatus;
};

export type SignInRestrictionConnection = {
  __typename?: 'SignInRestrictionConnection';
  edges: Array<SignInRestrictionEdge>;
  pageInfo: PageInfo;
};

export type SignInRestrictionEdge = {
  __typename?: 'SignInRestrictionEdge';
  cursor: Scalars['Cursor']['output'];
  node: SignInRestriction;
};

export enum SignInRestrictionStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Expired = 'EXPIRED',
  Unknown = 'UNKNOWN'
}

export type SuccessReferralReviewResult = ReferralReviewResultInterface & {
  __typename?: 'SuccessReferralReviewResult';
  passed: Scalars['Boolean']['output'];
};

export type SuccessWithoutVerificationReferralReviewResult = ReferralReviewResultInterface & {
  __typename?: 'SuccessWithoutVerificationReferralReviewResult';
  passed: Scalars['Boolean']['output'];
};

export type Sum = {
  __typename?: 'Sum';
  amount: Scalars['Float']['output'];
  currency: Scalars['Currency']['output'];
};

export type SumFlow = {
  __typename?: 'SumFlow';
  direction?: Maybe<SumFlowDirection>;
  sum: Sum;
};

export enum SumFlowDirection {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type SumInput = {
  amount: Scalars['Float']['input'];
  currency: Scalars['Currency']['input'];
};

export type SuspectQualifiedReferralInput = {
  id: Scalars['ID']['input'];
};

export type SuspectQualifiedReferralPayload = {
  __typename?: 'SuspectQualifiedReferralPayload';
  referral: QualifiedReferral;
};

export type SuspectRewardInput = {
  id: Scalars['ID']['input'];
};

export type SuspectRewardPayload = {
  __typename?: 'SuspectRewardPayload';
  reward: Reward;
};

export enum SuspectState {
  Eligible = 'ELIGIBLE',
  NotEligible = 'NOT_ELIGIBLE',
  Suspicious = 'SUSPICIOUS'
}

export type SuspendCompanyAffiliateApplicationInput = {
  id: Scalars['ID']['input'];
};

export type SuspendCompanyAffiliateApplicationPayload = {
  __typename?: 'SuspendCompanyAffiliateApplicationPayload';
  companyAffiliateApplication: CompanyAffiliateApplication;
};

export type SuspendCompanyApplicationInput = {
  id: Scalars['ID']['input'];
};

export type SuspendCompanyApplicationPayload = {
  __typename?: 'SuspendCompanyApplicationPayload';
  companyApplication: CompanyApplication;
};

export type SuspendPartnerApplicationInput = {
  id: Scalars['ID']['input'];
};

export type SuspendPartnerApplicationPayload = {
  __typename?: 'SuspendPartnerApplicationPayload';
  partnerApplication: PartnerApplication;
};

export type SuspendWithdrawalInput = {
  id: Scalars['ID']['input'];
};

export type SuspendWithdrawalPayload = {
  __typename?: 'SuspendWithdrawalPayload';
  withdrawal: Withdrawal;
};

export type Tournament = {
  __typename?: 'Tournament';
  /** @deprecated Use `TournamentInterface` interface. */
  id: Scalars['ID']['output'];
  /** @deprecated Use `TournamentInterface` interface. */
  name: Scalars['String']['output'];
};

export type TournamentAccountEvent = AccountEventInterface & {
  __typename?: 'TournamentAccountEvent';
  billingBalance: BalanceChange;
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use `tournamentCompatible` field. */
  tournament: Tournament;
  tournamentCompatible: TournamentInterface;
  type: Scalars['TournamentAccountEventType']['output'];
};

export type TournamentInterface = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TournamentRebuy = {
  __typename?: 'TournamentRebuy';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sum: Sum;
  tournament: TournamentInterface;
};

export type TournamentRebuyConnection = {
  __typename?: 'TournamentRebuyConnection';
  edges: Array<TournamentRebuyEdge>;
  pageInfo: PageInfo;
};

export type TournamentRebuyEdge = {
  __typename?: 'TournamentRebuyEdge';
  cursor: Scalars['Cursor']['output'];
  node: TournamentRebuy;
};

export type UndeletePlayerInput = {
  id: Scalars['ID']['input'];
};

export type UndeletePlayerPayload = {
  __typename?: 'UndeletePlayerPayload';
  player: Player;
};

export type UnsuspectQualifiedReferralInput = {
  id: Scalars['ID']['input'];
};

export type UnsuspectQualifiedReferralPayload = {
  __typename?: 'UnsuspectQualifiedReferralPayload';
  referral: QualifiedReferral;
};

export type UnsuspectRewardInput = {
  id: Scalars['ID']['input'];
};

export type UnsuspectRewardPayload = {
  __typename?: 'UnsuspectRewardPayload';
  reward: Reward;
};

export type UpdateAccessToPartnerRevShareRewardTariffsInput = {
  accessedRevShareTariffIds: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateAccessToPartnerRevShareRewardTariffsPayload = {
  __typename?: 'UpdateAccessToPartnerRevShareRewardTariffsPayload';
  accessToRevShareRewardTariffs: Array<AccessToRevShareRewardTariff>;
  id: Scalars['ID']['output'];
};

export type UpdateAdminInput = {
  firstName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  roles: Array<Scalars['AdminRole']['input']>;
};

export type UpdateAdminPayload = {
  __typename?: 'UpdateAdminPayload';
  admin: Admin;
};

export type UpdateCompanyAffiliateApplicationInput = {
  affiliateId: Scalars['ID']['input'];
  applicationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyAffiliateApplicationItemInput = {
  applicationId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyAffiliateApplicationItemPayload = {
  __typename?: 'UpdateCompanyAffiliateApplicationItemPayload';
  item: CompanyAffiliateApplicationItem;
};

export type UpdateCompanyAffiliateApplicationPayload = {
  __typename?: 'UpdateCompanyAffiliateApplicationPayload';
  affiliate: CompanyAffiliate;
};

export type UpdateCompanyContactForCommunicationInput = {
  id: Scalars['ID']['input'];
  type: Scalars['ContactForCommunicationType']['input'];
  value: Scalars['String']['input'];
};

export type UpdateCompanyContactForCommunicationPayload = {
  __typename?: 'UpdateCompanyContactForCommunicationPayload';
  company: Company;
};

export type UpdateCompanyIdentitiesInput = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  /** @deprecated Field moved to `UpdateCompanyPersonalDataInput` */
  website?: InputMaybe<Scalars['Url']['input']>;
};

export type UpdateCompanyIdentitiesPayload = {
  __typename?: 'UpdateCompanyIdentitiesPayload';
  company: Company;
};

export type UpdateCompanyNameInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateCompanyNamePayload = {
  __typename?: 'UpdateCompanyNamePayload';
  company: Company;
};

export type UpdateCompanyNoteInput = {
  id: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type UpdateCompanyNotePayload = {
  __typename?: 'UpdateCompanyNotePayload';
  company: Company;
};

export type UpdateCompanyPersonalDataInput = {
  id: Scalars['ID']['input'];
  type: Scalars['ContactForCommunicationType']['input'];
  value: Scalars['String']['input'];
  website?: InputMaybe<Scalars['Url']['input']>;
};

export type UpdateCompanyPersonalDataPayload = {
  __typename?: 'UpdateCompanyPersonalDataPayload';
  company: Company;
};

export type UpdateCpaRewardTariffTitleInput = {
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type UpdateCpaRewardTariffTitlePayload = {
  __typename?: 'UpdateCpaRewardTariffTitlePayload';
  cpaRewardTariff: CpaRewardTariff;
};

export type UpdateDocumentNoteInput = {
  documentId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};

export type UpdateDocumentNotePayload = {
  __typename?: 'UpdateDocumentNotePayload';
  document: Document;
};

export type UpdateMediaCampaignInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  url: Scalars['Url']['input'];
};

export type UpdateMediaCampaignPayload = {
  __typename?: 'UpdateMediaCampaignPayload';
  mediaCampaign: MediaCampaign;
};

export type UpdatePartnerIdentitiesInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['ID']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartnerIdentitiesPayload = {
  __typename?: 'UpdatePartnerIdentitiesPayload';
  partner: Partner;
};

export type UpdatePartnerNoteInput = {
  partnerId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type UpdatePartnerNotePayload = {
  __typename?: 'UpdatePartnerNotePayload';
  partner: Partner;
};

export type UpdatePlayerIdentitiesInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlayerIdentitiesPayload = {
  __typename?: 'UpdatePlayerIdentitiesPayload';
  player: Player;
};

export type UpdatePlayerNoteInput = {
  playerId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type UpdatePlayerNotePayload = {
  __typename?: 'UpdatePlayerNotePayload';
  player: Player;
};

export type UpdatePlayerRankInput = {
  id: Scalars['ID']['input'];
  rank: Scalars['PlayerRank']['input'];
};

export type UpdatePlayerRankPayload = {
  __typename?: 'UpdatePlayerRankPayload';
  player: Player;
};

export type UpdateQualifiedReferralConfirmationSettingInput = {
  comment: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  type: QualifiedReferralConfirmationSettingType;
};

export type UpdateQualifiedReferralConfirmationSettingPayload = {
  __typename?: 'UpdateQualifiedReferralConfirmationSettingPayload';
  qualifiedReferralConfirmationSetting: QualifiedReferralConfirmationSetting;
};

export type UpdateReferralLinkMediaItemNameInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateReferralLinkMediaItemNamePayload = {
  __typename?: 'UpdateReferralLinkMediaItemNamePayload';
  referralLinkMediaItem: ReferralLinkMediaItem;
};

export type UploadFileInput = {
  file: Scalars['Upload']['input'];
};

export type UploadFilePayload = {
  __typename?: 'UploadFilePayload';
  file: File;
};

export type Wager = {
  __typename?: 'Wager';
  id: Scalars['ID']['output'];
  status: WagerStatus;
  sum: Sum;
  wonSum: Sum;
};

export enum WagerStatus {
  InProgress = 'IN_PROGRESS',
  InQueue = 'IN_QUEUE',
  Unknown = 'UNKNOWN',
  Won = 'WON',
  WonDueToWithdrawal = 'WON_DUE_TO_WITHDRAWAL'
}

export type Withdrawal = {
  __typename?: 'Withdrawal';
  account?: Maybe<Account>;
  actor: WithdrawalActor;
  admin?: Maybe<Admin>;
  createdAt: Scalars['DateTime']['output'];
  hasUnfinalizedPayments: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  paidSum?: Maybe<Sum>;
  paymentMethod: PaymentMethod;
  payments: PaymentConnection;
  /** @deprecated Use Withdrawal.actor. */
  player: Player;
  remainingSum: Sum;
  status: WithdrawalStatus;
  sum: Sum;
  updatedAt: Scalars['DateTime']['output'];
};


export type WithdrawalPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WithdrawalAccountEvent = AccountEventInterface & {
  __typename?: 'WithdrawalAccountEvent';
  billingBalance: BalanceChange;
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['WithdrawalAccountEventType']['output'];
};

export type WithdrawalActor = Company | Partner | Player;

export type WithdrawalConnection = {
  __typename?: 'WithdrawalConnection';
  edges: Array<WithdrawalEdge>;
  pageInfo: PageInfo;
};

export type WithdrawalEdge = {
  __typename?: 'WithdrawalEdge';
  cursor: Scalars['Cursor']['output'];
  node: Withdrawal;
};

export type WithdrawalFilterInput = {
  paymentMethodName?: InputMaybe<Scalars['PaymentMethodName']['input']>;
  status?: InputMaybe<WithdrawalStatus>;
  withdrawalId?: InputMaybe<Scalars['ID']['input']>;
};

export type WithdrawalLimits = {
  __typename?: 'WithdrawalLimits';
  dailyLimit: Sum;
  monthlyCryptoLimit: Sum;
};

export type WithdrawalRestriction = RestrictionInterface & {
  __typename?: 'WithdrawalRestriction';
  bypassRestrictionIfApproved: Scalars['Boolean']['output'];
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum WithdrawalStatus {
  Accepted = 'ACCEPTED',
  Automatic = 'AUTOMATIC',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  InterruptedByCancel = 'INTERRUPTED_BY_CANCEL',
  InterruptedByDecline = 'INTERRUPTED_BY_DECLINE',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type XTournament = TournamentInterface & {
  __typename?: 'XTournament';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export const ListAllOperations = {
  Query: {
    GetCompaniesForDropdown: 'GetCompaniesForDropdown',
    GetCompanyAffiliateReferralLinkMediaItems: 'GetCompanyAffiliateReferralLinkMediaItems',
    GetCpaTariffs: 'GetCpaTariffs',
    GetConvertCurrency: 'GetConvertCurrency',
    GetPaymentProcessors: 'GetPaymentProcessors',
    GetWithdrawalPayout: 'GetWithdrawalPayout',
    GetWithdrawalRestrictionAndLimits: 'GetWithdrawalRestrictionAndLimits',
    GetMediaCampaignsForDropdown: 'GetMediaCampaignsForDropdown',
    GetPartnersForDropdown: 'GetPartnersForDropdown',
    GetRevShareTariffs: 'GetRevShareTariffs',
    GetAdmin: 'GetAdmin',
    GetAdmins: 'GetAdmins',
    GetCompanies: 'GetCompanies',
    GetCompanyAffiliatePromoCodeItem: 'GetCompanyAffiliatePromoCodeItem',
    GetCompanyAffiliatePromoCodesBlock: 'GetCompanyAffiliatePromoCodesBlock',
    GetCompanyAffiliateReferralLinksBlock: 'GetCompanyAffiliateReferralLinksBlock',
    GetCompanyAffiliates: 'GetCompanyAffiliates',
    GetCompanyAdditionalData: 'GetCompanyAdditionalData',
    GetCompanyMediaCampaignsBlock: 'GetCompanyMediaCampaignsBlock',
    GetCompanyReferralLinkMediaItem: 'GetCompanyReferralLinkMediaItem',
    GetCompanyReferralLinkMediaItemName: 'GetCompanyReferralLinkMediaItemName',
    GetCompanyPromoCodeMediaItem: 'GetCompanyPromoCodeMediaItem',
    GetCompanyPromoCodeMediaItems: 'GetCompanyPromoCodeMediaItems',
    GetCompanyPromoCodeMediaItemsBlock: 'GetCompanyPromoCodeMediaItemsBlock',
    GetCompanyReferralLinks: 'GetCompanyReferralLinks',
    GetCompanyReferralLinksBlock: 'GetCompanyReferralLinksBlock',
    GetCompanyOutgoingMoneyTransfer: 'GetCompanyOutgoingMoneyTransfer',
    GetCompanyOutgoingMoneyTransfers: 'GetCompanyOutgoingMoneyTransfers',
    GetCompanyOutgoingMoneyTransfersBlock: 'GetCompanyOutgoingMoneyTransfersBlock',
    GetCompanyData: 'GetCompanyData',
    GetCompanyAffiliateApplication: 'GetCompanyAffiliateApplication',
    GetCompanyAffiliateApplications: 'GetCompanyAffiliateApplications',
    GetCompanyApplication: 'GetCompanyApplication',
    GetCompanyApplications: 'GetCompanyApplications',
    GetMediaCampaign: 'GetMediaCampaign',
    GetMediaCampaigns: 'GetMediaCampaigns',
    GetMediaCampaignForPromoCode: 'GetMediaCampaignForPromoCode',
    GetMediaItemPromoCode: 'GetMediaItemPromoCode',
    GetMediaItemsPromoCode: 'GetMediaItemsPromoCode',
    GetCompanyAffiliatesForReferralLinkMediaItem: 'GetCompanyAffiliatesForReferralLinkMediaItem',
    GetMediaItemReferralLink: 'GetMediaItemReferralLink',
    GetMediaItemsReferralLink: 'GetMediaItemsReferralLink',
    GetPostbackReferralLink: 'GetPostbackReferralLink',
    GetPostbackDisabledNotifications: 'GetPostbackDisabledNotifications',
    GetPostbackDisabledNotificationsBlock: 'GetPostbackDisabledNotificationsBlock',
    GetPartnerOutgoingMoneyTransfer: 'GetPartnerOutgoingMoneyTransfer',
    GetPartnerOutgoingMoneyTransfers: 'GetPartnerOutgoingMoneyTransfers',
    GetPartnerOutgoingMoneyTransfersBlock: 'GetPartnerOutgoingMoneyTransfersBlock',
    GetPartnerAdditionalData: 'GetPartnerAdditionalData',
    GetPartnerMediaCampaigns: 'GetPartnerMediaCampaigns',
    GetPartnerReferralLinkMediaItem: 'GetPartnerReferralLinkMediaItem',
    GetPartnerReferralLinkMediaItemName: 'GetPartnerReferralLinkMediaItemName',
    GetPartnerPromoCodeMediaItem: 'GetPartnerPromoCodeMediaItem',
    GetPartnerPromoCodeMediaItems: 'GetPartnerPromoCodeMediaItems',
    GetPartnerPromoCodeMediaItemsBlock: 'GetPartnerPromoCodeMediaItemsBlock',
    GetPartnerReferralLinkMediaItems: 'GetPartnerReferralLinkMediaItems',
    GetPartnerReferralLinkMediaItemsBlock: 'GetPartnerReferralLinkMediaItemsBlock',
    GetPartnerData: 'GetPartnerData',
    GetPartnerApplication: 'GetPartnerApplication',
    GetPartnerApplications: 'GetPartnerApplications',
    GetPartners: 'GetPartners',
    GetAccountEventGroup: 'GetAccountEventGroup',
    GetAccountEventGroups: 'GetAccountEventGroups',
    GetAccountEventGroupsBlock: 'GetAccountEventGroupsBlock',
    GetAccounts: 'GetAccounts',
    GetPlayerAnalytics: 'GetPlayerAnalytics',
    GetBonus: 'GetBonus',
    GetPlayerCashback: 'GetPlayerCashback',
    GetBonuses: 'GetBonuses',
    GetBonusesBlock: 'GetBonusesBlock',
    GetPlayerReceiveGiftRestriction: 'GetPlayerReceiveGiftRestriction',
    GetDeposit: 'GetDeposit',
    GetAvailableDepositFilters: 'GetAvailableDepositFilters',
    GetDeposits: 'GetDeposits',
    GetDepositsBlock: 'GetDepositsBlock',
    GetPlayerDocuments: 'GetPlayerDocuments',
    GetPlayerExternalMoneyTransfer: 'GetPlayerExternalMoneyTransfer',
    GetPlayerExternalMoneyTransfers: 'GetPlayerExternalMoneyTransfers',
    GetPlayerExternalMoneyTransfersBlock: 'GetPlayerExternalMoneyTransfersBlock',
    GetActiveAccountCurrency: 'GetActiveAccountCurrency',
    GetFreespin: 'GetFreespin',
    GetFreespins: 'GetFreespins',
    GetFreespinsBlock: 'GetFreespinsBlock',
    GetPlayerLocale: 'GetPlayerLocale',
    GetPlayerMessage: 'GetPlayerMessage',
    GetPlayerMessages: 'GetPlayerMessages',
    GetPlayerMessagesBlock: 'GetPlayerMessagesBlock',
    GetPlayerAdditionalData: 'GetPlayerAdditionalData',
    GetLastRestrictionsCreateForm: 'GetLastRestrictionsCreateForm',
    GetLastRestrictions: 'GetLastRestrictions',
    GetRestriction: 'GetRestriction',
    GetRestrictions: 'GetRestrictions',
    GetRestrictionsBlock: 'GetRestrictionsBlock',
    GetSignInRestriction: 'GetSignInRestriction',
    GetSignInRestrictionHistory: 'GetSignInRestrictionHistory',
    GetSignInRestrictionHistoryBlock: 'GetSignInRestrictionHistoryBlock',
    GetTournamentRebuy: 'GetTournamentRebuy',
    GetTournamentRebuys: 'GetTournamentRebuys',
    GetTournamentRebuysBlock: 'GetTournamentRebuysBlock',
    GetTransfer: 'GetTransfer',
    GetAvailableMoneyTransferFilters: 'GetAvailableMoneyTransferFilters',
    GetTransfers: 'GetTransfers',
    GetWithdrawal: 'GetWithdrawal',
    GetWithdrawalPayments: 'GetWithdrawalPayments',
    GetWithdrawalSidebar: 'GetWithdrawalSidebar',
    GetAvailableWithdrawalFilters: 'GetAvailableWithdrawalFilters',
    GetWithdrawals: 'GetWithdrawals',
    GetWithdrawalsBlock: 'GetWithdrawalsBlock',
    GetPlayerData: 'GetPlayerData',
    GetPlayers: 'GetPlayers',
    GetCompanyReferralCodes: 'GetCompanyReferralCodes',
    GetPartnerReferralCodes: 'GetPartnerReferralCodes',
    GetCompanyQualifiedReferralConfirmationSettings: 'GetCompanyQualifiedReferralConfirmationSettings',
    GetPartnerQualifiedReferralConfirmationSettings: 'GetPartnerQualifiedReferralConfirmationSettings',
    GetQualifiedReferralConfirmationSetting: 'GetQualifiedReferralConfirmationSetting',
    GetQualifiedReferralConfirmationSettings: 'GetQualifiedReferralConfirmationSettings',
    GetQualifiedReferrals: 'GetQualifiedReferrals',
    GetCountries: 'GetCountries',
    GetCpaRewardTariff: 'GetCpaRewardTariff',
    GetCpaRewardTariffs: 'GetCpaRewardTariffs',
    GetRevShareRewardTariff: 'GetRevShareRewardTariff',
    GetRevShareRewardTariffs: 'GetRevShareRewardTariffs',
    GetRewardItem: 'GetRewardItem',
    GetRewards: 'GetRewards',
    GetCpaGeneralReport: 'GetCpaGeneralReport',
    GetExportCpaGeneralReport: 'GetExportCpaGeneralReport',
    GetExportRevShareGeneralReport: 'GetExportRevShareGeneralReport',
    GetRevShareGeneralReport: 'GetRevShareGeneralReport',
    GetCpaCompanyAffiliateReport: 'GetCpaCompanyAffiliateReport',
    GetExportCompanyAffiliateCpaReport: 'GetExportCompanyAffiliateCpaReport',
    GetCpaCompanyPartnersReport: 'GetCpaCompanyPartnersReport',
    GetExportCpaCompanyPartnerReport: 'GetExportCpaCompanyPartnerReport',
    GetCpaCompanyReport: 'GetCpaCompanyReport',
    GetExportCompanyCpaReport: 'GetExportCompanyCpaReport',
    GetCpaPartnerReport: 'GetCpaPartnerReport',
    GetExportPartnerCpaReport: 'GetExportPartnerCpaReport',
    GetCpaReferralLinkReport: 'GetCpaReferralLinkReport',
    GetExportReferralLinkMediaItemCpaReport: 'GetExportReferralLinkMediaItemCpaReport',
    GetExportCompanyAffiliateRevShareReport: 'GetExportCompanyAffiliateRevShareReport',
    GetRevShareCompanyAffiliateReport: 'GetRevShareCompanyAffiliateReport',
    GetExportRevShareCompanyPartnerReport: 'GetExportRevShareCompanyPartnerReport',
    GetRevShareCompanyPartnersReport: 'GetRevShareCompanyPartnersReport',
    GetExportCompanyRevShareReport: 'GetExportCompanyRevShareReport',
    GetRevShareCompanyReport: 'GetRevShareCompanyReport',
    GetExportPartnerRevShareReport: 'GetExportPartnerRevShareReport',
    GetRevSharePartnerReport: 'GetRevSharePartnerReport',
    GetExportReferralLinkMediaItemRevShareReport: 'GetExportReferralLinkMediaItemRevShareReport',
    GetRevShareReferralLinkReport: 'GetRevShareReferralLinkReport',
    GetWithdrawalItem: 'GetWithdrawalItem',
    GetWithdrawalListPayments: 'GetWithdrawalListPayments',
    GetWithdrawalPaymentsStatistic: 'GetWithdrawalPaymentsStatistic',
    GetWithdrawalsList: 'GetWithdrawalsList',
    GenerateReferralLinkCode: 'GenerateReferralLinkCode',
    GetGames: 'GetGames',
    GetGenerateReferralLinkCode: 'GetGenerateReferralLinkCode',
    GetPromoCodeMediaItemByToken: 'GetPromoCodeMediaItemByToken',
    GetReferralLinkMediaItemByUrl: 'GetReferralLinkMediaItemByUrl'
  },
  Mutation: {
    DeleteAdmin: 'DeleteAdmin',
    ResetAdminPassword: 'ResetAdminPassword',
    UpdateAdmin: 'UpdateAdmin',
    CreateAdmin: 'CreateAdmin',
    AddCompanyAffiliates: 'AddCompanyAffiliates',
    CreateCompanyAffiliatePromoCodeMediaItem: 'CreateCompanyAffiliatePromoCodeMediaItem',
    UpdateCompanyIdentities: 'UpdateCompanyIdentities',
    UpdateCompanyPersonalData: 'UpdateCompanyPersonalData',
    ApproveCompanyPlayerAccount: 'ApproveCompanyPlayerAccount',
    DeclineCompanyPlayerAccount: 'DeclineCompanyPlayerAccount',
    ResetCompanyTwoFactor: 'ResetCompanyTwoFactor',
    UpdateCompanyName: 'UpdateCompanyName',
    UpdateCompanyNote: 'UpdateCompanyNote',
    UpdateCompanyReferralLinkMediaItemName: 'UpdateCompanyReferralLinkMediaItemName',
    UpdateCompanyAffiliateApplicationItem: 'UpdateCompanyAffiliateApplicationItem',
    AssignCompanyAffiliateApplication: 'AssignCompanyAffiliateApplication',
    ConfirmCompanyAffiliateApplication: 'ConfirmCompanyAffiliateApplication',
    SuspendCompanyAffiliateApplication: 'SuspendCompanyAffiliateApplication',
    AcceptCompanyApplication: 'AcceptCompanyApplication',
    AssignCompanyApplication: 'AssignCompanyApplication',
    SuspendCompanyApplication: 'SuspendCompanyApplication',
    DeclineCompanyApplication: 'DeclineCompanyApplication',
    CreateMediaCampaign: 'CreateMediaCampaign',
    UpdateMediaCampaign: 'UpdateMediaCampaign',
    CreatePromoCodeMediaItem: 'CreatePromoCodeMediaItem',
    CreateCompanyAffiliateReferralLinkMediaItem: 'CreateCompanyAffiliateReferralLinkMediaItem',
    UpdateReferralLinkMediaItemName: 'UpdateReferralLinkMediaItemName',
    DeleteAllPostbackDisabledNotifications: 'DeleteAllPostbackDisabledNotifications',
    EnableAllPostbacks: 'EnableAllPostbacks',
    DeletePostbackDisabledNotification: 'DeletePostbackDisabledNotification',
    EnablePostback: 'EnablePostback',
    UpdatePartnerIdentities: 'UpdatePartnerIdentities',
    ApprovePartnerPlayerAccount: 'ApprovePartnerPlayerAccount',
    DeclinePartnerPlayerAccount: 'DeclinePartnerPlayerAccount',
    ResetPartnerName: 'ResetPartnerName',
    ResetPartnerTwoFactor: 'ResetPartnerTwoFactor',
    UpdatePartnerReferralLinkMediaItemName: 'UpdatePartnerReferralLinkMediaItemName',
    UpdatePartnerNote: 'UpdatePartnerNote',
    DeclinePartnerApplication: 'DeclinePartnerApplication',
    AcceptPartnerApplication: 'AcceptPartnerApplication',
    AssignPartnerApplication: 'AssignPartnerApplication',
    SuspendPartnerApplication: 'SuspendPartnerApplication',
    CancelGiftBonus: 'CancelGiftBonus',
    CreateGiftBonus: 'CreateGiftBonus',
    AcceptDeposit: 'AcceptDeposit',
    RevertDepositToPresumablyDeclined: 'RevertDepositToPresumablyDeclined',
    CreateDocuments: 'CreateDocuments',
    DeleteDocument: 'DeleteDocument',
    UpdateDocumentNote: 'UpdateDocumentNote',
    UploadFile: 'UploadFile',
    CreateFreespin: 'CreateFreespin',
    CreateMessage: 'CreateMessage',
    DeleteMessage: 'DeleteMessage',
    UpdatePlayerIdentities: 'UpdatePlayerIdentities',
    CancelSignInRestriction: 'CancelSignInRestriction',
    CreateSingInRestriction: 'CreateSingInRestriction',
    DeletePlayer: 'DeletePlayer',
    UndeletePlayer: 'UndeletePlayer',
    ResetPlayerName: 'ResetPlayerName',
    ResetPlayerTwoFactor: 'ResetPlayerTwoFactor',
    DisablePlayerInternalOutgoingMoneyTransfers: 'DisablePlayerInternalOutgoingMoneyTransfers',
    EnablePlayerInternalOutgoingMoneyTransfers: 'EnablePlayerInternalOutgoingMoneyTransfers',
    UpdatePlayerRank: 'UpdatePlayerRank',
    CreateBadBankRestriction: 'CreateBadBankRestriction',
    CreateChargeBackRestriction: 'CreateChargeBackRestriction',
    CreateReceiveGiftRestriction: 'CreateReceiveGiftRestriction',
    CreateWithdrawalRestriction: 'CreateWithdrawalRestriction',
    CancelBadBankRestriction: 'CancelBadBankRestriction',
    CancelChargeBackRestriction: 'CancelChargeBackRestriction',
    CancelReceiveGiftRestriction: 'CancelReceiveGiftRestriction',
    CancelWithdrawalRestriction: 'CancelWithdrawalRestriction',
    AcceptPayment: 'AcceptPayment',
    AssignWithdrawal: 'AssignWithdrawal',
    DeclineWithdrawal: 'DeclineWithdrawal',
    SuspendWithdrawal: 'SuspendWithdrawal',
    DeclinePayment: 'DeclinePayment',
    CreatePayment: 'CreatePayment',
    UpdatePlayerNote: 'UpdatePlayerNote',
    DeleteQualifiedReferralConfirmationSetting: 'DeleteQualifiedReferralConfirmationSetting',
    CreateQualifiedReferralConfirmationSetting: 'CreateQualifiedReferralConfirmationSetting',
    UpdateQualifiedReferralConfirmationSetting: 'UpdateQualifiedReferralConfirmationSetting',
    ApproveQualifiedReferral: 'ApproveQualifiedReferral',
    RejectQualifiedReferral: 'RejectQualifiedReferral',
    SuspectQualifiedReferral: 'SuspectQualifiedReferral',
    UnsuspectQualifiedReferral: 'UnsuspectQualifiedReferral',
    ApproveAllQualifiedReferrals: 'ApproveAllQualifiedReferrals',
    RejectAllQualifiedReferrals: 'RejectAllQualifiedReferrals',
    UpdateCpaRewardTariffTitle: 'UpdateCpaRewardTariffTitle',
    CreateCpaRewardTariff: 'CreateCpaRewardTariff',
    CreateRevShareRewardTariff: 'CreateRevShareRewardTariff',
    AcceptReward: 'AcceptReward',
    AcceptAllRewards: 'AcceptAllRewards',
    SuspectReward: 'SuspectReward',
    UnsuspectReward: 'UnsuspectReward',
    DeclineReward: 'DeclineReward',
    AcceptWithdrawalPayment: 'AcceptWithdrawalPayment',
    AssignWithdrawalInList: 'AssignWithdrawalInList',
    DeclineWithdrawalInList: 'DeclineWithdrawalInList',
    SuspendWithdrawalInList: 'SuspendWithdrawalInList',
    DeclineWithdrawalPayment: 'DeclineWithdrawalPayment',
    CreateWithdaralListPayment: 'CreateWithdaralListPayment',
    CreateCompanyReferralLinkMediaItem: 'CreateCompanyReferralLinkMediaItem',
    CreatePartnerReferralLinkMediaItem: 'CreatePartnerReferralLinkMediaItem'
  },
  Fragment: {
    CompanyListData: 'CompanyListData',
    CompanyAffiliatePromoCodeBasicData: 'CompanyAffiliatePromoCodeBasicData',
    CompanyAffiliateReferralLinkBasicData: 'CompanyAffiliateReferralLinkBasicData',
    CompanyAffiliateBasicData: 'CompanyAffiliateBasicData',
    CompanyAdditionalData: 'CompanyAdditionalData',
    CompanyMediaCampaignBasicData: 'CompanyMediaCampaignBasicData',
    CompanyReferralLinkMediaItemDetailsData: 'CompanyReferralLinkMediaItemDetailsData',
    CompanyPromoCodeMediaItemsBasicData: 'CompanyPromoCodeMediaItemsBasicData',
    CompanyPromoCodeMediaItemsData: 'CompanyPromoCodeMediaItemsData',
    CompanyReferralLinksBasicData: 'CompanyReferralLinksBasicData',
    CompanyReferralLinksData: 'CompanyReferralLinksData',
    CompanyOutgoingMoneyTransferBasicData: 'CompanyOutgoingMoneyTransferBasicData',
    CompanyOutgoingMoneyTransferData: 'CompanyOutgoingMoneyTransferData',
    CompanyAffiliateApplicationsData: 'CompanyAffiliateApplicationsData',
    CompanyApplicationData: 'CompanyApplicationData',
    MediaCampaignData: 'MediaCampaignData',
    MediaCampaignForPromoCodeData: 'MediaCampaignForPromoCodeData',
    PostbackReferralLinkData: 'PostbackReferralLinkData',
    GetPostbackDisabledNotificationData: 'GetPostbackDisabledNotificationData',
    PartnerOutgoingMoneyTransferBasicData: 'PartnerOutgoingMoneyTransferBasicData',
    PartnerOutgoingMoneyTransferData: 'PartnerOutgoingMoneyTransferData',
    PartnerAdditionalData: 'PartnerAdditionalData',
    PartnerMediaCampaignData: 'PartnerMediaCampaignData',
    PartnerReferralLinkMediaItemDetailsData: 'PartnerReferralLinkMediaItemDetailsData',
    PartnerPromoCodeMediaItemsBasicData: 'PartnerPromoCodeMediaItemsBasicData',
    PartnerPromoCodeMediaItemsData: 'PartnerPromoCodeMediaItemsData',
    PartnerReferralLinkMediaItemsBasicData: 'PartnerReferralLinkMediaItemsBasicData',
    PartnerReferralLinkMediaItemsData: 'PartnerReferralLinkMediaItemsData',
    PartnerApplicationData: 'PartnerApplicationData',
    PartnerListData: 'PartnerListData',
    AccountData: 'AccountData',
    AccountEventBasicData: 'AccountEventBasicData',
    AccountEventData: 'AccountEventData',
    AccountEventGroupBasicData: 'AccountEventGroupBasicData',
    AccountEventGroupData: 'AccountEventGroupData',
    BalanceChangeData: 'BalanceChangeData',
    PlayerAccountStatisticData: 'PlayerAccountStatisticData',
    PlayerAccountStatisticFixedCurrencyData: 'PlayerAccountStatisticFixedCurrencyData',
    PlayerStatisticData: 'PlayerStatisticData',
    PlayerCashbackData: 'PlayerCashbackData',
    BonusBasicData: 'BonusBasicData',
    BonusCashbackData: 'BonusCashbackData',
    BonusData: 'BonusData',
    BonusFreespinData: 'BonusFreespinData',
    BonusGiftData: 'BonusGiftData',
    BonusPromoCodeData: 'BonusPromoCodeData',
    CashbackAccountData: 'CashbackAccountData',
    UpdatedDepositData: 'UpdatedDepositData',
    DepositBasicData: 'DepositBasicData',
    DepositData: 'DepositData',
    DocumentData: 'DocumentData',
    PlayerExternalMoneyTransferBasicData: 'PlayerExternalMoneyTransferBasicData',
    PlayerExternalMoneyTransferData: 'PlayerExternalMoneyTransferData',
    FreespinBasicData: 'FreespinBasicData',
    FreespinData: 'FreespinData',
    PlayerLocaleData: 'PlayerLocaleData',
    MessageBasicData: 'MessageBasicData',
    MessageData: 'MessageData',
    PlayerAdditionalData: 'PlayerAdditionalData',
    SignInRestrictionData: 'SignInRestrictionData',
    BadBankRestrictionData: 'BadBankRestrictionData',
    ChargeBackRestrictionData: 'ChargeBackRestrictionData',
    ReceiveGiftRestrictionData: 'ReceiveGiftRestrictionData',
    WithdrawalRestrictionData: 'WithdrawalRestrictionData',
    LastBadBankRestrictionData: 'LastBadBankRestrictionData',
    LastChargeBackRestrictionData: 'LastChargeBackRestrictionData',
    LastReceiveGiftRestrictionData: 'LastReceiveGiftRestrictionData',
    LastRestrictionData: 'LastRestrictionData',
    LastRestrictionsData: 'LastRestrictionsData',
    LastWithdrawalRestrictionData: 'LastWithdrawalRestrictionData',
    RestrictionsBasicData: 'RestrictionsBasicData',
    RestrictionsData: 'RestrictionsData',
    SignInRestrictionHistoryBlockData: 'SignInRestrictionHistoryBlockData',
    SignInRestrictionHistoryData: 'SignInRestrictionHistoryData',
    TournamentRebuysBasicData: 'TournamentRebuysBasicData',
    TournamentRebuysData: 'TournamentRebuysData',
    TransferData: 'TransferData',
    PaymentData: 'PaymentData',
    WithdrawalBasicData: 'WithdrawalBasicData',
    WithdrawalData: 'WithdrawalData',
    PlayerListData: 'PlayerListData',
    QualifiedReferralConfirmationSettingData: 'QualifiedReferralConfirmationSettingData',
    QualifiedReferralData: 'QualifiedReferralData',
    CpaRewardTariffData: 'CpaRewardTariffData',
    RevShareRewardTariffData: 'RevShareRewardTariffData',
    RewardData: 'RewardData',
    CpaGeneralReportData: 'CpaGeneralReportData',
    CpaGeneralReportTotalData: 'CpaGeneralReportTotalData',
    RevShareGeneralReportData: 'RevShareGeneralReportData',
    RevShareGeneralReportTotalData: 'RevShareGeneralReportTotalData',
    CpaCompanyAffiliateReportData: 'CpaCompanyAffiliateReportData',
    CpaCompanyAffiliateReportTotalData: 'CpaCompanyAffiliateReportTotalData',
    CpaCompanyPartnersReportCompanyData: 'CpaCompanyPartnersReportCompanyData',
    CpaCompanyPartnersReportData: 'CpaCompanyPartnersReportData',
    CpaCompanyPartnersReportTotalData: 'CpaCompanyPartnersReportTotalData',
    CpaCompanyReportData: 'CpaCompanyReportData',
    CpaCompanyReportTotalData: 'CpaCompanyReportTotalData',
    CpaPartnerReportData: 'CpaPartnerReportData',
    CpaPartnerReportTotalData: 'CpaPartnerReportTotalData',
    CpaReferralLinkReportData: 'CpaReferralLinkReportData',
    CpaReferralLinkReportTotalData: 'CpaReferralLinkReportTotalData',
    RevShareCompanyAffiliateReportData: 'RevShareCompanyAffiliateReportData',
    RevShareCompanyAffiliateReportTotalData: 'RevShareCompanyAffiliateReportTotalData',
    RevShareCompanyPartnersReportCompanyData: 'RevShareCompanyPartnersReportCompanyData',
    RevShareCompanyPartnersReportData: 'RevShareCompanyPartnersReportData',
    RevShareCompanyPartnersReportTotalData: 'RevShareCompanyPartnersReportTotalData',
    RevShareCompanyReportData: 'RevShareCompanyReportData',
    RevShareCompanyReportTotalData: 'RevShareCompanyReportTotalData',
    RevSharePartnerReportData: 'RevSharePartnerReportData',
    RevSharePartnerReportTotalData: 'RevSharePartnerReportTotalData',
    RevShareReferralLinkReportData: 'RevShareReferralLinkReportData',
    RevShareReferralLinkReportTotalData: 'RevShareReferralLinkReportTotalData',
    AffiliateRewardStatisticData: 'AffiliateRewardStatisticData',
    CpaPlayerStatisticData: 'CpaPlayerStatisticData',
    CpaPlayerStatusStatisticData: 'CpaPlayerStatusStatisticData',
    MarketingStatisticData: 'MarketingStatisticData',
    RegisteredPlayerStatisticData: 'RegisteredPlayerStatisticData',
    RevSharePlayerStatisticData: 'RevSharePlayerStatisticData',
    WithdrawalItemData: 'WithdrawalItemData',
    WithdrawalListPaymentData: 'WithdrawalListPaymentData',
    WithdrawalListData: 'WithdrawalListData',
    AdminData: 'AdminData',
    AffiliateReferralLinkMediaItemRewardTariffData: 'AffiliateReferralLinkMediaItemRewardTariffData',
    CompanyBasicData: 'CompanyBasicData',
    MediaItemPromoCodeBasicData: 'MediaItemPromoCodeBasicData',
    MediaItemPromoCodeData: 'MediaItemPromoCodeData',
    MediaItemReferralLinkData: 'MediaItemReferralLinkData',
    NoteData: 'NoteData',
    PageInfoData: 'PageInfoData',
    PartnerBasicData: 'PartnerBasicData',
    PlayerBasicData: 'PlayerBasicData',
    PlayerData: 'PlayerData',
    SumData: 'SumData'
  }
}