import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetReferralLinkMediaItemByUrlVariables = SchemaTypes.Exact<{
  url: SchemaTypes.Scalars['Url']['input'];
}>;


export type GetReferralLinkMediaItemByUrl = { __typename: 'Query', referralLinkMediaItemByUrl: { __typename: 'ReferralLinkMediaItem', id: string, mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string } } };


export const GetReferralLinkMediaItemByUrlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetReferralLinkMediaItemByUrl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"url"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Url"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItemByUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"url"},"value":{"kind":"Variable","name":{"kind":"Name","value":"url"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"mediaCampaign"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetReferralLinkMediaItemByUrl__
 *
 * To run a query within a React component, call `useGetReferralLinkMediaItemByUrl` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralLinkMediaItemByUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralLinkMediaItemByUrl({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetReferralLinkMediaItemByUrl(baseOptions: ApolloReactHooks.QueryHookOptions<GetReferralLinkMediaItemByUrl, GetReferralLinkMediaItemByUrlVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetReferralLinkMediaItemByUrl, GetReferralLinkMediaItemByUrlVariables>(GetReferralLinkMediaItemByUrlDocument, options);
      }
export function useGetReferralLinkMediaItemByUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReferralLinkMediaItemByUrl, GetReferralLinkMediaItemByUrlVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetReferralLinkMediaItemByUrl, GetReferralLinkMediaItemByUrlVariables>(GetReferralLinkMediaItemByUrlDocument, options);
        }
export type GetReferralLinkMediaItemByUrlHookResult = ReturnType<typeof useGetReferralLinkMediaItemByUrl>;
export type GetReferralLinkMediaItemByUrlLazyQueryHookResult = ReturnType<typeof useGetReferralLinkMediaItemByUrlLazyQuery>;
export type GetReferralLinkMediaItemByUrlQueryResult = Apollo.QueryResult<GetReferralLinkMediaItemByUrl, GetReferralLinkMediaItemByUrlVariables>;