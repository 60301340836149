import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { Route } from 'src/router/routes.const';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { generatePath } from 'react-router';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { twoEmDash } from 'src/const';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import { RevShareReferralLinkReportData } from '../../../queries/generated/RevShareReferralLinkReportData';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { AddTwoLinedSubTitle } from '../../../../../components/AddTwoLinedSubTitle';
import { getFirstDepositInfo } from '../../../../helpers';

const columnHelper = createColumnHelper<RevShareReferralLinkReportData>();

const columnsSource: ColumnsSource<RevShareReferralLinkReportData> = [
  columnHelper.accessor(ColumnId.Player, {
    id: ColumnId.Player,
    header: mapColumnIdToName[ColumnId.Player],
    cell: ({ row }) => {
      if (!row.original.player) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name, email, phone } = row.original.player;
      const playerUrl = generatePath(Route.PLAYER, { id });

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          subTitle={
            <AddTwoLinedSubTitle firstValue={email} secondValue={phone} />
          }
          route={playerUrl}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.FirstDeposit, {
    id: ColumnId.FirstDeposit,
    header: mapColumnIdToName[ColumnId.FirstDeposit],
    cell: ({ row }) =>
      MayBeNullCell(getFirstDepositInfo(row.original.firstDeposit), twoEmDash),
  }),
  columnHelper.accessor(ColumnId.AllDeposit, {
    id: ColumnId.AllDeposit,
    header: mapColumnIdToName[ColumnId.AllDeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.AllRedeposit, {
    id: ColumnId.AllRedeposit,
    header: mapColumnIdToName[ColumnId.AllRedeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Redeposits, {
    id: ColumnId.Redeposits,
    header: mapColumnIdToName[ColumnId.Redeposits],
    cell: ({ row }) => formatNumberWithRuLocale(row.original.redeposits),
  }),
  columnHelper.accessor(ColumnId.IncomingMoneyTransfer, {
    id: ColumnId.IncomingMoneyTransfer,
    header: mapColumnIdToName[ColumnId.IncomingMoneyTransfer],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.incomingMoneyTransfer;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Withdrawal, {
    id: ColumnId.Withdrawal,
    header: mapColumnIdToName[ColumnId.Withdrawal],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.DepositFee, {
    id: ColumnId.DepositFee,
    header: mapColumnIdToName[ColumnId.DepositFee],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.depositFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.WithdrawalFee, {
    id: ColumnId.WithdrawalFee,
    header: mapColumnIdToName[ColumnId.WithdrawalFee],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawalFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.AdminFee, {
    id: ColumnId.AdminFee,
    header: mapColumnIdToName[ColumnId.AdminFee],
    cell: ({ row }) => {
      const { amount, currency } = row.original.adminFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Bonus, {
    id: ColumnId.Bonus,
    header: mapColumnIdToName[ColumnId.Bonus],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.bonus;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.TournamentPrize, {
    id: ColumnId.TournamentPrize,
    header: mapColumnIdToName[ColumnId.TournamentPrize],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.tournamentPrize;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.Jackpot, {
    id: ColumnId.Jackpot,
    header: mapColumnIdToName[ColumnId.Jackpot],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.jackpot;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.NGR, {
    id: ColumnId.NGR,
    header: mapColumnIdToName[ColumnId.NGR],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Profit, {
    id: ColumnId.Profit,
    header: mapColumnIdToName[ColumnId.Profit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.AffiliateUpcomingReward, {
    id: ColumnId.AffiliateUpcomingReward,
    header: mapColumnIdToName[ColumnId.AffiliateUpcomingReward],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateUpcomingReward;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetRevShareReferralLinksColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
