import { FC } from 'react';
import { useModalManageData } from 'ui/Modal';
import { PromoCodeModalDetails } from 'commonComponents/PromoCodeModalDetails';
import { useGetMediaItemPromoCode } from './queries/generated/GetMediaItemPromoCode';

interface MediaItemPromoCodeModalProps {
  mediaItemPromoCodeId: string;
}
export const MediaItemPromoCodeModal: FC<MediaItemPromoCodeModalProps> = ({
  mediaItemPromoCodeId,
}) => {
  const { data, loading, error } = useGetMediaItemPromoCode({
    variables: { id: mediaItemPromoCodeId },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-media-item-promo-code-error' },
    hasData: !!data,
  });

  const promoCodeMediaItem = data?.promoCodeMediaItem;

  if (!promoCodeMediaItem) {
    return null;
  }

  return (
    <PromoCodeModalDetails
      promoCodeMediaItem={promoCodeMediaItem}
      shouldDisplayRefCode
    />
  );
};
