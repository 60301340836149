import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { generatePath } from 'react-router';
import { Route as RouteEnum } from 'src/router/routes.const';
import { useGetMessagesColumns } from './hooks/useGetMessagesColumns';
import MessagesTable from './components/MessagesTable/MessagesTable';

interface MessagesProps {
  id: string;
}

const MessagesBlock: FC<MessagesProps> = ({ id }) => {
  const { columns } = useGetMessagesColumns(false);

  return (
    <MessagesTable
      playerId={id}
      columns={columns}
      routeToCustomPage={generatePath(RouteEnum.PLAYER_MESSAGES, { id })}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(MessagesBlock);
