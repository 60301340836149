import { FC } from 'react';
import { TabsWrapper, Tabs } from 'ui/Modal';
import Privilege from 'commonComponents/Privilege';
import Ban from 'src/components/Ban';
import Delete from 'commonComponents/Delete';
import UnBan from 'commonComponents/UnBan';
import { useAuth, UserPrivilege } from 'commonComponents/Auth';
import { PlayerBasicData } from '../../../../../../queries/generated/PlayerBasicData';
import { useChangePlayerState } from './useChangePlayerState';

interface ChangePlayerStateProps {
  id: string;
  signInRestriction?: PlayerBasicData['signInRestriction'];
}

const ChangePlayerState: FC<ChangePlayerStateProps> = ({
  id,
  signInRestriction,
}) => {
  const auth = useAuth();
  const {
    onCancelSignInRestriction,
    onCreateSingInRestriction,
    onDeletePlayer,
    loading,
    error,
  } = useChangePlayerState({ id });
  const isRenderTab = auth.privileges.areEveryGranted({
    privileges: [
      UserPrivilege.DeletePlayer,
      UserPrivilege.CreateSignInRestriction,
    ],
  });
  let activeTabDefault;
  const isHavePrivilegeForDeletePlayer = auth.privileges.isSomeGranted({
    privileges: [UserPrivilege.DeletePlayer],
  });

  if (!isRenderTab && isHavePrivilegeForDeletePlayer) {
    activeTabDefault = 1;
  }

  const tabs: Array<Tabs> = [
    {
      title: isRenderTab ? 'Блокировка' : '',
      content: signInRestriction ? (
        <UnBan
          onCancelSignInRestriction={onCancelSignInRestriction}
          error={error}
          isLoading={loading}
          canCancel={signInRestriction.cancellable}
          isProtected={signInRestriction.protected}
          signInRestrictionExpiredAt={signInRestriction.expiredAt}
          signInRestrictionReason={signInRestriction.reason}
        />
      ) : (
        <Privilege privileges={[UserPrivilege.CreateSignInRestriction]}>
          <Ban
            onCreateSingInRestriction={onCreateSingInRestriction}
            error={error}
            isLoading={loading}
          />
        </Privilege>
      ),
    },
    {
      title: isRenderTab ? 'Удаление' : '',
      content: (
        <Privilege privileges={[UserPrivilege.DeletePlayer]}>
          <Delete
            error={error}
            isLoading={loading}
            onDeleteClient={onDeletePlayer}
          />
        </Privilege>
      ),
    },
  ];

  return <TabsWrapper tabs={tabs} activeTabDefault={activeTabDefault} />;
};

export default ChangePlayerState;
