import { FC } from 'react';
import { ClientDataBlock } from 'commonComponents/ClientDataBlock/ClientDataBlock';
import { withBlockProvider } from 'ui/Block/store';
import { usePartnerData } from './hooks/usePartnerData';

interface PartnerDataBlockProps {
  id: string;
}

const Component: FC<PartnerDataBlockProps> = ({
  id,
}: PartnerDataBlockProps) => {
  const { refetchPartnerData, clientData } = usePartnerData({ id });

  return (
    <ClientDataBlock
      blockId="partnerData"
      title="Данные партнера"
      onUpdate={refetchPartnerData}
      data={clientData}
    />
  );
};

export const PartnerDataBlock = withBlockProvider(Component);
