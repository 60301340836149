import { FC, ReactElement } from 'react';
import styles from './PromoCodeActionBlockContainer.module.scss';

interface PromoCodeActionBlockContainerProps {
  title: string;
  children: ReactElement;
}

export const PromoCodeActionBlockContainer: FC<
  PromoCodeActionBlockContainerProps
> = ({ title, children }) => (
  <div className={styles.container}>
    <span className={styles.blockTitle}>{title}</span>
    <div className={styles.content}>{children}</div>
  </div>
);
