import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { AccountEventData } from '../../../../queries/fragments/generated/AccountEventData';
import { getEventNameFromEvent } from '../../../../helpers';

import { getRowsByEvent } from './helpers';
import { SumTitle } from '../SumTitle';

interface AccountEventGroupItemProps {
  event: AccountEventData;
}

export const AccountEventGroupItem: FC<AccountEventGroupItemProps> = ({
  event,
}) => {
  const leftTitle = getEventNameFromEvent(event);

  return (
    <DataCard
      withBorder
      header={{
        leftTitle: leftTitle || undefined,
        leftSubTitle: `#${event.entityId}`,
        rightTitle: <SumTitle event={event} />,
      }}
      rows={getRowsByEvent(event)}
    />
  );
};
