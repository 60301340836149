import { FC, useContext } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { nbsp } from '../../../../../../const';
import styles from '../../../../../PartnerApplications/components/PartnerApplicationModal/components/ButtonsUpdatePartnerApplication/ButtonsUpdatePartnerApplication.module.scss';
import { CompanyAffiliateApplicationContext } from '../../store';
import { isProcessedAffiliate } from './helpers/isProcessedAffiliate';
import { useButtonsHandlers } from './hooks/useButtonsHandlers';

interface UpdateCompanyAffiliateApplicationButtonsProps {
  companyAffiliateApplicationId: string;
  hasAssignButton: boolean;
  hasConfirmButton: boolean;
  hasSuspendButton: boolean;
}

export const UpdateCompanyAffiliateApplicationButtons: FC<
  UpdateCompanyAffiliateApplicationButtonsProps
> = ({
  companyAffiliateApplicationId,
  hasAssignButton,
  hasConfirmButton,
  hasSuspendButton,
}) => {
  const { state } = useContext(CompanyAffiliateApplicationContext);

  const {
    handleAssignApplication,
    assignCompanyAffiliateApplicationLoading,
    handleConfirmApplication,
    confirmCompanyAffiliateApplicationLoading,
    handleSuspendApplication,
    suspendCompanyAffiliateApplicationLoading,
  } = useButtonsHandlers(companyAffiliateApplicationId);

  return (
    <div className={styles.btnContainer}>
      {hasAssignButton && (
        <Button
          className={styles.btn}
          onClick={handleAssignApplication}
          type="submit"
          isLoading={assignCompanyAffiliateApplicationLoading}
          theme={ButtonTheme.Success}
        >
          {`Взять${nbsp}в${nbsp}работу`}
        </Button>
      )}
      {hasConfirmButton && (
        <Button
          className={styles.btn}
          onClick={handleConfirmApplication}
          type="submit"
          isLoading={confirmCompanyAffiliateApplicationLoading}
          disabled={!isProcessedAffiliate(state.affiliatesInProgress)}
          theme={ButtonTheme.Success}
        >
          Подтвердить
        </Button>
      )}
      {hasSuspendButton && (
        <Button
          className={styles.btn}
          onClick={handleSuspendApplication}
          type="submit"
          isLoading={suspendCompanyAffiliateApplicationLoading}
          theme={ButtonTheme.Warning}
        >
          {`В${nbsp}ожидание`}
        </Button>
      )}
    </div>
  );
};
