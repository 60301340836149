import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { useGetRewardsColumns } from './hooks/useGetRewardsColumns';
import { RewardData } from '../../queries/generated/RewardData';
import { useOpenModal } from '../../hooks/useOpenModal';

interface RewardsTableProps {
  rewards?: Array<RewardData>;
}

export const RewardsTable: FC<RewardsTableProps> = ({ rewards }) => {
  const { columns } = useGetRewardsColumns();
  const { openModal } = useOpenModal();

  return (
    <BlockTable
      onClickOnRow={(reward, initiatorId) => openModal({ reward, initiatorId })}
      columns={columns}
      data={rewards}
      isFullScreenTable
    />
  );
};
