import { forwardRef, HTMLProps } from 'react';
import {
  FloatingPortal,
  useMergeRefs,
  useTransitionStyles,
  FloatingArrow,
} from '@floating-ui/react';
import classNames from 'classnames';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TooltipTheme } from 'ui/Tooltip/types';
import styles from './TooltipContent.module.scss';
import { useTooltipState } from '../../hooks/useTooltipState';

const ANIMATION_DURATION = 250;

interface TooltipContentProps extends HTMLProps<HTMLDivElement> {
  tooltipTheme?: TooltipTheme;
  arrowOffset?: string | number | null;
}

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
  (
    { className, tooltipTheme = TooltipTheme.Dark, arrowOffset, ...props },
    propRef
  ) => {
    const state = useTooltipState();
    const ref = useMergeRefs([state.refs.setFloating, propRef]);

    const { isMounted, styles: transitionStyles } = useTransitionStyles(
      state.context,
      {
        duration: ANIMATION_DURATION,
        initial: {
          opacity: 0,
        },
      }
    );

    if (!isMounted) return null;

    const handleCloseClick = () => {
      state.setOpen(false);
    };

    return (
      <FloatingPortal>
        <div
          className={classNames(
            styles.tooltip,
            className,
            tooltipTheme === TooltipTheme.Dark ? styles.dark : styles.light
          )}
          ref={ref}
          style={{
            ...state.floatingStyles,
            ...props.style,
            ...transitionStyles,
          }}
          {...state.getFloatingProps(props)}
        >
          <div>
            {props.children}
            {state.button?.shouldShow && (
              <Button
                theme={ButtonTheme.Process}
                size={ButtonSize.Small}
                className={styles.button}
                onClick={state.button.onClick}
              >
                {state.button.text || 'Перейти'}
              </Button>
            )}
          </div>
          {state.clickable && (
            <div
              onClick={handleCloseClick}
              className={styles.closeIconContainer}
            >
              <FontAwesomeIcon
                role="button"
                data-testid="closeButton"
                className={styles.closeIcon}
                icon={faTimes}
              />
            </div>
          )}
          <FloatingArrow
            ref={state.arrowRef}
            height={5}
            context={state.context}
            staticOffset={arrowOffset}
            className={styles.tooltipArrow}
          />
        </div>
      </FloatingPortal>
    );
  }
);
