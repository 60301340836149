import { FC, ReactElement, ReactNode } from 'react';
import { SidebarLoader } from 'ui/SidebarLoader';
import { LoadMoreStatus } from 'commonComponents/LoadMoreStatus';
import { useInfiniteScroll } from 'src/utils/hooks/useInfiniteScroll';
import classnames from 'classnames';
import styles from './MediaItemsList.module.scss';
import { TopBlockMarginBottomSize } from './const';

interface ModalItemsListProps {
  topBlock?: ReactNode;
  topBlockClassName?: string;
  topBlockMarginBottomSize?: TopBlockMarginBottomSize;
  itemsListContent?: Array<ReactElement>;
  loading: boolean;
  paginationConfig: {
    loadMore: () => void;
    hasNextPage: boolean;
  };
}

export const ModalItemsList: FC<ModalItemsListProps> = ({
  topBlock,
  topBlockClassName,
  topBlockMarginBottomSize = TopBlockMarginBottomSize.Large,
  loading,
  paginationConfig,
  itemsListContent,
}) => {
  const isListEmpty = !itemsListContent?.length;
  const { loadMore, hasNextPage } = paginationConfig;

  const loadTrigger = useInfiniteScroll(loadMore);

  return (
    <SidebarLoader loading={loading && isListEmpty}>
      {topBlock && (
        <div
          className={classnames(
            !isListEmpty && styles[topBlockMarginBottomSize],
            topBlockClassName && topBlockClassName
          )}
          data-testid="topBlock"
        >
          {topBlock}
        </div>
      )}
      {itemsListContent}
      {loadTrigger}
      {!isListEmpty && (
        <LoadMoreStatus
          fetchMore={loadMore}
          isLoading={loading}
          isEndOfList={!hasNextPage}
          messages={{
            complete: 'Вы достигли конца списка',
          }}
        />
      )}
    </SidebarLoader>
  );
};
