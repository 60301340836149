import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { LastRestrictionsData } from './LastRestrictionsData';
import { LastBadBankRestrictionData } from './LastBadBankRestrictionData';
import { LastRestrictionData } from './LastRestrictionData';
import { LastChargeBackRestrictionData } from './LastChargeBackRestrictionData';
import { LastReceiveGiftRestrictionData } from './LastReceiveGiftRestrictionData';
import { LastWithdrawalRestrictionData } from './LastWithdrawalRestrictionData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetLastRestrictionsVariables = SchemaTypes.Exact<{
  playerId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetLastRestrictions = { __typename: 'Query', player: { __typename: 'Player', id: string, lastRestrictions: { __typename: 'LastRestrictions', badBankRestriction?: { __typename: 'BadBankRestriction', canceledAt?: string | null, createdAt: string, id: string, reason?: string | null, updatedAt: string } | null, chargeBackRestriction?: { __typename: 'ChargeBackRestriction', canceledAt?: string | null, createdAt: string, id: string, reason?: string | null, updatedAt: string } | null, receiveGiftRestriction?: { __typename: 'ReceiveGiftRestriction', bypassRestrictionIfApproved: boolean, canceledAt?: string | null, createdAt: string, id: string, reason: string, updatedAt: string } | null, withdrawalRestriction?: { __typename: 'WithdrawalRestriction', bypassRestrictionIfApproved: boolean, canceledAt?: string | null, createdAt: string, id: string, reason: string, updatedAt: string } | null } } };


export const GetLastRestrictionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLastRestrictions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastRestrictions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LastRestrictionsData"}}]}}]}}]}},...LastRestrictionsData.definitions,...LastBadBankRestrictionData.definitions,...LastRestrictionData.definitions,...LastChargeBackRestrictionData.definitions,...LastReceiveGiftRestrictionData.definitions,...LastWithdrawalRestrictionData.definitions]} as unknown as DocumentNode;

/**
 * __useGetLastRestrictions__
 *
 * To run a query within a React component, call `useGetLastRestrictions` and pass it any options that fit your needs.
 * When your component renders, `useGetLastRestrictions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastRestrictions({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetLastRestrictions(baseOptions: ApolloReactHooks.QueryHookOptions<GetLastRestrictions, GetLastRestrictionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLastRestrictions, GetLastRestrictionsVariables>(GetLastRestrictionsDocument, options);
      }
export function useGetLastRestrictionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLastRestrictions, GetLastRestrictionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLastRestrictions, GetLastRestrictionsVariables>(GetLastRestrictionsDocument, options);
        }
export type GetLastRestrictionsHookResult = ReturnType<typeof useGetLastRestrictions>;
export type GetLastRestrictionsLazyQueryHookResult = ReturnType<typeof useGetLastRestrictionsLazyQuery>;
export type GetLastRestrictionsQueryResult = Apollo.QueryResult<GetLastRestrictions, GetLastRestrictionsVariables>;