import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { CpaReferralLinkReportData } from '../../queries/generated/CpaReferralLinkReportData';
import { useGetCpaReferralLinkReportColumns } from './hooks/useGetCpaReferralLinkReportColumns';

interface CpaReferralLinkReportTableProps {
  report?: Array<CpaReferralLinkReportData>;
  hasTotalRow: boolean;
}

export const CpaReferralLinkReportTable: FC<
  CpaReferralLinkReportTableProps
> = ({ report, hasTotalRow }) => {
  const { columns } = useGetCpaReferralLinkReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={report}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
