import { FC, useMemo } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { generatePath } from 'react-router';
import { Route } from 'src/router/routes.const';
import { useGetExternalMoneyTransfersColumns } from './components/ExternalMoneyTransfersTable/hooks/useGetExternalMoneyTransfersColumns';
import { ExternalMoneyTransfersTable } from './components/ExternalMoneyTransfersTable';

interface ExternalMoneyTransfersProps {
  id: string;
}

const Component: FC<ExternalMoneyTransfersProps> = ({ id }) => {
  const { columns } = useGetExternalMoneyTransfersColumns(false);

  const routeToCustomPage = useMemo(
    () => generatePath(Route.PLAYER_EXTERNAL_MONEY_TRANSFERS, { id }),
    [id]
  );

  return (
    <ExternalMoneyTransfersTable
      id={id}
      columns={columns}
      routeToCustomPage={routeToCustomPage}
      isFullScreenTable={false}
    />
  );
};

export const ExternalMoneyTransfersBlock = withBlockProvider(Component);
