import { FC } from 'react';
import DropdownWithQuery from 'ui/DropdownWithQuery/DropdownWithQuery';
import { useGetCompaniesQuery } from './hooks/useGetCompaniesQuery';
import { getCompaniesOptions } from './helpers';

interface CompaniesDropdownProps {
  name: string;
  label: string;
  onChange?: (value: string | Array<string>) => void;
}

export const CompaniesDropdown: FC<CompaniesDropdownProps> = ({
  name,
  label,
  onChange,
}) => {
  const {
    loadCompaniesWithThrottle,
    data,
    loading,
    hasNextPage,
    fetchMore,
    error,
  } = useGetCompaniesQuery();

  const companiesOptions = getCompaniesOptions(data?.companies.edges);

  return (
    <DropdownWithQuery
      onChange={onChange}
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMore();
          }
        },
        hasNextPage,
      }}
      loading={loading}
      loadingError={error}
      name={name}
      label={label}
      options={companiesOptions}
      searchMinLength={3}
      loadList={loadCompaniesWithThrottle}
    />
  );
};
